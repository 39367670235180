import React, { FC, useCallback } from 'react'

type Props = {
  value?: any
  title?: string
}

const HtmlMediaTypeNodeInfo: FC<Props> = ({ value, title }) => {
  return <div dangerouslySetInnerHTML={{ __html: value }} />
}

export default HtmlMediaTypeNodeInfo
