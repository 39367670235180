import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { HydrogenState } from '../types/HydrogenState'
import { hydrogenState } from './hydrogenState'

export type SetHydrogenStateFunction = (newValue: HydrogenState) => void

export const useGetHydrogenState = (): HydrogenState => {
  return useRecoilValue(hydrogenState)
}

export const useSetHydrogenState = (): SetHydrogenStateFunction => {
  return useSetRecoilState(hydrogenState)
}

export const useHydrogenState = (): [HydrogenState, SetHydrogenStateFunction] => {
  return useRecoilState(hydrogenState)
}
