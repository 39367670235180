import { JSONSchema7Definition } from 'json-schema'
import { toConstant } from './toConstant'

export function optionsList(schema: JSONSchema7Definition): any[] {
  if (typeof schema === 'boolean' || !schema) {
    return []
  }

  if (schema.enum && Array.isArray(schema.enum)) {
    const uniqueSet = new Set(schema.enum)
    return Array.from(uniqueSet).map((value, i) => {
      const label = ((schema as any).enumNames && (schema as any).enumNames[i]) || String(value)
      return { label, value }
    })
  } else {
    const altSchemas = schema.oneOf || schema.anyOf
    if (Array.isArray(altSchemas)) {
      return altSchemas.map((schema, i) => {
        const value = toConstant(schema)
        const label = (schema as any).title || String(value)
        return { label, value }
      })
    }

    return []
  }
}
