import { JSONSchema7TypeName } from 'json-schema'

export const getInstanceTypeName = (content: any): JSONSchema7TypeName | undefined => {
  const contentTypeOf = typeof content
  if (
    !content &&
    contentTypeOf !== 'boolean' &&
    contentTypeOf !== 'string' &&
    contentTypeOf !== 'number' &&
    content !== '' &&
    content !== 0
  ) {
    return 'null'
  }

  switch (contentTypeOf) {
    case 'bigint':
    case 'number':
      return 'number'
    case 'boolean':
      return 'boolean'
    case 'object':
      if (Array.isArray(content)) {
        return 'array'
      }
      return 'object'

    case 'string':
      return 'string'
    default:
      return 'null'
  }

  return undefined
}
