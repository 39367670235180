import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Button,
  Flex,
  Menu,
  MenuItem,
  MenuList,
  ScaleFade,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import MediaCard from './MediaCard'
import { Media } from '../../../core/cms/media/Media'

type MediaExplorerGridProps = {
  medias: any
  onClick?: (media: Media) => void
  loaded?: boolean
}

type RefType = any

const MediaExplorerGrid = React.forwardRef<RefType, MediaExplorerGridProps>((props, ref) => {
  const { medias, loaded } = props
  const [gridVisible, setGridVisibile] = useState<boolean>(false)
  const [selectedMedia, setSelectedMedia] = useState<Media | null>(null)

  const handleOnClick = useCallback(
    (media: Media): void => {
      if (props.onClick) {
        props.onClick(media)
      }
    },
    [props]
  )

  useEffect(() => {
    setGridVisibile(false)
    setTimeout(() => {
      setGridVisibile(true)
    }, 10)
  }, [medias])

  return (
    <ScaleFade in={loaded || gridVisible}>
      <SimpleGrid columns={[2, 2, 3, 3, 4]} spacing={4} w="100%" pb="5" ref={ref}>
        {/* First Card (add,import,new folder) */}

        {medias &&
          medias.map((media: any, index: number) => {
            return <MediaCard key={index} onClick={handleOnClick} media={media} />
          })}
      </SimpleGrid>
    </ScaleFade>
  )
})

export default MediaExplorerGrid
