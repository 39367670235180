import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Box, chakra, Divider, Fade, Flex, SlideFade, useDisclosure } from '@chakra-ui/react'
import { Resizable, ResizeCallback } from 're-resizable'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useClient } from 'urql'
import ErrorAlert from '../base/ErrorAlert'
import Loading from '../base/Loading'
import SourceWorkbenchRightPanel from './SourceWorkbenchRightPanel'
import { useSourceWorkbenchState } from '../../core/source-workbench/useSourceWorkbenchState'
import {
  gql_EnumSourceSchemaMode,
  gql_GetListEnvironmentsQuery,
  gql_GetListSourcesQuery,
  gql_GetListSourcesQueryVariables,
  useGetProjectByIdQuery,
  useGetSourceByIdQuery,
} from '../../core/graphql'
import { QUERY_GET_LIST_ENVIRONMENTS, QUERY_GET_LIST_SOURCES } from '../../queries'
import EditorPanel from '../workbench/EditorPanel'
import { useSchema } from '../../core/schema/useSchema'
import { useContent } from '../../core/contents/useContent'
import SourceNodesPreview from './SourceNodesPreview'
import ExternalPreview from './ExternalPreview'

export const QUERY_PARAM_ENV_KEY = 'env'
export const QUERY_PARAM_LANG_KEY = 'lang'

type Props = {}

export const SourceWorkbench: FC<Props> = (props) => {
  const [sourceWorkbenchState, setSourceWorkbenchState] = useSourceWorkbenchState()
  const { sourceCode, bottomPanel, rightPanel } = sourceWorkbenchState
  const { params } = useRouteMatch<{ projectId: string; id: string }>()
  const { projectId, id } = params
  const [environmentId, setEnvironmentId] = useQueryParam(QUERY_PARAM_ENV_KEY, StringParam)
  const [locale, setLocale] = useQueryParam(QUERY_PARAM_LANG_KEY, StringParam)

  // Initialize source, content and schema
  const [projectResult, refetchProject] = useGetProjectByIdQuery({
    pause: !projectId,
    variables: {
      id: projectId,
    },
  })
  const [sourceResult, refetchSource] = useGetSourceByIdQuery({
    pause: !id,
    variables: {
      id,
    },
  })
  const { data, fetching, error } = sourceResult
  const { reading: readingContent, initialized: contentInitialized, readContent } = useContent({
    projectId,
    sourceId: id,
    locale: locale!,
    environmentId: environmentId!,
  })
  const { reading: readingSchema, initialized: schemaInitialized } = useSchema({
    projectId,
    sourceId: id,
    environmentId: environmentId!,
  })

  const [isResizing, setIsResizing] = useState(false)
  const [previewSize, setPreviewSize] = useState<{ width: number; height: number }>()
  const previewRef = useRef<Resizable>()
  const { isOpen: isPreviewOpen, onToggle: onPreviewToggle } = useDisclosure()

  const handleExpandPreview = useCallback(() => {
    if (previewRef.current && previewRef.current.resizable) {
      if (previewRef.current.resizable.style.height == '100vh')
        return setPreviewSize({ width: window.innerWidth, height: 0 })
      previewRef.current.resizable.style.height = '100vh'
      setPreviewSize({ width: window.innerWidth, height: window.innerHeight })
    }
  }, [previewRef])

  const handleTooglePreview = useCallback(() => {
    if (previewRef.current && previewRef.current.resizable) {
      if (parseFloat(previewRef.current.resizable.style.height) <= 25) {
        previewRef.current.resizable.style.height = '100vh'
        setPreviewSize({ width: window.innerWidth, height: window.innerHeight })
      } else {
        previewRef.current.resizable.style.height = '0px'
        setPreviewSize({ width: window.innerWidth, height: 0 })
      }
    }
    onPreviewToggle()
  }, [previewRef])

  const handlePreviewResizeStart = useCallback(() => {
    setIsResizing(true)
    if (previewRef.current) {
      setPreviewSize({
        width: previewRef.current.size.width,
        height: previewRef.current.size.height,
      })
    }
  }, [])

  const handlePreviewResizeStop = useCallback<ResizeCallback>((e, direction, ref, d) => {
    setIsResizing(false)
    if (previewRef.current) {
      setPreviewSize({
        width: previewRef.current.size.width,
        height: previewRef.current.size.height,
      })
    }

    // setSourceWorkbenchState({
    //   ...sourceWorkbenchState,
    //   bottomPanel: {
    //     ...sourceWorkbenchState.bottomPanel,
    //     size: d,
    //   },
    // })
  }, [])

  const handleRightPanelResizeStop = useCallback<ResizeCallback>((e, direction, ref, d) => {
    // setSourceWorkbenchState({
    //   ...sourceWorkbenchState,
    //   rightPanel: {
    //     ...sourceWorkbenchState.rightPanel,
    //     size: d,
    //   },
    // })
  }, [])

  // useEffect(() => {
  //   if (!id) {
  //     setSourceWorkbenchState({
  //       ...sourceWorkbenchState,
  //       currentSourceId: undefined,
  //     })
  //   } else if (id && data?.sourceById?.id) {
  //     let newLocale
  //     if (sourceWorkbenchState.currentSourceLocale) {
  //       const foundedLocale = data.sourceById.locales?.find(
  //         (item) => item?.locale === sourceWorkbenchState.currentSourceLocale
  //       )
  //       if (foundedLocale && foundedLocale.locale) {
  //         newLocale = foundedLocale.locale
  //       } else if (data.sourceById.defaultLocale === sourceWorkbenchState.currentSourceLocale) {
  //         newLocale = data.sourceById.defaultLocale
  //       }
  //     }

  //     if (!newLocale) {
  //       newLocale = data.sourceById.defaultLocale
  //     }

  //     if (!newLocale) {
  //       newLocale = projectResult.data?.project?.defaultLocale
  //     }

  //     setSourceWorkbenchState({
  //       ...sourceWorkbenchState,
  //       currentSourceId: data.sourceById.id,
  //       currentSourceLocale: newLocale,
  //     })
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data?.sourceById, projectId])

  // useEffect(() => {
  //   if (sourceWorkbenchState.currentSourceLocale && contentInitialized && !readingContent) {
  //     console.log(sourceWorkbenchState.currentSourceLocale)
  //     readContent()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sourceWorkbenchState.currentSourceLocale])

  if (error) {
    return <ErrorAlert errorMessage={error.message} />
  }

  if (fetching || readingContent || readingSchema || !id) {
    return <Loading title="Loading Workbench" h="100vh" />
  }

  // if (!source && mode === WorkbenchMode.SOURCE_EDITOR_MODE) {
  //   /** For some reason the user has entered the workbench but doesn't have any source in the project.
  //   /** We'll show a create Source modal so the user is not blocked */
  //   return (
  //     <Box display="flex" h="100vh" w="100%" overflow="hidden" flexDirection="row">
  //       <SourceSettingsModal isOpen isCentered onClose={handleCreateSourceModalClose} project={project!} />
  //     </Box>
  //   )
  // }

  return (
    <Box display="flex" h="100vh" w="100%" overflow="hidden" flexDirection="row">
      <Flex display="flex" flexDirection="column" w="100%" overflow="hidden" zIndex="0">
        <SourceNodesPreview
          locale={locale!}
          sourceId={id}
          projectId={projectId}
          environmentId={environmentId!}
        />

        {data?.sourceById?.schemaMode === gql_EnumSourceSchemaMode.Components && (
          <EditorPanel
            ref={previewRef}
            style={{
              display: 'flex',
              // overflow: 'hidden',
              width: '100%',
              flexDirection: 'column',
              boxShadow: '0 4px -6px -1px rgba(0, 0, 0, 0.1), 0 2px -4px -1px rgba(0, 0, 0, 0.06)',
              zIndex: 3,
              // maxHeight: !isOpen ? '0px' : '100vh',
            }}
            dragDirection="top"
            minHeight="54px"
            maxHeight="calc(100vh - 60px)"
            defaultSize={
              bottomPanel.size || {
                width: '100%',
                height: '54px',
              }
            }
            onResizeStart={handlePreviewResizeStart}
            onResizeStop={handlePreviewResizeStop}
          >
            <ExternalPreview
              sourceId={id}
              projectId={projectId}
              locale={locale!}
              environmentId={environmentId!}
              onToggle={handleTooglePreview}
              onExpand={handleExpandPreview}
              isPanelResizing={isResizing}
              isOpen={isPreviewOpen}
            />
          </EditorPanel>
        )}
      </Flex>

      <EditorPanel
        dragDirection="left"
        defaultSize={rightPanel.size || { width: '480px', height: 'auto' }}
        onResizeStop={handleRightPanelResizeStop}
        minWidth="420px"
      >
        <SourceWorkbenchRightPanel
          sourceId={id}
          projectId={projectId}
          environmentId={environmentId!}
          locale={locale!}
          minW="320px"
        />
      </EditorPanel>
    </Box>
  )
}

const SourceWorkbenchLoader: FC = () => {
  const history = useHistory()
  const urqlClient = useClient()
  const { url, params } = useRouteMatch<{ projectId: string; id?: string }>()
  const { projectId, id } = params
  const [{ fetching, data }, refetchProject] = useGetProjectByIdQuery({
    variables: { id: projectId },
    pause: !projectId,
  })
  const [environmentId, setEnvironmentId] = useQueryParam(QUERY_PARAM_ENV_KEY, StringParam)
  const [locale, setLocale] = useQueryParam(QUERY_PARAM_LANG_KEY, StringParam)

  useEffect(() => {
    // this route is available also if no source is selected,
    // here we select one source automatically and redirect the user to that source
    const init = async () => {
      try {
        if (!id || !environmentId || !locale) {
          let newSourceId = id
          let newEnvironmentId = environmentId
          let newLang = locale

          if (!id) {
            const foundedSource = await urqlClient
              .query<gql_GetListSourcesQuery, gql_GetListSourcesQueryVariables>(
                QUERY_GET_LIST_SOURCES,
                {
                  page: 0,
                  perPage: 1,
                  filter: {
                    project: projectId,
                  },
                }
              )
              .toPromise()
            if (
              foundedSource.data?.sourcePagination &&
              foundedSource.data.sourcePagination?.items &&
              foundedSource.data.sourcePagination?.items.length > 0
            ) {
              newSourceId = foundedSource.data.sourcePagination.items[0]?.id
            }
          }

          if (!environmentId) {
            const foundedEnvironments = await urqlClient
              .query<gql_GetListEnvironmentsQuery, gql_GetListSourcesQueryVariables>(
                QUERY_GET_LIST_ENVIRONMENTS
              )
              .toPromise()

            if (
              foundedEnvironments.data?.environmentPagination?.items &&
              foundedEnvironments.data.environmentPagination.items.length > 0
            ) {
              newEnvironmentId = foundedEnvironments.data.environmentPagination.items[0].id
            }
          }

          if (!newLang && data?.project?.locales && data?.project?.locales.length > 0) {
            newLang = data?.project?.locales[0]
          }

          history.replace(
            `${url}/${newSourceId}?${QUERY_PARAM_ENV_KEY}=${newEnvironmentId}&${QUERY_PARAM_LANG_KEY}=${newLang}`
          )
        }
      } catch (error) {
        console.log('error', error)
      }
    }

    if (!fetching && data?.project?.id) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, fetching, id, environmentId, locale])

  if (!id) {
    return <Loading title="Loading Workbench" boxSize="100%" icon="FaTools" iconCollection="fa" />
  }

  return <SourceWorkbench />
}

export default SourceWorkbenchLoader
