import React, { FC, useCallback } from 'react'
import {
  Badge,
  chakra,
  Divider,
  Flex,
  FormLabel,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Switch,
  Text,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import { ID } from '../../core/types/BaseCRUD'
import { useSourceWorkbenchState } from '../../core/source-workbench/useSourceWorkbenchState'
import { useGetListEnvironmentsQuery } from '../../core/graphql'

type Props = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  [prop: string]: any
}

const OptionsMenu: FC<Props> = ({ sourceId, projectId, environmentId }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const [sourceWorkbenchState, setSourceWorkbenchState] = useSourceWorkbenchState()
  const [envResult, refetchEnv] = useGetListEnvironmentsQuery({
    variables: { filter: { project: projectId } },
  })

  const { data: envData, fetching: fetchingEnv, error: envError } = envResult

  const handleToggleShowHistory = useCallback(() => {
    setSourceWorkbenchState({
      ...sourceWorkbenchState,
      showHistory: !sourceWorkbenchState.showHistory,
    })
  }, [setSourceWorkbenchState, sourceWorkbenchState])

  // const handleSetEnvironment = useCallback(
  //   (selectedEnvironmentId: string | undefined) => () => {
  //     setWorkbenchState({
  //       ...workbenchState,
  //       selectedEnvironmentId,
  //     })
  //   },
  //   [setWorkbenchState, workbenchState]
  // )

  if (fetchingEnv) {
    return (
      <BaseButton
        boxSize="50px"
        size="md"
        disabled
        isLoading
        alignSelf="center"
        lightBgColor="light.50"
        // onClick={(event: any): void => {}}
        boxShadow="none"
      />
    )
  }

  return (
    <Menu placement="bottom-end">
      <BaseButton
        as={MenuButton}
        h="40px"
        w="50px"
        size="md"
        alignSelf="center"
        // onClick={(event: any): void => {}}
        boxShadow="none"
        lightBgColor="light.50"
        p={0}
      >
        <BaseIcon name="HiMenuAlt3" collection="hi" />
      </BaseButton>
      <Portal>
        <MenuList borderWidth={1} border="0px solid" shadow="lg">
          <MenuOptionGroup title="Menu">
            <MenuItem
              display="flex"
              flexDirection="row"
              w="100%"
              alignItems="center"
              icon={<BaseIcon name="RiRocket2Fill" collection="ri" fontSize="lg" />}
              color="purple.500"
            >
              <Text alignSelf="center">Publish</Text>
            </MenuItem>
            <MenuItem
              display="flex"
              flexDirection="row"
              w="100%"
              alignItems="center"
              onClick={handleToggleShowHistory}
              icon={<BaseIcon name="BsClockHistory" collection="bs" fontSize="lg" />}
            >
              <Text alignSelf="center">
                {sourceWorkbenchState.showHistory ? 'Hide' : ''} History
              </Text>
            </MenuItem>
            <MenuItem
              display="flex"
              flexDirection="row"
              w="100%"
              alignItems="center"
              icon={<BaseIcon name="FaFileImport" collection="fa" fontSize="md" />}
            >
              <Text alignSelf="center">Import</Text>
            </MenuItem>
            <MenuItem
              display="flex"
              flexDirection="row"
              w="100%"
              alignItems="center"
              icon={<BaseIcon name="FaBolt" collection="fa" fontSize="md" />}
              color="blue.500"
            >
              <Text alignSelf="center">Export</Text>
            </MenuItem>
          </MenuOptionGroup>
          <Divider my={2} />
          <MenuOptionGroup title="Environment" defaultValue={environmentId} type="radio">
            {/* <MenuItemOption
              value={undefined}
              display="flex"
              flexDirection="row"
              w="100%"
              alignItems="center"
              // onClick={handleSetEnvironment(undefined)}
              color="green.500"
              icon={<BaseIcon color="green.500" name="MdPublic" collection="md" fontSize="lg" />}
            >
              <Text textTransform="capitalize" w="full">
                Live
              </Text>
            </MenuItemOption> */}

            {envData?.environmentPagination?.items?.map((item, index) => {
              return (
                <MenuItemOption
                  value={item.id}
                  key={`environment-${item.id}`}
                  display="flex"
                  flexDirection="row"
                  w="100%"
                  alignItems="center"
                  // onClick={handleSetEnvironment(item.id)}
                  icon={<BaseIcon name="BiCheckCircle" collection="bi" fontSize="sm" />}
                >
                  <Text textTransform="capitalize" w="full">
                    {item.name}
                  </Text>
                </MenuItemOption>
              )
            })}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default OptionsMenu
