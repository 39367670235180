import React, { cloneElement, FC, useCallback, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Text,
} from '@chakra-ui/react'
import SchemaField from './SchemaField'
import { FieldProps } from '../../core/types/FieldProps'
import BaseButton from '../base/BaseButton'

type Props = FieldProps

const ObjectField: FC<Props> = ({
  value,
  schema,
  sourceId,
  path,
  schemaNodes,
  schemaPath,
  rootSchema,
  onChange,
  label,
}) => {
  const availableProps = useMemo(() => {
    if (typeof schema === 'boolean') {
      return []
    }

    if (schema && schema.properties) {
      return Object.keys(schema.properties)
    }

    return []
  }, [schema])

  const splittedPath = useMemo(() => {
    if (path === '/' || !path) {
      return []
    }
    const splitted = path.split('/')
    splitted.splice(0, 1)
    return splitted
  }, [path])

  const finalLabel = useMemo(() => {
    if (splittedPath.length === 0 || !path) {
      if (label) return label
      return 'root'
    }

    return splittedPath[splittedPath.length - 1]
  }, [label, path, splittedPath])

  const openedIndex = { defaultIndex: 0 }

  return (
    <>
      {availableProps.map((property, index) => {
        return (
          <SchemaField
            key={`node-${path.replace('/', '-')}-${property}`}
            path={path === '/' ? path + property : `${path}/${property}`}
            onChange={onChange}
            // source={source}
            parentPath={path}
            parentSchemaPath={schemaPath}
            schemaPath={
              schema &&
              typeof schema !== 'boolean' &&
              schema.properties &&
              schema.properties[property]
                ? schemaPath === '/'
                  ? `${schemaPath}properties/${property}`
                  : `${schemaPath}/properties/${property}`
                : schemaPath
            }
            value={value && (value as any)[property] ? (value as any)[property] : undefined}
            sourceId={sourceId}
            schema={
              schema &&
              typeof schema !== 'boolean' &&
              schema.properties &&
              schema.properties[property]
                ? schema.properties[property]
                : undefined
            }
            rootSchema={rootSchema}
            schemaNodes={schemaNodes}
          />
        )
      })}
    </>
  )
}

export default ObjectField
