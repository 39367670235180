import { useDisclosure, ButtonProps } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ID } from '../../../core/types/BaseCRUD'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import EditSourceModal from '../EditSourceModal'
import SourceSettingsModal from '../SourceSettingsModal'

type Props = {
  sourceId: ID
  projectId: ID
} & ButtonProps

const SourceSettingsButton: FC<Props> = ({ sourceId, projectId, ...rest }) => {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure()

  return (
    <BaseButton mr="-px" px={0} onClick={onToggle} lightBgColor="light.50" boxShadow="sm" {...rest}>
      <BaseIcon name="IoEllipsisVertical" collection="io5" fontSize="lg" />
      <EditSourceModal
        isOpen={isOpen}
        onClose={onClose}
        projectId={projectId}
        sourceId={sourceId}
      />
    </BaseButton>
  )
}

export default SourceSettingsButton
