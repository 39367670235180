import { JSONSchema7Definition } from 'json-schema'
import { defaultValues } from './defaultValues'
import { getSchemaTypeName } from './getSchemaTypeName'

/**
 * compute default values for required fields
 *
 * @param schema JsonSchema7
 * @param content Json Content to manage
 */
export const computeRequired = (schema: JSONSchema7Definition, content: any): void => {
  if (typeof schema === 'boolean') {
    return
  }

  if (schema.type !== 'object' || !schema.required || !schema.properties) {
    return
  }

  for (let i = 0; i < schema.required.length; i++) {
    if (!content.hasOwnProperty!(schema.required[i])) {
      const type = getSchemaTypeName(schema.properties[schema.required[i]])
      if (type && !Array.isArray(type)) {
        content[schema.required[i]] =
          !!schema.properties[schema.required[i]] &&
          typeof schema.properties[schema.required[i]] !== 'boolean' &&
          !Array.isArray(schema.properties[schema.required[i]]) &&
          (schema.properties[schema.required[i]] as any).default
            ? (schema.properties[schema.required[i]] as any).default
            : defaultValues[type]
      } else if (type && Array.isArray(type)) {
        content[schema.required[i]] =
          !!schema.properties[schema.required[i]] &&
          typeof schema.properties[schema.required[i]] !== 'boolean' &&
          !Array.isArray(schema.properties[schema.required[i]]) &&
          (schema.properties[schema.required[i]] as any).default
            ? (schema.properties[schema.required[i]] as any).default
            : defaultValues[type[0]]
      }
    }
  }
}
