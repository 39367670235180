import React, { FC, useCallback } from 'react'
import { Box, ButtonGroup, chakra, Divider, Flex, useMultiStyleConfig } from '@chakra-ui/react'
import { ID } from '../../../core/types/BaseCRUD'
import { useSourceWorkbenchState } from '../../../core/source-workbench/useSourceWorkbenchState'
import SaveContentButton from '../../toolbar/SaveContentButton'
import GradientBox from '../../base/GradientBox'
import SourceToolbarMenu from '../SourceToolbarMenu/SourceToolbarMenu'
import GlobalSearch from '../GlobalSearch'
import OptionsMenu from '../OptionsMenu'
import SourceHistoryModal from './SourceHistoryModal'
import SuggestionsPopover from '../SuggestionsPopover'
import ProjectEnvironmentPopover from '../../projects/ProjectEnvironmentPopover'
import SourceNodesPreviewModeSwitch from './SourceNodesPreviewModeSwitch'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'

type Props = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  locale: string
}

const SourceNodesPreviewToolbar: FC<Props> = (props) => {
  const styles = useMultiStyleConfig('PreviewToolbar', props)
  const [sourceWorkbenchState] = useSourceWorkbenchState()

  const { showHistory } = sourceWorkbenchState

  return (
    <chakra.div w="100%" position="sticky" top="0" zIndex="3" minH="60px">
      <Box sx={styles.wrapper}>
        {/* <GradientBox
          position="absolute"
          width="100%"
          top={0}
          left={0}
          right={0}
          maxWidth="100%"
          zIndex="0"
          lightColors={['light.200', 'transparent']}
          darkColors={['blackAlpha.300', 'transparent']}
          transition="height 0.05s"
        /> */}

        <Flex flexDir="column" w="40px">
          <SourceToolbarMenu sourceId={props.sourceId} projectId={props.projectId} />
          <SourceNodesPreviewModeSwitch />
          <Flex flexDir="column">
            <SuggestionsPopover
              locale={props.locale}
              environmentId={props.environmentId}
              projectId={props.projectId}
              sourceId={props.sourceId}
            />
          </Flex>
        </Flex>

        <GlobalSearch
          position="absolute"
          expandible={true}
          left="50%"
          transform="translateX(-50%)"
        />

        {/* Right Buttons */}
        <Box ml="auto" display="flex" zIndex={3}>
          <ProjectEnvironmentPopover />
          {/* Tem Button */}
          <ButtonGroup isAttached boxShadow="sm" borderRadius="md" ml={3}>
            <SaveContentButton {...props} />
            <Divider orientation="vertical" h="auto" zIndex="1" ml="-px" />
            <OptionsMenu {...props} />
          </ButtonGroup>
        </Box>
      </Box>
      <SourceHistoryModal
        isOpen={sourceWorkbenchState.showHistory}
        onClose={() => {}}
        locale={props.locale}
        environmentId={props.environmentId}
        sourceId={props.sourceId}
      />
    </chakra.div>
  )
}

export default SourceNodesPreviewToolbar
