import { chakra, MenuItem, MenuItemProps } from '@chakra-ui/react'
import React, { FC, useCallback, useMemo } from 'react'
import * as locale from 'locale-codes'
import FlagIcon from '../base/FlagIcon'

type Props = {
  locale: string
  onClick: (locale: string) => void
} & Omit<MenuItemProps, 'onClick'>

const SourceLanguageMenuItem: FC<Props> = ({ locale: localeProp, onClick, ...rest }) => {
  const localeInfo = useMemo(() => locale.getByTag(localeProp), [localeProp])
  const label = useMemo(
    () => (localeInfo ? (localeInfo.local ? localeInfo.local : localeInfo.name) : localeProp),
    [localeInfo, localeProp]
  )

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick(localeProp)
    },
    [localeProp, onClick]
  )

  const locationFlag = useMemo(
    () =>
      localeInfo.tag && localeInfo.tag.indexOf('-') !== -1
        ? localeInfo.tag.split('-')[1].toLowerCase()
        : undefined,
    [localeInfo.tag]
  )

  return (
    <MenuItem onClick={handleClick} {...rest}>
      <chakra.div borderRadius="sm" overflow="hidden" d="flex" mr={3}>
        <FlagIcon code={locationFlag as string} />
      </chakra.div>
      {label}
    </MenuItem>
  )
}

export default SourceLanguageMenuItem
