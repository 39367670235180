import React, { FC, useCallback } from 'react'
import { ButtonProps } from '@chakra-ui/react'
import { useContent } from '../../core/contents/useContent'
import { ID } from '../../core/types/BaseCRUD'

import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'

type Props = {
  label?: string
  projectId?: ID
  sourceId: ID
  locale: string
  environmentId: ID
} & ButtonProps

const SaveContentButton: FC<Props> = ({
  label,
  locale,
  sourceId,
  environmentId,
  projectId,
  ...props
}) => {
  const { ...rest } = props

  if (!sourceId) {
    throw new Error('source prop is mandatory')
  }

  const { writing, writeContent, initialized } = useContent({
    sourceId,
    locale,
    environmentId,
  })

  const handleSave = useCallback(async () => {
    if (writing) {
      return
    }

    await writeContent()
  }, [writeContent, writing])

  return (
    <BaseButton
      isLoading={writing || !initialized}
      onClick={handleSave}
      color="green.500"
      lightBgColor="light.50"
      {...rest}
    >
      <BaseIcon name="FiDownloadCloud" fontSize="lg" collection="fi" mr={label && 2} />
      {label}
    </BaseButton>
  )
}

export default SaveContentButton
