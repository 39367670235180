import {
  Text,
  Flex,
  InputGroup,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  AccordionProps,
  chakra,
  useRadio,
  HStack,
  useRadioGroup,
  Tag,
  Textarea,
  Switch,
  Spacer,
  Button,
  Alert,
  Stack,
  InputRightAddon,
  InputRightElement,
  ButtonGroup,
} from '@chakra-ui/react'
import React, { FC, useCallback, useMemo } from 'react'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'
import RadioCard from '../../base/RadioCard'

type ProjectDeployFormProps = {
  projectData?: any[any]
  register: any
}

const ProjectDeployForm: FC<ProjectDeployFormProps> = ({ register, projectData }) => {
  const options = [
    {
      value: 'transfer-protocol',
      title: 'Transfer Protocol',
      icon: 'TiUpload',
      iconCollection: 'ti',
    },
    { value: 'vercel', title: 'Vercel', icon: 'IoLogoVercel', iconCollection: 'io5' },
    { value: 'github', title: 'GitHub', icon: 'FaGithub', iconCollection: 'fa' },
  ]
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'projectType',
    defaultValue: 'transfer-protocol',
    onChange: console.log,
  })
  const group = getRootProps()
  const selectedOption = useMemo(() => {
    return options.find((option) => option.value == value)
  }, [value])

  const [showPassword, setShowPassword] = React.useState(false)
  const handleShowPasswordClick = useCallback(() => setShowPassword(!showPassword), [showPassword])

  return (
    <Stack spacing={8}>
      <FormControl id="deployType">
        <FormLabel>Type</FormLabel>
        <HStack spacing={5} w="100%" justifyContent="space-between" {...group}>
          {options.map((option: any) => {
            const radio = getRadioProps({ value: option.value, enterKeyHint: 0 })
            return (
              <RadioCard key={option.value} {...radio}>
                <BaseIcon
                  name={option.icon}
                  collection={option.iconCollection as any}
                  fontSize="6xl"
                  mb={3}
                />
                <Text
                  fontWeight="bold"
                  fontSize="xl"
                  colorScheme={(radio as any).isChecked ? '' : 'green'}
                  mb={0}
                >
                  {option.title}
                </Text>
                <Text fontSize="sm" lineHeight="1.5">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text>
              </RadioCard>
            )
          })}
        </HStack>
        {selectedOption && selectedOption.iconCollection && (
          <Flex d="flex" borderRadius="md" mt={4}>
            <BaseIcon
              name={selectedOption.icon}
              collection={selectedOption.iconCollection as any}
              mr="2"
            />
            breve spiegazione sul tipo progetto scelto
          </Flex>
        )}
      </FormControl>
      <FormControl id="protocol">
        <FormLabel>Protocol</FormLabel>
        <ButtonGroup size="lg">
          <Button mr="-px">FTP</Button>
          <Button mr="-px">SFTP</Button>
          <Button mr="-px">FTPS</Button>
        </ButtonGroup>
      </FormControl>
      <FormControl id="projectName" mr={6}>
        <FormLabel>Name</FormLabel>
        <InputGroup size="lg">
          <Input
            type="text"
            placeholder="Project name"
            name="name"
            ref={register({ required: false, minLength: 1, maxLength: 50 })}
          />
        </InputGroup>
      </FormControl>
      <FormControl id="url">
        <FormLabel>URL</FormLabel>
        <InputGroup size="lg">
          <Input
            placeholder="Host URL"
            name="url"
            ref={register({ required: false, minLength: 1, maxLength: 50 })}
          />
        </InputGroup>
      </FormControl>
      <FormControl id="username">
        <FormLabel>User</FormLabel>
        <InputGroup size="lg">
          <Input
            placeholder="Username"
            name="username"
            ref={register({ required: false, minLength: 1, maxLength: 50 })}
          />
        </InputGroup>
      </FormControl>
      <FormControl id="password">
        <FormLabel>Password</FormLabel>
        <InputGroup size="lg">
          <Input
            pr="4.5rem"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter password"
            borderWidth="0px"
            name="password"
            ref={register({ required: false, minLength: 1, maxLength: 50 })}
          />
          <InputRightElement borderWidth="0px" margin={0} boxShadow="none" px={2} w="100px">
            <BaseButton size="sm" onClick={handleShowPasswordClick}>
              <BaseIcon name={showPassword ? 'HiEyeOff' : 'HiEye'} collection="hi" mr={2} />
              {showPassword ? 'Hide' : 'Show'}
            </BaseButton>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Stack>
  )
}

export default ProjectDeployForm
