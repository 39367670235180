import React, { cloneElement, FC, isValidElement } from 'react'
import { HashRouter } from 'react-router-dom'

type Props = {
  routerComponent?: React.ReactNode
}

const HydrogenRouter: FC<Props> = ({ routerComponent = <HashRouter />, ...props }) => {
  return isValidElement(routerComponent) ? (
    cloneElement(routerComponent, props)
  ) : (
    <HashRouter {...props} />
  )
}

export default HydrogenRouter
