import { JSONSchema7Definition } from 'json-schema'

export function toConstant(schema: JSONSchema7Definition) {
  if (!schema || typeof schema === 'boolean') {
    return null
  }

  if (schema.enum && Array.isArray(schema.enum) && schema.enum.length === 1) {
    return schema.enum[0]
  } else if (schema.hasOwnProperty!('const')) {
    return schema.const
  } else {
    throw new Error('schema cannot be inferred as a constant')
  }
}
