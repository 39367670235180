import React, { FC, useCallback, useEffect, useMemo } from 'react'
import {
  Collapse,
  FormControl,
  FormLabel,
  Tag,
  Spacer,
  InputGroup,
  InputLeftAddon,
  FormHelperText,
  chakra,
  Switch,
  Text,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react'
import get from 'lodash.get'
import { useFormContext } from 'react-hook-form'
import * as locale from 'locale-codes'
import FlagIcon from '../../base/FlagIcon'
import SlugInput from './SlugInput'

type Props = {
  tag: string
  slugInputName: string
  localeInputName?: string
  activeInputName?: string
  isDefault?: boolean
  projectUrl: string
  onSetAsDefault?: (locale: string) => void
}

const LanguageSlugField: FC<Props> = ({
  tag,
  isDefault,
  slugInputName,
  localeInputName,
  activeInputName,
  projectUrl,
  onSetAsDefault,
}) => {
  const { register, unregister, watch, setValue, getValues, errors } = useFormContext()
  const activeFormValue = watch(activeInputName!)
  const active = useMemo(() => (activeInputName ? activeFormValue : true), [
    activeFormValue,
    activeInputName,
  ])

  const currentLocale = useMemo(() => locale.getByTag(tag), [tag])
  const locationFlag = useMemo(
    () => (tag && tag.indexOf('-') !== -1 ? tag.split('-')[1].toLowerCase() : undefined),
    [tag]
  )

  const handleActiveChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(activeInputName!, e.target.checked)
    },
    [activeInputName, setValue]
  )

  const handleSetAsDefault = useCallback(
    (e: React.MouseEvent) => {
      if (onSetAsDefault) {
        // console.log('cliccato')
        onSetAsDefault(tag)
      }
    },
    [onSetAsDefault, tag]
  )

  useEffect(() => {
    if (activeInputName) {
      register({ name: activeInputName } as any)
      setValue(activeInputName, activeFormValue)
    }

    return () => {
      if (activeInputName) {
        unregister(activeInputName!)
      }
    }
  }, [])

  const slugError = useMemo(() => get(errors, slugInputName), [errors, slugInputName])

  if (!currentLocale) {
    return <div>Error! locale not found</div>
  }

  return (
    <>
      {localeInputName && (
        <input type="hidden" name={localeInputName} defaultValue={tag} ref={register} />
      )}
      <Collapse in={active} unmountOnExit>
        <FormControl id={`source-slug-${tag}`} isInvalid={!!slugError?.message}>
          <FormLabel d="flex" px={0} mx={0} alignItems="center">
            {locationFlag && <FlagIcon code={locationFlag} />}
            <Text fontWeight="bold" textTransform="capitalize" ml={2}>
              {currentLocale.name}
              {currentLocale.local &&
                currentLocale.name !== currentLocale.local &&
                ` (${currentLocale.local})`}
            </Text>
            <Tag ml={1} colorScheme="green">
              Active
            </Tag>
            {isDefault && (
              <Tag colorScheme="blue" ml={1}>
                Default
              </Tag>
            )}
            <Spacer w="100%" />
            {isDefault ? (
              <Switch size="lg" colorScheme="green" isDisabled={true} isChecked={true} />
            ) : (
              <Switch
                size="lg"
                colorScheme="green"
                isChecked={activeFormValue}
                defaultChecked={activeFormValue}
                onChange={handleActiveChange}
              />
            )}
          </FormLabel>
          <InputGroup size="md">
            <InputLeftAddon isTruncated maxW={200}>
              {projectUrl}
            </InputLeftAddon>
            <SlugInput name={slugInputName} />
          </InputGroup>
          <FormErrorMessage>{slugError?.message}</FormErrorMessage>
          <FormHelperText>The source slug rules (A-Z,0-9, no spaces)</FormHelperText>
          {!isDefault && (
            <Flex mt={4} alignItems="center">
              <Text mr={2}>Set as default language</Text>
              <Switch
                size="sm"
                // onChange={handleSetAsDefault}
                isChecked={false}
                onClick={handleSetAsDefault}
                // isChecked={isDefault}
              />
            </Flex>
          )}
        </FormControl>
      </Collapse>
      <Collapse in={!active} unmountOnExit>
        <chakra.div py={0} d="flex" alignItems="center">
          {locationFlag && <FlagIcon code={locationFlag} />}
          <Text fontWeight="bold" textTransform="capitalize" ml={2}>
            {currentLocale.name}
            {currentLocale.local &&
              currentLocale.name !== currentLocale.local &&
              ` (${currentLocale.local})`}
          </Text>
          <Tag ml={1}>Disabled</Tag>
          <Spacer />
          {!isDefault && (
            <Switch size="lg" isChecked={activeFormValue} onChange={handleActiveChange} />
          )}
        </chakra.div>
      </Collapse>
    </>
  )
}

export default LanguageSlugField
