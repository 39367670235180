import {
  Text,
  Popover,
  useDisclosure,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  ButtonProps,
  Button,
} from '@chakra-ui/react'
import React, { ReactElement, ReactNode, useCallback, useState } from 'react'
import FocusLock from 'react-focus-lock'
import BaseIcon from '../../base/BaseIcon'
import LangsList from '../LangsList'

type Props = {
  label: string
} & ButtonProps

const LangSelectButton = (props: Props) => {
  const { label, ...rest } = props
  const [langs, setLangs] = useState([
    { name: 'english', code: 'gb', active: true, isDefault: true },
    { name: 'italiano', code: 'it', active: true, isDefault: false },
    { name: 'espanol', code: 'es', active: false, isDefault: false },
  ])
  const { onOpen, onClose, isOpen } = useDisclosure()
  const firstFieldRef = React.useRef(null)

  return (
    <Popover
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
      trigger="hover"
    >
      <PopoverTrigger>
        <Button size="sm" {...rest}>
          <BaseIcon name="AiOutlinePlus" collection="ai" /> {label && <Text ml={2}>{label}</Text>}
        </Button>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <LangsList firstFieldRef={firstFieldRef} onSelectLangs={(selectedLangs) => {}} />
        </FocusLock>
      </PopoverContent>
    </Popover>
  )
}

export default LangSelectButton
