import React, { FC, useCallback } from 'react'
import { Flex, Text, Box, Link } from '@chakra-ui/react'
import BaseIcon from '../../base/BaseIcon'

type Props = {
  value?: any
  title?: string
}

const EmailNodeInfo: FC<Props> = ({ value, title }) => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation()
  }, [])

  return (
    <Flex flexDir="column" px="2" flex="1" ml="3">
      <Text fontSize="lg" fontWeight="300" textTransform="capitalize">
        {title}
      </Text>
      <Box display="flex" fontSize="xl" fontWeight="bold">
        <Text mr="2">{value}</Text>
        {value && (
          <Link href={`mailto:${value}`} target="_blank" onClick={handleClick}>
            <BaseIcon fontSize="xs" name="FaExternalLinkAlt" />
          </Link>
        )}
      </Box>
    </Flex>
  )
}

export default EmailNodeInfo
