import copy from 'fast-copy'
import { JSONSchema7, JSONSchema7Definition, JSONSchema7Type } from 'json-schema'
import merge from 'deepmerge'
import { Validator } from 'jsonschema'
import { getSchemaTypeName } from '../utils/getSchemaTypeName'
import { isObject } from '../utils/isObject'
import { SchemaBridge } from './SchemaBridge'

const v = new Validator()

export class JsonSchemaBridge implements SchemaBridge {
  initialSchemaResolver(schema: JSONSchema7Definition): JSONSchema7Definition {
    throw new Error('Method not implemented.')
  }

  resolveInstanceSchema(
    schema: JSONSchema7Definition,
    content: JSONSchema7Type
  ): JSONSchema7Definition {
    if (typeof schema === 'boolean') {
      return schema
    }

    if (!schema) {
      return true
    }

    if (schema.oneOf || schema.anyOf) {
      const subSchemaKey = schema.oneOf ? 'oneOf' : 'anyOf'
      if (schema[subSchemaKey] && Array.isArray(schema[subSchemaKey])) {
        const newSchema = copy(schema)
        newSchema[subSchemaKey] = newSchema[subSchemaKey]?.map((item) => ({
          ...(item as any),
          required: [],
        }))

        for (let i = 0; i < (newSchema[subSchemaKey] as any).length; i++) {
          const validationResult = v.validate(content, (newSchema[subSchemaKey] as any)[i], {
            allowUnknownAttributes: true,
            disableFormat: true,
          } as any)
          if (
            validationResult.valid
            // validationResult.errors.filter(
            //   (item) => item.name === 'oneOf' || item.name === 'anyOf' || item.name === 'type'
            // ).length === 0
          ) {
            // console.log('sono quiii validationResult', validationResult.errors, content)
            const mergedSchema = merge(newSchema, (newSchema[subSchemaKey] as any)[i])
            delete mergedSchema[subSchemaKey]
            return this.resolveInstanceSchema(mergedSchema, content)
          }
        }
      }
      // return schema
    }

    let newSchema = copy(schema)
    const schemaType = getSchemaTypeName(newSchema)

    if (schemaType === 'object' && content && isObject(content) && !Array.isArray(content)) {
      const properties = Object.keys(content)
      const dependenciesKeys = Object.keys(
        (newSchema as any) && (newSchema as any).dependencies ? (newSchema as any).dependencies : []
      )

      for (const key of properties) {
        if (dependenciesKeys.indexOf(key) > -1) {
          if (
            (newSchema as any).dependencies[key] &&
            !Array.isArray((newSchema as any).dependencies[key]) &&
            isObject((newSchema as any).dependencies[key])
          ) {
            newSchema = merge(newSchema, (newSchema as any).dependencies[key])
            delete newSchema.dependencies
            return this.resolveInstanceSchema(newSchema, content)
            // newSchema = this.resolveInstanceSchema(newSchema, content) as JSONSchema7
          }
        }

        if (newSchema.properties && newSchema.properties[key]) {
          const itemSchemaTypeName = getSchemaTypeName(newSchema.properties[key])
          // if (
          //   itemSchemaTypeName === 'object' || itemSchemaTypeName=== 'array'){
          //   newSchema.properties[key] = this.resolveInstanceSchema(newSchema.properties[key], (content as any)[key])
          // }
        }
      }
    }
    // else if (schemaType === 'array' && content && Array.isArray(content) && newSchema.items && isObject(newSchema.items) && !Array.isArray(newSchema.items)) {
    // const itemsType = getSchemaTypeName(newSchema.items)
    // if (itemsType === 'object') {
    // for (let i = 0; i < content.length; i++) {
    // ;(newSchema as any).items = this.resolveInstanceSchema(newSchema.items, content[i])
    // }
    // }
    // }

    return newSchema
  }
}
