import React, { FC } from 'react'
import { Badge, Box, BoxProps, Flex, Text, useMultiStyleConfig } from '@chakra-ui/react'
import BaseIcon, { IconsCollections } from '../base/BaseIcon'

type Props = {
  icon?: string
  collection?: IconsCollections
  // icon?: IconDefinition
  typeLabel?: string
} & BoxProps

export const NodeViewIcon: FC<Props> = (props) => {
  const { icon, collection, typeLabel, ...rest } = props
  const styles = useMultiStyleConfig('NodeView', props)

  return (
    <Flex flexDir="column" {...rest}>
      <Flex sx={styles.iconBox}>
        <Box alignSelf="center" w="100%">
          {icon && <BaseIcon collection={collection} name={icon} fontSize="md" />}
        </Box>
        <Text
          fontSize="xs"
          textTransform="capitalize"
          textAlign="center"
          isTruncated
          maxWidth="50px"
        >
          {typeLabel}
        </Text>
      </Flex>
    </Flex>
  )
}

export default NodeViewIcon
