import { Button, chakra, Image, Text, useDisclosure } from '@chakra-ui/react'
import React, { FC, useCallback, useMemo, useRef } from 'react'
import { Media } from '../../core/cms/media/Media'
import { useGetOneMedia } from '../../core/cms/media/useGetOneMedia'
import { ID } from '../../core/types/BaseCRUD'
import { useNodeDelegate } from '../../core/workbench/useNodeDelegate'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import Loading from '../base/Loading'
import MediaExplorerModal from '../../old-components/workbench/MediaExplorer/MediaExplorerModal'
import { FieldProps } from '../../core/types/FieldProps'
import Surface from '../base/Surface'

type Props = FieldProps

const MediaField: FC<Props> = ({ value, onChange, label, path, schema, rootSchema }) => {
  const id = useMemo(() => value as ID, [value])
  const { loading, data } = useGetOneMedia({ id })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<any>()

  const handleImageSelected = useCallback(
    (media: Media) => {
      onChange(path, media.id)
      onClose()
    },
    [onChange, onClose, path]
  )

  const handleRemoveImage = useCallback(() => {
    if (onChange) {
      onChange(path, '')
    }
  }, [onChange, path])

  if (loading) {
    return <Loading />
  }

  return (
    <Surface
      d="flex"
      flexDir="row"
      mb={4}
      p={3}
      lightBgColor="darkAlpha.50"
      darkBgColor="darkAlpha.100"
      borderRadius="md"
      alignItems="center"
    >
      {data && data.urlMedium ? (
        <chakra.div
          boxSize="100px"
          bgColor="darkAlpha.100"
          bgImage={`url(${data.urlMedium})`}
          bgPos="center"
          alt={data.description}
          backgroundSize="cover"
          borderRadius="md"
          onClick={onOpen}
          cursor="pointer"
        />
      ) : (
        <chakra.div
          boxSize="100px"
          bgColor="whiteAlpha.100"
          backgroundSize="cover"
          borderRadius="md"
          cursor="pointer"
        />
      )}
      <chakra.div p="3">
        <Text mb={3}>{data?.name || 'Select image'}</Text>
        <Button ref={btnRef} onClick={onOpen} size="sm" mr={2}>
          <BaseIcon name="BsImages" collection="bs" mr={2} /> Select
        </Button>
        {value && value !== '' && data?.urlMedium && (
          <Button size="sm" color="red.400" onClick={handleRemoveImage}>
            <BaseIcon name="FaTrashAlt" collection="fa" fontSize="sm" />
          </Button>
        )}
      </chakra.div>

      <MediaExplorerModal
        isOpen={isOpen}
        onClose={onClose}
        onClick={handleImageSelected}
        finalFocusRef={btnRef}
      />
    </Surface>
  )
}

export default MediaField
