/* eslint-disable no-underscore-dangle */
import { Client } from 'urql'
import { MUTATION_CREATE_CONTENT, MUTATION_UPDATE_CONTENT, QUERY_GET_CONTENT } from '../../queries'
import { createUrqlClient } from '../../urqlClient'
import {
  gql_GetContentQuery,
  gql_GetContentQueryVariables,
  gql_UpdateContentMutation,
  gql_UpdateContentMutationVariables,
} from '../graphql'
import { ID } from '../types/BaseCRUD'
import { BaseFile, ReadContent, Write } from '../types/BaseFile'

export class HydrogenContentProvider implements BaseFile<string> {
  urqlClient!: Client

  init(client: Client) {
    this.urqlClient = client
  }

  read: ReadContent<string> = async (
    sourceId: ID,
    projectId?: ID,
    environment?: ID,
    locale?: string
  ) => {
    const result = await this.urqlClient
      .query<gql_GetContentQuery, gql_GetContentQueryVariables>(QUERY_GET_CONTENT, {
        sourceId,
        locale,
        environment,
      })
      .toPromise()

    if (result && result.data && result.data.contentOne) {
      return result.data.contentOne.content
    }

    return null
  }

  write: Write<string> = async (
    sourceId: ID,
    locale: string,
    environmentId: ID,
    newContent: any
  ) => {
    const result = await this.urqlClient
      .query<gql_GetContentQuery, gql_GetContentQueryVariables>(
        QUERY_GET_CONTENT,
        {
          sourceId,
          locale,
          environment: environmentId,
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()

    const { data, error } = result

    if (error) {
      return { success: false }
    }

    let writeResult
    if (data && data.contentOne && data.contentOne.id) {
      writeResult = await this.urqlClient
        .mutation<gql_UpdateContentMutation, gql_UpdateContentMutationVariables>(
          MUTATION_UPDATE_CONTENT,
          {
            id: data.contentOne.id,
            record: {
              content: newContent,
              environment: environmentId,
              locale,
            },
          }
        )
        .toPromise()
    } else {
      writeResult = await this.urqlClient
        .mutation(MUTATION_CREATE_CONTENT, {
          record: {
            source: sourceId,
            locale,
            content: newContent,
            environment: environmentId,
          },
        })
        .toPromise()
    }

    if (writeResult.error) {
      return { success: false }
    }

    return {
      success: true,
    }
  }
}
