import { useEffect, useState, useRef, MutableRefObject, RefObject } from 'react'

// Hook
const useHover = (timeout?: number): [MutableRefObject<any>, boolean] => {
  const [value, setValue] = useState(false)

  const ref = useRef()
  const timeoutRef = useRef<any>()

  const handleMouseOver = (): void => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setValue(true)
    }, timeout)
  }
  const handleMouseOut = (): void => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setValue(false)
    }, timeout)
  }

  useEffect(
    () => {
      const node: any = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return (): void => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [ref.current] // Recall only if ref changes
  )

  return [ref, value]
}

export default useHover
