import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  BoxProps,
  Button,
  chakra,
  Flex,
  FlexProps,
  Heading,
  Input,
  Skeleton,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { DropEvent, useDropzone } from 'react-dropzone'
import { Media } from '../../../core/cms/media/Media'
import Surface from '../../../components/base/Surface'
import BaseIcon from '../../../components/base/BaseIcon'
import BaseButton from '../../../components/base/BaseButton'

type Props = {
  onDropFiles: (files: any[], rejectedFiles: any[], event: DropEvent) => void
} & FlexProps

const MediaDropZone: FC<Props> = (props) => {
  const { onDropFiles, ...rest } = props
  const [files, setFiles] = useState<Media[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)

  const handleOnDrop = useCallback(
    (acceptedFiles: any[], rejectedFiles: any[], event: DropEvent) => {
      // const droppedFiles = acceptedFiles.map((file) =>
      // Object.assign(file, {
      //   previewUrl: URL.createObjectURL(file),
      // })
      // )
      setFiles(acceptedFiles)
      onDropFiles(acceptedFiles, rejectedFiles, event)
      // mediaProvider?.create(acceptedFiles[0])
    },
    [onDropFiles]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleOnDrop })
  const styles = useMultiStyleConfig('MediaDropZone', { ...props, isDragActive })

  return (
    <Flex sx={styles.wrapper} {...rest} p={2}>
      <Surface
        {...getRootProps()}
        darkBgColor="darkAlpha.50"
        darkBgHoverColor="darkAlpha.100"
        lightBgColor="darkAlpha.50"
        lightBgHoverColor="darkAlpha.100"
        hoverable
        variant="heavy"
        sx={styles.zone}
        outline="none"
      >
        {isDragActive && (
          <Skeleton
            pos="absolute"
            left={0}
            top={0}
            w="100%"
            h="100%"
            startColor="darkAlpha.100"
            endColor="lightAlpha.100"
          />
        )}
        <Flex
          border="2px dashed"
          borderColor={`${!isDragActive ? 'darkAlpha.100' : 'green.200'}`}
          boxSize="100%"
          borderRadius="md"
          justifyContent="center"
          p={8}
        >
          <Input {...(getInputProps() as any)} sx={styles.input} _focus={{ outline: 'none' }} />
          <Flex flexDir="column" alignContent="center" justifyContent="center">
            <BaseIcon name="FaFileImport" mr={2} fontSize="5xl" mb={4} />
            <Text fontSize="2xl" sx={styles.text}>
              {isDragActive ? 'Drop the files here ...' : "Drag 'n' drop some files here"}
            </Text>
            <Text opacity="0.6"> {!isDragActive && ' or click to select files'}</Text>
            {!isDragActive && (
              <Flex alignSelf="center" mt={3}>
                <BaseButton opacity="0.6"> Select file</BaseButton>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Surface>
    </Flex>
  )
}

export default MediaDropZone
