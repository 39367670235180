import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  chakra,
  Text,
  useColorMode,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import React, { FC, forwardRef, RefAttributes } from 'react'
import BaseButton from './BaseButton'
import BaseIcon from './BaseIcon'
import GradientBox from './GradientBox'
import Surface from './Surface'

type Props = {
  title?: string
  description?: string
  ctaTitle?: string
} & BoxProps

const Empty = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    title = 'No content..',
    description, //= 'Start adding some nodes and fill with data',
    ctaTitle = 'Add first node',
    ...rest
  } = props
  const styles = useMultiStyleConfig('Empty', props)

  return (
    <chakra.div
      d="flex"
      justifyContent="center"
      alignItems="center"
      boxSize="100%"
      position="relative"
      {...rest}
    >
      <GradientBox
        borderRadius="lg"
        minW="320px"
        textAlign="center"
        py={9}
        d="flex"
        flexDir="column"
        alignItems="center"
        lightColors={['light.50', 'transparent']}
        darkColors={['dark.700 5%', 'transparent']}
        pointerEvents="all"
      >
        <BaseIcon name="ImFileEmpty" collection="im" fontSize="5xl" mb={5} />
        <Text mr={2} fontSize="xl" fontWeight="bold" mb={1}>
          {title}
        </Text>
        <Text mr={2} fontSize="xs">
          {description}
        </Text>

        {children}
      </GradientBox>
    </chakra.div>
  )
})

export default Empty
