import React, { FC, useCallback, useEffect } from 'react'
import { Box, BoxProps, Center, chakra, Fade, Flex, Text } from '@chakra-ui/react'
import { JSONSchema7Type } from 'json-schema'
import SchemaNode from '../../nodes/SchemaNode'
import { SchemaNodeKey } from '../../../core/types/NodeProps'
import { useBridgedContent } from '../../../core/contents/useBridgedContent'
import { useSelectedNodeState } from '../../../core/workbench/useSelectedNodeState'
import { useSchema } from '../../../core/schema/useSchema'
import Surface from '../../base/Surface'
import { useNodeDelegate } from '../../../core/workbench/useNodeDelegate'
import Empty from '../../base/Empty'
import Loading from '../../base/Loading'
import { useGetSourceByIdQuery } from '../../../core/graphql'
import { ID } from '../../../core/types/BaseCRUD'
import SourceNodesPreviewToolbar from './SourceNodesPreviewToolbar'
import SuggestedNodes from '../SuggestedNodes'
import { useGetSourceWorkbenchState } from '../../../core/source-workbench/useSourceWorkbenchState'
import SourceCodeEditor from '../SourceCodeEditor'

type Props = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  locale: string
  schemaNodes?: {
    [component in SchemaNodeKey]: React.ReactElement
  }
} & BoxProps

const SourceNodesPreview: FC<Props> = (props) => {
  const { sourceId, projectId, schemaNodes, environmentId, locale, ...rest } = props
  if (!sourceId) {
    throw new Error('source is mandatory in PreviewEditor component')
  }
  const { sourceCode } = useGetSourceWorkbenchState()
  const [result, refetch] = useGetSourceByIdQuery({ variables: { id: sourceId } })
  const { data, fetching } = result
  const { formattedValue, setValue, initialized } = useBridgedContent({
    sourceId,
    locale: locale!,
    environmentId,
  })
  const { schema } = useSchema({ sourceId, projectId, environmentId })
  const [{ path, schemaPath }, setSelectedNode] = useSelectedNodeState()
  const node = useNodeDelegate()

  useEffect(() => {
    if (!formattedValue && path && path !== '/') {
      setSelectedNode({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedValue])

  const handleSetValue = useCallback(
    (path: string, value: JSONSchema7Type) => {
      const newFinalObject = node.setValue(formattedValue, value, path)
      if (newFinalObject) {
        setValue(newFinalObject)
      }
    },
    [formattedValue, node, setValue]
  )

  if (fetching) {
    return <Loading />
  }

  return (
    <Surface
      display="flex"
      flexDir="column"
      h="100%"
      w="100%"
      overflow="hidden"
      alignItems="center"
      zIndex={3}
      position="relative"
      darkVariant="heavy"
      lightBgColor="light.100"
      darkBgColor="transparent"
      minW="40vw"
      minH="55px"
      overflowX="scroll"
      {...rest}
    >
      <Flex overflow="scroll" overflowX="hidden" w="100%" h="100%" flexDir="column" pb={0}>
        <SourceNodesPreviewToolbar
          sourceId={sourceId}
          projectId={projectId}
          environmentId={environmentId}
          locale={locale}
        />
        <Fade in={!sourceCode.showSourceCode}>
          <Flex w="100%" h="100%" justifyContent="center" position="relative">
            {!sourceCode.showSourceCode && (
              <chakra.div
                pt={2}
                pb={5}
                px={7}
                ml={4}
                w="100%"
                maxW={['100%', null, null, '890px']}
                d="flex"
                flexDir="column"
                justifyContent="center"
                justifyItems="center"
                h={
                  !formattedValue &&
                  typeof formattedValue !== 'boolean' &&
                  formattedValue !== 0 &&
                  formattedValue !== ''
                    ? '100%'
                    : ''
                }
              >
                {!initialized && <Loading imageSrc={undefined} mb={6} />}
                {initialized &&
                  !formattedValue &&
                  typeof formattedValue !== 'boolean' &&
                  formattedValue !== 0 &&
                  formattedValue !== '' && (
                    <Center>
                      <Flex flexDir="column">
                        <Empty />
                        <Text my={4}>Suggestions</Text>
                        <SuggestedNodes
                          sourceId={sourceId}
                          projectId={projectId}
                          environmentId={environmentId!}
                          locale={locale}
                          p={0}
                          m={0}
                        />
                      </Flex>
                    </Center>
                  )}

                {initialized && formattedValue && (
                  // <div>per ora no</div>
                  <SchemaNode
                    sourceId={sourceId}
                    value={formattedValue}
                    schema={schema}
                    rootSchema={schema}
                    path="/"
                    schemaPath="/"
                    schemaNodes={schemaNodes}
                    onChange={handleSetValue}
                  />
                )}
              </chakra.div>
            )}
          </Flex>
        </Fade>

        <Fade
          in={initialized && sourceCode.showSourceCode}
          unmountOnExit
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <chakra.div
            pt={3}
            pb={2}
            px={7}
            ml={4}
            minW="280px"
            w="100%"
            h="100%"
            maxW={['100%', null, null, '890px']}
            alignSelf="center"
          >
            <Flex borderRadius="md" overflow="hidden" boxShadow="lg" h="100%">
              <SourceCodeEditor
                sourceId={sourceId}
                projectId={projectId}
                locale={locale}
                environmentId={environmentId}
              />
            </Flex>
          </chakra.div>
        </Fade>
      </Flex>
      {/* <PreviewMessage parsedCode={editor.parsedCode} code={editor.code || ''} /> */}
    </Surface>
  )
}

export default SourceNodesPreview
