import React, { FC } from 'react'
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import Moment from 'react-moment'
import { useContent } from '../../core/contents/useContent'
import { ID } from '../../core/types/BaseCRUD'
import BaseIcon from '../base/BaseIcon'
import GradientBox from '../base/GradientBox'
import SourceLanguageMenu from './SourceLanguageMenu'
import Inspector from './Inspector'
import Surface from '../base/Surface'
import { useGetSourceByIdQuery } from '../../core/graphql'

type Props = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  locale: string
  optionsTabIndex?: number
  minW?: string | number
  maxW?: string | number
  // nodeEditorComponent: ReactElement
  // suggestedNodesComponent: ReactElement
  // onChangeOptionsTabIndex: (index: number) => void
}

const SourceWorkbenchRightPanel: FC<Props> = ({
  optionsTabIndex,
  sourceId,
  projectId,
  environmentId,
  locale,
  minW = '480px',
  maxW,
  // nodeEditorComponent,
  // suggestedNodesComponent,
  // onChangeOptionsTabIndex,
}) => {
  const [sourceResult] = useGetSourceByIdQuery({
    variables: {
      id: sourceId,
    },
  })
  const { data: sourceData, fetching: sourceFetching, error: sourceError } = sourceResult
  // return value && value.length ? (
  //   <Resizable
  //     style={{
  //       display: 'flex',
  //       height: '100vh',
  //       overflow: 'hidden',
  //     }}
  //     defaultSize={{ width: '480px', height: 'auto' }}
  //     enable={{ top: false, right: false, left: true, bottom: false }}
  //     handleComponent={{
  //       left: (
  //         <Divider
  //           orientation="horizontal"
  //           position="absolute"
  //           left="-5px"
  //           h="100%"
  //           borderWidth="1px"
  //           zIndex=""
  //         />
  //       ),
  //     }}
  //   >
  // eslint-disable-next-line react/jsx-tag-spacing
  return (
    <Tabs px={0} h="100%" w="100%" overflow="visible">
      <Surface
        d="flex"
        shadow="sm"
        zIndex={2}
        w="100%"
        minH="60px"
        px="3"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        position="absolute"
        minW={minW}
        maxW={maxW}
        darkBgColor="dark.900"
        lightBgColor="light.100"
      >
        <Flex
          zIndex={1}
          w="100%"
          py={4}
          px={2}
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Text fontSize="lg">
            {(() => {
              if (optionsTabIndex == 0 || !optionsTabIndex) {
                return 'Edit source'
              } else if (optionsTabIndex == 1) {
                return 'Suggestions'
              } else if (optionsTabIndex == 2) {
                return 'Settings'
              }
            })()}
          </Text>
          <Text fontSize="sm" opacity="0.6">
            Updated <Moment fromNow>{sourceData?.sourceById?.updatedAt}</Moment>
          </Text>
        </Flex>
        {/*   <TabList>
           <Tab>
            <BaseIcon fontSize="sm" name="GrStackOverflow" collection="gr" />
          </Tab>
         <Tab>
            <BaseIcon fontSize="sm" name="BsFillGridFill" collection="bs" />
          </Tab> 
        </TabList> */}
        <Flex>
          <SourceLanguageMenu sourceId={sourceId} environmentId={environmentId} />
        </Flex>
      </Surface>
      <TabPanels overflow="scroll" pt={16} mt={4} minW={minW}>
        <TabPanel>
          <Inspector
            locale={locale}
            environmentId={environmentId!}
            sourceId={sourceId}
            projectId={projectId}
          />
        </TabPanel>
        {/* <TabPanel>
          <SuggestedNodes projectId={projectId} sourceId={sourceId} p={3} />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  )
  // ) : // </Resizable>
  // null
}

export default SourceWorkbenchRightPanel
