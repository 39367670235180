import { useRecoilState, useRecoilValue, useSetRecoilState, atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { ID } from '../types/BaseCRUD'

export type PanelSize = {
  width: number
  height: number
}

export type SorceCodePanelState = {
  showSourceCode?: boolean
  codeType?: 'source' | 'schema'
}

export type SourceRightPanelState = {
  size?: PanelSize
}

export type SourceBottomPanelState = {
  size?: PanelSize
}

export type SourceWorkbenchState = {
  projectId?: ID
  sourceId?: ID
  environmentId?: ID
  locale?: string
  sourceCode: SorceCodePanelState
  rightPanel: SourceRightPanelState
  bottomPanel: SourceBottomPanelState
  showHistory: boolean
}

const { persistAtom } = recoilPersist({
  key: 'hydrogen_source_workbench_state',
})

export const sourceWorkbenchState = atom<SourceWorkbenchState>({
  key: 'sourceWorkbenchState',
  default: {
    sourceCode: {
      showSourceCode: true,
    },
    rightPanel: {},
    bottomPanel: {},
    showHistory: false,
  },
  effects_UNSTABLE: [persistAtom],
})

export type SetSourceWorkbenchStateFunction = (newValue: SourceWorkbenchState) => void

export const useGetSourceWorkbenchState = (): SourceWorkbenchState => {
  return useRecoilValue(sourceWorkbenchState)
}

export const useSetSourceWorkbenchState = (): SetSourceWorkbenchStateFunction => {
  return useSetRecoilState(sourceWorkbenchState)
}

export const useSourceWorkbenchState = (): [
  SourceWorkbenchState,
  SetSourceWorkbenchStateFunction
] => {
  return useRecoilState(sourceWorkbenchState)
}
