import { atomFamily } from 'recoil'
import { ID } from '../types/BaseCRUD'
import { SchemaState } from './SchemaState'

const defaultState: SchemaState = {
  initialized: false,
  writing: false,
  modified: false,
  reading: false,
}

export const schemaStateFamily = atomFamily<SchemaState, ID>({
  key: 'schemaState',
  default: defaultState,
})
