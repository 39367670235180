import React, { FC, useEffect } from 'react'
import {
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import { gql_EnumDKeySourceKind, gql_EnumSourceSchemaMode } from '../../../core/graphql'
import { CreateSourceData } from '../../../core/source-workbench/CreateSourceData'
import BaseIcon from '../../base/BaseIcon'
// import SourceSchemaModeRadioGroup from './SourceSchemaModeRadioGroup'
import SourceKindRadioGroup from './SourceKindRadioGroup'

type Props = {
  layouts?: any[]
  disableKindRadio?: boolean
  // layouts?: gql_Source[]
}

const SourceBasicInfoFormSection: FC<Props> = ({ layouts = [], disableKindRadio = false }) => {
  const { register, errors, control, watch, setValue } = useFormContext<CreateSourceData>()

  const currentKind = watch('kind')

  useEffect(() => {
    setValue(
      'schemaMode',
      currentKind === gql_EnumDKeySourceKind.BasicSource
        ? gql_EnumSourceSchemaMode.Free
        : gql_EnumSourceSchemaMode.Components
    )
  }, [currentKind])

  return (
    <chakra.div py={4} px={2} h="100%">
      <Stack spacing={7}>
        {/* <FormControl id="sourceType">
          <FormLabel>Type</FormLabel>
          <SourceTypeRadioCards options={options} defaultValue={value} register={register} />
        </FormControl> */}
        {/* <FormControl id="schemaMode">
          <FormLabel>Schema Mode</FormLabel>
          <InputGroup>
            <Controller
              name="schemaMode"
              control={control}
              defaultValue={gql_EnumSourceSchemaMode.Components}
              rules={{ required: true }}
              render={({ name, onChange, ref, value }) => (
                <RadioGroup
                  defaultValue={gql_EnumSourceSchemaMode.Components}
                  onChange={onChange}
                  name={name}
                  value={value}
                  ref={ref}
                >
                  <Stack spacing={5} direction="row">
                    <Radio colorScheme="green" value={gql_EnumSourceSchemaMode.Components}>
                      Components
                    </Radio>
                    <Radio colorScheme="green" value={gql_EnumSourceSchemaMode.Free}>
                      Free
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </InputGroup>
        </FormControl> */}
        <Controller
          name="schemaMode"
          control={control}
          defaultValue={gql_EnumSourceSchemaMode.Components}
          render={(controllerProps: any) => <></>}
          // rules={{ required: true }}
          // as={<SourceSchemaModeRadioGroup defaultValue={gql_EnumSourceSchemaMode.Components} />}
        />
        <FormControl id="kind" isDisabled={disableKindRadio}>
          <FormLabel>Source Kind</FormLabel>
          <InputGroup>
            <Controller
              name="kind"
              control={control}
              defaultValue={gql_EnumDKeySourceKind.Page}
              rules={{ required: true }}
              // as={<SourceKindRadioGroup defaultValue={gql_EnumDKeySourceKind.Page} />}
              render={({ name, onChange, ref, value }) => (
                <SourceKindRadioGroup
                  name={name}
                  onChange={onChange}
                  value={value}
                  defaultValue={gql_EnumDKeySourceKind.Page}
                />
              )}
            />
          </InputGroup>
        </FormControl>

        <FormControl isInvalid={!!errors?.name?.message}>
          <FormLabel>Source name</FormLabel>
          <InputGroup>
            <Input
              type="text"
              placeholder="Source name"
              name="name"
              defaultValue="Untitled"
              ref={register({
                // required: 'Source name is required',
                // minLength: { value: 3, message: 'Source name must be at lest 3 characters long' },
                maxLength: { value: 50, message: 'Source name must be maximum 50 characters long' },
              })}
            />
          </InputGroup>
          <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
        </FormControl>
        {currentKind === gql_EnumDKeySourceKind.Page && (
          <FormControl id="layout">
            <FormLabel>Layout</FormLabel>
            <Controller
              name="layout"
              control={control}
              render={({ name, onChange, ref, value }) => {
                return (
                  <Select
                    ref={ref}
                    placeholder={
                      (!value || value === null) && layouts.length === 0
                        ? 'No layouts available'
                        : 'Select layout'
                    }
                    onChange={(e) => onChange(e.target.value)}
                    name={name}
                    value={value}
                    disabled={(!value || value === null) && layouts.length === 0}
                  >
                    {layouts.map((layout: any) => (
                      <option key={`select-layout-${layout.id}`} value={layout.id}>
                        {layout.name}
                      </option>
                    ))}
                  </Select>
                )
              }}
            />
          </FormControl>
        )}
        <FormControl id="public">
          <Flex d="flex" justifyContent="space-between">
            <FormLabel d="flex">
              <BaseIcon name="HiGlobe" collection="hi" mr={2} /> Public
            </FormLabel>
            <Flex flexDir="column" alignSelf="center" justifySelf="center">
              <Controller
                name="isPublic"
                control={control}
                render={({ name, onBlur, onChange, ref, value }) => (
                  <Switch
                    ref={ref}
                    type="checkbox"
                    size="lg"
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    onBlur={onBlur}
                    isChecked={value}
                  />
                )}
              />
            </Flex>
          </Flex>
          <FormHelperText flex="1" mt={0} justifyContent="space-between" d="flex">
            <Flex
              flexDir="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              alignSelf="flex-start"
            >
              <Text>Make this source public or private</Text>
            </Flex>
          </FormHelperText>
        </FormControl>
        <FormControl id="isDefaultSource">
          <Flex d="flex" justifyContent="space-between">
            <FormLabel d="flex">
              <BaseIcon name="AiFillHome" collection="ai" mr={2} /> Default source
            </FormLabel>
            <Flex flexDir="column" alignSelf="center" justifySelf="center">
              <Controller
                name="isDefaultSource"
                control={control}
                render={({ name, onBlur, onChange, ref, value }) => (
                  <Switch
                    ref={ref}
                    type="checkbox"
                    size="lg"
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    onBlur={onBlur}
                    isChecked={value}
                  />
                )}
              />
            </Flex>
          </Flex>
          <FormHelperText flex="1" mt={0} justifyContent="space-between" d="flex">
            <Flex
              flexDir="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              alignSelf="flex-start"
            >
              <Text>Make this source the default one</Text>
            </Flex>
          </FormHelperText>
        </FormControl>
        <FormControl id="isDefaultSource">
          <Flex d="flex" justifyContent="space-between">
            <FormLabel d="flex">
              <BaseIcon name="AiFillHome" collection="ai" mr={2} /> Global source
            </FormLabel>
            <Flex flexDir="column" alignSelf="center" justifySelf="center">
              <Controller
                name="isGlobal"
                control={control}
                render={({ name, onBlur, onChange, ref, value }) => (
                  <Switch
                    ref={ref}
                    type="checkbox"
                    size="lg"
                    onChange={(e) => onChange(e.target.checked)}
                    name={name}
                    onBlur={onBlur}
                    isChecked={value}
                  />
                )}
              />
            </Flex>
          </Flex>
          <FormHelperText flex="1" mt={0} justifyContent="space-between" d="flex">
            <Flex
              flexDir="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              alignSelf="flex-start"
            >
              <Text>Make this source a global one</Text>
            </Flex>
          </FormHelperText>
        </FormControl>
      </Stack>
    </chakra.div>
  )
}

export default SourceBasicInfoFormSection
