import { useMemo } from 'react'
import { JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema'
import { getSchemaTypeName } from '../utils/getSchemaTypeName'
import { getInstanceTypeName } from '../utils/getInstanceTypeName'
import { NodeProps } from '../types/NodeProps'
import { useSchemaBridge } from './useSchemaBridge'

export type UseSchemaNodeOptions = Omit<NodeProps, 'typeName'>

export type UseSchemaNodeResult = {
  schemaTypeName: JSONSchema7TypeName | JSONSchema7TypeName[] | undefined
  instanceTypeName: JSONSchema7TypeName | undefined
  typeName: JSONSchema7TypeName | 'unsupported'
  resolvedSchema: JSONSchema7Definition | undefined

  // instanceSchema?: JSONSchema7
}

export const useSchemaNode = ({
  schema,
  value,
  ...rest
}: UseSchemaNodeOptions): UseSchemaNodeResult => {
  const schemaBridge = useSchemaBridge()

  const resolvedSchema = useMemo(() => {
    if (schemaBridge) {
      const result = schemaBridge.resolveInstanceSchema(schema || true, value || null)
      return result
    } else {
      return schema
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.path, rest.schemaPath, schema, schemaBridge, value])

  const schemaTypeName = useMemo(() => getSchemaTypeName(resolvedSchema), [resolvedSchema])
  const instanceTypeName = useMemo(() => getInstanceTypeName(value), [value])
  const typeName = useMemo(() => {
    if (schemaTypeName !== instanceTypeName && instanceTypeName && instanceTypeName !== 'null') {
      return instanceTypeName
    }

    if (Array.isArray(schemaTypeName) && schemaTypeName.length > 0) {
      return schemaTypeName[0]
    }

    return schemaTypeName as JSONSchema7TypeName
  }, [schemaTypeName, instanceTypeName])

  return { schemaTypeName, instanceTypeName, typeName, resolvedSchema }
}
