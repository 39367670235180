import React, { FC, useCallback } from 'react'
import { Spacer, useMultiStyleConfig } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import { ID } from '../../../core/types/BaseCRUD'
import { useSourceWorkbenchState } from '../../../core/source-workbench/useSourceWorkbenchState'
import Surface from '../../base/Surface'

type Props = {}

const SourceNodesPreviewModeSwitch: FC<Props> = (props) => {
  const styles = useMultiStyleConfig('PreviewToolbar', props)
  const [sourceWorkbenchState, setSourceWorkbenchState] = useSourceWorkbenchState()

  const { sourceCode, showHistory } = sourceWorkbenchState

  const showSourceCode = useCallback(
    (codeType = 'source') => {
      setSourceWorkbenchState({
        ...sourceWorkbenchState,
        sourceCode: { ...sourceCode, showSourceCode: true },
      })
    },
    [sourceCode, setSourceWorkbenchState, sourceWorkbenchState]
  )

  const hideSourceCode = useCallback(() => {
    setSourceWorkbenchState({
      ...sourceWorkbenchState,
      sourceCode: { ...sourceCode, showSourceCode: false },
    })
  }, [sourceCode, setSourceWorkbenchState, sourceWorkbenchState])

  return (
    <Surface
      borderRadius="md"
      overflow="visible"
      shadow="sm"
      p="0px"
      d="flex"
      flexDir="column"
      alignSelf="flex-start"
      my={3}
      lightBgColor="light.50"
      w="40px"
    >
      <BaseButton
        isActive={sourceCode.showSourceCode === false}
        darkBgColor={sourceCode.showSourceCode === false ? 'dark.500' : 'transparent'}
        lightBgColor={sourceCode.showSourceCode === false ? 'light.100' : 'transparent'}
        onClick={hideSourceCode}
        shadow={!sourceCode.showSourceCode ? 'sm' : 'none'}
        maxW={40}
        borderRadius="md"
        color={!sourceCode.showSourceCode ? 'green.500' : 'none'}
        _hover={{
          boxShadow: 'outline',
        }}
      >
        <BaseIcon fontSize="md" name="BsViewList" collection="bs" />
      </BaseButton>
      <Spacer mt="1px" mb={0} />
      <BaseButton
        onClick={showSourceCode}
        isActive={sourceCode.showSourceCode}
        darkBgColor={sourceCode.showSourceCode ? 'dark.500' : 'transparent'}
        lightBgColor={sourceCode.showSourceCode ? 'light.200' : 'transparent'}
        shadow={sourceCode.showSourceCode ? 'sm' : 'none'}
        maxW={40}
        borderRadius="md"
        color={sourceCode.showSourceCode ? 'blue.500' : 'none'}
      >
        <BaseIcon fontSize="md" name="IoCode" collection="io5" />
      </BaseButton>
    </Surface>
  )
}

export default SourceNodesPreviewModeSwitch
