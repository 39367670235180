import { useCallback } from 'react'
import Container from 'typedi'
import { ClassType } from '../types/ClassType'
import { NodeDelegate } from './NodeDelegate'

export const TOKEN_NODE_DELEGATE = 'hydrogen_nodeDelegate'

type SetNodeDelegateFunction = (newValue: ClassType<NodeDelegate>) => void

export const useNodeDelegate = (): NodeDelegate => {
  return Container.get<NodeDelegate>(TOKEN_NODE_DELEGATE)
}

export const useSetNodeDelegate = (): SetNodeDelegateFunction => {
  return useCallback((newValue: ClassType<NodeDelegate>) => {
    Container.set({ id: TOKEN_NODE_DELEGATE, type: newValue })
  }, [])
}
