import React, { FC, ReactElement, useCallback, useMemo } from 'react'
import {
  List,
  chakra,
  ListItem,
  useMultiStyleConfig,
  BoxProps,
  Divider,
  Text,
  Flex,
} from '@chakra-ui/react'
import { JSONSchema7Type, JSONSchema7TypeName } from 'json-schema'
import { useSelectedNodeState } from '../../../core/workbench/useSelectedNodeState'
import { useBridgedContent } from '../../../core/contents/useBridgedContent'
import { useNodeDelegate } from '../../../core/workbench/useNodeDelegate'
import { getPartialSchema } from '../../../core/utils/getPartialSchema'
import { useSchema } from '../../../core/schema/useSchema'
import SuggestedNodesItem from './SuggestedNodesItem'
import { ID } from '../../../core/types/BaseCRUD'

type SuggestedNodesProps = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  locale: string
  listComponent?: any
  divider?: ReactElement
} & BoxProps

const SuggestedNodes: FC<SuggestedNodesProps> = (props) => {
  const { projectId, environmentId, locale, sourceId, listComponent, divider, ...propsRest } = props
  const { formattedValue, setValue } = useBridgedContent({
    sourceId,
    locale,
    environmentId: props.environmentId,
  })
  const { schema } = useSchema({
    sourceId,
    projectId: props.projectId,
    environmentId: props.environmentId,
  })
  const [
    { path, schemaPath: selectedSchemaPath, ...rest },
    setSelectedNode,
  ] = useSelectedNodeState()
  const node = useNodeDelegate()
  const styles = useMultiStyleConfig('SuggestedNodes', props)
  const ListComponent = listComponent || List

  const selectedValue = useMemo(() => {
    const result = node.getValue(formattedValue || null, path)
    if (result === undefined) {
      return null
    }
    return result
  }, [path, formattedValue, node])

  const onMenuItemClick = useCallback(
    (typeName: JSONSchema7TypeName, path: string, newContent: JSONSchema7Type) => {
      const newValue = node.setValue(formattedValue, newContent, path === '/' ? undefined : path)
      setValue(newValue || null)

      // if (path && path !== '/') {
      //   setTimeout(() => {
      //     const newElement = document.getElementById(`schema-node-${path.replace('/', '-')}`)
      //     if (newElement) {
      //       newElement.scrollIntoView({ behavior: 'smooth' })
      //     }
      //   }, 300)
      // }
    },
    [node, setValue, formattedValue]
  )

  const suggestedNodes = useMemo(() => {
    return node.getAvailableNodes({
      ...rest,
      value: selectedValue,
      schema: getPartialSchema(schema, selectedSchemaPath),
      rootSchema: schema,
      path: path || '/',
      schemaPath: selectedSchemaPath,
      sourceId: props.sourceId,
    })
  }, [node, path, props.sourceId, rest, schema, selectedSchemaPath, selectedValue])

  return (
    <chakra.div sx={styles.wrapper} {...propsRest}>
      <List spacing={2} as={ListComponent} sx={styles.list}>
        {suggestedNodes.length > 0 ? (
          suggestedNodes.map((item, index) => {
            // console.log(item)
            return (
              <SuggestedNodesItem
                divider={index < suggestedNodes.length - 1 ? <Divider /> : null}
                key={`suggested-node-${index}`}
                onClick={onMenuItemClick}
                {...item}
              />
            )
          })
        ) : (
          <Flex sx={styles.emptyList}>
            <Text>No Suggestions</Text>
            {path != '/' && <Text ml={1}>for selected node</Text>}
          </Flex>
        )}
        {/* <ListItem display="flex" flexDirection="row" w="100%" alignItems="center">
          <Button size="sm">
          </Button> 
          <Text ml="3" textTransform="capitalize">
            Object
          </Text>
        </ListItem> */}
      </List>
    </chakra.div>
  )
}

export default SuggestedNodes
