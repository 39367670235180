import React, { FC } from 'react'
import { Flex, Switch, Text } from '@chakra-ui/react'

type Props = {
  title?: string
  content?: boolean
}

const BooleanNodeInfo: FC<Props> = ({ title, content }) => {
  return (
    <Flex flexDir="column" px="2" flex="1" ml="3">
      <Text fontSize="lg" fontWeight="300" textTransform="capitalize">
        {title}
      </Text>
      <Switch isChecked={content} isDisabled size="md" mt="1" />
    </Flex>
  )
}

export default BooleanNodeInfo
