import { Box, Flex, Tooltip } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import BaseIcon from '../base/BaseIcon'
import Surface from '../base/Surface'

type Props = {
  isVisible?: boolean
  onMoveBefore?: () => void
  onMoveAfter?: () => void
}

const NodeViewStartControls: FC<Props> = ({ isVisible, onMoveBefore, onMoveAfter }) => {
  const handleMoveBefore = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      if (onMoveBefore) {
        onMoveBefore()
      }
    },
    [onMoveBefore]
  )

  const handleMoveAfter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      if (onMoveAfter) {
        onMoveAfter()
      }
    },
    [onMoveAfter]
  )

  return (
    <>
      <Flex
        alignItems="center"
        position="absolute"
        left="-5px"
        top="0"
        w="15px"
        h="100%"
        zIndex={1}
        opacity={isVisible ? 1 : 0}
        pointerEvents={!isVisible ? 'none' : 'all'}
      >
        <Surface
          display="flex"
          rounded="full"
          darkBgColor="dark.600"
          darkBgHoverColor="dark.700"
          lightBgColor="light.200"
          lightBgHoverColor="light.300"
          alignItems="center"
          boxSize="20px"
          position="absolute"
          top="-6px"
          cursor="pointer"
          right="-14px"
          shadow="sm"
          onClick={handleMoveBefore}
          hoverable
        >
          <Tooltip
            placement="top"
            label="Move Element before"
            aria-label="Add node before"
            zIndex={1}
          >
            <Flex mx="auto" alignItems="center">
              <BaseIcon name="FiArrowUp" collection="fi" fontSize="md" />
            </Flex>
          </Tooltip>
        </Surface>

        <Surface
          display="flex"
          darkBgColor="transparent"
          darkBgHoverColor="dark.900"
          lightBgHoverColor="dark.50"
          alignItems="center"
          cursor="grab"
          position="relative"
          rounded="md"
          py="3"
          left="-1px"
          w="15px"
          flexDir="column"
          zIndex={0}
          hoverable
          boxShadow="none"
        >
          <BaseIcon name="FaGripLinesVertical" />
        </Surface>

        <Surface
          display="flex"
          rounded="full"
          darkBgColor="dark.600"
          darkBgHoverColor="dark.700"
          lightBgColor="light.200"
          lightBgHoverColor="light.300"
          alignItems="center"
          boxSize="20px"
          position="absolute"
          bottom="-6px"
          right="-14px"
          shadow="sm"
          cursor="pointer"
          hoverable
          onClick={handleMoveAfter}
        >
          <Tooltip
            placement="bottom"
            label="Move Element after"
            aria-label="Add node after"
            zIndex={1}
          >
            <Flex mx="auto" alignItems="center">
              <BaseIcon name="FiArrowDown" collection="fi" fontSize="md" />
            </Flex>
          </Tooltip>
        </Surface>
      </Flex>
    </>
  )
}

export default NodeViewStartControls
