import React from 'react'
import {
  Box,
  Divider,
  Flex,
  Image,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  chakra,
} from '@chakra-ui/react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import logo from '../../../assets/images/hydrogen-logo.png'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'
import LoggedUserNavbarInfo from '../../auth/LoggedUserMenu/LoggedUserNavbarInfo'

type MainAsideProps = {}

type RefType = HTMLDivElement

const MainAside = React.forwardRef<RefType, MainAsideProps>((props, ref) => {
  const location = useLocation()
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <Surface
      darkVariant="heavy"
      lightBgColor="whiteAlpha.300"
      darkBgColor="dark.800"
      h="100%"
      minW={['240px', null, null, null, '320px']}
      p="6"
      ref={ref}
    >
      <Flex flexDir="column" h="100%" w="100%">
        <Flex alignItems="center">
          <Image src={logo} w="50px" />
          <Flex flexDir="column">
            <Text ml="3" fontSize={['sm', null, 'md', null, 'lg']} fontWeight="bold">
              Hydrogen Editor
            </Text>
            <Text ml="3" fontSize="xs" fontWeight="bold">
              v 0.1
            </Text>
          </Flex>
        </Flex>
        <Flex my="5" pt="5" flex="1">
          <List spacing={3} w="100%">
            <ListItem
              display="flex"
              mt="5"
              py="3"
              rounded="md"
              alignItems="center"
              fontWeight={location && location.pathname.indexOf('/projects') == 0 ? '700' : '500'}
            >
              <Flex w="100%" flexDir="column">
                <Link to="/projects">
                  <Flex w="100%" alignItems="center">
                    <Surface
                      boxSize={35}
                      darkBgColor="dark.600"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="md"
                    >
                      <BaseIcon name="FaGripHorizontal" />
                    </Surface>

                    <Text ml="3" flex="1" fontSize={['md', null, 'md', null, 'lg']} w="100%">
                      Projects
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            </ListItem>
            <ListItem
              display="flex"
              mt="5"
              py="3"
              rounded="md"
              alignItems="center"
              fontWeight={location && location.pathname.indexOf('/setting') == 0 ? '700' : '500'}
            >
              <Flex w="100%" flexDir="column">
                <Link to="/settings">
                  <Flex w="100%">
                    <Surface
                      boxSize={35}
                      darkBgColor="dark.600"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="md"
                    >
                      <BaseIcon name="FaCogs" />
                    </Surface>
                    <Text ml="3" flex="1" fontSize={['md', null, 'md', null, 'xl']} w="100%">
                      Settings
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            </ListItem>
            <Divider></Divider>
            <ListItem display="flex" mt="5" py="3" rounded="md" alignItems="center">
              <Flex w="100%" flexDir="column">
                <Link to="/projects">
                  <Flex w="100%">
                    <Surface
                      boxSize={35}
                      darkBgColor="dark.600"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="md"
                    >
                      <BaseIcon name="FaHandsHelping" />
                    </Surface>
                    <Text ml="3" flex="1" fontSize={['md', null, 'md', null, 'lg']} w="100%">
                      Get started
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            </ListItem>
            <ListItem display="flex" mt="5" py="3" rounded="md" alignItems="center">
              <Surface
                boxSize={35}
                darkBgColor="dark.600"
                d="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="md"
              >
                <BaseIcon name="FaBook" />
              </Surface>
              <Link to="/projects">
                <Text ml="3" flex="1" fontSize={['md', null, 'md', null, 'lg']}>
                  Documentation
                </Text>
              </Link>
            </ListItem>
            <ListItem display="flex" mt="5" py="3" rounded="md" alignItems="center">
              <Flex w="100%" flexDir="column">
                <Link to="/projects">
                  <Flex w="100%">
                    <Surface
                      boxSize={35}
                      darkBgColor="dark.600"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="md"
                    >
                      <BaseIcon name="FaVial" />
                    </Surface>
                    <Text ml="3" flex="1" fontSize={['md', null, 'md', null, 'lg']} w="100%">
                      Samples
                    </Text>
                  </Flex>
                </Link>
              </Flex>
            </ListItem>
          </List>
        </Flex>
        <Flex alignItems="center">
          <Button onClick={toggleColorMode} boxSize="40px">
            <BaseIcon name={colorMode === 'light' ? 'FaSun' : 'FaMoon'} />
          </Button>
          <Text ml="3" textTransform="capitalize">
            {colorMode} Mode
          </Text>
        </Flex>
        <Divider my={3} />
        <Flex>
          <LoggedUserNavbarInfo showUserInfo showUserPopover />
        </Flex>
      </Flex>
    </Surface>
  )
})

export default MainAside
