import React, { FC } from 'react'
import { Box, Flex, Link, Text } from '@chakra-ui/react'
import BaseIcon from '../../base/BaseIcon'

type Props = {
  value?: any
  title?: string
}

const UriImageNodeInfo: FC<Props> = ({ value, title }) => {
  return (
    <Flex flexDir="row" px="2" flex="1" ml="3" minW={0}>
      <Box
        my="0"
        w={200}
        minW={200}
        h={150}
        shadow="md"
        bg="dark.800"
        rounded="md"
        bgImage={`url(${value})`}
        bgSize="contain"
        bgPosition="center"
        bgRepeat="no-repeat"
      />
      <Flex ml="5" flexDir="column" minW={0}>
        <Text fontSize="lg" fontWeight="300" textTransform="capitalize">
          {title}
        </Text>
        <Flex alignItems="center" minW={0}>
          <Text mr="2" fontSize="xl" fontWeight="bold" maxW="100%">
            {value}
            <Link href={value} target="_blank" ml="2">
              <BaseIcon fontSize="xs" name="FaExternalLinkAlt" />
            </Link>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UriImageNodeInfo
