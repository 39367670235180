import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import React, { createElement, FC, useMemo } from 'react'
import { useSchemaNode } from '../../core/schema/useSchemaNode'
import { FieldProps } from '../../core/types/FieldProps'
import ArrayField from './ArrayField'
import BooleanField from './BooleanField'
import NullField from './NullField'
import NumberField from './NumberField'
import ObjectField from './ObjectField'
import StringField from './StringField'

type Props = Omit<FieldProps, 'label' | 'typeName'>

const SchemaField: FC<Props> = ({
  schemaNodes = {
    array: ArrayField,
    object: ObjectField,
    string: StringField,
    boolean: BooleanField,
    integer: NumberField,
    number: NumberField,
    unsupported: NullField,
    null: NullField,
  },
  onChange,
  ...props
}) => {
  const { typeName, resolvedSchema } = useSchemaNode(props)

  const label = useMemo(() => {
    if (props.path === '/' || !props.path) {
      return '' // 'root'
    }
    const splitted = props.path.split('/')

    return splitted[splitted.length - 1]
  }, [props.path])

  if (!typeName || typeName === 'unsupported') {
    return (
      <Alert borderRadius={5} status="error">
        <AlertIcon />
        <AlertTitle color="black" mr={2}>
          Schema Error!
        </AlertTitle>
        <AlertDescription color="black">Schema type is undefined.</AlertDescription>
      </Alert>
    )
  }

  if (Array.isArray(typeName)) {
    return (
      <Alert borderRadius={5} status="error">
        <AlertIcon />
        <AlertTitle color="black" mr={2}>
          Schema Error!
        </AlertTitle>
        <AlertDescription color="black">Array type is not supported.</AlertDescription>
      </Alert>
    )
  }

  if (!schemaNodes || !schemaNodes[typeName]) {
    return (
      <Alert borderRadius={5} status="error">
        <AlertIcon />
        <AlertTitle color="black" mr={2}>
          Preview Error!
        </AlertTitle>
        <AlertDescription color="black">
          schemaNodes props is undefined or component node is undefined.
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <>
      {createElement(schemaNodes[typeName] as any, {
        ...props,
        schema: resolvedSchema,
        rootSchema: props.rootSchema,
        value: props.value,
        sourceId: props.sourceId,
        path: props.path,
        schemaPath: props.schemaPath,
        parentPath: props.parentPath,
        parentSchemaPath: props.parentSchemaPath,
        typeName,
        label,
        schemaNodes: typeName === 'array' || typeName === 'object' ? schemaNodes : undefined,
        onChange,
      })}
    </>
  )
}

export default SchemaField
