import { List, ListItem, ListIcon, ListProps, Flex } from '@chakra-ui/react'
import React, {
  FormEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from 'react'
import FocusLock from 'react-focus-lock'
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import BaseSearch from '../base/BaseSearch'
import FlagIcon from '../base/FlagIcon'

export type LangObject = { name: string; code: string; active: boolean; isDefault: boolean }

type Props = {
  firstFieldRef: MutableRefObject<any>
  onSelectLangs: (lang: LangObject) => void
} & ListProps

const LangsList = (props: Props) => {
  const { firstFieldRef, onSelectLangs, ...rest } = props
  const [selectedItems, setSelectedItems] = React.useState<LangObject[]>([])

  const langs = useMemo(
    () => [
      { name: 'english', code: 'gb', active: true, isDefault: true },
      { name: 'italiano', code: 'it', active: true, isDefault: false },
      { name: 'espanol', code: 'es', active: false, isDefault: false },
    ],
    []
  )

  const handleOnSelectLang = useCallback(
    (selectedLang: LangObject) => {
      onSelectLangs && onSelectLangs(selectedLang)
    },
    [langs]
  )

  const handleOnChange = useCallback((event: FormEvent<HTMLDivElement>) => {}, [langs])

  return (
    <Flex flexDir="column">
      <BaseSearch
        placeholder="Type a Country"
        onChange={handleOnChange}
        ref={firstFieldRef}
        mb={2}
      />
      <List spacing={3} {...rest}>
        {langs.map((lang, index) => (
          <ListItem key={index} onClick={() => handleOnSelectLang(lang)}>
            <FlagIcon code={lang.code} />
            {lang.name}
          </ListItem>
        ))}
      </List>
    </Flex>
  )
}

export default LangsList
