import { BoxProps, chakra } from '@chakra-ui/react'
import React, { useState, useEffect, useRef, FC } from 'react'
// import {} from 'react-icons/'

export type IconsCollections =
  | 'ai'
  | 'bi'
  | 'bs'
  | 'cg'
  | 'da'
  | 'fa'
  | 'fc'
  | 'fi'
  | 'gi'
  | 'go'
  | 'gr'
  | 'hi'
  | 'im'
  | 'io'
  | 'io5'
  | 'md'
  | 'ri'
  | 'si'
  | 'ti'
  | 'wi'
// eslint-disable-next-line react/require-default-props
type BaseIconProps = {
  name: string
  color?: string
  collection?: IconsCollections
  fontSize?: string
} & BoxProps

const BaseIcon: FC<BaseIconProps> = ({
  name,
  color, // = 'green.500',
  collection = 'fa',
  fontSize = 'lg',
  ...rest
}) => {
  const ImportedBaseIconRef = useRef<any>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const importBaseIcon = async () => {
      try {
        const collectionIcons = await import(`react-icons/${collection}`)
        ImportedBaseIconRef.current = collectionIcons[name]
      } finally {
        setLoading(false)
      }
    }
    importBaseIcon()
  }, [name])

  if (!loading && ImportedBaseIconRef.current) {
    const { current: ImportedBaseIcon } = ImportedBaseIconRef
    return (
      <chakra.div
        d="flex"
        justifyContent="center"
        alignItems="center"
        color={color}
        fontSize={fontSize}
        {...rest}
      >
        <ImportedBaseIcon />
      </chakra.div>
    )
  }

  return null
}

export default BaseIcon
