import { Button, chakra, Divider, Flex, IconButton, useMultiStyleConfig } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connectToChild } from 'penpal'
import { AsyncMethodReturns, CallSender } from 'penpal/lib/types'
import { useClient } from 'urql'
import { ID } from '../../core/types/BaseCRUD'
import useHover from '../../core/utils/useHover'
import {
  gql_ResolveDynamicContentQuery,
  gql_ResolveDynamicContentQueryVariables,
  useGetEnvironmentByIdQuery,
  useGetProjectByIdQuery,
} from '../../core/graphql'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import ErrorAlert from '../base/ErrorAlert'
import Loading from '../base/Loading'
import Status from '../base/Status'
import { SelectedNode } from '../../core/types/SelectedNode'
import { Node } from '../../core/types/Node'
import { useBridgedContent } from '../../core/contents/useBridgedContent'
import { useSchema } from '../../core/schema/useSchema'
import { useSchemaBridge } from '../../core/schema/useSchemaBridge'
import { getSchemaTypeName } from '../../core/utils/getSchemaTypeName'
import { isObject } from '../../core/utils/isObject'
import {
  useGetSelectedNodeState,
  useSetSelectedNodeState,
} from '../../core/workbench/useSelectedNodeState'
import { useDebounce } from '../../core/utils/useDebounce'
import { QUERY_RESOLVE_DYNAMIC_CONTENT } from '../../queries'
import Surface from '../base/Surface'
import DragHandle from './DragHandle'

const { PanZoom } = require('react-easy-panzoom')

const EVENT_CONTENT_UPDATE_KEY = '__hydrogen_contentUpdate'

type Props = {
  sourceId: ID
  projectId: ID
  locale: string
  environmentId: ID
  isPanelResizing?: boolean
  isOpen?: boolean
  onToggle?: () => void
  onExpand?: () => void
}

const ExternalPreview: FC<Props> = (props) => {
  const {
    sourceId,
    projectId,
    locale,
    environmentId,
    isPanelResizing: isResizing,
    isOpen,
    onToggle,
    onExpand,
  } = props

  const client = useClient()
  const { path } = useGetSelectedNodeState()
  const [childConnection, setChildConnection] = useState<
    AsyncMethodReturns<CallSender, string> | undefined
  >()
  const setSelectedNode = useSetSelectedNodeState()
  const schemaBridge = useSchemaBridge()
  const [nodesMap, setNodesMap] = useState<{ [path: string]: SelectedNode }>({})
  const [nodesMapInitialized, setNodesMapInitialized] = useState<boolean>(false)
  const initializing = useRef<boolean>(false)

  const [projectData, refetchProject] = useGetProjectByIdQuery({ variables: { id: projectId } })
  const [environmentData, refetchEnvironment] = useGetEnvironmentByIdQuery({
    variables: { id: environmentId },
    // pause: !selectedEnvironmentId,
  })

  const url = useMemo(() => {
    if (
      environmentData?.data?.environmentById &&
      environmentData?.data?.environmentById.previewSettings &&
      environmentData?.data?.environmentById.previewSettings.path
    ) {
      let replaced = environmentData?.data?.environmentById.previewSettings.path.replace(
        ':id',
        sourceId
      )
      if (locale) {
        replaced = replaced.replace(':locale', locale)
      }
      return replaced
    } else if (
      projectData?.data?.project &&
      projectData?.data?.project.settings &&
      projectData?.data?.project &&
      projectData?.data?.project.settings.previewUrl
    ) {
      let replaced = projectData?.data?.project.settings.previewUrl.replace(':id', sourceId)
      if (locale) {
        replaced = replaced.replace(':locale', locale)
      }

      return replaced
    }
    return undefined
    // return path.replace(':id', `${sourceId}`)
  }, [locale, environmentData?.data?.environmentById, projectData?.data?.project, sourceId])

  const origin = useMemo(() => {
    if (
      environmentData?.data?.environmentById &&
      environmentData?.data?.environmentById.previewSettings &&
      environmentData?.data?.environmentById.previewSettings.origin
    ) {
      return environmentData?.data?.environmentById.previewSettings.origin
    } else if (
      projectData?.data?.project &&
      projectData?.data?.project.settings &&
      projectData?.data?.project &&
      projectData?.data?.project.settings.livePreviewOrigin
    ) {
      return projectData?.data?.project.settings.livePreviewOrigin
    }
    return undefined
  }, [environmentData?.data?.environmentById, projectData?.data?.project])

  const { formattedValue, originalValue, value, initialized } = useBridgedContent({
    sourceId,
    locale: locale!,
    environmentId: environmentId!,
  })
  const debouncedFormattedValue = useDebounce(formattedValue, 250)
  const { schema } = useSchema({ sourceId, projectId, environmentId: environmentId! })
  const iframeRef = useRef<HTMLIFrameElement>()
  const [ref, isHover] = useHover(300)
  const styles = useMultiStyleConfig('ExternalPreview', { ...props, isHover, isOpen })
  const [screen, setScreen] = useState<'sm' | 'md' | 'lg'>('lg')

  const screenSize = useMemo(() => {
    if (screen == 'sm') {
      return { width: '375px', height: '667px' }
    }
    if (screen == 'md') {
      return { width: '1024px', height: '768px' }
    }
    if (screen == 'lg') {
      return { width: '1440px', height: '900px' }
    }
  }, [screen])

  const handleExternalPreviewNodeClick = useCallback(
    (path: string) => {
      if (nodesMap && nodesMap[path]) {
        // console.log('ciao, seleziono il nodo', nodesMap[path])
        const node = nodesMap[path]
        setSelectedNode({
          path,
          sourceId: node.sourceId,
          schemaPath: node.schemaPath,
          parentPath: node.parentPath,
          parentSchemaPath: node.parentSchemaPath,
          selectedSchema: node.selectedSchema,
        })
      }
    },
    [nodesMap, setSelectedNode]
  )

  const onIframeRef = useCallback(
    async (ref: any) => {
      iframeRef.current = ref
      if (ref) {
        const connection = connectToChild({
          iframe: ref,
          methods: {
            selectNode: handleExternalPreviewNodeClick,
          },
        })
        const resolvedConnection = await connection.promise
        setChildConnection(resolvedConnection)
        resolvedConnection.updateSelectedPath(path || '/')
      }
      // console.log('esisto e sono bello conme un iframe')
    },
    [handleExternalPreviewNodeClick, path]
  )

  const openPreviewWindow = useCallback(() => {
    window.open(url, '_blank')
  }, [url])

  const togglePreview = useCallback(() => {
    onToggle && onToggle()
  }, [onToggle])

  const expandPreview = useCallback(() => {
    onExpand && onExpand()
  }, [onExpand])

  const refreshPreview = useCallback(() => {
    if (iframeRef.current) {
      // eslint-disable-next-line no-self-assign
      iframeRef.current.src = iframeRef.current.src
    }
  }, [])

  useEffect(() => {
    if (iframeRef.current) {
      // eslint-disable-next-line no-self-assign
      iframeRef.current.src = iframeRef.current.src
    }
  }, [originalValue])

  useEffect(() => {
    if (childConnection && childConnection.updateContent) {
      client
        .query<gql_ResolveDynamicContentQuery, gql_ResolveDynamicContentQueryVariables>(
          QUERY_RESOLVE_DYNAMIC_CONTENT,
          {
            payload: {
              content: value as any,
              locale: locale as any,
              environmentId: environmentId!,
              projectId,
            },
          }
        )
        .toPromise()
        .then((response) => {
          if (
            response?.data?.resolveDynamicContent &&
            response.data.resolveDynamicContent.content
          ) {
            childConnection.updateContent(response.data.resolveDynamicContent.content)
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormattedValue])

  useEffect(() => {
    if (childConnection && childConnection.updateContent) {
      childConnection.updateSelectedPath(path || '/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  useEffect(() => {
    const init = () => {
      if (
        !nodesMapInitialized &&
        initialized &&
        !initializing.current &&
        schema &&
        formattedValue
      ) {
        initializing.current = true
        const newNodesMap: { [path: string]: SelectedNode } = {}

        const resolveSelectableNodes = (node: Omit<Node, 'typeName'>) => {
          const { schema, value } = node
          const resolvedSchema = schemaBridge?.resolveInstanceSchema(schema || true, value || null)

          newNodesMap[node.path] = { ...node, selectedSchema: resolvedSchema }

          let schemaTypeName = getSchemaTypeName(resolvedSchema)
          if (Array.isArray(schemaTypeName) && schemaTypeName.length > 0) {
            const firstElement = schemaTypeName[0]
            schemaTypeName = firstElement
          }

          if (schemaTypeName === 'array') {
            if (value && Array.isArray(value)) {
              // console.log("qua è l'array", node)
              for (let i = 0; i < value.length; i++) {
                const item = value[i]

                if (item) {
                  resolveSelectableNodes({
                    sourceId,
                    value: item,
                    schema:
                      resolvedSchema &&
                      typeof resolvedSchema !== 'boolean' &&
                      resolvedSchema.items &&
                      !Array.isArray(resolvedSchema.items)
                        ? resolvedSchema.items
                        : undefined,
                    rootSchema: node.rootSchema,
                    path: node.path === '/' ? node.path + i : `${node.path}/${i}`,
                    schemaPath:
                      resolvedSchema &&
                      typeof resolvedSchema !== 'boolean' &&
                      resolvedSchema.items &&
                      !Array.isArray(resolvedSchema.items)
                        ? node.schemaPath === '/'
                          ? '/items'
                          : `${node.schemaPath}/items`
                        : node.schemaPath,
                    parentSchemaPath: node.schemaPath,
                  })
                }
              }
            }
          } else if (schemaTypeName === 'object') {
            if (value && isObject(value)) {
              // console.log("qua è l'object", node.path, resolvedSchema)

              const objectKeys = Object.keys(value)
              for (const property of objectKeys) {
                const item = (value as any)[property]
                resolveSelectableNodes({
                  sourceId,
                  value: item,
                  path: node.path === '/' ? node.path + property : `${node.path}/${property}`,
                  parentPath: node.path,
                  parentSchemaPath: node.schemaPath,
                  schemaPath:
                    resolvedSchema &&
                    typeof resolvedSchema !== 'boolean' &&
                    resolvedSchema.properties &&
                    resolvedSchema.properties[property]
                      ? node.schemaPath === '/'
                        ? `${node.schemaPath}properties/${property}`
                        : `${node.schemaPath}/properties/${property}`
                      : node.schemaPath,
                  schema:
                    resolvedSchema &&
                    typeof resolvedSchema !== 'boolean' &&
                    resolvedSchema.properties &&
                    resolvedSchema.properties[property]
                      ? resolvedSchema.properties[property]
                      : undefined,
                  rootSchema: node.rootSchema,
                })
              }
            }
          } else {
            // console.log('qua è un primitivo scemo', node, value)
          }
        }

        resolveSelectableNodes({
          sourceId,
          value: formattedValue,
          schema,
          rootSchema: schema,
          path: '/',
          schemaPath: '/',
        })
        // console.log('trovatoooo', newNodesMap)
        setNodesMapInitialized(true)
        setNodesMap(newNodesMap)
        initializing.current = false
      }
    }

    init()
  }, [formattedValue, initialized, nodesMapInitialized, schema, schemaBridge, sourceId])

  if (projectData.error) {
    return <ErrorAlert errorMessage={projectData.error.message} />
  }

  // if (environmentData.error) {
  //   return <ErrorAlert errorMessage={environmentData.error.message} />
  // }

  if (projectData.fetching || environmentData.fetching) {
    return <Loading />
  }

  if (!url) {
    return <div>No Url found</div>
  }

  return (
    <chakra.div pos="relative">
      <chakra.div
        h="100%"
        ref={ref}
        mx={5}
        mb={3}
        mt={3}
        borderRadius="md"
        overflow="hidden"
        shadow="none"
        pos="relative"
      >
        {/* <TransformWrapper
        options={{ centerContent: false, transformEnabled: true, minScale: 0.5 }}
        zoomIn={{ step: 15 }}
        zoomOut={{ step: 15 }}
        // scalePadding={{ size: 1 }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => ( */}

        <Surface w="100%" h="100%" darkBgColor="dark.800">
          <Flex sx={styles.toolbar}>
            <Flex left={2} position="absolute">
              <Button
                sx={styles.toolbarButton}
                colorScheme="green"
                children={
                  <BaseIcon
                    name={isOpen ? 'HiArrowDown' : 'HiArrowUp'}
                    collection="hi"
                    fontSize="12.5px"
                  />
                }
                onClick={togglePreview}
              />
            </Flex>
            {/* <Flex position="absolute" left={6}>
            <Button
              sx={styles.toolbarCircleButton}
              bgColor="yellow.400"
              children={
                <BaseIcon
                  name="FaMinus"
                  collection="fa"
                  fontSize="8.5px"
                  opacity={isHover ? 1 : 0}
                />
              }
              onClick={togglePreview}
            />
            <Button
              sx={styles.toolbarCircleButton}
              bgColor="blue.400"
              children={
                <BaseIcon
                  name="FaArrowUp"
                  collection="fa"
                  fontSize="8px"
                  opacity={isHover ? 1 : 0}
                />
              }
              onClick={expandPreview}
            />
            <Button
              sx={styles.toolbarCircleButton}
              bgColor="green.300"
              children={
                <BaseIcon
                  name="AiOutlinePlus"
                  collection="ai"
                  fontSize="8px"
                  opacity={isHover ? 1 : 0}
                />
              }
              onClick={openPreviewWindow}
            />
          </Flex>
          */}
            <Flex flex="1" pr={3} pl={10} justifyContent="flex-start">
              <chakra.a sx={styles.address} href={url} target="_blank" isTruncated noOfLines={1}>
                {url}
              </chakra.a>
              <Button
                sx={styles.toolbarButton}
                ml={1}
                children={<BaseIcon name="MdRefresh" collection="md" fontSize="11px" />}
                onClick={refreshPreview}
              />
            </Flex>
            <Flex position="absolute" right={2}>
              <Button
                sx={styles.toolbarButton}
                children={<BaseIcon name="FaMobile" collection="fa" fontSize="9px" />}
                onClick={() => setScreen('sm')}
                isActive={screen == 'sm'}
              />
              <Button
                sx={styles.toolbarButton}
                children={<BaseIcon name="FaTablet" collection="fa" fontSize="10px" />}
                onClick={() => setScreen('md')}
                mx={1}
                isActive={screen == 'md'}
              />
              <Button
                sx={styles.toolbarButton}
                children={<BaseIcon name="MdLaptop" collection="md" fontSize="10px" />}
                onClick={() => setScreen('lg')}
                isActive={screen == 'lg'}
              />
              <Divider orientation="vertical" h="50%" mx={2} />

              <BaseButton
                sx={styles.toolbarButton}
                size="xs"
                // onClick={zoomIn}
                children={<BaseIcon name="FiZoomIn" collection="fi" fontSize="xs" />}
              />
              <BaseButton
                sx={styles.toolbarButton}
                size="xs"
                // onClick={zoomOut}
                children={<BaseIcon name="FiZoomOut" collection="fi" fontSize="xs" />}
              />
              {/* <BaseButton
              sx={styles.toolbarButton}
              size="xs"
              // onClick={resetTransform}
              children={<BaseIcon name="FaBackspace" collection="fa" fontSize="xs" />}
            /> */}
            </Flex>
          </Flex>
          <chakra.div w="fit-content" maxW="100%">
            <PanZoom maxZoom={1} autoCenter style={{ outline: 'none', cursor: 'move' }}>
              <iframe
                title={`preview-${sourceId}`}
                ref={onIframeRef}
                src={url}
                style={{
                  border: 0,
                  margin: 'auto',
                  height: screenSize?.height,
                  width: screenSize?.width,
                  borderBottom: 'none',
                  pointerEvents: isResizing ? 'none' : 'all',
                  backgroundColor: 'white',

                  // minHeight: '100vh'
                }}
              />
            </PanZoom>
          </chakra.div>
        </Surface>
        {/* )}
      </TransformWrapper> */}
      </chakra.div>
    </chakra.div>
  )
}

export default ExternalPreview
