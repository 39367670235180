import {
  Text,
  Flex,
  Stack,
  InputGroup,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Divider,
  Button,
  Textarea,
  Select,
  Badge,
  Box,
  AccordionProps,
  chakra,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Container,
  Collapse,
  Heading,
  Image,
} from '@chakra-ui/react'
import React, {
  ChangeEvent,
  cloneElement,
  createRef,
  FC,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import MasterDetailMenu, {
  ModalMenuItem,
  ModalSubMenuItem,
} from '../../base/MasterDetail/MasterDetailMenu'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import GradientBox from '../../base/GradientBox'
import Surface from '../../base/Surface'
import {
  MasterDetailMenuItem,
  MasterDetailPanel,
  MasterDetailSubMenuItem,
} from '../../base/MasterDetail'
import { ID } from '../../../core/types/BaseCRUD'
import MasterDetailSubMenu from '../../base/MasterDetail/MasterDetailSubMenu'
import { ProjectInfoForm, ProjectEnvoirmentForm } from '.'
import logo from '../../../assets/images/hydrogen-logo.png'
import ProjectDeployForm from './ProjectDeployForm'

type Props = {
  isOpen: boolean
  onClose: () => void
  layout?: 'drawer' | 'modal'
} & AccordionProps

const CreateProject: FC<Props> = (props) => {
  const { onClose, layout = 'modal', isOpen, ...rest } = props

  const { register, handleSubmit, watch, control, errors } = useForm<any>({
    defaultValues: {},
  })

  const history = useHistory()
  // const sourcesProvider = useSourcesProvider()
  const [seoState, setSeoState] = useState<any>({ keywords: ['abc', 'def', 'lmn'] })
  const [keywordValue, setKeywordValue] = useState<any>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const keywordsRef = createRef<HTMLInputElement>()

  const menu: ModalMenuItem[] = [
    {
      id: 'project',
      title: 'Project',
      subtitle: 'subtitle step 1',
      iconComponent: <BaseIcon name="FaBox" collection="fa" fontSize="1xl" mr={3} />,
    },
    {
      id: 'envoirment',
      title: 'Envoirment',
      subtitle: 'subtitle step 2',
      iconComponent: <BaseIcon name="RiServerFill" collection="ri" fontSize="2xl" mr={3} />,
    },
    {
      id: 'deploy',
      title: 'Deploy',
      subtitle: 'subtitle step 3',
      iconComponent: <BaseIcon name="RiRocket2Fill" collection="ri" fontSize="2xl" mr={3} />,
    },
  ]

  const [selectedItem, setSelectedItem] = useState<ModalMenuItem | ModalSubMenuItem>(menu[0])

  const handleMenuClick = useCallback((menuItem: ModalMenuItem) => {
    setSelectedItem(menuItem)
  }, [])

  const onChangeKewords = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!seoState.keywords) setSeoState([keywordsRef.current?.value])
      else {
        const keywords = seoState.keywords.concat([keywordsRef.current?.value])
        const uniqKeywords = Array.from(new Set(keywords))

        setSeoState({ ...seoState, keywords: uniqKeywords })
      }
    },
    [seoState, keywordsRef]
  )

  const onRemoveKeywords = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setSeoState({ ...seoState, keywords: [] })
    },
    [seoState]
  )

  const onChangeKeywordValue = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setKeywordValue(event.currentTarget.value)
  }, [])

  const onKeyPress = useCallback((event: any): void => {
    if (event.keyCode == 13) {
      onRemoveKeywords(event as any)
    }
  }, [])

  const onSubmit = useCallback(async (data: any): Promise<any> => {}, [])

  const currentStep = useMemo(() => {
    return menu.findIndex((element) => element.id == selectedItem.id) || 0
  }, [menu, selectedItem])

  const nextStep = useCallback(() => {
    const nextIndex = currentStep < menu.length - 1 ? currentStep + 1 : 0
    handleMenuClick(menu[nextIndex])
  }, [currentStep])

  const renderForms = useCallback(
    (): React.ReactNode => (
      <Stack>
        {/* Project */}
        <Collapse in={selectedItem.id == 'project'}>
          <chakra.div py={4} px={2}>
            <Stack spacing={7}>
              <ProjectInfoForm register={register} projectData={{ isPublic: true }} />
            </Stack>
          </chakra.div>
        </Collapse>
        {/* Envoirment */}
        <Collapse in={selectedItem.id == 'envoirment'}>
          <chakra.div py={4} px={2}>
            <ProjectEnvoirmentForm register={register} projectData={{ isPublic: true }} />
          </chakra.div>
        </Collapse>
        {/* Deploy */}
        <Collapse in={selectedItem.id == 'deploy'}>
          <chakra.div py={4} px={2}>
            <ProjectDeployForm register={register} projectData={{ isPublic: true }} />
          </chakra.div>
        </Collapse>
      </Stack>
    ),
    [
      selectedItem.id,
      register,
      seoState.keywords,
      keywordsRef,
      onKeyPress,
      onChangeKeywordValue,
      onChangeKewords,
      keywordValue.length,
      onRemoveKeywords,
    ]
  )

  const StepIcon = useCallback(() => {
    return selectedItem && selectedItem.iconComponent ? (
      cloneElement(selectedItem.iconComponent, {
        fontSize: '3xl',
        mr: 0,
        alignSelf: 'center',
      })
    ) : (
      <></>
    )
  }, [selectedItem, menu, currentStep])

  return layout === 'modal' ? (
    <Modal motionPreset="scale" size="full" onClose={onClose} isOpen={isOpen} isCentered {...rest}>
      <ModalOverlay />

      <Surface
        as={ModalContent}
        lightBgColor="light.100"
        darkBgColor="dark.800"
        boxShadow="lg"
        overflow="hidden"
        d="flex"
        flexDir="row"
        justifyContent="stretch"
        alignContent="stretch"
        maxH="90vh"
        m={0}
      >
        <Surface darkBgColor="dark.600" lightBgColor="whiteAlpha.300">
          <MasterDetailMenu
            w="20vw"
            subtitle="Project"
            title="Create new project"
            index={currentStep}
          >
            {menu.map((menuItem, index) => (
              <MasterDetailMenuItem
                key={index}
                {...menuItem}
                isActive={selectedItem.id == menuItem.id}
                onClickItem={(menuItem) => handleMenuClick(menuItem)}
                statusComponent={
                  currentStep > index ? (
                    <BaseIcon
                      name="HiCheckCircle"
                      color="green.400"
                      collection="hi"
                      fontSize="2xl"
                    />
                  ) : (
                    <BaseIcon name="MdError" color="red.400" collection="md" fontSize="2xl" />
                  )
                }
              >
                {menuItem.subMenu && (
                  <MasterDetailSubMenu pl={5}>
                    {menuItem.subMenu.map((subMenuItem, index) => (
                      <MasterDetailSubMenuItem
                        key={index}
                        {...subMenuItem}
                        onClickItem={(item) => console.log(item)}
                      />
                    ))}
                  </MasterDetailSubMenu>
                )}
              </MasterDetailMenuItem>
            ))}
            <Flex>
              <Button size="lg" colorScheme="green" w="100%" disabled>
                Create
              </Button>
            </Flex>
          </MasterDetailMenu>
        </Surface>

        <MasterDetailPanel
          as={Surface}
          isActive
          w="80vw"
          position="relative"
          d="flex"
          flexDir="column"
          darkBgColor="dark.800"
          lightBgColor="light.100"
          overflow="scroll"
          h="100%"
          maxH="100vh"
          minH="100vh"
        >
          <ModalHeader w="100%" d="flex" p={12}>
            <Surface
              d="flex"
              boxSize="70px"
              alignItems="center"
              justifyContent="center"
              bgColor="blackAlpha.300"
              borderRadius="lg"
              mr={3}
            >
              <StepIcon />
            </Surface>
            <Flex flexDir="column">
              <Heading>{selectedItem.title}</Heading>
              <Text fontWeight="light" opacity="0.6">
                {selectedItem.subtitle}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalBody px={12} pb={12} flex="1">
            <ModalCloseButton
              as={BaseButton}
              variant="solid"
              size="lg"
              top={3}
              right={3}
              zIndex={9}
              position="fixed"
            />
            <Container maxWidth="container.md">{renderForms()}</Container>
          </ModalBody>
          <ModalFooter position="sticky" right="0" bottom="0" w="100%">
            <GradientBox
              position="absolute"
              height="100px"
              width="100%"
              bottom={0}
              left={0}
              right={0}
              maxWidth="100%"
              zIndex="0"
              lightColors={['light.100', 'transparent']}
              darkColors={['dark.800', 'transparent']}
              direction="to-t"
              pointerEvents="none"
            />
            <BaseButton mr={3} size="lg" variant="ghost" onClick={onClose}>
              Exit
            </BaseButton>
            <BaseButton color="green.500" size="lg" lightHue="light" onClick={nextStep}>
              Proceed <BaseIcon name="BsArrowRightShort" collection="bs" fontSize="2xl" ml={2} />
            </BaseButton>
          </ModalFooter>
        </MasterDetailPanel>
      </Surface>
    </Modal>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="md" {...rest}>
      <DrawerOverlay>
        <Surface as={DrawerContent} lightBgColor="light.50">
          <chakra.form display="flex" flexDir="column" w="100%" onSubmit={handleSubmit(onSubmit)}>
            <GradientBox
              position="absolute"
              height="100px"
              width="100%"
              top={0}
              left={0}
              right={0}
              maxWidth="100%"
              zIndex="1"
              lightColors={['light.100', 'transparent']}
              darkColors={['dark.800', 'transparent']}
              direction="to-b"
              pointerEvents="none"
            />
            <BaseButton
              right="-50px"
              top={3}
              position="absolute"
              boxSize="40px"
              p={2}
              onClick={onClose}
            >
              <BaseIcon name="IoCloseOutline" collection="io5" fontSize="3xl" />
            </BaseButton>
            <DrawerHeader pos="absolute" zIndex="1" left="0" top="0" w="100%">
              <Flex>
                <BaseIcon name="HiFolder" collection="hi" fontSize="3xl" mr={2} />
                <Text fontSize="2xl">Create New Source</Text>
              </Flex>
            </DrawerHeader>
            <DrawerBody pb={4} pt="80px" zIndex="0">
              {renderForms()}
            </DrawerBody>
            <DrawerFooter>
              <BaseButton variant="ghost" mr={3} onClick={onClose}>
                Cancel
              </BaseButton>
              <BaseButton
                color="green.400"
                lightHue="lighter"
                darkHue="light"
                size="lg"
                type="submit"
              >
                Create source
              </BaseButton>
            </DrawerFooter>
          </chakra.form>
        </Surface>
      </DrawerOverlay>
    </Drawer>
  )
}

export default CreateProject
