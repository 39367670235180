import React, { RefObject, useCallback, useEffect, useState } from 'react'
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import { gql_HyProjectFragment } from '../../../core/graphql'

const drawerTransition = 'transform 0.5s cubic-bezier(0.16, 1, 0.3, 1) 0s !important'

type ProjectDrawerProps = {
  project: gql_HyProjectFragment
  isOpen: boolean
  onClose: () => void
  onSave: (project: gql_HyProjectFragment) => void
  finalFocusRef?: RefObject<any>
}

type RefType = HTMLDivElement

const ProjectDrawer = React.forwardRef<RefType, ProjectDrawerProps>((props, ref) => {
  const { project: defaultProject, isOpen, onClose, onSave, finalFocusRef } = props
  const [project, setProject] = useState<gql_HyProjectFragment>(defaultProject)

  const handleOnChangeName = useCallback(
    (event: any): void => {
      const changedProject = { ...project, name: event.target.value }
      setProject(changedProject)
    },
    [project]
  )

  const handleOnSave = useCallback(
    (event: any): void => {
      onSave && onSave(project)
      onClose()
    },
    [project]
  )

  useEffect((): void => {
    if (project != defaultProject) setProject(defaultProject)
  }, [defaultProject])

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={finalFocusRef}
      size="xs"
    >
      <DrawerOverlay>
        <DrawerContent transition={drawerTransition}>
          <DrawerCloseButton />
          <DrawerHeader>Project</DrawerHeader>

          {project && defaultProject && (
            <DrawerBody>
              <FormControl display="flex" flexDir="column">
                <FormLabel>Name</FormLabel>
                <Input
                  name="name"
                  defaultValue={defaultProject.name}
                  value={project.name}
                  onChange={handleOnChangeName}
                  size="lg"
                  mb="3"
                />
              </FormControl>
            </DrawerBody>
          )}
          <DrawerFooter>
            <Button size="lg" variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button size="lg" w="100%" onClick={handleOnSave}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
})

export default ProjectDrawer
