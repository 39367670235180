import { Text, Flex, useDisclosure, chakra, ButtonGroup, Divider } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useGetSourceByIdQuery, useGetProjectByIdQuery } from '../../../core/graphql'
import { ID } from '../../../core/types/BaseCRUD'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import Loading from '../../base/Loading'
import Status from '../../base/Status'
import SourcesDrawer from '../SourcesDrawer'
import SourceSettingsButton from './SourceSettingsButton'

type Props = {
  sourceId: ID
  projectId: ID
}

const SourceToolbarMenu: FC<Props> = ({ sourceId, projectId }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const [sourceResult, refetcSource] = useGetSourceByIdQuery({
    variables: {
      id: sourceId,
    },
  })

  const [projectResult, refetchProject] = useGetProjectByIdQuery({
    variables: {
      id: projectId,
    },
  })

  const { data: sourceData, fetching: sourceFetching, error: sourceError } = sourceResult
  const { data: projectData, fetching: projectFetching, error: projectError } = projectResult

  if (sourceFetching || projectFetching) {
    return <Loading />
  }

  return (
    <chakra.div d="flex">
      {/* <pre>{JSON.stringify(source, null, 2)}</pre> */}
      <ButtonGroup mb={2}>
        <SourceSettingsButton sourceId={sourceId} projectId={projectId} size="sm" />
        <BaseButton
          alignSelf="center"
          boxShadow="none"
          px={2}
          py={1}
          borderRadius="md"
          onClick={onToggle}
          variant="ghost"
        >
          <Flex textAlign="left">
            <Flex flexDir="column" alignSelf="center" justifySelf="center">
              <Text
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                maxW="120px"
                fontSize="xs"
                lineHeight="13px"
                mb="1px"
                opacity="0.6"
                fontWeight="light"
              >
                {sourceData?.sourceById?.kind}
              </Text>
              <Flex maxW="120px" pr="1">
                {sourceData?.sourceById?.isPublic ? <Status /> : <Status bg="gray.400" />}
                <Text
                  maxW="120px"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontSize="sm"
                >
                  {sourceData?.sourceById?.name}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <BaseIcon name="HiSelector" collection="hi" fontSize="md" />
        </BaseButton>
      </ButtonGroup>
      <SourcesDrawer isOpen={isOpen} onClose={onClose} projectId={projectId} />
    </chakra.div>
  )
}

export default SourceToolbarMenu
