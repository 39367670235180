import { atomFamily, DefaultValue } from 'recoil'
import Container from 'typedi'
import { ID } from '../types/BaseCRUD'
import { Content } from '../types/Content'

const defaultState: Content<string> = {
  value: '',
  originalValue: '',
  initialized: false,
  writing: false,
  modified: false,
  reading: false,
}

export const contentStateFamily = atomFamily<Content<string>, ID>({
  key: 'contentState',
  default: defaultState,
})
