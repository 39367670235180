import React, { FC, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useGetHydrogenState } from '../../core/hydrogen/useHydrogenState'
import { HydrogenRedirectResolver } from '../../core/types/HydrogenRedirectResolver'

type Props = {
  indexRedirect?: HydrogenRedirectResolver
}

const HydrogenRouterRedirect: FC<Props> = ({ indexRedirect = true }) => {
  const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined)
  const [initialized, setInitialized] = useState<boolean>(false)
  const {
    hasProjectsExplorer,
    hasWorkbench,
    projectsExplorerDefaultRedirect,
    workbenchDefaultRedirect,
  } = useGetHydrogenState()

  useEffect(() => {
    const init = async (): Promise<void> => {
      if (!indexRedirect) {
        return
      }

      if (typeof indexRedirect === 'boolean') {
        if (hasProjectsExplorer && projectsExplorerDefaultRedirect) {
          setRedirectTo(projectsExplorerDefaultRedirect)
          setInitialized(true)
          return
        }
        if (hasWorkbench && workbenchDefaultRedirect) {
          setRedirectTo(workbenchDefaultRedirect)
          setInitialized(true)
          return
        }
      } else if (typeof indexRedirect === 'string') {
        setRedirectTo(indexRedirect)
        setInitialized(true)
      } else {
        const result = await indexRedirect()
        setRedirectTo(result)
        setInitialized(true)
      }
    }

    init()
    // eslint-disable-next-line
  }, [])

  if (!redirectTo || !initialized) {
    return null
  }

  return <Route exact path="/" render={(): React.ReactNode => <Redirect to={redirectTo} />} />
}

export default HydrogenRouterRedirect
