import React, { useCallback } from 'react'
import {
  chakra,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import Moment from 'react-moment'
import Surface from '../../../components/base/Surface'
import BaseButton from '../../../components/base/BaseButton'
import GradientBox from '../../../components/base/GradientBox'
import { Media } from '../../../core/cms/media/Media'
import BaseIcon from '../../../components/base/BaseIcon'

type MediaCardProps = {
  media: Media
  onClick?: (media: Media) => void
  // onOptionsClick?: (event: any) => void
}

const MediaCard = React.forwardRef<HTMLDivElement, MediaCardProps>((props, ref) => {
  const { media, onClick, ...rest } = props
  const { colorMode } = useColorMode()

  const handleOnClick = useCallback(
    (event: any): void => {
      event.stopPropagation()
      if (onClick) {
        onClick(media)
      }
    },
    [media, onClick]
  )

  // const handleOnOptionsClick = useCallback(
  //   (event: any): void => {
  //     event.stopPropagation()
  //     if (onOptionsClick) {
  //       onOptionsClick(media)
  //     }
  //   },
  //   [media, onOptionsClick]
  // )

  return (
    <Surface
      display="flex"
      flexDir="column"
      rounded="md"
      overflow="hidden"
      shadow="sm"
      h={130}
      onClick={handleOnClick}
      cursor="pointer"
      position="relative"
      ref={ref}
      {...rest}
    >
      <Surface
        w="100%"
        h="100%"
        p="2"
        position="relative"
        flexDir="column"
        darkBgColor="dark.500"
        lightBgColor="light.200"
        bgImage={`url(${media.urlSmall})`}
        bgSize="cover"
        bgPos="center"
      >
        <Menu closeOnSelect={true}>
          <MenuButton as={BaseButton} position="absolute" right={2} top={2} size="xs">
            <BaseIcon name="FaEllipsisH" collection="fa" fontSize="xs" />
          </MenuButton>
          <Portal>
            <MenuList zIndex={99} position="relative" boxShadow="md">
              <MenuItem>Download</MenuItem>
              <MenuItem>Create a Copy</MenuItem>
              <MenuItem>Mark as Draft</MenuItem>
              <MenuItem>Delete</MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Surface>
      <GradientBox
        position="absolute"
        height="100%"
        width="100%"
        bottom={0}
        left={0}
        right={0}
        maxWidth="100%"
        zIndex="0"
        lightColors={['light.100', 'transparent']}
        darkColors={['dark.900', 'transparent']}
        direction="to-t"
      />
      <Flex
        flexDir="column"
        flex="1"
        px="3"
        py="2"
        position="absolute"
        bottom="0"
        left="0"
        zIndex="1"
        w="100%"
      >
        <Text fontSize="md" fontWeight="bold">
          {media.description}
        </Text>
        <Text fontSize="xs">
          <Moment fromNow>{media.updatedAt}</Moment>
        </Text>
      </Flex>
      {!media.urlSmall && (
        <chakra.div
          position="absolute"
          boxSize="100%"
          left="0"
          top="0"
          d="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BaseIcon
            name="MdPermMedia"
            collection="md"
            fontSize="2xl"
            mr={2}
            alignSelf="center"
            justifySelf="center"
          />
        </chakra.div>
      )}
    </Surface>
  )
})

export default MediaCard
