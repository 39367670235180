import {
  BoxProps,
  chakra,
  Flex,
  Image,
  Progress,
  ScaleFade,
  Skeleton,
  SlideFade,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import React, { FC } from 'react'
import BaseIcon, { IconsCollections } from './BaseIcon'

type Props = {
  title?: string
  imageSrc?: string
  icon?: string
  iconCollection?: IconsCollections
} & BoxProps

const Loading: FC<Props> = (props) => {
  const { title = 'Loading', imageSrc, icon, iconCollection, ...rest } = props
  const styles = useMultiStyleConfig('Loading', props)
  return (
    <chakra.div sx={styles.wrapper} {...rest}>
      <ScaleFade in={true}>
        <chakra.div sx={styles.content}>
          {imageSrc && (
            <Flex mb={4} h="150px" alignItems="center" justifyContent="center">
              <Image src={imageSrc} h="150px" />
            </Flex>
          )}
          {icon && (
            <Flex mb={4} alignItems="center" justifyContent="center">
              <BaseIcon name={icon} collection={iconCollection} fontSize="5xl" />
            </Flex>
          )}
          <Text textAlign="center">{title}...</Text>
          <Progress colorScheme="green" isIndeterminate isAnimated borderRadius="lg" mt={5} />
        </chakra.div>
      </ScaleFade>
    </chakra.div>
  )
}

export default Loading
