import { BoxProps, HStack, useRadioGroup } from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import { gql_EnumSourceSchemaMode, useGetListEnvironmentsQuery } from '../../../core/graphql'
import { ID } from '../../../core/types/BaseCRUD'
import BaseIcon from '../../base/BaseIcon'
import SourceSchemaModeRadio, { SourceSchemaModeRadioProps } from './SourceSchemaModeRadio'

type Props = {
  name?: string
  defaultValue?: string
  spacing?: string | number
  onChange?: (value: string) => void
  projectId?: ID
} & Omit<BoxProps, 'onChange'>

const SourceEnvironmentRadioGroup: FC<Props> = ({
  name,
  defaultValue,
  projectId,
  onChange,
  spacing = 2,
  ...rest
}) => {
  const [envResult, refetchEnv] = useGetListEnvironmentsQuery({
    variables: { filter: { project: projectId } },
  })

  const { data: envData, fetching: fetchingEnv, error: envError } = envResult

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  })

  const group = getRootProps()

  return (
    <HStack d="flex" w="100%" {...group} py={1} spacing={spacing} {...rest} overflow="scroll">
      {envData?.environmentPagination?.items?.map((item, index) => {
        const radio = getRadioProps({ value: item.id } as any)
        return (
          <SourceSchemaModeRadio
            key={item.id}
            title={item.name}
            iconName={item.name == 'production' ? 'BiWorld' : 'RiServerFill'}
            iconCollection={item.name == 'production' ? 'bi' : 'ri'}
            {...radio}
            id={`option-${item.id}`}
            fontSize="md"
            iconFontSize="2xl"
            // alignItems="center"
          />
        )
      })}
    </HStack>
  )
}

export default SourceEnvironmentRadioGroup
