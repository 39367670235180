import { JSONSchema7Type } from 'json-schema'
import { ID } from '../types/BaseCRUD'
import { bridgedContentStateFamily } from './bridgedContentStateFamily'
import { useContent, UseContentResult } from './useContent'

export type UseBridgedContentOptions = {
  sourceId: ID
  environmentId: ID
  locale: string
  projectId?: ID
}

export const useBridgedContent = ({
  sourceId,
  locale,
  projectId,
  environmentId,
}: UseBridgedContentOptions): UseContentResult<JSONSchema7Type> =>
  useContent<JSONSchema7Type>({
    sourceId,
    locale,
    projectId,
    environmentId,
    atomFamily: bridgedContentStateFamily,
  })
