import React, { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Flex,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import BaseField from '../../old-components/workbench/ComponentEditor/base/BaseField'
import { FieldProps } from '../../core/types/FieldProps'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import SchemaField from './SchemaField'
import { getSchemaTypeName } from '../../core/utils/getSchemaTypeName'
import { defaultValues } from '../../core/utils/defaultValues'
import { computeRequired } from '../../core/utils/computeRequired'
import { computeDefaultValue } from '../../core/utils/computeDefaultValue'

type Props = {} & FieldProps

const ArrayField: FC<Props> = (props) => {
  const {
    value,
    onChange,
    label,
    path,
    schema,
    sourceId,
    schemaNodes,
    schemaPath,
    rootSchema,
  } = props
  const values: any[any] = value
  const styles = useMultiStyleConfig('ArrayField', props)

  const handleAddItem = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      if (onChange && (schema as any).items) {
        const pathToChange = path === '/' ? path + values.length : `${path}/${values.length}`
        onChange(pathToChange, computeDefaultValue((schema as any).items))
        // const typeName = getSchemaTypeName((schema as any).items)
        // if (typeName) {
        //   const pathToChange = path === '/' ? path + values.length : `${path}/${values.length}`
        //   if (typeName === 'object') {
        //     const newObj = {}
        //     computeRequired((schema as any).items, newObj)
        //     onChange(pathToChange, newObj)
        //   }
        //   onChange(pathToChange, defaultValues[Array.isArray(typeName) ? typeName[0] : typeName])
        // }
      }
    },
    [onChange, path, schema, values]
  )

  const formattedValues: any[any] = useMemo(() => {
    return (
      values &&
      values.filter((item: any) => typeof item === 'string' || typeof item === 'boolean' || !!item)
    )
  }, [values])

  return (
    <BaseField label={label}>
      <List sx={styles.list}>
        {formattedValues &&
          formattedValues.map((item: any, index: number) => {
            return (
              <Accordion
                key={`node-${path.replace('/', '-')}-${index}`}
                allowMultiple
                reduceMotion
                sx={styles.accordion}
              >
                <AccordionItem>
                  <AccordionButton as="div" sx={styles.listItem} justifyContent="flex-start">
                    <Flex flexDir="column" w="100%" alignItems="flex-start">
                      <Flex w="100%" overflow="hidden">
                        {!item && !item.title && (
                          <Badge
                            boxSize="20px"
                            d="inline-flex"
                            justifyContent="center"
                            alignItems="center"
                            mr={2}
                            ml={2}
                          >
                            {index + 1}
                          </Badge>
                        )}
                        {item && item.title && item.title.length ? item.title : item.component}
                        {label}
                        {!item.component && (
                          <Tag
                            ml={2}
                            size="sm"
                            w="100%"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                          >
                            {index + 1}
                          </Tag>
                        )}
                        <Spacer />

                        {item.component && (
                          <Tag
                            d="inline-flex"
                            justifyContent="center"
                            alignItems="center"
                            mr={2}
                            ml={2}
                            size="sm"
                          >
                            {item.component}
                          </Tag>
                        )}
                        <ArrayFieldMenu />
                      </Flex>
                      {item && item.componentDescription && (
                        <Text fontSize="sm" overflow="hidden" textOverflow="ellipsis" opacity="0.6">
                          {item.componentDescription}
                        </Text>
                      )}
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel pl={4} pr={0}>
                    <SchemaField
                      path={path === '/' ? path + index : `${path}/${index}`}
                      onChange={onChange}
                      // source={source}
                      parentPath={path}
                      parentSchemaPath={schemaPath}
                      schemaPath={
                        schema &&
                        typeof schema !== 'boolean' &&
                        schema.items &&
                        !Array.isArray(schema.items)
                          ? schemaPath === '/'
                            ? '/items'
                            : `${schemaPath}/items`
                          : schemaPath
                      }
                      value={item}
                      sourceId={sourceId}
                      schema={
                        schema &&
                        typeof schema !== 'boolean' &&
                        schema.items &&
                        !Array.isArray(schema.items)
                          ? schema.items
                          : undefined
                      }
                      rootSchema={rootSchema}
                      schemaNodes={schemaNodes}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              // <ListItem sx={styles.listItem} key={index}>
              //   <Badge
              //     boxSize="20px"
              //     d="inline-flex"
              //     justifyContent="center"
              //     alignItems="center"
              //     mr={2}
              //   >
              //     {index + 1}
              //   </Badge>
              //   {item && item.title && item.title.length ? item.title : item.component}
              // </ListItem>
            )
          })}
      </List>
      <Flex mb={4}>
        <BaseButton
          onClick={handleAddItem}
          size="sm"
          mt={0}
          lightBgColor="light.50"
          color="green.500"
        >
          <BaseIcon name="AiOutlinePlus" collection="ai" mr={1} />
          Add
        </BaseButton>
      </Flex>
    </BaseField>
  )
}

const ArrayFieldMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const onClickOpen = useCallback(
    (e: any) => {
      e.stopPropagation()
      onOpen()
    },
    [onOpen]
  )

  return (
    <Menu matchWidth isOpen={isOpen} onClose={onClose} isLazy>
      <MenuButton
        as={BaseButton}
        size="xs"
        variant="link"
        justifySelf="flex-end"
        onClick={onClickOpen}
      >
        <BaseIcon name="HiOutlineDotsVertical" collection="hi" />
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuItem>Duplicate</MenuItem>
          <MenuItem color="red.400">Delete</MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default ArrayField
