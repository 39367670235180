import React from 'react'
import { Input, useMultiStyleConfig, InputProps, BoxProps } from '@chakra-ui/react'
import Surface from './Surface'
import { useFocus } from '../../core/utils/useFocus'

type BaseSearchProps = {
  size?: 'sm' | 'md' | 'lg'
  expandible?: boolean
  inputProps?: InputProps
} & BoxProps

const BaseSearch = React.forwardRef<HTMLInputElement, BaseSearchProps>((props, ref) => {
  const { inputProps = {}, expandible, size = 'md', ...rest } = props
  const { focused, bind } = useFocus()
  const styles = useMultiStyleConfig('BaseSearch', { ...props, focused })

  return (
    <Surface sx={styles.wrapper} {...rest}>
      <Input
        sx={styles.input}
        as={Input}
        placeholder="Search.."
        borderRadius="md"
        border="none"
        ref={ref}
        size={size}
        {...inputProps}
      />
    </Surface>
  )
})

export default BaseSearch
