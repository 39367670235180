import React, { FC } from 'react'
import { NodeProps } from '../../core/types/NodeProps'
import NodeView from './NodeView'
import { useNode } from '../../core/workbench/useNode'
import NodeViewInfo from './NodeViewInfo'

type Props = NodeProps

const NumberNode: FC<Props> = (props) => {
  const { path, value, typeName } = props
  const { isSelected, toggleSelected } = useNode(props)
  return (
    <NodeView
      onValueChange={props.onChange!}
      // source={source}
      path={path}
      value={value}
      valueTypeName={typeName}
      isSelected={isSelected}
      onClick={toggleSelected}
      typeLabel="Number"
      icon="FaCalculator"
      infoComponent={<NodeViewInfo highlightedContent />}
      p={4}
    />
  )
}

export default NumberNode
