import React, { FC, forwardRef, useCallback, useMemo } from 'react'
import { Divider, useDisclosure } from '@chakra-ui/react'
import { Resizable, ResizableProps } from 're-resizable'
import DragHandle from '../sources/DragHandle'

type Props = {
  children?: React.ReactNode
  dragDirection: 'left' | 'right' | 'top' | 'bottom'
  isOpen?: boolean
} & ResizableProps

const EditorPanel = forwardRef<Resizable | undefined, Props>(
  ({ children, maxHeight, maxWidth, isOpen: defaultIsOpen, ...props }, ref) => {
    const { isOpen, isControlled, onToggle } = useDisclosure({ defaultIsOpen })
    const dragDirection = useMemo(() => (props as any).dragDirection || 'left', [props])
    const resizableProps = useMemo(() => {
      const { dragDirection, ...rest } = props as any
      return rest || {}
    }, [props])

    const enabledDragging = useMemo(
      () => ({
        top: dragDirection === 'top',
        right: dragDirection === 'right',
        left: dragDirection === 'left',
        bottom: dragDirection === 'bottom',
      }),
      [dragDirection]
    )

    return (
      <Resizable
        ref={ref}
        style={{
          display: 'flex',
          height: isOpen ? '100vh' : '0',
          overflow: 'visible',
          maxHeight: isOpen ? '90vh' : '0',
        }}
        enable={enabledDragging}
        maxHeight="95vh"
        handleComponent={{
          top: <DragHandle top="7px" onClick={onToggle} />,
          left: <DragHandle left="-5px" top="0px" direction="horizontal" onClick={onToggle} />,
          right: <DragHandle right="-5px" top="0px" direction="horizontal" onClick={onToggle} />,
          bottom: <DragHandle bottom="-7px" onClick={onToggle} />,
        }}
        {...resizableProps}
      >
        {/* <pre>{JSON.stringify(enabledDragging, null, 2)}</pre> */}
        {children}
      </Resizable>
    )
  }
)

export default EditorPanel
