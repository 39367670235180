import React, { FC, useCallback, useState } from 'react'
import { Button, chakra } from '@chakra-ui/react'
import { AddIcon } from '@chakra-ui/icons'
import ReactDataSheet from 'react-datasheet'

type CellValueType = string | number | null

interface GridElement extends ReactDataSheet.Cell<GridElement, string> {
  value: CellValueType
}

type Props = {
  projectId: string
}

const RedirectsMapForm: FC<Props> = () => {
  const [data, setData] = useState<GridElement[][]>([
    [{ value: '/jet-privati' }, { value: '/it-IT/jet-privati' }, { value: 301 }],
    [{ value: '/chi-siamo' }, { value: '/it-IT/chi-siamo' }, { value: 301 }],
    [{ value: '/servizi' }, { value: '/it-IT/servizi' }, { value: 301 }],
  ])

  const handleCellsChanged = useCallback<ReactDataSheet.CellsChangedHandler<GridElement, string>>(
    (changes, additions) => {
      const copiedData = data.map((item) => [...item])
      changes.forEach(({ cell, row, col, value }) => {
        copiedData[row][col] = { ...copiedData[row][col], value }
      })

      if (additions) {
        additions.forEach(({ row, col, value }) => {
          if (!copiedData[row]) {
            copiedData[row] = [{ value: '' }, { value: '' }, { value: 301 }]
          }

          if (copiedData[row][col]) {
            copiedData[row][col] = { ...copiedData[row][col], value }
          }
        })
      }

      setData(copiedData)
      // console.log('ciao da Excel changes:', changes)
      // console.log('ciao da Excel additions:', additions)
    },
    [data]
  )

  const handleAddNewRow = useCallback(() => {
    setData([...data, [{ value: '' }, { value: '' }, { value: 301 }]])
  }, [data])

  const valueRenderer = useCallback((cell: any) => cell.value, [])

  return (
    <chakra.div w="100%">
      <ReactDataSheet
        data={data}
        onCellsChanged={handleCellsChanged}
        valueRenderer={valueRenderer}
      />
      <Button onClick={handleAddNewRow} size="sm" leftIcon={<AddIcon />}>
        Add row
      </Button>
    </chakra.div>
  )
}

export default RedirectsMapForm
