import React, { FC, useMemo } from 'react'
import { NodeProps } from '../../../core/types/NodeProps'
import NodeView from '../NodeView'
import { useNode } from '../../../core/workbench/useNode'
import EmailNodeInfo from './EmailNodeInfo'
import UriImageNodeInfo from './UriImageNodeInfo'
import HtmlMediaTypeNodeInfo from './HtmlMediaTypeNodeInfo'

const isImage = require('is-image')

type Props = NodeProps

const StringNode: FC<Props> = (props) => {
  const { value, path, typeName } = props
  const { isSelected, toggleSelected } = useNode(props)

  const format = useMemo(() => {
    if (props.schema && typeof props.schema === 'object' && props.schema.format) {
      return props.schema.format
    }
  }, [props.schema])

  const contentMediaType = useMemo(() => {
    if (props.schema && typeof props.schema === 'object' && props.schema.contentMediaType) {
      return props.schema.contentMediaType
    }
  }, [props.schema])

  const nodeProps = useMemo(() => {
    if (format) {
      switch (format) {
        case 'email':
          return {
            typeLabel: 'E-mail',
            icon: 'FaEnvelope',
            iconCollection: 'fa',
            infoComponent: <EmailNodeInfo />,
          }
        case 'uri':
          if (isImage(value)) {
            return {
              typeLabel: 'Image',
              icon: 'FaImage',
              infoComponent: <UriImageNodeInfo />,
            }
          }
          break
        case 'text/html':
          return {
            typeLabel: 'Html',
            icon: 'FaLink',
            infoComponent: <HtmlMediaTypeNodeInfo />,
          }
        default:
          break
      }
    }

    if (contentMediaType) {
      switch (contentMediaType) {
        case 'text/html':
          return {
            typeLabel: 'HTML',
            icon: 'FaCode',
            infoComponent: <HtmlMediaTypeNodeInfo />,
          }
        default:
          break
      }
    }
    return {}
  }, [value, contentMediaType, format])

  return (
    <NodeView
      onValueChange={props.onChange!}
      // source={source}
      path={path}
      title="typeName"
      value={value || ''}
      valueTypeName={typeName}
      isSelected={isSelected}
      typeLabel="String"
      icon="FaFont"
      onClick={toggleSelected}
      p={4}
      {...nodeProps}
    />
  )
}

export default StringNode
