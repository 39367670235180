import React, { cloneElement, FC } from 'react'
import { Text, ListItem, useMultiStyleConfig, BoxProps, ListItemProps } from '@chakra-ui/react'
import BaseIcon from '../BaseIcon'
import Surface, { SurfaceProps } from '../Surface'
import { ModalMenuItem, ModalSubMenuItem } from './MasterDetailMenu'

type MasterDetailSubMenuItemProps = {
  selectedItem?: ModalSubMenuItem | ModalMenuItem
  onClickItem: (menuItem: ModalSubMenuItem) => void
  key?: string | number
} & Omit<ModalMenuItem, 'submenu'> &
  ListItemProps

export const MasterDetailSubMenuItem: FC<MasterDetailSubMenuItemProps> = (props) => {
  const { onClickItem, title, subtitle, id, selectedItem, iconComponent, ...rest } = props
  const styles = useMultiStyleConfig('MasterDetail', props)

  return (
    <ListItem
      sx={styles.menuListSubItem}
      as={Surface}
      lightBgColor="light.100"
      lightBgHoverColor="light.200"
      borderRadius="md"
      boxShadow="none"
      hoverable
      textAlign="left"
      d="flex"
      p={4}
      py={3}
      onClick={(event) => onClickItem({ title, subtitle, id })}
      isSelected={id === selectedItem?.id}
      {...rest}
    >
      {iconComponent ? (
        cloneElement(iconComponent, { alignSelf: 'center' })
      ) : (
        <BaseIcon name="BiCode" collection="bi" mr={2} alignSelf="center" />
      )}
      <Text>{title}</Text>
    </ListItem>
  )
}

export default MasterDetailSubMenuItem
