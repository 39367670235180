import { mode } from '@chakra-ui/theme-tools'

const styles = {
  global: (props: any): Record<any, unknown> => {
    return {
      body: {
        overflow: 'hidden',
      },
      '#root, html, body': {
        color: mode('light.600', 'dark.100')(props),
        backgroundColor: mode('light.100', 'dark.900')(props),
        fontFamily: 'body',
      },
    }
  },
}

export default styles
