import { createClient } from 'urql'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'

export const createUrqlClient = () =>
  createClient({
    url: process.env.REACT_APP_GATEWAY_URI || '/api/graphql',
    fetchOptions: {
      credentials: 'include',
    },
    exchanges: [multipartFetchExchange as any],
  })
