import React, { FC, isValidElement, useCallback, useEffect, useMemo, useState } from 'react'
import { JSONSchema7Type, JSONSchema7TypeName } from 'json-schema'
import { Box, BoxProps, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import NodeViewIcon from './NodeViewIcon'
import NodeViewInfo from './NodeViewInfo'
import NodeViewOptions from './NodeViewOptions'
import NodeViewStartControls from './NodeViewStartControls'
import NodeViewEndControls from './NodeViewEndControls'
import Surface from '../base/Surface'
import NodeViewSuggestions from './NodeViewSuggestions'
import { useSelectedNodeSuggestionsState } from '../../core/workbench/useSelectedNodeSuggestionsState'
import useHover from '../../core/utils/useHover'
import { gql_HySourceFragment } from '../../core/graphql'

type Props = {
  source?: gql_HySourceFragment
  valueTypeName: JSONSchema7TypeName | 'unsupported'
  value: JSONSchema7Type
  path: string
  isSelected: boolean
  onClick?: (path: string) => void
  infoComponent?: React.ReactNode
  optionsComponent?: React.ReactNode
  iconComponent?: React.ReactNode
  typeLabel: string
  icon: string
  onValueChange: (path: string, value: JSONSchema7Type) => void
} & BoxProps

const NodeView: FC<Props> = (props) => {
  const {
    children,
    source,
    path,
    value,
    valueTypeName,
    isSelected,
    onClick,
    typeLabel,
    icon,
    infoComponent = <NodeViewInfo />,
    optionsComponent = <NodeViewOptions />,
    iconComponent = <NodeViewIcon />,
    onValueChange,
    ...rest
  } = props
  const [hoverRef, isHover] = useHover(50)
  const [suggestionPosition, setSuggestionPosition] = useState<'before' | 'after' | null>(null)

  const title = useMemo(() => {
    if (path === '/' || !path) {
      if (props.title) return props.title
      return 'root'
    }
    const splitted = path.split('/')

    return splitted[splitted.length - 1]
  }, [path])

  const height = useMemo(() => {
    return hoverRef.current && hoverRef.current.clientHeight
  }, [hoverRef.current])

  const styles = useMultiStyleConfig('NodeView', { ...props, height })

  const handleInsertBefore = useCallback(() => {
    setSuggestionPosition(suggestionPosition == 'before' ? null : 'before')
    // setSelectedNodeSuggestions({ path })
  }, [path, suggestionPosition])

  const handleInsertAfter = useCallback(() => {
    setSuggestionPosition(suggestionPosition == 'after' ? null : 'after')
    // setSelectedNodeSuggestions({ path })
  }, [path, suggestionPosition])

  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(path)
      }
    },
    [path, onClick]
  )

  // useEffect(() => {
  //   if (selectedNodeSuggestions.path != path ) {
  //     setSuggestionPosition(null)
  //   }
  // }, [ selectedNodeSuggestions, path])

  if (path === '/' && (valueTypeName === 'array' || valueTypeName === 'object')) {
    return <>{children}</>
  }

  // TESTING WHY IS SO SLOW
  // return (
  //   <div style={{ padding: '10px', border: '1px solid white' }}>
  //     <div>
  //       <div>
  //         ciao: {title}
  //       </div>
  //       <div>
  //         {isValidElement(optionsComponent) && React.cloneElement<any>(optionsComponent, {
  //           title,
  //           value,
  //           source,
  //           path,
  //           valueTypeName,
  //           isSelected,
  //           onValueChange
  //         }, null)}
  //       </div>
  //       <div>
  //         {isValidElement(iconComponent) && React.cloneElement<any>(iconComponent, { typeLabel, icon }, null)}
  //         {isValidElement(infoComponent) &&
  //               React.cloneElement<any>(
  //                 infoComponent,
  //                 {
  //                   value,
  //                   title,
  //                   ...(infoComponent as any).props,
  //                 },
  //                 null
  //               )}
  //       </div>
  //     </div>
  //     <div>{children}</div>
  //   </div>
  // )

  return (
    <chakra.div
      d="flex"
      flexDirection={suggestionPosition == 'before' ? 'column' : 'column-reverse'}
    >
      <NodeViewSuggestions isOpen={suggestionPosition != null} source={source!} mx={-5} />
      <chakra.div sx={styles.wrapper} zIndex={isHover ? (isSelected ? 1 : 2) : undefined}>
        <chakra.div sx={styles.container} onClick={handleClick} ref={hoverRef}>
          <NodeViewStartControls isVisible={isHover} />
          <Surface
            darkVariant="base"
            lightBgColor="white"
            lightBgHoverColor="lightAlpha.900"
            hoverable
            isSelected={isSelected}
            sx={styles.content}
            {...rest}
          >
            <Box flex="1" display="flex" minW={0}>
              {isValidElement(iconComponent) &&
                React.cloneElement<any>(iconComponent, { typeLabel, icon }, null)}
              {isValidElement(infoComponent) &&
                React.cloneElement<any>(
                  infoComponent,
                  {
                    value,
                    title,
                    typeLabel,
                    ...(infoComponent as any).props,
                  },
                  null
                )}
            </Box>
            {isValidElement(optionsComponent) &&
              React.cloneElement<any>(
                optionsComponent,
                {
                  title,
                  value,
                  source,
                  path,
                  valueTypeName,
                  isSelected,
                  onValueChange,
                },
                null
              )}
          </Surface>
          <NodeViewEndControls
            onInsertBefore={handleInsertBefore}
            onInsertAfter={handleInsertAfter}
            isVisible={isHover}
          />
        </chakra.div>
        {children && <Box ml={15}>{children}</Box>}
      </chakra.div>
    </chakra.div>
  )
}

export default NodeView
