import React, { cloneElement, FC, useCallback } from 'react'
import {
  Text,
  chakra,
  List,
  Divider,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useMultiStyleConfig,
  AccordionIcon,
  BoxProps,
  useDisclosure,
} from '@chakra-ui/react'
import BaseButton from '../BaseButton'
import BaseIcon from '../BaseIcon'
import { ModalMenuItem } from './MasterDetailMenu'

type MasterDetailMenuItemProps = {
  onClickItem: (menuItem: ModalMenuItem) => void
  statusComponent?: React.ReactElement
} & ModalMenuItem &
  BoxProps

export const MasterDetailMenuItem: FC<MasterDetailMenuItemProps> = (props) => {
  const {
    children,
    onClickItem,
    title,
    subtitle,
    id,
    iconComponent,
    statusComponent,
    isActive = false,
    isDisabled,
    ...rest
  } = props
  const styles = useMultiStyleConfig('MasterDetail', props)
  const { isOpen, onToggle } = useDisclosure({ isOpen: isActive, defaultIsOpen: false })

  const onClick = useCallback(() => {
    onClickItem({ title, subtitle, id })
    onToggle()
  }, [onClickItem])

  return (
    <chakra.div sx={styles.menuItem} {...rest}>
      <AccordionItem mb={0} isOpen={isOpen} isDisabled={isDisabled}>
        <AccordionButton
          as={BaseButton}
          textAlign="left"
          justifyContent="flex-start"
          alignItems="flex-start"
          py={4}
          pl={9}
          pr={4}
          borderRadius="md"
          isActive={!!isOpen}
          isDisabled={isDisabled}
          onClick={onClick}
        >
          {iconComponent ? (
            cloneElement(iconComponent, { alignSelf: 'center' })
          ) : (
            <BaseIcon
              name="RiUploadCloud2Fill"
              collection="ri"
              fontSize="2xl"
              mr={3}
              alignSelf="center"
            />
          )}
          <chakra.div w="100%">
            <Heading fontSize="xl">{title}</Heading>
            {subtitle && (
              <Text fontSize="sm" fontWeight="normal">
                {subtitle}
              </Text>
            )}
          </chakra.div>
          {children && <AccordionIcon fontSize="2xl" />}
          {statusComponent && (
            <chakra.div position="absolute" right={3} top={3}>
              {cloneElement(statusComponent, {})}
            </chakra.div>
          )}
        </AccordionButton>
        {children && (
          <AccordionPanel>
            <List spacing={2} sx={styles.menuList}>
              {children}
            </List>
          </AccordionPanel>
        )}
      </AccordionItem>
      <Divider my={2} />
    </chakra.div>
  )
}

export default MasterDetailMenuItem
