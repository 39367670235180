import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import {
  ModalHeader,
  Flex,
  ModalBody,
  ModalFooter,
  Text,
  Badge,
  chakra,
  Button,
  Fade,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalProps,
  useToast,
} from '@chakra-ui/react'
import { DropEvent } from 'react-dropzone'
import BaseButton from '../../../components/base/BaseButton'
import BaseIcon from '../../../components/base/BaseIcon'
import Surface from '../../../components/base/Surface'
import MediaExplorerGrid from './MediaExplorerGrid'
import { Media } from '../../../core/cms/media/Media'
import { useMediaProvider } from '../../../core/cms/media/useMediaProvider'
import GradientBox from '../../../components/base/GradientBox'
import BaseSearch from '../../../components/base/BaseSearch'
import MediaDropZone from './MediaDropZone'
import MediaExplorerImporFiles from './MediaExplorerImportFiles'
import MediaForm from './MediaForm'

type Props = {
  isOpen: boolean
  onClose: () => void
  finalFocusRef?: React.RefObject<any>
  onClick?: (media: Media) => void
  children?: ReactNode
} & Omit<ModalProps, 'children'>

const MediaExplorerModal: FC<Props> = ({ isOpen, onClose, onClick, finalFocusRef, ...rest }) => {
  const [data, setData] = useState<Media[]>([])
  const [total, setTotal] = useState<number>(0)
  const [offset, setOffset] = useState<number>(1)
  const [limit, setLimit] = useState<number>(40)
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const toast = useToast()
  const mediaProvider = useMediaProvider()
  const [droppedFiles, setDroppedFiles] = useState<any[] | null>()
  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>(undefined)

  const fetchData = useCallback(
    async ({ append, forceRefresh }: { append: boolean; forceRefresh: boolean }) => {
      try {
        setLoading(true)
        const result = await mediaProvider?.getList({
          filters: {},
          pagination: {
            offset,
            limit,
          },
          sort: {
            id: 'DESC',
          },
          options: {
            forceNoCache: forceRefresh,
          },
        })

        if (result && result.total) {
          setTotal(result.total)
        }

        if (result && result.data) {
          if (append) {
            setData([...data, ...result.data])
          } else {
            setData(result.data)
          }
        } else {
          throw new Error('Result has no data')
        }
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
        setLoaded(true)
      }
    },
    [data, limit, mediaProvider, offset]
  )

  const handleLoadMore = useCallback((): void => {
    setOffset(offset + 1)
  }, [offset])

  const onDropFiles = useCallback((files: any[], rejectedFiles: any[], event: DropEvent) => {
    setDroppedFiles(files)
  }, [])

  const removeDroppedFiles = useCallback(() => {
    setDroppedFiles(null)
  }, [])

  const handleUploadImage = useCallback(async () => {
    try {
      setError(undefined)
      setLoading(true)
      if (droppedFiles && droppedFiles.length > 0) {
        for (const file of droppedFiles) {
          console.log(file, 'ciao')
          // eslint-disable-next-line no-await-in-loop
          const result = await mediaProvider?.create({
            file,
            name: file.name,
          })
        }

        await fetchData({ forceRefresh: true, append: false })
        removeDroppedFiles()
      }
    } catch (e) {
      console.error('Error uploading media', e)
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [droppedFiles, fetchData, mediaProvider, removeDroppedFiles])

  const handleMediaUpdate = useCallback(
    async (media: Media, data: any) => {
      try {
        const result = await mediaProvider?.update(media.id, {
          name: data.name,
          alternativeText: data.alternativeText,
        })
      } catch (error) {
        console.error('error updating media', error)
        toast({
          title: 'Error updating media',
          description: error.message,
          duration: 3000,
          status: 'error',
        })
      }
    },
    [toast]
  )

  const handleMediaDelete = useCallback(() => {}, [])

  const handleMediaClick = useCallback(
    (media: Media) => {
      console.log('onClick: ', onClick, 'selectedMedia', selectedMedia, 'newMedia', media)
      if (onClick) {
        onClick(media)
      } else if (selectedMedia?.id === media.id) {
        setSelectedMedia(undefined)
      } else {
        setSelectedMedia(media)
      }
    },
    [onClick, selectedMedia]
  )

  const handleDeselectMedia = useCallback(() => {
    setSelectedMedia(undefined)
  }, [])

  useEffect(() => {
    if (!isOpen) {
      removeDroppedFiles()
    }
  }, [isOpen])

  useEffect(() => {
    fetchData({ append: false, forceRefresh: true })
  }, [])

  useEffect(() => {
    if (offset > 1 && limit * (offset - 1) <= total) {
      fetchData({ append: true, forceRefresh: false })
    }
  }, [offset])

  if (error) {
    return <div>Error! {error.message}</div>
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" finalFocusRef={finalFocusRef} {...rest}>
      <ModalOverlay />
      <Surface
        as={ModalContent}
        lightBgColor="light.100"
        darkBgColor="dark.900"
        d="flex"
        flexDir="row"
        my={0}
        h="100vh"
        m={0}
      >
        <Flex flexDir="column" position="relative" w="60%">
          <GradientBox
            position="absolute"
            height="200px"
            width="100%"
            top={0}
            left={0}
            right={0}
            maxWidth="100%"
            zIndex="1"
            lightColors={['light.200', 'transparent']}
            darkColors={['dark.900', 'transparent']}
            direction="to-b"
          />
          <ModalHeader pos="absolute" zIndex="1" left="0" top="0" d="flex" w="100%">
            <chakra.div w="100%">
              <Flex>
                <BaseIcon name="MdPermMedia" collection="md" fontSize="2xl" mr={3} />
                <Flex flexDir="column">
                  <Text fontSize="2xl">Media</Text>
                  <Text fontSize="xs">{total || 0} media</Text>
                </Flex>
              </Flex>
            </chakra.div>

            <Flex alignSelf="flex-end">
              <BaseSearch size="md" w="100%" minW="240px" boxShadow="sm" />
            </Flex>
          </ModalHeader>

          <ModalBody pb={4} pt={24} mt={3} zIndex="0" overflow="scroll" h="100%">
            <MediaExplorerGrid loaded={loaded} medias={data} onClick={handleMediaClick} />
            {total > data.length && (
              <Surface
                variant="heavy"
                w="100%"
                p={3}
                borderRadius="md"
                textAlign="center"
                overflow="hidden"
                onClick={handleLoadMore}
                hoverable
              >
                {!loading ? 'Load more' : 'Loading...'}
              </Surface>
            )}
          </ModalBody>
        </Flex>
        <Surface
          w="40%"
          position="relative"
          d="flex"
          flexDir="column"
          darkBgColor="dark.700"
          lightBgColor="light.50"
        >
          <ModalHeader w="100%">
            <Text
              d="flex"
              cursor="pointer"
              fontSize="sm"
              opacity="0.6"
              onClick={removeDroppedFiles}
            >
              {droppedFiles ? (
                <>
                  <BaseIcon name="FaChevronLeft" mr={1} fontSize="xs" /> Import Files
                </>
              ) : (
                <>Import files</>
              )}
            </Text>
            <Flex>
              <BaseIcon name="BiImport" collection="bi" fontSize="2xl" mr={2} />
              {droppedFiles ? (
                <Text fontSize="2xl">Selected Files</Text>
              ) : selectedMedia ? (
                <Text fontSize="2xl">Selected Media</Text>
              ) : (
                <Text fontSize="2xl">Import Files</Text>
              )}
            </Flex>
          </ModalHeader>

          {selectedMedia ? (
            <MediaForm
              record={selectedMedia}
              onCancel={handleDeselectMedia}
              onSubmit={(record, data) => {
                console.log('vai che va', record, data)
              }}
            />
          ) : (
            <>
              <ModalBody p={5} flex="1">
                <ModalCloseButton
                  as={BaseButton}
                  variant="solid"
                  size="lg"
                  top={3}
                  right={3}
                  zIndex={9}
                />
                <Fade in={!!droppedFiles} unmountOnExit style={{ height: '100%' }}>
                  <MediaExplorerImporFiles files={droppedFiles || [{}]} />
                </Fade>
                <Fade in={!droppedFiles} unmountOnExit style={{ height: '100%' }}>
                  <MediaDropZone onDropFiles={onDropFiles} mb={3} h="100%" />
                </Fade>
              </ModalBody>
              {droppedFiles && (
                <ModalFooter>
                  <BaseButton variant="ghost" mr={3} onClick={removeDroppedFiles}>
                    Cancel
                  </BaseButton>
                  <Button
                    onClick={handleUploadImage}
                    variant="ghost"
                    colorScheme="green"
                    size="lg"
                    lightHue="light"
                  >
                    Import {droppedFiles.length} files
                    <BaseIcon name="BiUpload" collection="bi" ml={2} fontSize="xl" />
                  </Button>
                </ModalFooter>
              )}
            </>
          )}
        </Surface>
      </Surface>
    </Modal>
  )
}

export default MediaExplorerModal
