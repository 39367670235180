import React, { ChangeEventHandler, FC, useCallback, useState } from 'react'
import {
  Drawer,
  Text,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Flex,
  DrawerBody,
  useDisclosure,
  Badge,
  Heading,
  useToast,
  chakra,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'urql'
import SourcesGrid from './SourcesGrid'
import CreateSourceDrawer from '../CreateSourceDrawer'
import {
  useGetListSourcesQuery,
  gql_SortFindManySourceInput,
  useGetProjectByIdQuery,
  gql_EnumDKeySourceKind,
  gql_CreateSourceMutation,
  gql_CreateSourceMutationVariables,
  useCreateSourceMutation,
  gql_EnumSourceSchemaMode,
} from '../../../core/graphql'
import { ID } from '../../../core/types/BaseCRUD'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import BaseSearch from '../../base/BaseSearch'
import GradientBox from '../../base/GradientBox'
import Surface from '../../base/Surface'
import { useDebounce } from '../../../core/utils/useDebounce'
import { MUTATION_CREATE_SOURCE } from '../../../queries'
import BoxIcon from '../../base/BoxIcon'

type Props = {
  isOpen: boolean
  onClose: () => void
  finalFocusRef?: React.RefObject<any>
  projectId: ID
  sourceId?: ID
}

const SourcesDrawer: FC<Props> = (props) => {
  const { onClose, finalFocusRef, projectId, sourceId, isOpen, ...rest } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const toast = useToast()
  const [createSourceResult, createSource] = useCreateSourceMutation()
  const [projectResult, refetchProject] = useGetProjectByIdQuery({ variables: { id: projectId } })
  const [sourcesResult, refetchSources] = useGetListSourcesQuery({
    variables: {
      filter: {
        project: projectId,
        ...(debouncedSearchValue
          ? {
              _operators: {
                name: { regex: `/${debouncedSearchValue}/i` },
              },
            }
          : {}),
      },
      sort: gql_SortFindManySourceInput.NameAsc,
      page: 0,
      perPage: 100,
    },
  })

  const { data: projectData, fetching: projectFetching, error: projectError } = projectResult
  const { data: sourcesData, fetching: sourcesFetching, error: sourcesError } = sourcesResult

  const history = useHistory()

  const handleSearchValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSearchValue(e.target.value)
  }, [])

  const handleDeleteSourceComplete = useCallback(() => {
    refetchSources({
      requestPolicy: 'cache-and-network',
    })
  }, [refetchSources])

  const handleNewSourceClick = useCallback(
    async (kind: gql_EnumDKeySourceKind, schemaMode?: gql_EnumSourceSchemaMode) => {
      try {
        const result = await createSource({
          record: { kind, project: projectData?.project?.id, schemaMode },
        })

        if (result?.error) {
          throw new Error(result.error?.message)
        } else if (result?.data?.sourceCreateOne?.record?.id) {
          history.push(
            `/workbench/${projectId}/sources/${result.data?.sourceCreateOne?.record?.id}`
          )
        } else {
          throw new Error('unexpected error creating source')
        }
      } catch (error) {
        toast({
          title: 'Error creating source',
          description: error.message,
          status: 'error',
          duration: 3000,
        })
        console.error('error creating source', error)
      }
    },
    [createSource, history, projectData?.project?.id, projectId, toast]
  )

  const handleSourceClick = useCallback(
    (source) => {
      setTimeout(() => {
        history.push(`/workbench/${projectData?.project?.id}/sources/${source?.id}`)
      }, 100)
      onClose && onClose()
    },
    [history, onClose, projectData]
  )

  const closeDrawer = useCallback(() => {
    // onCloseDrawer()
    onClose && onClose()
  }, [onClose])

  // if (projectFetching || sourcesFetching) {
  //   // TODO: make me more ui friendly
  //   return (

  //   )
  // }

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={closeDrawer}
      finalFocusRef={finalFocusRef}
      size="xl"
      {...rest}
    >
      <DrawerOverlay>
        <Surface as={DrawerContent} lightBgColor="light.100" darkBgColor="dark.800">
          {projectFetching ? (
            <BaseButton
              disabled
              isLoading={true}
              right="-50px"
              top={3}
              position="absolute"
              boxSize="40px"
              p={2}
            >
              <BaseIcon name="IoCloseOutline" collection="io5" fontSize="3xl" />
            </BaseButton>
          ) : (
            <>
              <GradientBox
                position="absolute"
                height="200px"
                width="100%"
                top={0}
                left={0}
                right={0}
                maxWidth="100%"
                zIndex="1"
                lightColors={['light.100', 'transparent']}
                darkColors={['dark.800', 'transparent']}
                direction="to-b"
                pointerEvents="none"
              />
              <BaseButton
                right="-50px"
                top={3}
                position="absolute"
                boxSize="40px"
                p={2}
                onClick={closeDrawer}
              >
                <BaseIcon name="IoCloseOutline" collection="io5" fontSize="3xl" />
              </BaseButton>
              <DrawerHeader
                pos="sticky"
                zIndex="1"
                left="0"
                top="0"
                w="100%"
                d="flex"
                px={9}
                pt={9}
                pb={0}
              >
                <GradientBox
                  position="absolute"
                  height="100px"
                  width="100%"
                  top={0}
                  left={0}
                  right={0}
                  maxWidth="100%"
                  zIndex="0"
                  lightColors={['light.100', 'transparent']}
                  darkColors={['dark.900', 'transparent']}
                />
                <chakra.div w="100%" position="relative">
                  {/* <Text fontSize="sm" fontWeight="normal">
                    {projectData?.project?.name}
                  </Text> */}
                  <Flex>
                    <BoxIcon name="FaBox" collection="fa" />
                    <Flex flexDir="column" justifyContent="center">
                      <Heading fontSize="2xl"> {projectResult.data?.project?.name}</Heading>
                      <Text variant="outline" fontSize="xs" w="100%" textAlign="left">
                        {sourcesData?.sourcePagination?.count || 0} sources
                      </Text>
                    </Flex>
                  </Flex>
                </chakra.div>
                <Flex pos="relative" alignSelf="flex-end" justifySelf="flex-end" flexDir="column">
                  <BaseSearch
                    size="md"
                    minW="240px"
                    inputProps={{
                      value: searchValue,
                      onChange: handleSearchValueChange,
                    }}
                  />
                </Flex>
              </DrawerHeader>
              <DrawerBody p={9} pt={1} pos="relative" zIndex={1}>
                <Tabs variant="solid-rounded" align="start" isFitted={false} size="lg">
                  <TabList alignSelf="flex-start" mb={6}>
                    <Tab>Content</Tab>
                    <Tab>Page</Tab>
                    <Tab>Layout</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      {sourcesFetching ? (
                        <BaseButton
                          disabled
                          isLoading={true}
                          right="-50px"
                          top={3}
                          position="absolute"
                          boxSize="40px"
                          p={2}
                        >
                          <BaseIcon name="IoCloseOutline" collection="io5" fontSize="3xl" />
                        </BaseButton>
                      ) : (
                        <SourcesGrid
                          projectId={projectId}
                          sources={(sourcesData?.sourcePagination?.items || []) as any}
                          onClick={handleSourceClick}
                          onNewSourceClick={handleNewSourceClick}
                          onDeleteComplete={handleDeleteSourceComplete}
                        />
                      )}
                    </TabPanel>
                    <TabPanel>
                      <p>Pages</p>
                    </TabPanel>
                    <TabPanel>
                      <p>Layouts</p>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </DrawerBody>
            </>
          )}
        </Surface>
      </DrawerOverlay>
      {/* <CreateSourceDrawer
        isOpen={showNewSource}
        onClose={handleNewSourceClose}
        projectId={projectId}
      /> */}
    </Drawer>
  )
}

export default SourcesDrawer
