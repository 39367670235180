import React, { Children, cloneElement, FC, isValidElement } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useHydrogenCore } from '../../core/hydrogen/useHydrogenCore'
import { HydrogenCoreProps } from '../../core/types/HydrogenCoreProps'
import HydrogenRouterRedirect from './HydrogenIndexRedirect'
import ProjectsExplorer from '../projects/ProjectExplorer/ProjectsExplorer'
import WorkbenchGroup from '../workbench/WorkbenchGroup'
import LoginPage from '../auth/LoginPage'

type Props = HydrogenCoreProps

const HydrogenCore: FC<Props> = (props) => {
  const { children, customRoutes, indexRedirect = true } = props
  const [
    {
      initialized,
      childrenCount,
      projectsExplorerPath,
      workbenchPath,
      hasAuthProvider,
      hasProjectsExplorer,
    },
  ] = useHydrogenCore(props)
  if (!initialized) {
    return null
  }

  return (
    <>
      {childrenCount === 0 ? (
        <Switch>
          {hasAuthProvider && (
            <Route
              path="/login"
              exact
              render={({ match }): React.ReactNode => {
                return <LoginPage {...match.params} />
              }}
            />
          )}
          <Route
            path={projectsExplorerPath}
            render={({ match }): React.ReactNode => {
              return <ProjectsExplorer {...match.params} />
            }}
          />
          <Route
            path={workbenchPath}
            render={({ match }): React.ReactNode => {
              return <WorkbenchGroup {...match.params} />
            }}
          />
          {customRoutes && customRoutes}
          {indexRedirect && <HydrogenRouterRedirect indexRedirect={indexRedirect} />}
        </Switch>
      ) : (
        <Switch>
          {hasAuthProvider && (
            <Route
              path="/login"
              exact
              render={({ match }): React.ReactNode => {
                return <LoginPage {...match.params} />
              }}
            />
          )}
          {customRoutes && customRoutes}
          {Children.map(children, (child: React.ReactNode, index) => {
            if (isValidElement(child)) {
              switch (child.type) {
                case ProjectsExplorer:
                  return (
                    <Route
                      key={`route-${index}`}
                      exact={child.props.exact}
                      path={projectsExplorerPath}
                      render={({ match }): React.ReactNode => {
                        return cloneElement(child, {
                          path: undefined,
                          exact: undefined,
                          defaultRedirect: undefined,
                          ...match.params,
                          ...child.props,
                        })
                      }}
                    />
                  )
                case WorkbenchGroup:
                  return (
                    <Route
                      key={`route-${index}`}
                      exact={child.props.exact}
                      path={workbenchPath}
                      render={({ match }): React.ReactNode => {
                        return cloneElement(child, {
                          path: undefined,
                          exact: undefined,
                          defaultRedirect: undefined,
                          hasProjectsExplorer,
                          ...match.params,
                          ...child.props,
                        })
                      }}
                    />
                  )
                default:
                  return child
              }
            }

            return null
          })}
          {indexRedirect && (childrenCount > 1 || childrenCount === 0) && (
            <HydrogenRouterRedirect indexRedirect={indexRedirect} />
          )}
        </Switch>
      )}
    </>
  )
}

export default HydrogenCore
