import { JSONSchema7Definition } from 'json-schema'
import { useCallback } from 'react'
import { Container } from 'typedi'
import { BaseFile } from '../types/BaseFile'
import { ClassType } from '../types/ClassType'

export const TOKEN_SCHEMA_PROVIDER = 'hydrogen_schemaProvider'

type SetSchemaProviderFunction = (
  newValue: ClassType<BaseFile<JSONSchema7Definition>> | undefined
) => void

export const useSchemaProvider = (): BaseFile<JSONSchema7Definition> | undefined => {
  return Container.get<BaseFile<JSONSchema7Definition>>(TOKEN_SCHEMA_PROVIDER)
}

export const useSetSchemaProvider = (): SetSchemaProviderFunction => {
  return useCallback((newValue: ClassType<BaseFile<JSONSchema7Definition>> | undefined) => {
    Container.set({ id: TOKEN_SCHEMA_PROVIDER, type: newValue })
  }, [])
}
