import React, { FC, useCallback, useMemo } from 'react'
import { JSONSchema7TypeName } from 'json-schema'
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, chakra, Flex } from '@chakra-ui/react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useBridgedContent } from '../../core/contents/useBridgedContent'
import { useGetSelectedNodeState } from '../../core/workbench/useSelectedNodeState'
import { useNodeDelegate } from '../../core/workbench/useNodeDelegate'
import { useSchema } from '../../core/schema/useSchema'
import { ID } from '../../core/types/BaseCRUD'
import SchemaField from '../fields/SchemaField'

export type SchemaNodeKey = JSONSchema7TypeName | 'unsupported'

type Props = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  locale: string
  fields?: {
    [component in SchemaNodeKey]: React.ReactElement
  }
}

const Inspector: FC<Props> = ({ sourceId, projectId, environmentId, fields, locale }) => {
  const { formattedValue, setValue } = useBridgedContent({
    sourceId,
    projectId,
    environmentId,
    locale,
  })
  const { schema } = useSchema({ sourceId, projectId, environmentId })

  const selectedNodeState = useGetSelectedNodeState()
  const { path: selectedPath, schemaPath: selectedSchemaPath, selectedSchema } = selectedNodeState

  const node = useNodeDelegate()

  const localValue = useMemo(() => {
    return node.getValue(formattedValue || null, selectedPath) || null
  }, [node, selectedPath, formattedValue])

  const onValueChange = useCallback(
    (path, newValue) => {
      const newFinalObject = node.setValue(formattedValue, newValue, path)
      if (newFinalObject) {
        setValue(newFinalObject)
      }
    },
    [node, setValue, formattedValue]
  )

  return (
    <Flex h="100%" flexDir="column" overflow="visible">
      <TransitionGroup component={null}>
        <CSSTransition classNames="element-editor" timeout={150}>
          <chakra.div
            id="element-editor"
            display="flex"
            flexDir="column"
            w="100%"
            h="100%"
            overflowY="scroll"
            overflowX="visible"
          >
            <Box display="flex" flex="1" flexDir="column">
              {/* <SelectList values={typesValues} value={data.type} onChange={handleTypeChange} /> */}
              <Box
                display="flex"
                px={3}
                pb="3"
                flexDir="column"
                maxHeight="100%"
                height="100%"
                flex="1"
              >
                <Box height="100%" display="flex" flexDir="column">
                  {!formattedValue &&
                  typeof formattedValue !== 'boolean' &&
                  formattedValue !== 0 &&
                  formattedValue !== '' ? (
                    <Alert borderRadius={5} status="info">
                      <AlertIcon />
                      <AlertTitle color="black" mr={2}>
                        No Content.
                      </AlertTitle>
                      <AlertDescription color="black">Add something</AlertDescription>
                    </Alert>
                  ) : (
                    <chakra.div px={2} py={2}>
                      <SchemaField
                        sourceId={sourceId}
                        value={localValue}
                        schema={selectedSchema || schema}
                        rootSchema={schema}
                        path={selectedPath || '/'}
                        schemaPath={selectedSchemaPath}
                        schemaNodes={fields}
                        onChange={onValueChange}
                      />
                    </chakra.div>
                  )}
                </Box>
              </Box>
            </Box>
          </chakra.div>
        </CSSTransition>
      </TransitionGroup>
    </Flex>
  )
}

export default Inspector
