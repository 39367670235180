import { Children, isValidElement, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useClient } from 'urql'
import Container from 'typedi'
import ProjectsExplorer from '../../components/projects/ProjectExplorer/ProjectsExplorer'
import WorkbenchGroup from '../../components/workbench/WorkbenchGroup'
import { HydrogenCoreProps } from '../types/HydrogenCoreProps'
import { HydrogenState } from '../types/HydrogenState'
import { SetHydrogenStateFunction, useHydrogenState } from './useHydrogenState'
import { useContentProvider, useSetContentProvider } from '../contents/useContentProvider'
import { useContentBridge, useSetContentBridge } from '../contents/useContentBridge'
import { useNodeDelegate, useSetNodeDelegate } from '../workbench/useNodeDelegate'
import { JsonSchemaNode } from '../workbench/JsonSchemaNode'
import { useSetSchemaBridge } from '../schema/useSchemaBridge'
import { useSchemaProvider, useSetSchemaProvider } from '../schema/useSchemaProvider'
import { useMediaProvider, useSetMediaProvider } from '../cms/media/useMediaProvider'
import { useAuthProvider, useSetAuthProvider } from '../auth/useAuthProvider'
import {
  useComponentsProvider,
  useSetComponentsProvider,
} from '../components/useComponentsProvider'
import { HydrogenComponentsProvider } from '../components/HydrogenComponentsProvider'

const PROJECTS_EXPLORER_DEFAULT_PATH = ['/projects/:id', '/projects']
const PROJECTS_EXPLORER_DEFAULT_REDIRECT = '/projects'
const WORKBENCH_DEFAULT_PATH = ['/workbench/:projectId']
const WORKBENCH_DEFAULT_REDIRECT = '/workbench'

export const useHydrogenCore = ({
  children,
  contentProvider,
  schemaProvider,
  componentsProvider = HydrogenComponentsProvider,
  mediaProvider,
  contentBridge,
  schemaBridge,
  authProvider,
  nodeDelegate = JsonSchemaNode,
}: HydrogenCoreProps): [HydrogenState & { childrenCount: number }, SetHydrogenStateFunction] => {
  const [hydrogenState, setHydrogenState] = useHydrogenState()
  const client = useClient()
  const setContentProvider = useSetContentProvider()
  const setSchemaProvider = useSetSchemaProvider()
  const setComponentProvider = useSetComponentsProvider()
  const setMediaProvider = useSetMediaProvider()
  const setContentBridge = useSetContentBridge()
  const setSchemaBridge = useSetSchemaBridge()
  const setNodeDelegate = useSetNodeDelegate()
  const setAuthProvider = useSetAuthProvider()
  const authProviderInstance = useAuthProvider()
  const childrenCount = useMemo(() => Children.count(children), [children])
  const result = useMemo(() => ({ ...hydrogenState, childrenCount }), [
    hydrogenState,
    childrenCount,
  ])
  const { initialized } = hydrogenState
  const history = useHistory()

  useEffect(() => {
    let newHydrogenState: HydrogenState = {}

    const initHydrogenState = (): void => {
      if (childrenCount === 0) {
        setHydrogenState({
          ...newHydrogenState,
          initialized: true,
          hydrogenCoreInitialized: true,
          hasProjectsExplorer: true,
          hasWorkbench: true,
          projectsExplorerPath: PROJECTS_EXPLORER_DEFAULT_PATH,
          projectsExplorerDefaultRedirect: PROJECTS_EXPLORER_DEFAULT_REDIRECT,
          workbenchPath: WORKBENCH_DEFAULT_PATH,
          workbenchDefaultRedirect: WORKBENCH_DEFAULT_REDIRECT,
        })
      } else {
        const newState: HydrogenState = {
          ...newHydrogenState,
          initialized: true,
          hydrogenCoreInitialized: true,
          hasProjectsExplorer: false,
          hasWorkbench: false,
        }
        Children.map(children, (child: React.ReactNode, index) => {
          if (isValidElement(child)) {
            switch (child.type) {
              case ProjectsExplorer:
                newState.hasProjectsExplorer = true
                newState.projectsExplorerPath = child.props.path || PROJECTS_EXPLORER_DEFAULT_PATH
                newState.projectsExplorerDefaultRedirect =
                  child.props.defaultRedirect || PROJECTS_EXPLORER_DEFAULT_REDIRECT
                break
              case WorkbenchGroup:
                newState.hasWorkbench = true
                newState.workbenchPath = child.props.path || WORKBENCH_DEFAULT_PATH
                newState.workbenchDefaultRedirect =
                  child.props.defaultRedirect || WORKBENCH_DEFAULT_REDIRECT
                break
            }
          }
        })
        newHydrogenState = { ...newHydrogenState, ...newState }
      }
    }

    const initContent = (): void => {
      setContentProvider(contentProvider)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useContentProvider()?.init(client)
      newHydrogenState = { ...newHydrogenState, contentProviderInitialized: true }
    }

    const initSchemaProvider = (): void => {
      setSchemaProvider(schemaProvider)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSchemaProvider()?.init(client)
      newHydrogenState = { ...newHydrogenState, schemaProviderInitialized: true }
    }

    const initComponentsProvider = (): void => {
      setComponentProvider(componentsProvider)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useComponentsProvider()?.init(client)
      newHydrogenState = { ...newHydrogenState, componentsProviderInitialized: true }
    }

    const initMediaProvider = (): void => {
      setMediaProvider(mediaProvider)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMediaProvider()?.init(client)
      newHydrogenState = {
        ...newHydrogenState,
        mediaProviderInitialized: true,
        hasMediaProvider: true,
      }
    }

    const initNodeDelegate = (): void => {
      setNodeDelegate(nodeDelegate)
      newHydrogenState = { ...newHydrogenState, nodeDelegateInitialized: true }
    }

    const initContentBridge = (): void => {
      if (contentBridge) {
        setContentBridge(contentBridge)

        newHydrogenState = { ...newHydrogenState, hasContentBridge: true }
      }
    }

    const initAuthProvider = (): void => {
      if (authProvider) {
        setAuthProvider(authProvider)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAuthProvider()?.init(client)
        newHydrogenState = { ...newHydrogenState, hasAuthProvider: true }
      }
    }

    const initSchemaBridge = (): void => {
      if (schemaBridge) {
        setSchemaBridge(schemaBridge)
        newHydrogenState = { ...newHydrogenState, schemaBridgeInitialized: true }
      }
    }

    if (!initialized) {
      initAuthProvider()
      initContent()
      initComponentsProvider()
      initSchemaProvider()
      initMediaProvider()
      initNodeDelegate()
      initContentBridge()
      initSchemaBridge()
      initHydrogenState()
      setHydrogenState(newHydrogenState)
    }
    // eslint-disable-next-line
    return ():void => {
      // if (Container.has('contentListener')) {
      //   Container.remove('contentListener')
      // }
    }
  }, [])

  useEffect(() => {
    if (initialized && authProvider && authProviderInstance) {
      authProviderInstance
        .checkAuth()
        .then(() => {
          // nothing to do
        })
        .catch(() => {
          history.replace('/login')
        })
    }
  }, [initialized, authProviderInstance])

  return [result, setHydrogenState]
}
