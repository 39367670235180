import React, { FC, useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { ID } from '../../core/types/BaseCRUD'
import { HydrogenRouteProps } from '../../core/types/HydrogenRouteProps'
import ErrorAlert from '../base/ErrorAlert'
import Loading from '../base/Loading'
import logoImage from '../../assets/images/icon.png'
import { useGetListEnvironmentsQuery, useGetProjectByIdQuery } from '../../core/graphql'
import ActivityBar from './ActivityBar'
import SourceWorkbench from '../sources/SourceWorkbench'

type Props = HydrogenRouteProps & {
  projectId?: ID
  [prop: string]: any
}

const WorkbenchGroup: FC<Props> = ({ projectId }) => {
  const { path } = useRouteMatch()
  const [projectResult, refetchProject] = useGetProjectByIdQuery({
    variables: { id: projectId },
    pause: !projectId,
  })

  const [environmentsResult, refetchEnvironments] = useGetListEnvironmentsQuery({
    variables: {
      page: 1,
      perPage: 10,
      filter: {
        project: projectId,
      },
    },
    pause: !projectId,
  })

  const { data: projectData, fetching: fetchingProject, error: projectError } = projectResult
  const { data: envData, fetching: fetchingEnv, error: envError } = environmentsResult

  if (fetchingEnv || fetchingProject) {
    return <Loading title="Loading Workbench" h="100vh" w="100vw" imageSrc={logoImage} />
  }

  if (projectError) {
    return <ErrorAlert errorMessage={projectError.message} />
  }

  if (envError) {
    return <ErrorAlert errorMessage={envError.message} />
  }

  return (
    <Box display="flex" h="100vh" w="100%" overflow="hidden" flexDirection="row">
      <ActivityBar projectId={projectId!} />
      <Switch>
        <Route path={[`${path}/sources/:id`, `${path}/sources`]}>
          <SourceWorkbench />
        </Route>
        <Route path={`${path}/components`}>
          <div style={{ padding: '20px 20px' }}>SONO Components</div>
        </Route>

        <Redirect to={`${path}/sources`} />
      </Switch>
    </Box>
  )

  // TODO: Please, re-add and complete Component Workbench
  // if (mode === WorkbenchMode.COMPONENTS_VIEWER_MODE && project) {
  //   return (
  //     <ComponentWorkbench
  //       project={project}
  //       previewEditorComponent={previewEditorComponent}
  //       externalPreviewEditorComponent={externalPreviewEditorComponent}
  //       componentEditorComponent={componentEditorComponent}
  //       componentsGridComponent={componentsGridComponent}
  //     />
  //   )
  // }
}

export default WorkbenchGroup
