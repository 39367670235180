import React, { FC, useCallback } from 'react'
import { Flex } from '@chakra-ui/react'
import { gql_EnumDKeySourceKind, gql_EnumSourceSchemaMode } from '../../../core/graphql'
import CreateSourceButton from '../CreateSourceButton'
import CreateFolderButton from './CreateFolderButton'
import { ID } from '../../../core/types/BaseCRUD'

type Props = {
  projectId: ID
  showCreateFolder?: boolean
  onNewSourceClick?: (kind: gql_EnumDKeySourceKind, schemaMode?: gql_EnumSourceSchemaMode) => void
}

const CreateSourceCardButton: FC<Props> = ({ projectId, showCreateFolder, onNewSourceClick }) => {
  const handleNewSourceClick = useCallback(
    (kind: gql_EnumDKeySourceKind, schemaMode?: gql_EnumSourceSchemaMode) => () => {
      if (onNewSourceClick) {
        onNewSourceClick(kind, schemaMode)
      }
    },
    [onNewSourceClick]
  )

  return projectId ? (
    <Flex flexDir="column" p={0} h="100%">
      {showCreateFolder && <CreateFolderButton />}
      <CreateSourceButton projectId={projectId} h="100%" />
    </Flex>
  ) : (
    <></>
  )
  // <Menu>
  //   <Flex flexDir="column" p={0}>
  //     {showCreateFolder && (
  //       <Flex>
  //         <Surface
  //           //   bg={bgColor}
  //           // _hover={{ bgColor: buttonBgHoverColor, shadow: 'lg' }}
  //           p="3"
  //           textAlign="center"
  //           w="100%"
  //           alignItems="center"
  //           justifyContent="center"
  //           rounded="md"
  //           overflow="hidden"
  //           shadow="sm"
  //           mb="3"
  //           h="50px"
  //           as={BaseButton}
  //           onClick={() => {}}
  //           lightBgColor="white"
  //           lightBgHoverColor="whiteAlpha.600"
  //           darkBgColor="dark.900"
  //           darkBgHoverColor="dark.500"
  //         >
  //           <BaseIcon name="IoFolderOpen" collection="io5" />
  //           <Text ml="3">New Folder</Text>
  //         </Surface>
  //       </Flex>
  //     )}
  //     <Surface
  //       variant="heavy"
  //       alignItems="center"
  //       justifyContent="center"
  //       flexDir="column"
  //       p="5"
  //       textAlign="center"
  //       w="100%"
  //       flex="1"
  //       rounded="md"
  //       overflow="hidden"
  //       shadow="sm"
  //       as={MenuButton}
  //       lightBgColor="white"
  //       lightBgHoverColor="whiteAlpha.600"
  //       darkBgColor="dark.900"
  //       darkBgHoverColor="dark.500"
  //       // onClick={handleNewSourceClick}
  //       hoverable
  //     >
  //       <Flex flexDirection="column" justifyContent="center" h="100%">
  //         <BaseIcon name="HiDocumentAdd" collection="hi" fontSize="3xl" />
  //         <Text fontSize="md" mt="1">
  //           New
  //         </Text>
  //       </Flex>
  //     </Surface>
  //   </Flex>
  //   {/* <Portal> */}
  //   <MenuList fontSize="md" zIndex={1500}>
  //     <MenuItem
  //       onClick={handleNewSourceClick(gql_EnumDKeySourceKind.Folder)}
  //       icon={<Icon as={ImFolderPlus} mb={1} w={4} h={4} />}
  //     >
  //       Create new folder
  //     </MenuItem>
  //     <MenuDivider />
  //     <MenuItem
  //       onClick={handleNewSourceClick(
  //         gql_EnumDKeySourceKind.BasicSource,
  //         gql_EnumSourceSchemaMode.Free
  //       )}
  //     >
  //       Create new Basic Source
  //     </MenuItem>
  //     <MenuItem
  //       onClick={handleNewSourceClick(
  //         gql_EnumDKeySourceKind.BasicSource,
  //         gql_EnumSourceSchemaMode.Components
  //       )}
  //     >
  //       Create new Basic Source with Components
  //     </MenuItem>
  //     <MenuItem
  //       onClick={handleNewSourceClick(
  //         gql_EnumDKeySourceKind.Layout,
  //         gql_EnumSourceSchemaMode.Components
  //       )}
  //     >
  //       Create new Layout
  //     </MenuItem>
  //     <MenuItem
  //       onClick={handleNewSourceClick(
  //         gql_EnumDKeySourceKind.Page,
  //         gql_EnumSourceSchemaMode.Components
  //       )}
  //     >
  //       Create new Page
  //     </MenuItem>
  //   </MenuList>
  //   {/* </Portal> */}
  // </Menu>
}

export default CreateSourceCardButton
