import { JSONSchema7Type } from 'json-schema'
import { DefaultValue, selectorFamily } from 'recoil'
import Container from 'typedi'
import { contentStateFamily } from './contentStateFamily'
import { ID } from '../types/BaseCRUD'
import { Content } from '../types/Content'
import { ContentBridge } from './ContentBridge'
import { TOKEN_CONTENT_BRIDGE } from './useContentBridge'

export const bridgedContentStateFamily = selectorFamily<Content<JSONSchema7Type>, ID>({
  key: 'bridgedContentState',
  get: (sourceId) => ({ get }): any => {
    const contentBridge = Container.get<ContentBridge>(TOKEN_CONTENT_BRIDGE)
    const contentState = get(contentStateFamily(sourceId))
    if (contentBridge) {
      const formattedValue = contentBridge.formatForRead(contentState.value)
      const formattedOriginalValue = contentBridge.formatForRead(contentState.originalValue)
      // console.log('leggo e ritorno')
      return {
        ...contentState,
        formattedValue,
        formattedOriginalValue,
      }
    }

    return {
      ...contentState,
    }
  },

  // optional set
  set: (sourceId) => ({ set }, newValue): void => {
    const contentBridge = Container.get<ContentBridge>(TOKEN_CONTENT_BRIDGE)
    if (contentBridge && !(newValue instanceof DefaultValue)) {
      set(contentStateFamily(sourceId), (prevState) => {
        newValue.value = contentBridge.parseForWrite(newValue.value) || prevState.value
        newValue.originalValue =
          contentBridge.parseForWrite(newValue.originalValue) || prevState.originalValue
        return {
          ...newValue,
        } as Content<string>
      })
    }
  },
})
