import React, { FC } from 'react'
import { NodeProps } from '../../core/types/NodeProps'
import NodeView from './NodeView'

type Props = NodeProps

const NullNode: FC<Props> = () => {
  return null
}

export default NullNode
