import { mode } from '@chakra-ui/theme-tools'
// eslint-disable-next-line import/no-extraneous-dependencies
import { theme } from '@chakra-ui/theme'
import { inputStyle, surfaceColorHue } from '../utils'
import defaultColors from '../foundations/colors'

const components = {
  // custom component
  NodeView: {
    parts: ['wrapper', 'container', 'content', 'iconBox'],
    baseStyle: (props: any) => ({
      wrapper: {
        position: 'relative',
        perspective: '1000px',
      },
      container: {
        flexDirection: 'row',
        px: '3',
        position: 'relative',
        transition: 'all 0.25s',
        transform: props.isSelected ? 'scale(1.01,1.005)' : null,
        zIndex: props.isSelected ? 3 : 1,
        mb: props.isSelected ? 4 : 2,
        my: props.isSelected ? 2 : 2,
      },
      content: {
        cursor: 'pointer',
        position: 'relative',
        rounded: 'md',
        roundedLeft: 'md',
        display: 'flex',
        shadow: mode('sm', 'sm')(props),
        flex: '1',
        zIndex: 0,
        overflow: 'hidden',
      },
      iconBox: {
        w: '50px',
        h: '50px',
        flexDir: 'column',
        rounded: 'md',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        bgColor: mode('darkAlpha.100', 'lightAlpha.100')(props),
      },
    }),
  },
  GroupedNode: {
    parts: ['wrapper', 'header', 'scroll', 'stack', 'backgroundImage', 'stackItem'],
    baseStyle: ({ colorMode }: Record<string, any>) => ({
      wrapper: {
        d: 'grid',
        w: '100%',
      },
      header: {
        d: 'flex',
        flexDir: 'row',
        py: 3,
        px: 3,
        w: '100%',
        _hover: {
          // bgColor: colorMode === 'dark' ? 'blackAlpha.400' : 'blackAlpha.400',
        },
        borderRadius: 'lg',
        overflow: 'hidden',
      },
      backgroundImage: {
        pos: 'absolute',
        left: '0',
        top: '0',
        boxSize: '100%',
        zIndex: -1,
        backgroundSize: 'cover',
        bgPos: 'center',
        _after: {
          content: '""',
          position: 'absolute',
          boxSize: '100%',
          bgColor: mode('lightAlpha.600', 'darkAlpha.600')({ colorMode }),
        },
      },
      scroll: {
        overflow: 'scroll',
      },
      stack: {
        px: 4,
        py: 2,
        mb: 3,
        w: 'fit-content',
      },
      stackItem: {
        justifySelf: 'stretch',
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignContent: 'center',
      },
    }),
  },
  Surface: {
    parts: ['wrapper', 'clickable'],
    baseStyle: ({
      colorMode,
      variant,
      lightVariant,
      darkVariant,
      lightBgColor,
      darkBgColor,
      lightBgHoverColor,
      darkBgHoverColor,
      lightTextColor,
      darkTextColor,
      boxShadow,
    }: any) => {
      const currentVariant =
        lightVariant && darkVariant ? (colorMode == 'light' ? lightVariant : darkVariant) : variant
      const colorHue: number = surfaceColorHue(currentVariant, colorMode)
      const bgColor = colorMode == 'light' ? `gray.${colorHue}` : `dark.${colorHue}`
      const bgHoverColor =
        colorMode == 'light' ? `gray.${colorHue + 200}` : `dark.${colorHue - 100}`
      const color =
        lightTextColor || darkTextColor
          ? colorMode == 'light'
            ? { color: lightTextColor }
            : { color: darkTextColor }
          : {}

      const customBgColor = colorMode == 'light' ? lightBgColor : darkBgColor
      const customHoverBgColor = colorMode == 'light' ? lightBgHoverColor : darkBgHoverColor

      return {
        wrapper: {
          ...color,
          bgColor: customBgColor || bgColor,
          boxShadow,
        },
        hoverable: {
          _hover: {
            bgColor: customHoverBgColor || bgHoverColor,
            boxShadow: colorMode == 'light' ? 'surfaceLight' : 'surfaceLight',
          },
        },
        selected: {
          bgColor: customHoverBgColor || bgHoverColor,
          boxShadow: 'surfaceMedium',
        },
        clickable: {
          cursor: 'pointer',
        },
      }
    },
  },
  TabsButtons: {
    parts: ['wrapper', 'tab'],
    baseStyle: ({ colorMode, sort }: any) => ({
      wrapper: {
        display: 'flex',
        _hover: { bgColor: mode('darkAlpha.50', 'lightAlpha.50')({ colorMode }) },
        borderRadius: 'md',
        alignSelf: 'center',
        justifySelf: 'center',
        h: '40px',
        '& > *:not(:last-child)': {
          mr: '1px',
        },
      },
      tab: {
        h: '36px',
        borderRadius: 'md',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgColor: 'transparent',
        fontWeight: 'light',
        boxShadow: 'none',
        px: 3,
        _hover: {
          bgColor: 'transparent',
        },
        _active: {
          color: colorMode == 'dark' ? 'gray.200' : 'gray.800',
          bgColor: colorMode == 'dark' ? 'darkAlpha.300' : 'light.200',
          fontWeight: 'bold',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    }),
    variant: {
      full: {
        wrapper: {
          justifyContent: 'space-around',
        },
        tab: {
          flex: 1,
        },
      },
    },
  },
  Loading: {
    parts: ['wrapper', 'content'],
    baseStyle: ({ colorMode, sort }: any) => ({
      wrapper: {
        display: 'flex',
        flexDir: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {},
    }),
  },
  BaseButton: {
    parts: ['wrapper', 'clickable'],
    baseStyle: ({
      colorMode,
      darkHue,
      lightHue,
      variant,
      lightBgColor,
      darkBgColor,
      lightBgHoverColor,
      darkBgHoverColor,
      lightTextColor,
      darkTextColor,
    }: any) => {
      const currentVariant =
        lightHue && darkHue ? (colorMode == 'light' ? lightHue : darkHue) : variant
      const colorHue: number = surfaceColorHue(currentVariant, colorMode)
      const bgColor = colorMode == 'light' ? `light.${colorHue}` : `dark.${colorHue}`
      const bgHoverColor =
        colorMode == 'light' ? `light.${colorHue + 100}` : `dark.${colorHue - 100}`
      const color =
        lightTextColor || darkTextColor
          ? colorMode == 'light'
            ? { color: lightTextColor }
            : { color: darkTextColor }
          : {}

      const customBgColor = colorMode == 'light' ? lightBgColor : darkBgColor
      const customHoverBgColor = colorMode == 'light' ? lightBgHoverColor : darkBgHoverColor

      return {
        wrapper: {
          ...((variant as string) == 'solid' || !variant
            ? { bgColor: customBgColor || bgColor }
            : {}),
          // boxShadow: colorMode == 'light' ? 'surfaceLight' : 'surface',
          ...color,
          _hover: {
            bgColor: customHoverBgColor || bgHoverColor,
            // boxShadow: colorMode == 'light' ? 'surface' : 'surfaceMedium',
          },
          _selected: {
            bgColor: customHoverBgColor || bgHoverColor,
            boxShadow: colorMode == 'light' ? 'surface' : 'surfaceMedium',
          },
          _active: {
            bgColor: customHoverBgColor || bgHoverColor,
          },
        },
      }
    },
  },
  ArrayField: {
    parts: ['wrapper', 'list', 'listItem', 'accordion'],
    baseStyle: (props: Record<string, any>) => ({
      wrapper: {
        w: '100%',
      },
      list: {
        borderRadius: 'md',
        overflow: 'visible',
        py: 0,
        position: 'relative',
      },
      accordion: {
        pr: 1,
        '&::before': {
          content: "''",
          borderLeft: '1px solid',
          borderColor: mode('dark.50', 'dark.700')(props),
          height: 'calc( 100% - 45px )',
          position: 'absolute',
          left: '2px',
          top: '22px',
        },
      },
      listItem: {
        pr: 3,
        pl: 5,
        py: 4,
        minH: 50,
        position: 'relative',
        outline: 'none',
        ml: '-2px',
        mr: 0,
        zIndex: '1',
        border: 'none',
        '&:not(:last-child)': {
          border: 'none',
          borderColor: mode('light.200', 'dark.900')(props),
        },
      },
    }),
  },
  GlobalSearch: {
    parts: ['wrapper', 'input', 'command', 'results', 'list', 'listItem'],
    baseStyle: ({ colorMode, expandible, focused, ...rest }: Record<string, any>) => ({
      wrapper: {
        justifyContent: 'center',
        display: 'flex',
        transition: 'all 0.3s ease-out',
        bgColor: colorMode == 'dark' ? 'dark.600' : 'light.100',
        borderRadius: 'md',
        boxShadow: mode('sm', 'sm')({ colorMode }),
      },
      input: {
        bgColor: 'transparent',
        _hover: {
          bgColor: mode('transparent', 'transparent')({ colorMode }),
        },
        _focus: {
          bgColor: colorMode == 'dark' ? 'dark.600' : 'light.100',
          outline: 'none',
          boxShadow: 'none',
        },
        w: focused && expandible ? '300px' : '250px',
      },
      command: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        h: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        pr: '2',
        pointerEvents: 'none',
      },
      results: {
        maxH: { base: '100%', md: '50vh' },
        overflow: 'scroll',
        overflowX: 'hidden',
        outline: 'none',
        boxShadow: 'lg',
      },
      list: {},
      listItem: {
        py: 2,
        borderBottomWidth: '0px',
        display: 'flex',
        alignItems: 'center',
        _hover: { backgroundColor: mode('light.100', 'dark.800')({ colorMode }) },
        px: '2',
        borderRadius: 'md',
        outline: 'none',
      },
    }),
  },
  BaseSearch: {
    parts: ['wrapper', 'input'],
    baseStyle: ({ colorMode, expandible, focused, ...rest }: Record<string, any>) => ({
      wrapper: {
        justifyContent: 'center',
        display: 'flex',
        transition: 'all 0.3s ease-out',
        bgColor: mode('light.100', 'dark.600')({ colorMode }),
        borderRadius: 'md',
      },
      input: {
        _hover: {
          // bgColor: mode('transparent', 'transparent')({ colorMode }),
        },
        _focus: {
          //  bgColor: colorMode == 'dark' ? 'dark.700' : 'light.50',
          outline: 'none',
        },
      },
      command: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        h: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        pr: '2',
        pointerEvents: 'none',
      },
    }),
  },
  PreviewToolbar: {
    parts: ['wrapper'],
    baseStyle: ({ colorMode }: Record<string, any>) => ({
      wrapper: {
        h: '60px',
        minH: '60px',
        w: '100%',
        py: 3,
        display: 'flex',
        flexDirection: 'row',
        position: 'sticky',
        top: '0',
        px: '5',
        zIndex: 3,
        boxSizing: 'border-box',
        alignItems: 'flex-start',
      },
    }),
  },
  SuggestedNodes: {
    parts: ['wrapper', 'list', 'item', 'emptyList'],
    baseStyle: (props: Record<string, any>) => ({
      wrapper: {
        d: 'flex',
        flexDir: 'column',
      },
      list: {},
      item: {
        display: 'flex',
        flexDirection: 'row',
        w: '100%',
        alignItems: 'center',
        pointerEvents: 'all',
        borderRadius: 'none',
        _hover: { bgColor: mode('darkAlpha.50', 'lightAlpha.50')(props), borderRadius: 'md' },
        p: 2,
        cursor: 'pointer',
      },
      emptyList: {
        bgColor: mode('darkAlpha.50', 'lightAlpha.50')(props),
        borderRadius: 'md',
        p: 3,
      },
    }),
  },
  Workbench: {
    parts: ['wrapper', 's', 'item'],
    baseStyle: (props: Record<string, any>) => ({
      wrapper: {},
      tabsListWrapper: {},
    }),
  },
  ExternalPreview: {
    parts: ['wrapper', 'toolbar', 'toolbarButton', 'toolbarCircleButton', 'addressBar'],
    baseStyle: ({ colorMode, isHover, isOpen }: Record<string, any>) => ({
      wrapper: {
        zIndex: 3,
        position: 'relative',
      },
      toolbar: {
        alignItems: 'center',
        left: 1,
        color: 'darkAlpha.200',
        px: 1,
        d: 'flex',
        transition: 'all 0.25s',
        h: isHover || !isOpen ? '30px' : '25px',
        bgColor: mode('gray.100', 'dark.800')({ colorMode }),
        border: 'none',
        boxShadow: 'md',
      },
      toolbarCircleButton: {
        p: 0,
        d: 'flex',
        top: isHover || !isOpen ? '0px' : '0px',
        positon: 'relative',
        borderRadius: 'full',
        justifyContent: 'center',
        alignItems: 'center',
        w: isHover ? '20px' : '15px',
        minW: isHover ? '20px' : '15px',
        maxW: isHover ? '20px' : '15px',
        h: isHover ? '20px' : '15px',
        minH: isHover ? '20px' : '15px',
        maxH: isHover ? '20px' : '15px',
        transition: 'all 0.25s',
        overflow: 'hidden',
        mr: 1,
        _hover: {
          opacity: 0.65,
        },
        _focus: {},
      },
      address: {
        h: '20px', // isHover ? '19px' : '15px',
        // lineHeight: isHover || !isOpen ? '19px' : '15px',
        fontSize: 'xs',
        lineHeight: '20px',
        py: 0,
        maxW: '250px',
        textAlign: 'center',
        color: mode('darkAlpha.700', 'lightAlpha.900')({ colorMode }),
        bgColor: 'transparent',
        borderRadius: 'sm',
      },
      toolbarButton: {
        h: '20px',
        // mt: isHover || !isOpen ? '0px' : '-1px',
        fontSize: '10px',
        px: 1,
        py: 0,
        w: '25px',
        minW: 'auto',
        textAlign: 'center',
        color: mode('darkAlpha.700', 'lightAlpha.900')({ colorMode }),
        bgColor: 'transparent',
        _hover: { bgColor: mode('light.300', 'dark.500')({ colorMode }) },
        borderRadius: 'sm',
      },
    }),
  },
  MediaDropZone: {
    parts: ['wrapper', 'zone', 'input', 'button', 'text'],
    baseStyle: (props: Record<string, any>) => ({
      wrapper: {
        d: 'flex',
        alignContent: 'center',
      },
      zone: {
        zIndex: 1,
        position: 'relative',
        textAlign: 'center',
        border: '0px',
        color: props.isDragActive ? 'green.500' : '',
        borderRadius: 'md',
        cursor: 'pointer',
        d: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        w: '100%',
        boxShadow: 'none',
      },
      text: {
        alignSelf: 'center',
      },
      input: {},
      button: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDir: 'column',
        p: '3',
        textAlign: 'center',
        w: '100%',
        flex: '1',
        rounded: 'md',
        overflow: 'hidden',
        shadow: 'sm',
      },
    }),
  },
  SourceHistoryModal: {
    parts: ['wrapper', 'button', 'list'],
    baseStyle: ({ colorMode }: Record<string, any>) => ({
      wrapper: {
        px: 7,
        py: 1,
        w: '100%',
        overflow: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
      },
      list: {
        flexWrap: 'nowrap',
        position: 'relative',
      },
      item: {
        p: 3,
        fontSize: 'sm',
        minW: 'min-content',
        transition: 'all 0.2s',
        overflow: 'hidden',
        bgColor: mode('light.50', 'dark.600')({ colorMode }),
        _after: {
          content: '""',
          position: 'absolute',
          top: '0px',
          left: '0%',
          h: '100%',
          w: '0',
          borderRadius: 'xs',
          bgColor: colorMode == 'dark' ? 'green.500' : 'green.400',
        },
        _active: {
          // bgColor: colorMode == 'dark' ? 'darkAlpha.100' : 'darkAlpha.100',
          _after: {
            w: '6px',
            bgColor: colorMode == 'dark' ? 'green.500' : 'green.400',
          },
        },
        _hover: {
          // bgColor: colorMode == 'dark' ? 'darkAlpha.100' : 'darkAlpha.100',
          _after: {
            bgColor: colorMode == 'dark' ? 'green.400' : 'green.500',
          },
        },
      },
    }),
    variants: {
      boxed: (props: Record<string, any>) => ({
        button: {
          _active: {
            bgColor: mode('darkAlpha.50', 'darkAlpha.200')(props),
          },
          _hover: {
            bgColor: mode('darkAlpha.100', 'darkAlpha.100')(props),
          },
          _after: {
            top: 'calc(50% - 3px)',
            left: '9px',
          },
          _expanded: {
            _after: {
              top: '20%',
              left: '9px',
              height: '60%',
            },
          },
        },
      }),
    },
  },
  WorkbenchNavbar: {
    parts: ['wrapper', 'header', 'list', 'listItem', 'footer', 'panel'],
    baseStyle: (props: Record<string, any>) => ({
      wrapper: {
        d: 'flex',
        zIndex: 1,
        w: '65px',
        maxW: '65px',
        minW: '65px',
        h: '100%',
        p: '2',
        flexDirection: 'column',
        alignItems: 'center',
        boxShadow: mode('md', 'md')(props),
      },
      header: {
        boxSize: '45px',
        mb: 6,
      },
      list: {
        w: '45px',
      },
      listItem: {
        bgColor: mode('light.50', 'dark.800')(props),
        color: mode('dark.200', 'dark.200')(props),
        _hover: {
          color: mode('dark.500', 'light.50')(props),
          bgColor: mode('light.200', 'dark.900')(props),
        },
        _active: {
          bgColor: mode('light.200', 'dark.900')(props),
          color: mode('dark.600', 'white')(props),
        },
      },
      footer: {
        d: 'flex',
        flexDir: 'column',
        alignItems: 'center',
        w: '100%',
        mt: 'auto',
      },
    }),
  },
  GradientBox: {
    baseStyle: {
      d: 'flex',
      overflow: 'hidden',
      pointerEvents: 'none',
      position: 'absolute',
      minHeight: '100px',
      width: '100%',
      top: 0,
      left: 0,
      right: 0,
      maxWidth: '100%',
    },
    defaultProps: {},
  },
  // chakra
  Divider: {
    baseStyle: (props: Record<string, any>) => ({
      borderColor: mode('darkAlpha.100', 'lightAlpha.50')(props),
    }),
  },
  Button: {
    sizes: {
      md: {
        fontSize: 'sm',
        lineHeight: 'sm',
      },
    },
    variants: {
      solid: (props: Record<string, any>) => ({
        _active: {
          bgColor: mode('light.200', 'dark.300')(props),
        },
      }),
    },
  },
  Badge: {
    baseStyle: ({ colorMode }: Record<string, any>) => ({
      ...theme.components.Badge.baseStyle,
    }),
    variants: {
      solid: ({ colorMode }: Record<string, any>) => ({
        bg: mode('light.400', 'dark.600')({ colorMode }),
        _hover: mode('light.400', 'dark.600')({ colorMode }),
      }),
    },
    defaultProps: {
      variant: 'solid',
    },
  },
  Menu: {
    baseStyle: ({ colorMode }: Record<string, any>) => ({
      list: {
        border: 'none',
        bg: mode('light.50', 'dark.600')({ colorMode }),
      },
      item: {
        border: 'none',
        _hover: { bg: 'rgba(0,0,0,0.15)' },
        _focus: { bg: 'rgba(0,0,0,0.15)' },
        _selected: { bg: 'rgba(0,0,0,0.15)' },
        _active: { bg: 'rgba(0,0,0,0.15)' },
      },
    }),
  },
  Accordion: {
    parts: ['container', 'button', 'panel', 'icon'],
    baseStyle: (props: any) => ({
      container: {
        border: 'none',
        boxShadow: 'none',
      },
      button: {
        justifyContent: 'space-between',
        pl: 4,
        py: 1,
        mb: 3,
        h: 'auto',
        position: 'relative',
        bg: 'transparent',
        outline: 'none',
        borderRadius: 'md',
        //  bgColor: colorMode == 'dark' ? 'darkAlpha.100' : 'darkAlpha.100',

        _after: {
          content: '""',
          position: 'absolute',
          top: 'calc(50% - 3px)',
          left: '2px',
          h: '5px',
          w: '5px',
          bgColor: props.colorMode == 'dark' ? 'dark.500' : 'dark.100',
          transition: 'all 0.2s ease',
          borderRadius: '1px',
        },
        _hover: {
          bgColor: mode('darkAlpha.100', 'darkAlpha.100')(props),
          _after: {
            bgColor: mode('green.400', 'green.500'),
            top: 'calc(50% - 7px)',
            h: '14px',
          },
        },
        _expanded: {
          //  bgColor: colorMode == 'dark' ? 'darkAlpha.700' : 'darkAlpha.100',
          _after: {
            top: 'calc(50% - 13px)',
            h: '26px',
            bgColor: props.colorMode == 'dark' ? 'green.500' : 'green.400',
          },
        },
      },
      panel: {
        py: 0,
        pl: 0,
        position: 'relative',
        border: 'none',
      },
      icon: {
        alignSelf: 'center',
      },
    }),
    variants: {
      boxed: (props: Record<string, any>) => ({
        button: {
          _active: {
            bgColor: mode('darkAlpha.50', 'dark.800')(props),
          },
          _hover: {
            bgColor: mode('darkAlpha.100', 'darkAlpha.100')(props),
            _after: {
              left: '9px',
              h: '7px',
              w: '7px',
            },
          },
          _after: {
            top: 'calc(50% - 3px)',
            left: '9px',
          },

          _expanded: {
            _after: {
              top: '20%',
              left: '9px',
              height: '60%',
            },
          },
        },
      }),
    },
  },
  Input: {
    parts: ['field', 'addon'],
    baseStyle: {
      field: {
        outline: 'none',
        border: 'none',
      },
    },

    variants: {
      filled: (props: Record<string, any>) => ({
        field: inputStyle(props),
      }),
    },
    defaultProps: {
      variant: 'filled',
    },
  },
  Textarea: {
    baseStyle: {
      outline: 'none',
      border: 'none',
      _hover: {
        boxShadow: `0px 0px 1px 2px ${defaultColors.lightAlpha[800]}`,
      },
    },
    variants: {
      filled: inputStyle,
    },
    defaultProps: {
      variant: 'filled',
    },
  },
  Select: {
    parts: ['field'],
    baseStyle: (props: any) => ({
      field: {
        borderWidth: '0px',
        '&': { ...inputStyle(props), border: 'none !important', boxShadow: 'none !important' },
      },
    }),
    defaultProps: {},
  },
  Kbd: {
    baseStyle: {
      fontSize: '10px',
      borderBottomWidth: '2px',
    },
  },
  Tabs: {
    parts: ['root', 'tablist', 'tab', 'tabpanel', 'indicator'],
    baseStyle: (props: Record<string, any>) => ({
      root: {
        d: 'flex',
        flexDirection: 'column',
      },
      tablist: {
        zIndex: 1,
        borderRadius: 'md',
        bgColor: mode('light.100', 'dark.800')(props),
        // boxShadow: mode('sm', 'sm')(props),
        _hover: { bgColor: mode('light.100', 'lightAlpha.50')(props) },
      },
      tab: {
        minW: '50px',
        minH: '34px',
        alignSelf: 'center',
        _selected: {
          boxShadow: mode('sm', 'sm')(props),
        },
        _focus: {
          boxShadow: mode('sm', 'sm')(props),
        },
      },
      tabpanel: {
        d: 'flex',
        flexDirection: 'column',
        p: 0,
        boxShadow: 'none',
        _focus: {
          outline: 'none',
          border: 'none',
        },
      },
      indicator: {},
    }),
    variants: {
      'solid-rounded': (props: Record<string, any>) => ({
        tablist: {
          // w: '100%',
        },
        tab: {
          fontWeight: 'normal',
          borderRadius: 'md',
          flex: 1,
          _selected: {
            color: mode('gray.800', 'gray.200')(props),
            bgColor: mode('light.200', 'darkAlpha.300')(props),
            fontWeight: 'bold',
          },
        },
      }),
    },
    sizes: {
      md: {
        tablist: {
          h: '40px',
          minH: '40px',
        },
      },
    },
    // defaultProps: {
    //   size: 'md',
    //   variant: 'solid-rounded',
    //   colorScheme: null,
    // },
  },
  Popover: {
    baseStyle: (props: any) => ({
      popper: {
        border: 'none',
      },
      content: {
        border: 'none',
        borderRadius: 'md',
        overflow: 'hidden',
        bg: mode('white', 'dark.600')(props),
      },
      header: {},
      body: {
        bgColor: mode('white', 'dark.600')(props),
      },
      footer: {},
      arrow: {
        bg: mode('light.200', 'dark.600')(props),
      },
    }),
  },
  Tooltip: {
    baseStyle: (props: any) => ({
      boxShadow: 'surface',
      borderRadius: 'full',
      px: 3,
      color: mode('dark.600', 'light.200')(props),
      bg: mode('light.100', 'dark.600')(props),
    }),
  },
  Modal: {
    sizes: {
      full: {
        dialog: { borderRadius: '0px' },
      },
    },
  },
  Switch: {
    variants: {
      vertical: {
        flexDirection: 'column',
        display: 'none',
      },
    },
  },
  Link: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
}

export default components
