import React, { FC } from 'react'
import { RecoilRoot } from 'recoil'
import { HydrogenRootProps } from '../../core/types/HydrogenRootProps'

type Props = HydrogenRootProps

const HydrogenRoot: FC<Props> = ({ enableRecoilRoot = true, children }) => {
  return enableRecoilRoot ? (
    <RecoilRoot children={children} />
  ) : (
    <React.Fragment children={children} />
  )
}

export default HydrogenRoot
