import React, { FC } from 'react'
import { ChakraProvider, ColorModeScript, ScaleFade, Theme } from '@chakra-ui/react'
import { LayoutProps } from '../../core/types/LayoutProps'
import defaultTheme from '../../theme'

type Props = LayoutProps & {
  theme?: Theme
}

const ChakraUiLayout: FC<Props> = ({ theme = defaultTheme, children }) => {
  return (
    <>
      <ChakraProvider resetCSS theme={theme}>
        <ScaleFade in={true}>{children}</ScaleFade>
      </ChakraProvider>
    </>
  )
}

export default ChakraUiLayout
