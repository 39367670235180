import { JSONSchema7, JSONSchema7Definition } from 'json-schema'
import { Inject } from 'typedi'
import { Client } from 'urql'
import { BaseFile, WriteResult } from '../types/BaseFile'
import schema from '../../samples/json-schema-draft-07/hypertingcms.schema.json'
import { ComponentsCRUD } from '../types/Component'
import { TOKEN_COMPONENTS_PROVIDER } from '../components/useComponentsProvider'
import { ID } from '../types/BaseCRUD'
import { QUERY_GET_ONE_PROJECT, QUERY_GET_ONE_SOURCE } from '../../queries'
import {
  gql_EnumSourceSchemaMode,
  gql_GetProjectByIdQuery,
  gql_GetProjectByIdQueryVariables,
  gql_GetSourceByIdQuery,
  gql_GetSourceByIdQueryVariables,
} from '../graphql'

const CACHE_TTL = 5 * 1000

export class HydrogenSchemaProvider implements BaseFile<JSONSchema7Definition> {
  urqlClient!: Client

  @Inject(TOKEN_COMPONENTS_PROVIDER)
  components!: ComponentsCRUD

  cachedSchema: any

  cachedSchemaLastTime: number = 0

  init(client: Client) {
    this.urqlClient = client
  }

  async read(sourceId: ID, projectId?: ID, environmentId?: ID): Promise<JSONSchema7Definition> {
    // console.log(sourceId, projectId, 'sono quaaa')
    try {
      const now = new Date().getTime()

      if (this.cachedSchemaLastTime > 0 && now - this.cachedSchemaLastTime < CACHE_TTL) {
        return this.cachedSchema
      }

      const { data, error } = await this.urqlClient
        .query<gql_GetSourceByIdQuery, gql_GetSourceByIdQueryVariables>(QUERY_GET_ONE_SOURCE, {
          id: sourceId,
        })
        .toPromise()

      if (error && error.message) {
        return true
      }

      if (data?.sourceById?.schemaMode === gql_EnumSourceSchemaMode.Components && projectId) {
        console.log('sono projectId', projectId)
        const { data: componentsData } = await this.components.getList({
          filters: {
            projectId,
            environmentId,
          },
          sort: {
            name: 'ASC',
          },
          pagination: {
            offset: 0,
            limit: 1000,
          },
        })

        // console.log(componentsData, 'componenti trovati')

        const newSchema: any = {
          $schema: 'http://json-schema.org/draft-07/schema#',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              component: {
                type: 'string',
                enum: [],
                default: 'Slider',
              },
            },
            dependencies: {
              component: {
                oneOf: [],
              },
            },
            required: ['component'],
          },
        }
        if (componentsData.length > 0) {
          for (const component of componentsData) {
            newSchema.items.properties.component.enum.push(component.name)
            const { component: componentProp, ...rest } = component.partialSchema as any
            newSchema.items.dependencies.component.oneOf.push({
              properties: {
                component: componentProp,
                ...Object.keys(rest).reduce((acc, item) => {
                  const { required, ...prop } = rest[item]
                  return {
                    ...acc,
                    [item]: prop,
                  }
                }, {}),
              },
              required: Object.keys(rest).filter((item) => rest[item].requiredParent),
            })
          }

          newSchema.items.properties.component.default = componentsData[0].name
          this.cachedSchemaLastTime = new Date().getTime()
          this.cachedSchema = { ...newSchema }
          return newSchema
        } else {
          return true
        }
      } else if (
        data?.sourceById?.schemaMode === gql_EnumSourceSchemaMode.Free &&
        data?.sourceById?.contentSchema
      ) {
        return JSON.parse(data?.sourceById?.contentSchema)
      } else if (projectId) {
        const { data: projectData } = await this.urqlClient
          .query<gql_GetProjectByIdQuery, gql_GetProjectByIdQueryVariables>(QUERY_GET_ONE_PROJECT, {
            id: projectId,
          })
          .toPromise()
        // const { data } = await this.projects.getOne(source.projectId)
        if (projectData?.project?.schema) {
          return JSON.parse(projectData?.project?.schema)
        }
      }
    } catch (error) {
      console.log('error parsing or retrieving json', error)
      //
    }
    // TODO: REMOVE THIS LINE
    return true
  }

  write(): WriteResult {
    return {
      success: false,
      errorMessage: 'Not implemented',
    }
  }
}
