import React, { FC } from 'react'
import { useMultiStyleConfig, ListProps, List } from '@chakra-ui/react'

type MasterDetailSubMenuProps = {} & ListProps

export const MasterDetailSubMenu: FC<MasterDetailSubMenuProps> = (props) => {
  const { children, ...rest } = props
  const styles = useMultiStyleConfig('MasterDetail', props)

  return (
    <List sx={styles.menuSubList} {...rest}>
      {children}
    </List>
  )
}

export default MasterDetailSubMenu
