import { HStack, useRadioGroup } from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import { gql_EnumDKeySourceKind, gql_EnumSourceSchemaMode } from '../../../core/graphql'
import SourceSchemaModeRadio, { SourceSchemaModeRadioProps } from './SourceSchemaModeRadio'

type Props = {
  name?: string
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
}

const SourceKindRadioGroup: FC<Props> = ({ name, defaultValue, onChange, value }) => {
  const options: SourceSchemaModeRadioProps[] = useMemo(
    () => [
      {
        title: 'Basic',
        value: gql_EnumDKeySourceKind.BasicSource,
        iconName: 'RiStickyNote2Fill',
        iconCollection: 'ri',
      },
      {
        title: 'Page',
        value: gql_EnumDKeySourceKind.Page,
        iconName: 'RiPagesFill',
        iconCollection: 'ri',
      },
      {
        title: 'Layout',
        value: gql_EnumDKeySourceKind.Layout,
        iconName: 'RiLayout3Fill',
        iconCollection: 'ri',
      },
    ],
    []
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
    value,
  })

  const group = getRootProps()

  return (
    <HStack d="flex" w="100%" {...group}>
      {options.map(({ value, title, iconName, iconCollection }) => {
        const radio = getRadioProps({ value } as any)
        return (
          <SourceSchemaModeRadio
            key={value}
            title={title}
            iconName={iconName}
            iconCollection={iconCollection}
            {...radio}
            id={`option-${value}`}
          />
        )
      })}
    </HStack>
  )
}

export default SourceKindRadioGroup
