import { JSONSchema7Definition } from 'json-schema'
import { useCallback, useEffect, useRef } from 'react'
import { RecoilState, useRecoilState } from 'recoil'
import { ID } from '../types/BaseCRUD'
import { SchemaState } from './SchemaState'
import { schemaStateFamily } from './schemaStateFamily'
import { useSchemaProvider } from './useSchemaProvider'

export type UseSchemaResult = SchemaState & {
  readSchema: () => Promise<void>
  writeSchema: () => Promise<void>
  setSchema: (value: JSONSchema7Definition) => void
  setInstanceSchema: (value: JSONSchema7Definition) => void
}

export type UseSchemaOptions = {
  sourceId: ID
  projectId: ID
  environmentId: ID
  atomFamily?: (sourceId: ID) => RecoilState<SchemaState>
}

export const useSchema = ({
  sourceId,
  projectId,
  environmentId,
  atomFamily = schemaStateFamily,
}: UseSchemaOptions): UseSchemaResult => {
  if (!sourceId) {
    throw new Error('sourceId not present')
  }

  const lastEnvironmentId = useRef(environmentId)
  const schemaProvider = useSchemaProvider()
  if (!schemaProvider) {
    throw new Error('SchemaProvider not present')
  }

  const [schemaState, setSchemaState] = useRecoilState(atomFamily(sourceId))

  const readSchema = useCallback(async () => {
    try {
      setSchemaState({ ...schemaState, reading: true })
      const schema = await schemaProvider.read(sourceId, projectId, environmentId)
      setSchemaState({
        ...schemaState,
        sourceId,
        initialized: true,
        schema,
        instanceSchema: schema,
        originalSchema: schema,
        reading: false,
      })
    } catch (error) {
      setSchemaState({
        ...schemaState,
        initialized: true,
        error,
        errorMessage: error.message,
        reading: false,
      })
    }
  }, [setSchemaState, schemaState, schemaProvider, sourceId, projectId, environmentId])

  const writeSchema = useCallback(async () => {
    try {
      setSchemaState({ ...schemaState, writing: true })
      const { success, error, errorMessage } = await schemaProvider.write(
        sourceId,
        '',
        environmentId,
        schemaState.schema || true
      )
      if (success) {
        setSchemaState({
          ...schemaState,
          writing: false,
          modified: false,
          originalSchema: schemaState.schema,
        })
      } else {
        setSchemaState({
          ...schemaState,
          writing: false,
          error,
          errorMessage,
        })
      }
    } catch (error) {
      setSchemaState({
        ...schemaState,
        writing: false,
        error,
        errorMessage: error.message,
      })
    }
  }, [setSchemaState, schemaState, schemaProvider, sourceId, environmentId])

  const setSchema = useCallback(
    (schema: JSONSchema7Definition) => {
      setSchemaState({
        ...schemaState,
        modified: true,
        schema,
      })
    },
    [schemaState, setSchemaState]
  )

  const setInstanceSchema = useCallback(
    (instanceSchema: JSONSchema7Definition) => {
      setSchemaState({
        ...schemaState,
        modified: true,
        instanceSchema,
      })
    },
    [schemaState, setSchemaState]
  )

  useEffect(() => {
    if (!schemaState.initialized && sourceId && !schemaState.reading) {
      // console.log('leggo per useEffect', schemaState)
      readSchema()
    }
  }, [readSchema, schemaState, sourceId])

  useEffect(() => {
    if (
      !schemaState.reading &&
      schemaState.initialized &&
      environmentId !== lastEnvironmentId.current
    ) {
      lastEnvironmentId.current = environmentId
      readSchema()
      // console.log('cambio environment: ', environmentId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentId])

  return { ...schemaState, readSchema, writeSchema, setSchema, setInstanceSchema }
}
