import React, { FC } from 'react'
import {
  Box,
  BoxProps,
  chakra,
  Divider,
  Fade,
  Flex,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import Moment from 'react-moment'
import BaseButton from '../../base/BaseButton'
import Status from '../../base/Status'
import Surface from '../../base/Surface'

type SourceHistoryListItemProps = {
  key: string | number
  item: any
  index: number
  selected: any
  onSelect: (selected: any) => void
}

const SourceHistoryListItem: FC<SourceHistoryListItemProps> = (props) => {
  const { item, index, selected, onSelect } = props
  const styles = useMultiStyleConfig('SourceHistoryModal', props)

  return (
    <BaseButton
      key={index}
      size={selected == index ? 'xl' : 'lg'}
      darkHue={selected == index ? 'light' : 'base'}
      onClick={() => onSelect(index)}
      sx={styles.item}
      isActive={selected == index}
      boxShadow="surfaceLight"
    >
      <chakra.div textAlign="left">
        <Text mb={1}>
          <Moment fromNow>{item.date}</Moment>
        </Text>
        <Flex color="white">
          <Text fontSize="xs" color="green.500" px={1} borderRadius="xs">
            +{item.addCount}
          </Text>
          <Divider orientation="vertical" h="auto" zIndex="1" mx={1} />
          <Text fontSize="xs" color="red.400" px={1} borderRadius="xs" ml={0}>
            -{item.removeCount}
          </Text>
        </Flex>
      </chakra.div>
    </BaseButton>
  )
}

export default SourceHistoryListItem
