import { useCallback } from 'react'
import { Container } from 'typedi'
import { BaseFile } from '../types/BaseFile'
import { ClassType } from '../types/ClassType'

export const TOKEN_CONTENT_PROVIDER = 'hydrogen_contentProvider'

type SetContentProviderFunction = (newValue: ClassType<BaseFile<string>> | undefined) => void

export const useContentProvider = (): BaseFile<string> | undefined => {
  return Container.get<BaseFile<string>>(TOKEN_CONTENT_PROVIDER)
}

export const useSetContentProvider = (): SetContentProviderFunction => {
  return useCallback((newValue: ClassType<BaseFile<string>> | undefined) => {
    Container.set({ id: TOKEN_CONTENT_PROVIDER, type: newValue })
  }, [])
}
