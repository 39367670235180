import { Client } from 'urql'
import { Create, Delete, GetList, GetOne, Update } from '../types/BaseCRUD'
import {
  Component,
  ComponentCreateInput,
  ComponentsCRUD,
  ComponentsFilters,
  ComponentsSort,
  ComponentUpdateInput,
} from '../types/Component'
import {
  MUTATION_CREATE_COMPONENT,
  MUTATION_UPDATE_COMPONENT,
  QUERY_GET_LIST_COMPONENTS,
  QUERY_GET_ONE_COMPONENT,
} from '../../queries'

export class HydrogenComponentsProvider implements ComponentsCRUD {
  urqlClient!: Client

  init(client: Client) {
    this.urqlClient = client
  }

  getOne: GetOne<Component> = async (id, options) => {
    const result = await this.urqlClient
      .query(QUERY_GET_ONE_COMPONENT, {
        id,
      })
      .toPromise()

    return {
      data: {
        ...result.data.componentById,
      },
      loading: false,
    }
  }

  getList: GetList<Component, ComponentsFilters, ComponentsSort> = async ({
    filters,
    pagination,
    sort,
    options,
  }) => {
    const { projectId, environmentId, ...derivedFilters } = filters
    const sortKeys = Object.keys(sort)
    const result = await this.urqlClient
      .query(QUERY_GET_LIST_COMPONENTS, {
        filter: {
          _id: derivedFilters.id,
          ...derivedFilters,
          project: projectId,
          environment: environmentId,
        },
        page: pagination.offset,
        perPage: pagination.limit,
        sort:
          sortKeys.length > 0
            ? `${sortKeys[0] === 'id' ? '_' : ''}${sortKeys[0].toUpperCase()}_${
                (sort as any)[sortKeys[0]]
              }`
            : undefined,
      })
      .toPromise()

    return {
      // eslint-disable-next-line no-underscore-dangle
      data: result.data.componentPagination.items,
      loading: false,
      error: result.error,
      total: result.data.componentPagination.count,
      pagination,
    }
  }

  create: Create<Component, ComponentCreateInput> = async (data) => {
    const result = await this.urqlClient
      .mutation(MUTATION_CREATE_COMPONENT, {
        record: {
          ...data,
        },
      })
      .toPromise()

    return {
      ...result.data.componentCreateOne.record,
    }
  }

  update: Update<Component, ComponentUpdateInput> = async (id, data) => {
    const result = await this.urqlClient
      .mutation(MUTATION_UPDATE_COMPONENT, {
        id,
        record: {
          ...data,
        },
      })
      .toPromise()

    return {
      ...result.data.componentUpdateById.record,
    }
  }

  delete: Delete = (id) => false
}
