import { Box, Textarea } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import BaseField from '../../old-components/workbench/ComponentEditor/base/BaseField'
import { FieldProps } from '../../core/types/FieldProps'

type Props = FieldProps

const NullField: FC<Props> = ({ value = '', onChange, label }) => {
  return (
    <BaseField label={label}>
      <Box my="2">Null Field</Box>
    </BaseField>
  )
}

export default NullField
