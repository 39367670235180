import { Button, chakra, Flex, Image, Input, Text } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useAuthProvider } from '../../core/auth/useAuthProvider'
import BaseButton from '../base/BaseButton'
import GradientBox from '../base/GradientBox'
import logo from '../../assets/images/logo-w.png'
import mockup from '../../assets/images/mockup-1.png'
import BaseIcon from '../base/BaseIcon'
import Surface from '../base/Surface'

type Props = {}

type LoginData = {
  email: string
  password: string
}

const LoginPage: FC<Props> = () => {
  const authProvider = useAuthProvider()

  if (!authProvider) {
    throw new Error('AuthProvider not available in LoginPage')
  }

  const history = useHistory()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const { register, handleSubmit } = useForm<LoginData>()

  const onSubmit = handleSubmit(async (credentials) => {
    try {
      setSubmitting(true)
      setError(false)
      await authProvider.login(credentials)
      history.replace('/')
    } catch (e) {
      console.error(e)
      setError(true)
    } finally {
      setSubmitting(false)
    }
  })

  // useEffect(() => {
  //   authProvider
  //     .checkAuth()
  //     .then(() => history.replace('/'))
  //     .catch(() => {
  //     // need to login
  //     })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <chakra.div d="flex" h="100vh">
      <GradientBox
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        left={0}
        right={0}
        maxWidth="100%"
        zIndex="1"
        lightColors={['light.100', 'transparent']}
        darkColors={['dark.800', 'transparent']}
        direction="to-b"
      />
      <chakra.form onSubmit={onSubmit} alignSelf="center" position="relative" zIndex="1" w="35%">
        <Flex flexDir="column" p={16}>
          <Image src={logo} w="200px" mb={10} />
          <Text fontSize="2xl">Log in</Text>
          <Text fontSize="5xl" mb={6} fontWeight="bold" lineHeight="50px">
            Welcome back!
          </Text>
          <Text fontSize="md" mb={2}>
            Email
          </Text>
          <Input ref={register({ required: true })} name="email" mb={4} size="lg" />
          <Text fontSize="md" mb={2}>
            Password
          </Text>
          <Input
            ref={register({ required: true })}
            name="password"
            type="password"
            mb={4}
            size="lg"
          />
          {error && <div>Wrong Email or Password</div>}
          <Button
            disabled={submitting}
            bgColor="green.500"
            color="white"
            type="submit"
            size="lg"
            mt={2}
            isLoading={submitting}
            boxShadow="md"
          >
            {submitting ? 'Loading...' : 'Login'}
            <BaseIcon name="BsArrowRightShort" collection="bs" ml={2} fontSize="2xl" />
          </Button>
        </Flex>
      </chakra.form>
      <Flex w="65%" justifyContent="flex-end" p={4}>
        <Image src={mockup} h="600px" mb={10} position="absolute" left="40%" bottom="0%" />
        <Surface
          w="50%"
          h="100%"
          lightBgColor="light.200"
          darkBgColor="dark.500"
          alignSelf="flex-end"
          borderRadius="lg"
        />
      </Flex>
    </chakra.div>
  )
}

export default LoginPage
