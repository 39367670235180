import { Box, Input } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import BaseField from '../../old-components/workbench/ComponentEditor/base/BaseField'
import { FieldProps } from '../../core/types/FieldProps'

type Props = FieldProps

const NumberField: FC<Props> = ({ value, onChange, label, path }) => {
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        if (!event.target.value) {
          onChange(path, 0)
        } else if (!isNaN(Number(event.target.value))) {
          onChange(path, Number(event.target.value))
        }
      }
    },
    [onChange, path]
  )

  return (
    <BaseField label={label}>
      <Box my="2">
        <Input
          name="value"
          onChange={handleOnChange}
          value={value as any}
          isReadOnly={false}
          mb="3"
          bg="dark.700"
          border="dark.800"
        />
      </Box>
    </BaseField>
  )
}

export default NumberField
