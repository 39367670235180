import {
  Text,
  Flex,
  InputGroup,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  AccordionProps,
  chakra,
  useRadio,
  HStack,
  useRadioGroup,
  Tag,
  Textarea,
  Switch,
  Spacer,
  Button,
  Alert,
  Stack,
} from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'
import RadioCard from '../../base/RadioCard'

type ProjectInfoFormProps = {
  projectData?: any[any]
  register: any
}

const ProjectInfoForm: FC<ProjectInfoFormProps> = ({ register, projectData }) => {
  const options = [
    { value: 'nextjs', title: 'Next.JS', icon: 'SiNextDotJs', iconCollection: 'si' },
    { value: 'react', title: 'React', icon: 'SiReact', iconCollection: 'si' },
    { value: 'static', title: 'Static', icon: 'FaHtml5', iconCollection: 'fa' },
    { value: 'email', title: 'Email', icon: 'SiMinutemailer', iconCollection: 'si' },
  ]
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'projectType',
    defaultValue: 'nextjs',
    onChange: console.log,
  })
  const group = getRootProps()
  const selectedOption = useMemo(() => {
    return options.find((option) => option.value == value)
  }, [value])

  return (
    <Stack spacing={8}>
      <FormControl id="sourceName">
        <FormLabel>Type</FormLabel>
        <HStack spacing={5} w="100%" justifyContent="space-between" {...group}>
          {options.map((option: any) => {
            const radio = getRadioProps({ value: option.value, enterKeyHint: 0 })
            return (
              <RadioCard key={option.value} {...radio}>
                <BaseIcon
                  name={option.icon}
                  collection={option.iconCollection as any}
                  fontSize="6xl"
                  mb={3}
                />
                <Text
                  fontWeight="bold"
                  fontSize="lg"
                  colorScheme={(radio as any).isChecked ? '' : 'green'}
                >
                  {option.title}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>
        {selectedOption && selectedOption.iconCollection && (
          <Flex d="flex" borderRadius="md" mt={4}>
            <BaseIcon
              name={selectedOption.icon}
              collection={selectedOption.iconCollection as any}
              mr="2"
            />
            breve spiegazione sul tipo progetto scelto
          </Flex>
        )}
      </FormControl>
      <Flex>
        <FormControl id="projectName" mr={6}>
          <FormLabel>Name</FormLabel>
          <InputGroup size="lg">
            <Input
              type="text"
              placeholder="Project name"
              name="name"
              ref={register({ required: false, minLength: 1, maxLength: 50 })}
            />
          </InputGroup>
        </FormControl>
      </Flex>
      <FormControl id="projectCoverImage">
        <FormLabel>Cover Image</FormLabel>
        <chakra.div d="flex" flexDir="row" alignItems="center">
          <chakra.div
            h="120px"
            w="160px"
            bgColor="darkAlpha.100"
            // bgImage={`url(${data.urlMedium})`}
            bgPos="center"
            backgroundSize="cover"
            borderRadius="md"
            cursor="pointer"
            alignItems="center"
            justifyContent="center"
            d="flex"
            mr={3}
          >
            <BaseIcon name="BsImage" collection="bs" fontSize="2xl" />
          </chakra.div>
          <chakra.div p="3">
            <Text mb={3}>Select image to use as project cover</Text>
            <Button size="sm" mr={2}>
              <BaseIcon name="BiUpload" collection="bi" mr="2" /> Select
            </Button>
            {/* {value && value !== '' && data?.urlMedium && (
              <BaseButton size="sm" color="red.400" onClick={handleRemoveImage}>
                <BaseIcon name="FaTrashAlt" collection="fa" fontSize="sm" />
              </BaseButton>
            )} */}
          </chakra.div>
        </chakra.div>
      </FormControl>
      <FormControl id="projectDescription">
        <FormLabel>Description</FormLabel>
        <InputGroup size="lg">
          <Textarea
            placeholder="Project description"
            name="name"
            ref={register({ required: false, minLength: 1, maxLength: 50 })}
          />
        </InputGroup>
      </FormControl>
    </Stack>
  )
}

export default ProjectInfoForm
