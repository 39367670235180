import React, { FC } from 'react'
import { Flex, Tag, Text } from '@chakra-ui/react'

type Props = {
  value?: any
  title?: string
  highlightedContent?: boolean
}

const NodeViewInfo: FC<Props> = ({ value, title, highlightedContent = false }) => {
  return (
    <Flex flexDir="column" px="0" flex="1" ml="3">
      <Text fontSize="lg" fontWeight="300" textTransform="capitalize">
        {title}
      </Text>
      {highlightedContent ? (
        <Flex flexWrap="wrap">
          <Tag bg="dark.800" color="dark.200" rounded="md" fontWeight="bold" px="5" size="lg">
            {value}
          </Tag>
        </Flex>
      ) : (
        <Text fontSize="xl" mt="1" fontWeight="bold">
          {(typeof value === 'number' ||
            typeof value === 'string' ||
            (typeof value === 'boolean' && typeof value === 'string')) &&
            value}
        </Text>
      )}
    </Flex>
  )
}

export default NodeViewInfo
