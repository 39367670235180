import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  chakra,
  Stack,
  Divider,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  VStack,
  Collapse,
} from '@chakra-ui/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useGetProjectByIdQuery } from '../../../core/graphql'
import Loading from '../../base/Loading'
import { ID } from '../../../core/types/BaseCRUD'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import FlagIcon from '../../base/FlagIcon'

type Props = {
  name?: string
  projectId?: ID
  sourceId?: ID
}

const PageSEOFormSection: FC<Props> = ({ name = 'seo', projectId }) => {
  const [projectResult, refetchProject] = useGetProjectByIdQuery({
    variables: { id: projectId },
  })
  const { fetching, data } = projectResult
  const { control, getValues, setValue, register } = useFormContext()
  const { fields, insert, append, remove } = useFieldArray({ control, name })
  const [selectedLocale, setSelectedLocale] = useState<string | undefined>(undefined)
  const selectedLocaleIndex = useMemo<number>(() => {
    if (selectedLocale) {
      return fields.findIndex((item) => item.locale === selectedLocale)
    }

    return -1
  }, [fields, selectedLocale])

  const locationFlag = useMemo(
    () =>
      fields &&
      fields[selectedLocaleIndex] &&
      fields[selectedLocaleIndex].locale &&
      fields[selectedLocaleIndex].locale.indexOf('-') !== -1
        ? fields[selectedLocaleIndex].locale.split('-')[1].toLowerCase()
        : undefined,
    [selectedLocaleIndex, fields]
  )

  const handleLocaleClick = useCallback(
    (locale: string) => () => {
      setSelectedLocale(locale)
    },
    []
  )

  // useEffect(() => {
  //   if (
  //     fields.length === 0 &&
  //     data &&
  //     data.project &&
  //     data.project.locales &&
  //     data.project.locales.length > 0
  //   ) {
  //     append(
  //       data.project.locales.map((locale) => ({
  //         title: '',
  //         description: '',
  //         locale,
  //       }))
  //     )
  //   } else if (
  //     data &&
  //     data.project &&
  //     data.project.locales &&
  //     fields.length < data.project?.locales?.length
  //   ) {
  //     const toAddLocales = data.project.locales.filter((item) => {
  //       return !fields.find((f) => f.locale === item)
  //     })
  //     console.log('ciao 2', fields, data.project.locales)
  //     console.log('ciao - to add', toAddLocales)
  //     append(
  //       toAddLocales.map((locale) => ({
  //         title: '',
  //         description: '',
  //         locale,
  //       }))
  //     )
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, fields.length])

  useEffect(() => {
    if (!selectedLocale && data?.project?.defaultLocale) {
      setSelectedLocale(data?.project?.defaultLocale)
    }
  }, [data?.project?.defaultLocale, selectedLocale])

  if (fetching) {
    return <Loading />
  }

  return (
    <chakra.div display="flex" flexDir="column">
      <Menu>
        <MenuButton
          alignSelf="flex-end"
          isLoading={fetching}
          as={BaseButton}
          pr={2}
          pl={3}
          ml={2}
          darkBgColor="dark.800"
        >
          <Flex>
            <Box borderRadius="sm" overflow="hidden" d="flex">
              {locationFlag ? <FlagIcon code={locationFlag} /> : <span>ND</span>}
            </Box>
            <BaseIcon name="IoIosArrowDown" collection="io" fontSize="sm" m={0} ml={1} />
          </Flex>
        </MenuButton>
        <MenuList>
          {data &&
            data.project &&
            data.project.locales &&
            data.project.locales.map((item, index) => {
              return (
                <MenuItem key={`seo-lang-${item}`} onClick={handleLocaleClick(item as any)}>
                  {item}
                </MenuItem>
              )
            })}
        </MenuList>
      </Menu>
      {fields &&
        fields.map((item, index) => {
          return (
            <Collapse key={`seo-options-${item.locale}`} in={index === selectedLocaleIndex}>
              <input
                type="hidden"
                name={`${name}[${index}].locale`}
                defaultValue={item.locale}
                ref={register}
              />
              <VStack spacing={7}>
                {/* <pre>
                  {JSON.stringify({ item, index, name: `${name}[${index}].title` }, null, 2)}
                </pre> */}
                <FormControl>
                  <FormLabel>SEO Title</FormLabel>
                  <InputGroup>
                    <Input
                      defaultValue={item.title}
                      type="text"
                      placeholder="SEO Title"
                      name={`${name}[${index}].title`}
                      ref={register}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>SEO Description</FormLabel>
                  <InputGroup>
                    <Input
                      defaultValue={item.description}
                      type="text"
                      placeholder="SEO Description"
                      name={`${name}[${index}].description`}
                      ref={register}
                    />
                  </InputGroup>
                </FormControl>
              </VStack>
            </Collapse>
          )
        })}
      {/* {fields && fields[selectedLocaleIndex] ? (
        <VStack spacing={7}>
          <FormControl>
            <FormLabel>SEO Title</FormLabel>
            <InputGroup>
              <Input
                type="text"
                placeholder="SEO Title"
                name={`seo[${selectedLocaleIndex}].seo.title`}
                ref={register}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>SEO Description</FormLabel>
            <InputGroup>
              <Input
                type="text"
                placeholder="SEO Description"
                name={`seo[${selectedLocaleIndex}].seo.description`}
                ref={register}
              />
            </InputGroup>
          </FormControl>
        </VStack>
      ) : (
        <Alert status="error">
          <AlertIcon />
          There's an unexpected error in localized SEO
        </Alert>
      )} */}
    </chakra.div>
  )
}

export default PageSEOFormSection
