import React, { FC, useCallback, useEffect, useRef } from 'react'
import {
  Flex,
  chakra,
  Text,
  Icon,
  Button,
  Editable,
  EditablePreview,
  EditableInput,
  Input,
} from '@chakra-ui/react'
import Moment from 'react-moment'
import { source } from 'react-uid/dist/es5/context'
import { IoMdClose } from 'react-icons/io'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import Status from '../base/Status'
import Surface from '../base/Surface'

type Props = {
  onClose?: () => void
}

const CreateFolderCard: FC<Props> = ({ onClose }) => {
  const inputRef = useRef<any>()

  const handleOnCloseClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation()
      if (onClose) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <Surface
      display="flex"
      flexDir="column"
      rounded="md"
      overflow="hidden"
      shadow="sm"
      cursor="pointer"
      position="relative"
      // ref={ref}
      lightBgColor="white"
      lightBgHoverColor="whiteAlpha.600"
      darkBgColor="dark.600"
      darkBgHoverColor="dark.500"
      hoverable
    >
      <BaseButton
        as={Button}
        position="absolute"
        right={2}
        top={2}
        onClick={handleOnCloseClick}
        size="xs"
        darkBgColor="dark.700"
      >
        <Icon as={IoMdClose} />
      </BaseButton>

      <Flex flexDir="column" flex="1" px="5" my="5" w="100%" pr={12}>
        <Text ml={1} fontSize="xs">
          New folder
        </Text>

        <Input size="sm" ref={inputRef} type="text" />
      </Flex>
    </Surface>
  )
}

export default CreateFolderCard
