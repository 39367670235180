const radii = {
  none: '0px',
  xs: '2px',
  sm: '5px',
  md: '10px',
  lg: '20px',
  full: '100px',
}

export default radii
