import React, { FC, useCallback } from 'react'
import {
  Box,
  chakra,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
  Tooltip,
  useColorMode,
  useDisclosure,
  useMultiStyleConfig,
  Link as ChakraLink,
  Switch,
} from '@chakra-ui/react'
import { Link, useRouteMatch } from 'react-router-dom'
import Surface from '../base/Surface'
import logo from '../../assets/images/hydrogen-logo.png'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import LoggedUserNavbarInfo from '../auth/LoggedUserMenu/LoggedUserNavbarInfo'
import { MediaExplorerModal } from '../../old-components/workbench/MediaExplorer'
import {
  ProjectSettingsButton,
  ProjectSettingsModal,
} from '../../old-components/workbench/ProjectSettings'
import { ID } from '../../core/types/BaseCRUD'

type Props = {
  projectId: ID
}

const ActivityBar: FC<Props> = (props) => {
  const { children, projectId } = props
  const { url } = useRouteMatch()
  const { colorMode, toggleColorMode } = useColorMode()
  const { isOpen: isMediaOpen, onClose: onCloseMedia, onOpen: onOpenMedia } = useDisclosure()
  const {
    isOpen: isSettingsOpen,
    onClose: onCloseSettings,
    onOpen: onOpenSetting,
  } = useDisclosure()
  const styles = useMultiStyleConfig('WorkbenchNavbar', props)

  return (
    <Surface lightBgColor="light.50" darkBgColor="dark.800" sx={styles.wrapper}>
      <chakra.div sx={styles.header} as={Link} to="/projects">
        <Image src={logo} w="50px" />
      </chakra.div>

      <Stack spacing={3} sx={styles.list}>
        <Divider w="50%" alignSelf="center" />
        <BaseButton as="div" sx={styles.listItem}>
          <Link to={`/workbench/${projectId}/sources`}>
            <BaseIcon name="HiDocument" collection="hi" fontSize="lg" />
          </Link>
        </BaseButton>
        <BaseButton as="div" sx={styles.listItem}>
          <Link to={`/workbench/${projectId}/components`}>
            <BaseIcon name="HiViewGridAdd" collection="hi" fontSize="xl" />
          </Link>
        </BaseButton>
        {/* <BaseButton
          // isActive={mode === WorkbenchMode.SOURCE_EDITOR_MODE}
          // onClick={switchWorkbenchMode(WorkbenchMode.SOURCE_EDITOR_MODE)}
          sx={styles.listItem}
        >
          <BaseIcon name="RiPagesFill" collection="ri" />
        </BaseButton>
        <BaseButton
          // isActive={mode === WorkbenchMode.COMPONENTS_VIEWER_MODE}
          // onClick={switchWorkbenchMode(WorkbenchMode.COMPONENTS_VIEWER_MODE)}
          sx={styles.listItem}
        >
          <BaseIcon name="BsFillGridFill" collection="bs" fontSize="sm" />
        </BaseButton> */}
        <Divider w="50%" alignSelf="center" />
        <BaseButton sx={styles.listItem} isActive={isMediaOpen} onClick={onOpenMedia}>
          <BaseIcon name="MdPermMedia" collection="md" fontSize="md" />
        </BaseButton>
        <BaseButton sx={styles.listItem} isActive={isSettingsOpen} onClick={onOpenSetting}>
          <BaseIcon name="RiRouteFill" collection="ri" fontSize="xl" />
        </BaseButton>
      </Stack>
      <chakra.div sx={styles.footer}>
        <Tooltip label={colorMode === 'dark' ? 'Dark mode' : 'Light mode'} placement="right">
          <BaseButton onClick={toggleColorMode} sx={styles.listItem}>
            <BaseIcon name={colorMode === 'dark' ? 'FaMoon' : 'FaSun'} fontSize="md" />
          </BaseButton>
        </Tooltip>

        <Divider w="50%" alignSelf="center" my={3} />
        <LoggedUserNavbarInfo />
      </chakra.div>
      {children}
      <MediaExplorerModal isOpen={isMediaOpen} onClose={onCloseMedia} />
      <ProjectSettingsModal
        projectId={projectId}
        isOpen={isSettingsOpen}
        onClose={onCloseSettings}
      />
    </Surface>
  )
}

export default ActivityBar
