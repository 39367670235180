import React, { createElement, FC, forwardRef } from 'react'
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react'
import { useSchemaNode } from '../../core/schema/useSchemaNode'
import ArrayNode from './ArrayNode'
import ObjectNode from './ObjectNode'
import StringNode from './StringNode/StringNode'
import BooleanNode from './BooleanNode'
import NumberNode from './NumberNode'
import NullNode from './NullNode'
import { NodeProps } from '../../core/types/NodeProps'

type Props = Omit<NodeProps, 'typeName'>

const SchemaNode = forwardRef<HTMLSpanElement, Props>(
  (
    {
      // TODO: put schemaNodes somewhere else (recoil?)
      schemaNodes = {
        array: ArrayNode,
        object: ObjectNode,
        string: StringNode,
        boolean: BooleanNode,
        integer: NumberNode,
        number: NumberNode,
        unsupported: NullNode,
        null: NullNode,
      },
      ...props
    },
    ref
  ) => {
    const { typeName, resolvedSchema } = useSchemaNode(props)

    if (!typeName || typeName === 'unsupported') {
      return (
        <Alert borderRadius={5} status="error">
          <AlertIcon />
          <AlertTitle color="black" mr={2}>
            Schema Error!
          </AlertTitle>
          <AlertDescription color="black">Schema type is undefined.</AlertDescription>
        </Alert>
      )
    }

    if (Array.isArray(typeName)) {
      return (
        <Alert borderRadius={5} status="error">
          <AlertIcon />
          <AlertTitle color="black" mr={2}>
            Schema Error!
          </AlertTitle>
          <AlertDescription color="black">Array type is not supported.</AlertDescription>
        </Alert>
      )
    }

    if (!schemaNodes || !schemaNodes[typeName]) {
      return (
        <Alert borderRadius={5} status="error">
          <AlertIcon />
          <AlertTitle color="black" mr={2}>
            Preview Error!
          </AlertTitle>
          <AlertDescription color="black">
            schemaNodes props is undefined or component node is undefined.
          </AlertDescription>
        </Alert>
      )
    }

    // if (!props.value && typeof props.value !== 'boolean' && props.value !== 0 && props.value !== '') {
    // return null
    // return (
    //   <Alert borderRadius={5} status="info">
    //     <AlertIcon />
    //     <AlertTitle color="black" mr={2}>
    //       Empty content.
    //     </AlertTitle>
    //     <AlertDescription color="black">Add some content</AlertDescription>
    //   </Alert>
    // )
    // }

    return (
      <>
        <span id={`schema-node-${props.path ? props.path.replace('/', '-') : ''}`} />
        {/* <pre>{JSON.stringify(resolvedSchema, null, 2)}</pre> */}
        {createElement(schemaNodes[typeName] as any, {
          schema: resolvedSchema,
          rootSchema: props.rootSchema,
          value: props.value,
          sourceId: props.sourceId,
          // source: props.source,
          path: props.path,
          schemaPath: props.schemaPath,
          parentPath: props.parentPath,
          parentSchemaPath: props.parentSchemaPath,
          typeName,
          onChange: props.onChange,
        })}
      </>
    )
  }
)

export default SchemaNode
