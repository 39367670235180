import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react'
import React, { FC } from 'react'

type Props = {
  errorMessage?: React.ReactNode
}

const ErrorAlert: FC<Props> = ({ errorMessage }) => {
  return (
    <Alert
      status="error"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertDescription maxWidth="sm">{errorMessage}</AlertDescription>
    </Alert>
  )
}

export default ErrorAlert
