import React, { MutableRefObject, useCallback } from 'react'
import { mode } from '@chakra-ui/theme-tools'
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Portal,
  Button,
  Text,
  List,
  ListItem,
  Divider,
  keyframes,
  useColorMode,
  ColorMode,
  useTheme,
  BoxProps,
  Badge,
  SimpleGrid,
  chakra,
} from '@chakra-ui/react'
import Moment from 'react-moment'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'
import BaseButton from '../../base/BaseButton'
import {
  gql_HyProjectFragment,
  gql_SortFindManySourceInput,
  useGetListSourcesQuery,
} from '../../../core/graphql'
import useHover from '../../../core/utils/useHover'

type ProjectSourcesCardProps = {
  project: gql_HyProjectFragment
  onSelect?: (project: gql_HyProjectFragment) => void
  onClickSettings?: (project: gql_HyProjectFragment, targetRef?: any) => void
  onSelectProject?: (project: gql_HyProjectFragment) => void
}

type RefType = HTMLDivElement

const ProjectSourcesCard = React.forwardRef<RefType, ProjectSourcesCardProps>((props, ref) => {
  const { project, onSelect, onClickSettings, ...rest } = props
  const btnRef = React.useRef<any>()
  const [hoverRef, isHover] = useHover(100)

  const handleOnClick = (event: any): void => {
    event.stopPropagation()
    onSelect && onSelect(project)
  }
  const handleonClickSettings = (event: any): void => {
    event.stopPropagation()
    onClickSettings && onClickSettings(project, btnRef)
  }

  return (
    <Surface
      display="flex"
      flexDir="column"
      rounded="md"
      overflow="hidden"
      shadow="sm"
      h={230}
      _hover={{ shadow: 'lg' }}
      onClick={handleOnClick}
      cursor="pointer"
      ref={ref}
      {...rest}
      lightBgColor="light.50"
    >
      <Flex
        w="100%"
        h={180}
        p="2"
        position="relative"
        overflow="hidden"
        flexDir="column"
        ref={hoverRef}
      >
        <Flex
          flexDir="column"
          position="absolute"
          left="0"
          top="0"
          w="100%"
          h="100%"
          justifyContent="flex-start"
          alignItems="flex-start"
          zIndex={9}
        >
          <Flex minW="100%" boxSize="100%" px="0">
            <ProjectSourcesList project={project} spacing={2} limit={3} />
          </Flex>
        </Flex>
        <Menu placement="bottom">
          <MenuButton
            zIndex={9}
            as={Button}
            onClick={(event: any): void => {
              event.stopPropagation()
            }}
            alignSelf="flex-end"
            size="sm"
            ref={btnRef}
            shadow="sm"
          >
            <BaseIcon name="FaEllipsisH" />
          </MenuButton>
          <Portal>
            <MenuList
              borderWidth={1}
              border="0px solid"
              shadow="lg"
              position="absolute"
              zIndex="9999"
            >
              <MenuOptionGroup title="Options">
                <MenuItem onClick={handleOnClick}>Edit</MenuItem>
                <MenuItem onClick={handleonClickSettings}>Settings</MenuItem>

                <Divider />
                <MenuItem as="a" color="red.500">
                  <Text flex="1">Delete</Text>
                  <BaseIcon name="FaTrashAlt" />
                </MenuItem>
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
      <Flex flexDir="row" px="3" py="2">
        <Flex flexDir="column" flex="1">
          <Text fontSize="lg" fontWeight="bold">
            {project.name}
          </Text>
          <Text fontSize="sm" opacity={0.6}>
            <Moment fromNow>{project.updatedAt}</Moment>
          </Text>
        </Flex>
      </Flex>
    </Surface>
  )
})

const slideAnimation = keyframes`
0% {
  transform: translate3d(0, 0%, 0);
}

50% {
  transform: translate3d(0, -50%, 0);
}

100% {
  transform: translate3d(0,0%,0);
}
`

export type ProjectSourcesListProps = {
  project: gql_HyProjectFragment
  spacing?: number
  limit?: number
  animate?: boolean
  showDelete?: boolean
  onSelect?: (project: gql_HyProjectFragment) => void
} & BoxProps

export const ProjectSourcesList = React.forwardRef<HTMLElement, ProjectSourcesListProps>(
  (props, ref) => {
    const {
      project,
      fontSize = 'xs',
      spacing = 3,
      bg,
      color,
      limit = 3,
      showDelete = false,
      animate,
      onSelect,
      ...rest
    } = props

    const [result, refetch] = useGetListSourcesQuery({
      variables: {
        sort: gql_SortFindManySourceInput.NameAsc,
        perPage: 5,
        page: 0,
      },
    })

    const { data, fetching, error } = result

    const handleOnClickProject = useCallback(
      (project: gql_HyProjectFragment): void => {
        onSelect && onSelect(project)
      },
      [onSelect]
    )

    return (
      <Surface
        zIndex="-1"
        h="100%"
        css={css`
          width: 100%;
          animation: ${animate && slideAnimation} ${1 + 0.5 * limit}s ease infinite;
        `}
        {...rest}
      >
        <SimpleGrid
          columns={2}
          boxSize="100%"
          spacing={spacing}
          bg={bg}
          m="0"
          p="4"
          overflow="hidden"
          bgColor="darkAlpha.100"
        >
          {data?.sourcePagination?.items &&
            data?.sourcePagination?.items.slice(0, limit).map((source, index) => (
              <Surface
                key={index}
                fontSize={fontSize}
                color={color}
                onClick={(event: any) => handleOnClickProject(project)}
                cursor="pointer"
                py={0}
                px={3}
                darkBgColor="dark.700"
                borderRadius="md"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <chakra.div>
                  <BaseIcon name="HiDocument" collection="hi" fontSize="lg" />
                  <Text fontWeight="400" flex="1" overflow="hidden" textOverflow="ellipsis">
                    {source!.name}
                  </Text>
                  {showDelete && (
                    <Button size="xs" onClick={() => {}} alignSelf="flex-end">
                      <BaseIcon name="FaTrashAlt" fontSize="xs" />
                    </Button>
                  )}
                </chakra.div>
              </Surface>
            ))}
          {data?.sourcePagination &&
            data?.sourcePagination.count &&
            data?.sourcePagination.count > limit - 1 && (
              <Surface
                fontSize={fontSize}
                color={color}
                onClick={(event: any) => handleOnClickProject(project)}
                cursor="pointer"
                py={0}
                px={3}
                darkBgColor="dark.600"
                lightBgColor="light.100"
                borderRadius="md"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <chakra.div>
                  <BaseIcon name="HiDocument" collection="hi" fontSize="lg" />
                  <Text fontWeight="600">+{data.sourcePagination.count - limit}</Text>
                </chakra.div>
              </Surface>
            )}
        </SimpleGrid>
      </Surface>
    )
  }
)

const CardShadowBox = styled.div((props) => {
  const theme = useTheme()
  const { colorMode, toggleColorMode } = useColorMode()
  const gradientStartColor = colorMode == 'dark' ? theme.colors.dark[800] : theme.colors.light[50]
  const gradientEndColor =
    colorMode == 'dark' ? theme.colors.darkAlpha[200] : theme.colors.light[100]

  return {
    display: 'flex',
    backgroundImage: `linear-gradient(${gradientStartColor}, ${gradientEndColor} 95%)`,
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    maxWidth: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    zIndex: -1,
  }
})

export default ProjectSourcesCard
