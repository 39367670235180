import { useCallback, useEffect, useRef, useState } from 'react'
import { BaseCRUD, ID } from '../types/BaseCRUD'

export type Refetch = (id?: ID | undefined) => void

export type UseGetOneOptions<TModel> = {
  skip?: () => boolean
  dataProvider: BaseCRUD<TModel>
  id: ID
}

export type UseGetOneResult<TModel> = {
  loading: boolean
  loaded: boolean
  error?: any
  errorMessage?: string
  data?: TModel
  refetch: Refetch
}

export function useGetOne<TModel>({
  skip,
  dataProvider,
  id,
}: UseGetOneOptions<TModel>): UseGetOneResult<TModel> {
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const refLoading = useRef<boolean>(false)
  const refLoaded = useRef<boolean>(false)
  const [error, setError] = useState<any>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [data, setData] = useState<TModel | undefined>()
  const [lastId, setLastId] = useState<ID>(id)

  const fetchData = useCallback(
    async (localId?: ID): Promise<void> => {
      refLoading.current = true
      try {
        setLoading(true)

        const { data, error } = await dataProvider.getOne(localId || id)
        if (error) {
          setError(error)
          setErrorMessage(error.message)
        } else {
          setData(data)
        }
      } catch (e) {
        setError(e)
        setErrorMessage(e.message)
      } finally {
        refLoading.current = false
        refLoaded.current = true
        setLoading(false)
        setLoaded(true)
      }
    },
    [id, dataProvider]
  )

  const refetch = useCallback(
    (localId?: ID) => {
      fetchData(localId)
    },
    [fetchData]
  )

  useEffect(() => {
    if (!refLoaded.current && !refLoading.current && !error) {
      const skipResult = skip && skip()
      if (!skipResult) {
        fetchData()
      } else {
        refLoading.current = false
        refLoaded.current = true
        setLoading(false)
        setLoaded(true)
      }
    }
  }, [skip, id, loaded, loading, error, fetchData])

  useEffect(() => {
    const skipResult = skip && skip()
    if (!skipResult && !refLoading.current && id !== lastId) {
      fetchData()
      setLastId(id)
    }
  }, [id, lastId])

  return { loading, loaded, data, error, errorMessage, refetch }
}
