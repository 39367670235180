import React, { FC, useCallback, useState } from 'react'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  chakra,
  Flex,
  ScaleFade,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Link, useHistory } from 'react-router-dom'
import logoImage from '../../../assets/images/icon.png'
import { HydrogenRouteProps } from '../../../core/types/HydrogenRouteProps'
import ProjectsGrid from './ProjectsGrid'
import MainAside from './MainAside'
import ProjectDrawer from './ProjectDrawer'
import BaseIcon from '../../base/BaseIcon'
import BaseSearch from '../../base/BaseSearch'
import {
  gql_HyProjectFragment,
  gql_SortFindManyProjectInput,
  useGetListProjectsQuery,
} from '../../../core/graphql'
import Loading from '../../base/Loading'
import { CreateProject, ProjectInfoForm } from '../CreateProject'

type Props = HydrogenRouteProps & {
  [prop: string]: any
  folderId?: string | number
}

const ProjectsExplorer: FC<Props> = (props) => {
  const history = useHistory()
  const [result, refetch] = useGetListProjectsQuery({
    variables: {
      page: 0,
      perPage: 100,
      sort: gql_SortFindManyProjectInput.NameAsc,
    },
  })
  const { data, fetching } = result

  const [selected, setSelected] = useState<gql_HyProjectFragment>()

  const {
    isOpen: isProjectDrawerOpen,
    onOpen: onProjectOpen,
    onClose: onProjectClose,
  } = useDisclosure()
  const {
    isOpen: isFolderDrawerOpen,
    onOpen: onFolderOpen,
    onClose: onFolderClose,
  } = useDisclosure()

  const {
    isOpen: isCreateProjectOpen,
    onOpen: onCreateProjectOpen,
    onClose: onCreateProjectClose,
  } = useDisclosure({ defaultIsOpen: false })

  // const handleOpenSettings = (target: Project, ref?: any): void => {
  // if (ref) btnRef.current = ref.current
  // setSelected(target)
  // else onProjectOpen()
  // }

  const handleCloseSettings = (): void => {
    //   else onProjectClose()
    //   setSelected(undefined)
  }

  const handleOnEdit = useCallback(
    (project: gql_HyProjectFragment): void => {
      // if (folder) history.push(`/workbench/${folder.id}/${project.id}`)
      // else
      history.push(`/workbench/${project.id}`)
    },
    [history]
  )

  const handleOnSave = useCallback((target: gql_HyProjectFragment): void => {
    alert('Marchino salva sto cazzo di file')
  }, [])

  const handleOnSaveMove = useCallback((project: gql_HyProjectFragment): void => {
    alert('Marchino salva sto cazzo di file')
  }, [])

  const handleOpenMove = useCallback((project: gql_HyProjectFragment): void => {
    // setMoveTarget(project)
  }, [])

  const handleCloseMove = useCallback((): void => {
    // setMoveTarget(undefined)
  }, [])

  if (fetching) {
    return <Loading title="Loading Workbench" h="100vh" imageSrc={logoImage} />
  }

  return (
    <Flex h="100vh" w="100%">
      <MainAside />
      <ScaleFade in={true} style={{ width: '100%' }}>
        <chakra.div boxSize="100%">
          <Box
            display="flex"
            flex="1"
            flexDir="column"
            h="100%"
            w="100%"
            overflow="hidden"
            // color={textColor}
            // bgColor={layoutBgAltColor}
          >
            <Box py="6" px="50px" w="100%" overflow="scroll">
              <Flex alignItems="center" w="100%">
                <Breadcrumb
                  // color={textAltColor}
                  alignSelf="center"
                  mr={5}
                  separator={<BaseIcon name="FaChevronRight" />}
                >
                  <BreadcrumbItem isCurrentPage={true}>
                    <Link to="/projects">
                      <BreadcrumbLink href="#">{data?.projects?.count} projects</BreadcrumbLink>
                    </Link>
                  </BreadcrumbItem>
                  {/* {folder && (
                <BreadcrumbItem isCurrentPage justifySelf="center">
                  <BreadcrumbLink display="flex" alignItems="center">
                    <Text ml="1">{folder?.name}</Text>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                )} */}
                </Breadcrumb>
              </Flex>
              <Flex
                flexDir={['column', null, null, 'row']}
                alignItems={['flex-start', null, null, 'center']}
                mb="5"
                w="100%"
              >
                <Flex
                  fontSize="xx-large"
                  flex="1"
                  fontWeight="900"
                  mb={[5, 5, null, 0]}
                  alignItems="center"
                >
                  Projects
                </Flex>
                <Flex justifySelf="flex-end" alignItems="center" mb={[5, 5, null, 0]}>
                  <BaseSearch size="lg" />
                </Flex>
              </Flex>
              {/* grid */}

              <ProjectsGrid
                projects={data?.projects?.items}
                onEdit={handleOnEdit}
                onOpenSettings={onProjectOpen}
                // showAddFolder={!folder}
                onOpenMove={handleOpenMove}
                onAddProject={onCreateProjectOpen}
              />
            </Box>
          </Box>

          {/* <FolderDrawer
          isOpen={isFolderDrawerOpen && !!selected}
          onClose={handleCloseSettings}
          onSave={handleOnSave}
        /> */}

          <ProjectDrawer
            project={selected as gql_HyProjectFragment}
            isOpen={isProjectDrawerOpen && !!selected}
            onClose={handleCloseSettings}
            onSave={handleOnSave}
          />

          {/* <MoveProjectDrawer
          project={moveTarget as ProjectType}
          isOpen={!!moveTarget}
          onClose={handleCloseMove}
          onSave={handleOnSaveMove}
          onMove={handleOpenMove}
        /> */}
        </chakra.div>
      </ScaleFade>
      <CreateProject isOpen={isCreateProjectOpen} onClose={onCreateProjectClose} />
    </Flex>
  )
}

export default ProjectsExplorer
