import { Box, Switch, Textarea } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import BaseField from '../../old-components/workbench/ComponentEditor/base/BaseField'
import { FieldProps } from '../../core/types/FieldProps'

type Props = FieldProps

const BooleanField: FC<Props> = ({ value = '', onChange, label, path }) => {
  const [fieldValue, setFieldValue] = useState<string>(value as any)

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // setFieldValue(event.target.value)
      if (onChange) {
        onChange(path, event.target.checked)
      }
    },
    [onChange, path]
  )

  // const debouncedValue = useDebounce(fieldValue, 250)

  // useEffect(() => {
  //   if (onChange) {
  //     onChange(path, debouncedValue)
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedValue])

  // useEffect(() => {
  // if (value !== fieldValue) {
  //   setFieldValue(value as string)
  // }
  // }, [value])

  return (
    <BaseField label={label}>
      <Box my="2">
        {/* <Textarea
          name="value"
          onChange={handleOnChange}
          // defaultValue={defaultValue}
          value={fieldValue}
          isReadOnly={false}
          size="lg"
          mb="3"
          minHeight="200px"
          bg="dark.700"
          border="dark.800"
        /> */}
        <Switch
          name="value"
          colorScheme="green"
          isChecked={!!value}
          size="lg"
          mb="3"
          onChange={handleOnChange}
        />
      </Box>
    </BaseField>
  )
}

export default BooleanField
