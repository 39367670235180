import { useDisclosure, ButtonProps, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ID } from '../../core/types/BaseCRUD'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import Surface from '../base/Surface'
import CreateSourceDrawer from './CreateSourceDrawer'
import EditSourceModal from './EditSourceModal'
import SourceSettingsModal from './SourceSettingsModal'

type Props = {
  projectId: ID
} & ButtonProps

const CreateSourceButton: FC<Props> = ({ projectId }) => {
  const { isOpen, onToggle, onClose, ...rest } = useDisclosure()

  return (
    <Surface
      //   bg={bgColor}
      // _hover={{ bgColor: buttonBgHoverColor, shadow: 'lg' }}
      p="3"
      textAlign="center"
      h="100%"
      alignItems="center"
      justifyContent="center"
      rounded="md"
      overflow="hidden"
      shadow="sm"
      as={BaseButton}
      lightBgColor="white"
      lightBgHoverColor="whiteAlpha.600"
      darkBgColor="dark.900"
      darkBgHoverColor="dark.500"
      onClick={onToggle}
      d="flex"
      flexDirection="column"
    >
      <BaseIcon name="HiPlus" collection="hi" fontSize="3xl" mb={2} />
      <Text>New Source</Text>
      <CreateSourceDrawer isOpen={isOpen} onClose={onClose} projectId={projectId} />
    </Surface>
  )
}

export default CreateSourceButton
