import { useCallback } from 'react'
import { Container } from 'typedi'
import { ClassType } from '../types/ClassType'
import { ContentBridge } from './ContentBridge'

export const TOKEN_CONTENT_BRIDGE = 'hydrogen_contentBridge'

type SetContentBridgeFunction<T> = (newValue: ClassType<ContentBridge> | undefined) => void

export const useContentBridge = <T>(): ContentBridge | undefined => {
  return Container.get<ContentBridge>(TOKEN_CONTENT_BRIDGE)
}

export const useSetContentBridge = <T>(): SetContentBridgeFunction<T> => {
  return useCallback((newValue: ClassType<ContentBridge> | undefined) => {
    Container.set({ id: TOKEN_CONTENT_BRIDGE, type: newValue })
  }, [])
}
