import React, { FC } from 'react'
import { chakra, Collapse, Fade, useMultiStyleConfig } from '@chakra-ui/react'

import { SurfaceProps } from '../Surface'

type MasterDetailPanelProps = {
  isActive?: boolean
  unmountOnExit?: boolean
} & SurfaceProps

export const MasterDetailPanel: FC<MasterDetailPanelProps> = (props) => {
  const {
    children,
    isActive,
    width,
    height,
    w = 'fit-parent',
    h = 'fit-parent',
    minW,
    minWidth,
    minH,
    minHeight,
    unmountOnExit = true,
    ...rest
  } = props
  const styles = useMultiStyleConfig('MasterDetail', props)

  return (
    <Fade
      in={isActive}
      style={{
        width: (width || w) as string,
        height: (height || h) as string,
        minWidth: (minWidth || minW) as string,
        minHeight: (minHeight || minH) as string,
      }}
      unmountOnExit={unmountOnExit}
    >
      <chakra.div sx={styles.panel} boxSize="100%" minHeight={minHeight || minH} {...rest}>
        {children}
      </chakra.div>
    </Fade>
  )
}

export default MasterDetailPanel
