import React, { FC, useCallback } from 'react'
import { BoxProps } from '@chakra-ui/react'
import Surface from './Surface'
import BaseIcon, { IconsCollections } from './BaseIcon'

type BoxIconProps = { name?: string; collection?: IconsCollections } & BoxProps

const BoxIcon: FC<BoxIconProps> = (props) => {
  const { name = 'HiDocument', collection = 'hi', ...rest } = props
  return (
    <Surface
      d="flex"
      boxSize="60px"
      minW="60px"
      rounded="md"
      darkBgColor="whiteAlpha.100"
      lightBgColor="darkAlpha.100"
      alignItems="center"
      justifyContent="center"
      mr={3}
      {...rest}
    >
      <BaseIcon name={name} collection={collection} fontSize="3xl" />
    </Surface>
  )
}

export default BoxIcon
