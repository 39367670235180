import React, { FC, useCallback, useState } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalProps,
  Badge,
} from '@chakra-ui/react'
import BaseButton from '../../../components/base/BaseButton'
import BaseIcon from '../../../components/base/BaseIcon'
import Surface from '../../../components/base/Surface'
import {
  MasterDetailMenu,
  MasterDetailMenuItem,
  MasterDetailPanel,
  MasterDetailSubMenuItem,
} from '../../../components/base/MasterDetail'
import {
  ModalMenuItem,
  ModalSubMenuItem,
} from '../../../components/base/MasterDetail/MasterDetailMenu'
import MasterDetailSubMenu from '../../../components/base/MasterDetail/MasterDetailSubMenu'
import { ID } from '../../../core/types/BaseCRUD'
import RedirectsMapForm from '../../../components/projects/RedirectsMapForm'

type ProjectSettingsModalProps = {
  isOpen: boolean
  onClose: () => void
  finalFocusRef?: React.RefObject<any>
  projectId: ID
} & Omit<ModalProps, 'children'>

const ProjectSettingsModal: FC<ProjectSettingsModalProps> = (props) => {
  const { isOpen, onClose, finalFocusRef, projectId, ...rest } = props
  const menu: ModalMenuItem[] = [
    // {
    //   id: 'envoirment',
    //   title: 'Envoirment',
    //   subtitle: 'lorem ipsum dolor sit',
    //   iconComponent: <BaseIcon name="RiServerFill" collection="ri" fontSize="2xl" mr={3} />,
    //   subMenu: [
    //     {
    //       id: 'envoirment-development',
    //       title: 'Development',
    //       iconComponent: <BaseIcon name="BiCode" collection="bi" fontSize="md" mr={3} />,
    //     },
    //     {
    //       id: 'envoirment-stage',
    //       title: 'Stage',
    //       iconComponent: <BaseIcon name="FiBox" collection="fi" fontSize="md" mr={3} />,
    //     },
    //     {
    //       id: 'envoirment-public',
    //       title: 'Public',
    //       iconComponent: <BaseIcon name="HiGlobe" collection="hi" fontSize="md" mr={3} />,
    //     },
    //   ],
    // },
    // {
    //   id: 'settings',
    //   title: 'Settings',
    //   iconComponent: <BaseIcon name="RiSettings4Fill" collection="ri" fontSize="2xl" mr={3} />,
    // },
    // {
    //   id: 'analytics',
    //   title: 'Analytics',
    //   iconComponent: <BaseIcon name="IoAnalytics" collection="io5" fontSize="2xl" mr={3} />,
    //   subMenu: [
    //     {
    //       id: 'analytics-google',
    //       title: 'Google Analytics',
    //       iconComponent: <BaseIcon name="SiGoogleanalytics" collection="si" fontSize="md" mr={3} />,
    //     },
    //   ],
    // },
    {
      id: 'seo-redirects',
      title: 'Pages Redirect',
      subtitle: 'Manage redirects',
      iconComponent: <BaseIcon name="FaUserCog" collection="fa" fontSize="2xl" mr={3} />,
    },
  ]
  const [selectedItem, setSelectedItem] = useState<ModalMenuItem | ModalSubMenuItem>(menu[0])

  const handleMenuClick = useCallback((menuItem: ModalMenuItem) => {
    setSelectedItem(menuItem)
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="full"
      finalFocusRef={finalFocusRef}
      // isCentered
      {...rest}
    >
      <ModalOverlay />
      <Surface
        as={ModalContent}
        overflow="hidden"
        lightBgColor="light.100"
        d="flex"
        flexDir="row"
        my={0}
      >
        <MasterDetailMenu w="20vw" subtitle="Projects settings" title="prova">
          {menu.map((menuItem, index) => (
            <MasterDetailMenuItem
              key={index}
              {...menuItem}
              onClickItem={(menuItem) => handleMenuClick(menuItem)}
            >
              {menuItem.subMenu && (
                <MasterDetailSubMenu pl={5}>
                  {menuItem.subMenu.map((subMenuItem, index) => (
                    <MasterDetailSubMenuItem
                      key={index}
                      {...subMenuItem}
                      onClickItem={(item) => console.log(item)}
                    />
                  ))}
                </MasterDetailSubMenu>
              )}
            </MasterDetailMenuItem>
          ))}
        </MasterDetailMenu>
        <MasterDetailPanel
          as={Surface}
          isActive
          w="80vw"
          position="relative"
          d="flex"
          flexDir="column"
          darkBgColor="dark.800"
          lightBgColor="light.50"
        >
          <ModalHeader w="100%">
            <Badge>ciao</Badge>
          </ModalHeader>
          <ModalBody px={5} flex="1" overflowY="scroll">
            <ModalCloseButton
              as={BaseButton}
              variant="solid"
              size="lg"
              top={3}
              right={3}
              zIndex={9}
            />
            <RedirectsMapForm projectId={projectId} />
          </ModalBody>
          <ModalFooter>
            <BaseButton variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </BaseButton>
            <Button variant="ghost" colorScheme="green" size="lg" lightHue="light">
              Save
            </Button>
          </ModalFooter>
        </MasterDetailPanel>
      </Surface>
    </Modal>
  )
}

export default ProjectSettingsModal
