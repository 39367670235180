import { JSONSchema7Definition } from 'json-schema'

/**
 * This function checks if the given schema matches a single
 * constant value.
 */
export function isConstant(schema: JSONSchema7Definition) {
  if (typeof schema === 'boolean' || !schema) {
    return false
  }

  return (Array.isArray(schema.enum) && schema.enum.length === 1) || schema.hasOwnProperty!('const')
}
