import {
  Button,
  Flex,
  Icon,
  ListProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  SimpleGrid,
  SimpleGridProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ImFolderPlus } from 'react-icons/im'
import React, { FC, useCallback } from 'react'
import {
  gql_EnumDKeySourceKind,
  gql_EnumSourceSchemaMode,
  gql_HySourceFragment,
} from '../../../core/graphql'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'
import SourceCard from './SourceCard'
import CreateSourceCardButton from './CreateSourceCardButton'
import CreateFolderCard from '../CreateFolderCard'
import { ID } from '../../../core/types/BaseCRUD'

type Props = {
  projectId: ID
  sources?: gql_HySourceFragment[]
  onClick?: (source: gql_HySourceFragment) => void
  onNewSourceClick?: (kind: gql_EnumDKeySourceKind, schemaMode?: gql_EnumSourceSchemaMode) => void
  onDeleteComplete?: () => void
} & SimpleGridProps

const SourcesGrid: FC<Props> = (props) => {
  const { sources, onClick, onNewSourceClick, onDeleteComplete, projectId, ...rest } = props
  const {
    isOpen: isCreateFolderOpen,
    onOpen: onCreateFolderOpen,
    onClose: onCreateFolderClose,
  } = useDisclosure()

  const handleOnClick = useCallback(
    (source: gql_HySourceFragment) => {
      if (onClick) {
        onClick(source)
      }
    },
    [onClick]
  )

  const handleCreateSourceClick = useCallback(
    (kind: gql_EnumDKeySourceKind, schemaMode?: gql_EnumSourceSchemaMode) => {
      if (kind === gql_EnumDKeySourceKind.Folder) {
        onCreateFolderOpen()
      } else if (onNewSourceClick) {
        onNewSourceClick(kind, schemaMode)
      }
    },
    [onCreateFolderOpen, onNewSourceClick]
  )

  return (
    <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing={6} {...rest}>
      <CreateSourceCardButton
        projectId={projectId}
        onNewSourceClick={handleCreateSourceClick}
        showCreateFolder
      />
      {isCreateFolderOpen && <CreateFolderCard onClose={onCreateFolderClose} />}
      {sources &&
        sources.map((source: gql_HySourceFragment) => {
          return (
            <SourceCard
              source={source}
              onClick={handleOnClick}
              key={`source-menu-item-${source.id}`}
              onDeleteComplete={onDeleteComplete}
            />
          )
        })}
    </SimpleGrid>
  )
}

export default SourcesGrid
