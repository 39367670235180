import { Input, InputProps } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

type Props = { name: string } & InputProps

const SlugInput = React.forwardRef<HTMLInputElement, Props>(({ name, ...rest }, ref) => {
  const { setValue, register, unregister, watch } = useFormContext()
  const value = watch(name)

  const handleSlugChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const maskedValue = e.target.value
        .replace(/[^-a-zA-Z0-9\s+]+/gi, '')
        .replace(/\s+/gi, '-')
        .toLowerCase()
      setValue(name, maskedValue)
    },
    [name, setValue]
  )

  useEffect(() => {
    register(
      {
        name,
      },
      {
        // required: 'Slug is mandatory',
      }
    )

    if (value) {
      setValue(name, value)
    }

    return () => unregister(name!)
  }, [])

  return (
    <Input
      ref={ref}
      name={name}
      value={value}
      onChange={handleSlugChange}
      placeholder="source slug"
      {...rest}
    />
  )
})

export default SlugInput
