import React, { FC } from 'react'
import { QueryParamProvider } from 'use-query-params'
import { Route } from 'react-router-dom'
import HydrogenRouter from './HydrogenRouter'
import HydrogenCore from './HydrogenCore'
import HydrogenRoot from './HydrogenRoot'
import ChakraUiLayout from './ChakraUiLayout'
import { HydrogenProps } from '../../core/types/HydrogenProps'
import { ClientProvider } from '../../core/UrqlClientProvider'
import { createUrqlClient } from '../../urqlClient'

const Hydrogen: FC<HydrogenProps> = ({
  enableRecoilRoot,
  children,
  routerComponent,
  indexRedirect,
  contentProvider,
  schemaProvider,
  mediaProvider,
  authProvider,
  contentBridge,
  schemaBridge,
  nodeDelegate,
  layout: Layout = ChakraUiLayout,
  theme,
}) => {
  return (
    <ClientProvider makeClient={createUrqlClient}>
      <HydrogenRoot enableRecoilRoot={enableRecoilRoot}>
        <Layout theme={theme}>
          <HydrogenRouter routerComponent={routerComponent}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <HydrogenCore
                indexRedirect={indexRedirect}
                contentProvider={contentProvider}
                schemaProvider={schemaProvider}
                mediaProvider={mediaProvider}
                authProvider={authProvider}
                contentBridge={contentBridge}
                schemaBridge={schemaBridge}
                nodeDelegate={nodeDelegate}
                children={children}
              />
            </QueryParamProvider>
          </HydrogenRouter>
        </Layout>
      </HydrogenRoot>
    </ClientProvider>
  )
}

export default Hydrogen
