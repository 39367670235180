import { chakra, Flex, useRadio } from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import Surface from './Surface'

const RadioCard = (props: any) => {
  const { register } = props
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex as="label" w="100%" h="100%">
      <input {...input} ref={register && register({})} />
      <Surface
        {...checkbox}
        cursor="pointer"
        borderWidth="0px"
        borderRadius="md"
        boxShadow="sm"
        w="100%"
        h="fit-content"
        transition="all 0.2s"
        _checked={{
          bg: 'green.500',
          color: 'white',
          borderColor: 'green.600',
          borderWidth: 0,
          shadow: 'md',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={5}
        d="flex"
        flexDir="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        {props.children}
      </Surface>
    </Flex>
  )
}

export default RadioCard
