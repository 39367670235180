import React, { FC, useCallback, useState } from 'react'
import {
  PopoverBody,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverHeader,
  Input,
  Flex,
} from '@chakra-ui/react'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'
import BaseButton from '../../base/BaseButton'

type Props = {
  show?: boolean
  onCreateFolder?: (folderName: string) => void
}

const CreateFolderButton: FC<Props> = ({ onCreateFolder }) => {
  const [q, setQ] = useState<string>('')

  const handleCreateFolder = useCallback(
    (folderName: string) => () => {
      if (onCreateFolder) {
        onCreateFolder(folderName)
      }
    },
    [onCreateFolder]
  )
  const handleQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQ(event.target.value)
  }, [])

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Surface
          //   bg={bgColor}
          // _hover={{ bgColor: buttonBgHoverColor, shadow: 'lg' }}
          p="3"
          textAlign="center"
          w="100%"
          alignItems="center"
          justifyContent="center"
          rounded="md"
          overflow="hidden"
          shadow="sm"
          mb="3"
          h="50px"
          as={BaseButton}
          onClick={() => {}}
          lightBgColor="white"
          lightBgHoverColor="whiteAlpha.600"
          darkBgColor="dark.900"
          darkBgHoverColor="dark.500"
        >
          <BaseIcon name="IoFolderOpen" collection="io5" />
          <Text ml="3">New Folder</Text>
        </Surface>
      </PopoverTrigger>
      <PopoverContent p={4} as={Surface} boxShadow="lg" darkBgColor="dark.800">
        <PopoverCloseButton />
        <PopoverHeader d="flex" borderBottom="none">
          <BaseIcon name="IoFolderOpen" collection="io5" mr={2} />
          Create new folder
        </PopoverHeader>
        <PopoverBody>
          <Flex>
            <Input
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onChange={handleQueryChange}
              borderRadius="md"
              border="none"
              placeholder="Folder name"
              value={q}
              size="lg"
            />

            <BaseButton darkBgColor="dark.800" ml={3} size="lg">
              Create
            </BaseButton>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default CreateFolderButton
