import { mode } from '@chakra-ui/theme-tools'
import { SurfaceVariants } from '../../components/base/Surface'
import defaultColors from '../foundations/colors'

export const inputStyle = (props: Record<string, any>) => ({
  background: mode('darkAlpha.50', 'lightAlpha.50')(props),
  px: 3,
  _hover: {
    borderColor: 'none',
    background: mode('darkAlpha.50', 'lightAlpha.50')(props),
    boxShadow: `${mode(
      `0px 0px 0px 2px ${defaultColors.darkAlpha[100]}`,
      `0px 0px 0px 2px ${defaultColors.lightAlpha[200]}`
    )(props)} !important`,
  },
  _focus: {
    borderColor: 'none',
    background: mode('darkAlpha.50', 'lightAlpha.50')(props),
    boxShadow: mode(
      `0px 0px 0px 2px ${defaultColors.darkAlpha[100]}`,
      `0px 0px 0px 2px ${defaultColors.lightAlpha[200]}` + ' !important'
    )(props),
  },
})

export const surfaceColorHue = (variant: SurfaceVariants, colorMode: string): number => {
  let colorHue: any
  switch (variant) {
    case 'lighter':
      colorHue = colorMode == 'light' ? 100 : 400
      break
    case 'light':
      colorHue = colorMode == 'light' ? 200 : 500
      break
    case 'heavy':
      colorHue = colorMode == 'light' ? 400 : 700
      break
    case 'heavier':
      colorHue = colorMode == 'light' ? 500 : 900
      break
    default:
      colorHue = colorMode == 'light' ? 100 : 600
      break
  }

  return colorHue as number
}
