import { useCallback } from 'react'
import { Container } from 'typedi'
import { ClassType } from '../types/ClassType'
import { SchemaBridge } from './SchemaBridge'

export const TOKEN_SCHEMA_BRIDGE = 'hydrogen_schemaBridge'

type SetSchemaBridgeFunction = (newValue: ClassType<SchemaBridge> | undefined) => void

export const useSchemaBridge = (): SchemaBridge | undefined => {
  return Container.get<SchemaBridge>(TOKEN_SCHEMA_BRIDGE)
}

export const useSetSchemaBridge = (): SetSchemaBridgeFunction => {
  return useCallback((newValue: ClassType<SchemaBridge> | undefined) => {
    Container.set({ id: TOKEN_SCHEMA_BRIDGE, type: newValue })
  }, [])
}
