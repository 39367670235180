import React, { FC } from 'react'
import { FieldProps } from '../../core/types/FieldProps'

type Props = FieldProps

const SourceSlugSelect: FC<Props> = ({ value, onChange, label, path, schema, rootSchema }) => {
  return <div>ciao esisto</div>
}

export default SourceSlugSelect
