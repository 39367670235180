import { Badge, chakra, Flex, FormControl, FormLabel, HStack, Tooltip } from '@chakra-ui/react'
import React, { FC } from 'react'
import BaseButton from '../../../../components/base/BaseButton'
import BaseIcon from '../../../../components/base/BaseIcon'

type Props = {
  label: string
  description?: string
  mandatory?: boolean
}

const BaseField: FC<Props> = ({ children, description, label, mandatory }) => {
  return (
    <FormControl>
      <FormLabel d="flex" justifyContent="space-between" px={0} mr={0} fontSize="sm">
        {label}
        {description && (
          <Tooltip label={description} aria-label={description} placement="bottom-start">
            <chakra.div d="flex">
              <BaseIcon name="BsInfoCircleFill" collection="bs" fontSize="sm" ml={2} mr={0} />
            </chakra.div>
          </Tooltip>
        )}

        {children && label && (
          <HStack spacing={0}>
            <BaseButton size="xs" variant="ghost">
              <BaseIcon name="MdRefresh" collection="md" fontSize="inherith" />
            </BaseButton>
            <BaseButton size="xs" variant="ghost">
              <BaseIcon name="AiFillDelete" collection="ai" fontSize="inherith" />
            </BaseButton>
          </HStack>
        )}
      </FormLabel>
      {children}
      {mandatory && (
        <Badge
          variant="subtle"
          fontSize="10px"
          position="absolute"
          colorScheme="red"
          right={0}
          top={1}
        >
          mandatory
        </Badge>
      )}
    </FormControl>
  )
}

export default BaseField
