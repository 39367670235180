import { useState } from 'react'

export const useFocus = (): {
  focused: boolean
  bind: {
    onFocus: () => void
    onBlur: () => void
  }
} => {
  const [focused, set] = useState(false)
  return {
    focused,
    bind: {
      onFocus: (): void => set(true),
      onBlur: (): void => set(false),
    },
  }
}
