import { useCallback, useEffect, useMemo } from 'react'
import deepEqual from 'deep-equal'
import { useSelectedNodeState } from './useSelectedNodeState'
import { SelectedNode } from '../types/SelectedNode'
import { NodeProps } from '../types/NodeProps'

export type UseNodeOptions = NodeProps

export type UseNodeResult = {
  selectedNode: SelectedNode
  setAsSelectedNode: () => void
  toggleSelected: () => void
  isSelected: boolean
}

export const useNode = ({ path, ...node }: UseNodeOptions): UseNodeResult => {
  const [selectedNode, setSelectedNode] = useSelectedNodeState()

  const isSelected = useMemo(() => selectedNode.path === path, [selectedNode.path, path])

  const setAsSelectedNode = useCallback(() => {
    setSelectedNode({
      path,
      sourceId: node.sourceId,
      schemaPath: node.schemaPath,
      parentPath: node.parentPath,
      parentSchemaPath: node.parentSchemaPath,
      selectedSchema: node.schema,
    })
  }, [node, path, setSelectedNode])

  useEffect(() => {
    if (selectedNode.path === path && !deepEqual(selectedNode.selectedSchema, node.schema)) {
      setSelectedNode({
        ...selectedNode,
        selectedSchema: node.schema,
      })
    }
  }, [node.schema, path, selectedNode, setSelectedNode])

  const toggleSelected = useCallback(() => {
    if (isSelected) {
      setSelectedNode({})
    } else {
      setAsSelectedNode()
    }
  }, [isSelected, setSelectedNode, setAsSelectedNode])

  return { setAsSelectedNode, selectedNode, isSelected, toggleSelected }
}
