import { JSONSchema7Type } from 'json-schema'
import { ContentBridge } from './ContentBridge'

export class JsonContentBridge implements ContentBridge {
  formatForRead(contentValue: string): JSONSchema7Type {
    let result = null
    try {
      result = JSON.parse(contentValue)
    } catch (error) {
      //
    }
    return result
  }

  parseForWrite(toWriteValue: JSONSchema7Type): string {
    if (typeof toWriteValue === 'string') {
      return toWriteValue
    }

    let result = ''
    try {
      result = JSON.stringify(toWriteValue, null, 2)
    } catch (error) {
      //
    }
    return result
  }
}
