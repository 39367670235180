import { atom } from 'recoil'
import { HydrogenState } from '../types/HydrogenState'

export const hydrogenState = atom<HydrogenState>({
  key: 'hydrogenState',
  default: {
    initialized: false,
    hasProjectsExplorer: false,
    hasWorkbench: false,
    hasMediaProvider: false,
    hasAuthProvider: false,
  },
})
