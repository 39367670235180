import React, { FC } from 'react'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Text } from '@chakra-ui/react'
import BaseIcon from '../base/BaseIcon'

type Props = {
  content?: any
  title?: string
  typeLabel?: string
  length: number | string
}

const NodeViewListInfo: FC<Props> = ({ title, content, typeLabel, length }) => {
  return (
    <Flex flexDir="column" flex="1" ml={5}>
      <Flex alignItems="flex-start" h="100%" flexDir="column">
        <Breadcrumb fontSize="sm">
          <BreadcrumbItem>
            <Text>Root</Text>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Text>
              {typeLabel} Node {title}
            </Text>
          </BreadcrumbItem>
        </Breadcrumb>

        <Text fontSize="xl" fontWeight="bold">
          {length} childs
        </Text>
      </Flex>
    </Flex>
  )
}

export default NodeViewListInfo
