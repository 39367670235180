import React, { createRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  BoxProps,
  Box,
  Input,
  chakra,
  useMultiStyleConfig,
  Kbd,
  Flex,
  List,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Button,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  ListItem,
  Text,
  Portal,
  Divider,
  Badge,
  useOutsideClick,
} from '@chakra-ui/react'
import { useFocus } from '../../core/utils/useFocus'
import BaseIcon from '../base/BaseIcon'
import { useDebounce } from '../../core/utils/useDebounce'
import GlobalSearchResult, { SearchResult } from './GlobalSearchResult'
import GradientBox from '../base/GradientBox'
import Empty from '../base/Empty'

type GlobalSearchProps = {
  size?: 'sm' | 'md' | 'lg'
  expandible?: boolean
  onClose?: () => void
  onResultSelected?: (airport: SearchResult) => void
  inputPlaceholder?: string
} & BoxProps

const GlobalSearch = React.forwardRef<HTMLDivElement, GlobalSearchProps>((props, ref) => {
  const {
    expandible,
    onClose,
    onResultSelected,
    inputPlaceholder = 'Search something',
    ...rest
  } = props
  const { focused, bind } = useFocus()
  const styles = useMultiStyleConfig('GlobalSearch', { ...props, focused })
  const inputRef = useRef<any>()
  const resultsRef = createRef<HTMLElement>()
  const [q, setQ] = useState<string>('')
  const debouncedQ = useDebounce(q, 250)

  const data = useMemo<SearchResult[]>(
    () => [
      {
        id: 'page',
        type: 'page',
        name: "I'm a page",
      },
      {
        id: 'image',
        type: 'Image',
        name: 'Immagine figa',
        image:
          'https://media-exp1.licdn.com/dms/image/C4E03AQFTlQlS5eqwjQ/profile-displayphoto-shrink_400_400/0/1517402263336?e=1626307200&v=beta&t=Up4SWrOkIbOrnQM-Tl17IEXt2DVisavlXnG29n7ClZ8',
      },
      {
        id: 'source',
        type: 'Source',
        name: 'Source with icon',
        iconName: 'MdPermMedia',
        iconCollection: 'md',
      },
      {
        id: 'source',
        type: 'Source',
        name: 'Source with poo',
        iconName: 'FaPooStorm',
        iconCollection: 'fa',
      },
    ],
    []
  )

  const isOpen = useMemo(() => {
    if (q && q.length > 0) return true
    return false
  }, [q])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
    setQ('')
  }, [onClose])

  // useOutsideClick({
  //   ref: inputRef,
  //   handler: (e: Event) => {
  //     console.log(e, resultsRef)
  //     isOpen && handleClose()
  //   },
  // })

  const handleQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setQ(event.target.value)
  }, [])

  const handleResultSelected = useCallback(
    (result: SearchResult) => {
      if (onResultSelected) {
        onResultSelected(result)
        handleClose()
      }
    },
    [handleClose, onResultSelected]
  )

  const handleKeyboardEvents = useCallback(
    (event) => {
      // console.log(event)
      if (event.code == 'KeyF' && event.altKey) {
        if (inputRef.current != document.activeElement) event.preventDefault()
        !focused && inputRef && inputRef.current && inputRef.current.focus()
      } else if (event.key == 'Escape' && inputRef.current == document.activeElement) {
        inputRef && inputRef.current && inputRef.current.blur()
        inputRef.current.value = ''
      }
    },
    [focused, null]
  )

  useEffect(() => {
    // window.addEventListener('keypress', handleKeyboardEvents)
    // window.addEventListener('keyup', handleKeyboardEvents)
    return () => {
      //   window.removeEventListener('keypress', handleKeyboardEvents)
      //   window.removeEventListener('keyup', handleKeyboardEvents)
    }
  }, [])

  return (
    <chakra.div sx={styles.wrapper} {...rest} ref={ref}>
      <Popover isOpen={isOpen} autoFocus={false}>
        <PopoverTrigger>
          <Flex sx={styles.wrapper}>
            <Input
              sx={styles.input}
              placeholder={inputPlaceholder}
              onFocus={bind.onFocus}
              onBlur={bind.onBlur}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              borderRadius="md"
              border="none"
              ref={inputRef}
              value={q}
              onChange={handleQueryChange}
            />
            <Flex sx={styles.command}>
              <Kbd borderRadius="sm" py="3px" mr="1">
                <BaseIcon name="BsOption" collection="bs" fontSize="xs" />
              </Kbd>{' '}
              +{' '}
              <Kbd borderRadius="sm" py="3px" ml="1">
                F
              </Kbd>
            </Flex>
          </Flex>
        </PopoverTrigger>
        <Portal>
          <PopoverContent sx={styles.results} minW="500px" ref={resultsRef}>
            <PopoverHeader
              position="sticky"
              top="0"
              d="flex"
              flexDirection="column"
              borderBottom="none"
            >
              <GradientBox position="absolute" height="100px" />
              <Text pos="relative">Search results for "{q}"</Text>
              <Text fontSize="xs" textTransform="capitalize">
                {data.length || 0} results
              </Text>
              <PopoverCloseButton onClick={handleClose} />
            </PopoverHeader>

            <PopoverBody>
              <List sx={styles.list}>
                {data.map((result, index) => {
                  return (
                    <chakra.div key={`search-result-${index}`}>
                      <GlobalSearchResult
                        key={`search-result-${result.id}`}
                        searchResult={result}
                        onClick={handleResultSelected}
                      />
                      {data.length > index + 1 && <Divider my={1} />}
                    </chakra.div>
                  )
                })}
              </List>
              {(!data || (data && data.length == 0)) && <Empty title="No results" h="240px" />}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </chakra.div>
  )
})

export default GlobalSearch
