export const secureJsonStringify = (value: any, defaultString = undefined): string | undefined => {
  if (!value) {
    return defaultString
  }

  try {
    return JSON.stringify(value)
  } catch (e) {
    return defaultString
  }
}
