import { ButtonGroup, chakra, Textarea } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { createEditor, Editor, Node, Element as SlateElement, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, useSlate, withReact } from 'slate-react'
import isHotkey from 'is-hotkey'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import { useDebounce } from '../../core/utils/useDebounce'
import { FieldProps } from '../../core/types/FieldProps'
import { secureJsonParse } from '../../core/utils/secureJsonParse'
import { secureJsonStringify } from '../../core/utils/secureJsonStringify'
import Loading from '../base/Loading'

const initialValue: any[] = [
  {
    type: 'paragraph',
    children: [{ text: 'Write here' }],
  },
]

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

const toggleBlock = (editor: Editor, format: any) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n as any) && SlateElement.isElement(n as any) && (n as any).type
      ),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: Editor, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: Editor, format: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isMarkActive = (editor: Editor, format: any) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon, iconCollection }: any) => {
  const editor = useSlate()
  return (
    <BaseButton
      isActive={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <BaseIcon name={icon} collection={iconCollection} />
    </BaseButton>
  )
}

const MarkButton = ({ format, icon, iconCollection }: any) => {
  const editor = useSlate()
  return (
    <BaseButton
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <BaseIcon name={icon} collection={iconCollection} />
    </BaseButton>
  )
}

type Props = FieldProps

const RichTextField: FC<Props> = ({ value, onChange, path }) => {
  const [fieldValue, setFieldValue] = useState<Node[]>(secureJsonParse(value as string))
  const renderElement = useCallback((props) => <Element as={Textarea} {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const debouncedValue = useDebounce(fieldValue, 500)

  useEffect(() => {
    if (onChange) {
      const stringifiedDebouncedValue = secureJsonStringify(debouncedValue)
      if (stringifiedDebouncedValue && value !== stringifiedDebouncedValue) {
        console.log('chiamato RichTextField', value, debouncedValue)
        onChange(path, stringifiedDebouncedValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    const stringifiedDebouncedValue = secureJsonStringify(debouncedValue)
    if (value !== stringifiedDebouncedValue) {
      if (!value || value === '') {
        setFieldValue(initialValue)
      } else {
        const result = secureJsonParse(value as string)
        // console.log('useEffect zio mailae', value, fieldValue)
        setFieldValue(result || initialValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  if (!Array.isArray(fieldValue) || !fieldValue || typeof fieldValue === 'string') {
    return <Loading />
  }

  return (
    <Slate editor={editor} value={fieldValue} onChange={(value) => setFieldValue(value)}>
      <chakra.div pos="relative" mr={-6} ml={-3} overflowX="scroll" pr={6} pl={3}>
        <ButtonGroup overflow="scroll" overflowX="scroll">
          <MarkButton format="bold" icon="AiOutlineBold" iconCollection="ai" />
          <MarkButton format="italic" icon="AiOutlineItalic" iconCollection="ai" />
          <MarkButton format="underline" icon="AiOutlineUnderline" iconCollection="ai" />
          <MarkButton format="code" icon="DiCode" iconCollection="di" />
          <BlockButton format="heading-one" icon="looks_one" iconCollection="ai" />
          <BlockButton format="heading-two" icon="looks_two" iconCollection="ai" />
          <BlockButton format="block-quote" icon="format_quote" iconCollection="ai" />
          <BlockButton format="numbered-list" icon="format_list_numbered" iconCollection="ai" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" iconCollection="ai" />
        </ButtonGroup>
      </chakra.div>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Enter rich text…"
        style={{
          backgroundColor: 'rgba(245, 245, 245, 0.05)',
          padding: '8px 10px',
          borderRadius: '10px',
          margin: '8px 0px',
          minHeight: '200px',
        }}
        spellCheck
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault()
              const mark = (HOTKEYS as any)[hotkey]
              toggleMark(editor, mark)
            }
          }
        }}
      />
    </Slate>
  )
}

export default RichTextField
