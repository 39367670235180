import React, { FC, useCallback } from 'react'
import {
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { StringParam, useQueryParam } from 'use-query-params'
import BaseIcon from '../base/BaseIcon'
import { ID } from '../../core/types/BaseCRUD'
import SourceEnvironmentRadioGroup from '../sources/SourceForm/SourceEnvironmentRadioGroup'
import { useGetEnvironmentByIdQuery } from '../../core/graphql'
import { QUERY_PARAM_ENV_KEY } from '../sources/SourceWorkbench'

type Props = {}

const ProjectEnvironmentPopover: FC<Props> = (props) => {
  const styles = useMultiStyleConfig('ProjectEnvironmentPopover', props)
  const [environmentId, setEnvironmentId] = useQueryParam(QUERY_PARAM_ENV_KEY, StringParam)

  const [environmentData, refetchEnvironment] = useGetEnvironmentByIdQuery({
    variables: { id: environmentId },
    pause: !environmentId,
  })

  const handleEnvironmentChange = useCallback(
    (value: string) => {
      setEnvironmentId(value)
    },
    [setEnvironmentId]
  )

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Flex cursor="pointer" justifyContent="center" alignItems="flex-end" flexDir="column">
          <Text fontSize="xs" opacity="0.6">
            Environment
          </Text>
          <Flex
            justifyContent="center"
            fontSize="sm"
            color="green.500"
            w="100%"
            maxW="100px"
            overflow="hidden"
          >
            <Text textOverflow="ellipsis" w="100%" whiteSpace="nowrap" overflow="hidden">
              {environmentData?.data?.environmentById?.name}
            </Text>
            {environmentData?.data?.environmentById?.name == 'production' && (
              <BaseIcon name="BiWorld" collection="bi" fontSize="md" ml={1} />
            )}
            {/* <BaseIcon name="HiChevronDown" collection="hi" fontSize="md" ml={0} /> */}
          </Flex>
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxW="350px" overflow="scroll">
          <PopoverBody>
            <SourceEnvironmentRadioGroup
              defaultValue={environmentId!}
              onChange={handleEnvironmentChange}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default ProjectEnvironmentPopover
