import React, { FC } from 'react'
import {
  Box,
  useRadio,
  UseRadioProps,
  Text,
  Heading,
  Flex,
  chakra,
  useColorMode,
} from '@chakra-ui/react'
// import { mode } from '@chakra-ui/theme-tools'
import BaseIcon, { IconsCollections } from '../../base/BaseIcon'
import Surface from '../../base/Surface'
// import fonts from '../../../theme/foundations/fonts'

export type SourceSchemaModeRadioProps = UseRadioProps & {
  title: string
  iconName?: string
  iconCollection?: IconsCollections
  fontSize?: string
  iconFontSize?: string
  alignItems?: string
}

const SourceSchemaModeRadio: FC<SourceSchemaModeRadioProps> = ({
  title,
  iconName,
  iconCollection,
  ...props
}) => {
  const { getInputProps, getCheckboxProps, state } = useRadio(props)
  // const { colorMode } = useColorMode()
  const input = getInputProps()
  const checkbox = getCheckboxProps()
  const { fontSize = 'lg', iconFontSize = '4xl', alignItems = 'flex-start' } = props

  return (
    <Flex as="label" w="100%" h="100%">
      <input {...input} />
      <Surface
        {...checkbox}
        cursor={input['aria-disabled'] ? 'not-allowed' : 'pointer'}
        borderWidth="0px"
        borderRadius="md"
        // boxShadow="sm"
        boxSize="100%"
        transition="all 0.2s"
        _checked={{
          bg: 'green.500',
          color: 'white',
          borderColor: 'green.600',
          borderWidth: 0,
          shadow: 'md',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        opacity={input['aria-disabled'] ? 0.5 : undefined}
        px={5}
        py={5}
        d="flex"
        flexDir="column"
        alignItems={alignItems}
        justifyContent="center"
      >
        {iconName && iconCollection && (
          <BaseIcon name={iconName} collection={iconCollection} fontSize={iconFontSize} mb={3} />
        )}
        <Text fontWeight="bold" fontSize={fontSize} colorScheme={state.isChecked ? '' : 'green'}>
          {title}
        </Text>
      </Surface>
    </Flex>
  )
}

export default SourceSchemaModeRadio
