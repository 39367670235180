import { JSONSchema7Definition } from 'json-schema'
import jsonpointer from 'jsonpointer'

export const getPartialSchema = (
  schema: JSONSchema7Definition | undefined,
  pointer: string | undefined
): JSONSchema7Definition => {
  if (typeof schema === 'boolean') {
    return schema
  }

  if (typeof schema === 'undefined') {
    return true
  }

  const finalPointer = !pointer || pointer === '/' ? '' : pointer
  return jsonpointer.get(schema, finalPointer)
}
