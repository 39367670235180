const theme = {
  base: 'vs',
  inherit: true,
  rules: [
    { foreground: '484c69' }, // font color
    {
      background: '1B1F30',
      token: '',
    },
    {
      foreground: '969896',
      token: 'comment',
    },
    {
      foreground: 'ced1cf',
      token: 'keyword.operator.class',
    },
    {
      foreground: 'ced1cf',
      token: 'constant.other',
    },
    {
      foreground: 'ced1cf',
      token: 'source.php.embedded.line',
    },
    {
      foreground: 'cc6666',
      token: 'variable',
    },
    {
      foreground: 'cc6666',
      token: 'support.other.variable',
    },
    {
      foreground: 'cc6666',
      token: 'string.other.link',
    },
    {
      foreground: 'cc6666',
      token: 'string.regexp',
    },
    {
      foreground: 'cc6666',
      token: 'entity.name.tag',
    },
    {
      foreground: 'cc6666',
      token: 'entity.other.attribute-name',
    },
    {
      foreground: 'cc6666',
      token: 'meta.tag',
    },
    {
      foreground: 'cc6666',
      token: 'declaration.tag',
    },
    {
      foreground: 'cc6666',
      token: 'markup.deleted.git_gutter',
    },
    {
      foreground: 'de935f',
      token: 'constant.numeric',
    },
    {
      foreground: 'de935f',
      token: 'constant.language',
    },
    {
      foreground: 'de935f',
      token: 'support.constant',
    },
    {
      foreground: 'de935f',
      token: 'constant.character',
    },
    {
      foreground: 'de935f',
      token: 'variable.parameter',
    },
    {
      foreground: 'de935f',
      token: 'punctuation.section.embedded',
    },
    {
      foreground: 'de935f',
      token: 'keyword.other.unit',
    },
    {
      foreground: 'f0c674',
      token: 'entity.name.class',
    },
    {
      foreground: 'f0c674',
      token: 'entity.name.type.class',
    },
    {
      foreground: 'f0c674',
      token: 'support.type',
    },
    {
      foreground: 'f0c674',
      token: 'support.class',
    },
    {
      foreground: 'b5bd68',
      token: 'string',
    },
    {
      foreground: 'b5bd68',
      token: 'constant.other.symbol',
    },
    {
      foreground: 'b5bd68',
      token: 'entity.other.inherited-class',
    },
    {
      foreground: 'b5bd68',
      token: 'markup.heading',
    },
    {
      foreground: 'b5bd68',
      token: 'markup.inserted.git_gutter',
    },
    {
      foreground: '8abeb7',
      token: 'keyword.operator',
    },
    {
      foreground: '8abeb7',
      token: 'constant.other.color',
    },
    {
      foreground: '81a2be',
      token: 'entity.name.function',
    },
    {
      foreground: '81a2be',
      token: 'meta.function-call',
    },
    {
      foreground: '81a2be',
      token: 'support.function',
    },
    {
      foreground: '81a2be',
      token: 'keyword.other.special-method',
    },
    {
      foreground: '81a2be',
      token: 'meta.block-level',
    },
    {
      foreground: '81a2be',
      token: 'markup.changed.git_gutter',
    },
    {
      foreground: 'b294bb',
      token: 'keyword',
    },
    {
      foreground: 'b294bb',
      token: 'storage',
    },
    {
      foreground: 'b294bb',
      token: 'storage.type',
    },
    {
      foreground: 'b294bb',
      token: 'entity.name.tag.css',
    },
    {
      foreground: 'ced2cf',
      background: 'df5f5f',
      token: 'invalid',
    },
    {
      foreground: 'ced2cf',
      background: '82a3bf',
      token: 'meta.separator',
    },
    {
      foreground: 'ced2cf',
      background: 'b798bf',
      token: 'invalid.deprecated',
    },
    {
      foreground: 'ffffff',
      token: 'markup.inserted.diff',
    },
    {
      foreground: 'ffffff',
      token: 'markup.deleted.diff',
    },
    {
      foreground: 'ffffff',
      token: 'meta.diff.header.to-file',
    },
    {
      foreground: 'ffffff',
      token: 'meta.diff.header.from-file',
    },
    {
      foreground: '718c00',
      token: 'markup.inserted.diff',
    },
    {
      foreground: '718c00',
      token: 'meta.diff.header.to-file',
    },
    {
      foreground: 'c82829',
      token: 'markup.deleted.diff',
    },
    {
      foreground: 'c82829',
      token: 'meta.diff.header.from-file',
    },
    {
      foreground: 'ffffff',
      background: '4271ae',
      token: 'meta.diff.header.from-file',
    },
    {
      foreground: 'ffffff',
      background: '4271ae',
      token: 'meta.diff.header.to-file',
    },
    {
      foreground: '3e999f',
      fontStyle: 'italic',
      token: 'meta.diff.range',
    },
  ],
  colors: {
    // editor
    'editor.background': '#e9eaee',
    'editor.selectionBackground': '#c7c7d0',
    'editor.selectionHighlightBackground': '#000000',
    'editor.lineHighlightBackground': '#c7c7d0',
    'editor.hoverHighlightBackground': '#ff000044',
    'editorCursor.foreground': '#777ba0',
    'editorGroup.border': '#777ba0',
    'peekView.border': '#ffffff',
    'editorLineNumber.foreground': '#c7c7d0',

    //
    'editor.foreground': '#F8F8F8',
    'editorWhitespace.foreground': '#c10000',
    'editorLineNumber.activeForeground': '#ffbbbb88',
    'editorWidget.background': '#300000',
    'editorHoverWidget.background': '#300000',
    'editorSuggestWidget.background': '#300000',
    'editorSuggestWidget.border': '#220000',

    'editorLink.activeForeground': '#FFD0AA',
    'peekViewTitle.background': '#550000',
    'peekViewResult.background': '#400000',
    'peekViewEditor.background': '#300000',
  },
}

export default theme
