import React, {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  chakra,
  useColorMode,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { FC, forwardRef, RefAttributes } from 'react'
import Surface from './Surface'

export type BaseButtonProps = {
  darkHue?: 'lighter' | 'light' | 'base' | 'heavy' | 'heavier'
  lightHue?: 'lighter' | 'light' | 'base' | 'heavy' | 'heavier'
  darkBgColor?: string
  lightBgColor?: string
  darkBgHoverColor?: string
  lightBgHoverColor?: string
  darkTextColor?: string
  lightTextColor?: string
} & ButtonProps

const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>((props, ref) => {
  const {
    darkHue,
    lightHue,
    darkBgColor,
    lightBgColor,
    darkBgHoverColor,
    lightBgHoverColor,
    darkTextColor,
    lightTextColor,
    ...rest
  } = props
  const styles = useMultiStyleConfig('BaseButton', props)

  return <Button sx={styles.wrapper} {...rest} ref={ref} />
})

export default BaseButton
