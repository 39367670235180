import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNode } from '../../../core/workbench/useNode'
import { NodeProps } from '../../../core/types/NodeProps'
import NodeView from '../NodeView'
import SchemaNode from '../SchemaNode'
import NodeViewListInfo from '../NodeViewListInfo'
import ObjectNodeOptions from './ObjectNodeOptions'
import NodeViewObjectInfo from '../NodeViewObjectInfo'

type Props = NodeProps

const ObjectNode: FC<Props> = (props) => {
  const { value, schema, sourceId, path, schemaNodes, typeName, schemaPath, onChange } = props
  const { isSelected, selectedNode, toggleSelected } = useNode(props)
  const [expanded, setExpanded] = useState<boolean>(
    !!(
      !path ||
      path === '/' ||
      (selectedNode.path?.startsWith(path) && selectedNode.path.length > path.length)
    )
  )

  const objectSize = useMemo(() => (value ? Object.keys(value).length : 0), [value])

  const handleNodeClick = useCallback<any>(
    (path: string) => {
      // setExpanded(!isSelected)
      toggleSelected()
    },
    [toggleSelected]
  )

  const onToggleExpanded = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <NodeView
      onValueChange={props.onChange!}
      // source={source}
      value={value}
      path={path}
      valueTypeName={typeName}
      isSelected={isSelected}
      onClick={handleNodeClick}
      typeLabel="Object"
      icon="FaProjectDiagram"
      infoComponent={
        <NodeViewObjectInfo
          length={objectSize}
          componentName={
            value && (value as any).component && typeof (value as any).component === 'string'
              ? (value as any).component
              : undefined
          }
        />
      }
      optionsComponent={<ObjectNodeOptions expanded={expanded} toggleExpand={onToggleExpanded} />}
      p={4}
    >
      {expanded &&
        value &&
        Object.keys(value).map((property, index) => {
          return (
            <SchemaNode
              // source={source}
              key={`node-${path.replace('/', '-')}-${property}`}
              path={path === '/' ? path + property : `${path}/${property}`}
              parentPath={path}
              parentSchemaPath={schemaPath}
              schemaPath={
                schema &&
                typeof schema !== 'boolean' &&
                schema.properties &&
                schema.properties[property]
                  ? schemaPath === '/'
                    ? `${schemaPath}properties/${property}`
                    : `${schemaPath}/properties/${property}`
                  : schemaPath
              }
              value={(value as any)[property]}
              sourceId={sourceId}
              schema={
                schema &&
                typeof schema !== 'boolean' &&
                schema.properties &&
                schema.properties[property]
                  ? schema.properties[property]
                  : undefined
              }
              rootSchema={props.rootSchema}
              schemaNodes={schemaNodes}
              onChange={onChange}
            />
          )
        })}
    </NodeView>
  )
}

export default ObjectNode
