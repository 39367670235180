import { Button } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import NodeViewOptions from '../NodeViewOptions'

type Props = {
  expanded?: boolean
  toggleExpand: () => void
}

const ObjectNodeOptions: FC<Props> = ({ expanded, toggleExpand, ...props }) => {
  const handleToggleExpanded = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
      if (toggleExpand) {
        toggleExpand()
      }
    },
    [toggleExpand]
  )

  return (
    <NodeViewOptions alignSelf="center" {...props} justifySelf="center">
      <BaseButton
        onClick={handleToggleExpanded}
        ml={3}
        px={4}
        h="40px"
        darkHue="heavier"
        lightHue="lighter"
      >
        <BaseIcon name={expanded ? 'FaChevronUp' : 'FaChevronDown'} />
      </BaseButton>
    </NodeViewOptions>
  )
}

export default ObjectNodeOptions
