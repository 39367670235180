import React, { cloneElement, FC, ReactElement } from 'react'
import {
  Flex,
  Text,
  Heading,
  Accordion,
  useMultiStyleConfig,
  Box,
  BoxProps,
  Tag,
} from '@chakra-ui/react'

export type ModalMenuItem = {
  id: string
  title: string
  subtitle?: string
  iconComponent?: ReactElement
  isActive?: boolean
  isDisabled?: boolean
  subMenu?: {
    id: string
    title: string
    subtitle?: string
    iconComponent?: ReactElement
  }[]
}

export type ModalSubMenuItem = {} & Omit<ModalMenuItem, 'submenu'>

type MasterDetailMenuProps = {
  title: string
  subtitle: string
  divider?: ReactElement
  titleIconComponent?: ReactElement
  tagTitle?: string
  tagColorScheme?: string
  onChange?: (expandedIndex: number | number[]) => void
  index?: number | number[]
  defaultIndex?: number | number[]
} & Omit<BoxProps, 'onChange'>

export const MasterDetailMenu: FC<MasterDetailMenuProps> = (props) => {
  const {
    children,
    title,
    subtitle,
    titleIconComponent,
    defaultIndex = 0,
    tagTitle,
    tagColorScheme,
    onChange,
    index,
    ...rest
  } = props
  const styles = useMultiStyleConfig('MasterDetail', props)

  return (
    <Flex sx={styles.menu} flexDir="column" position="relative" w="35%" p={5} {...rest}>
      <Box boxShadow="none" mb={3} borderRadius="md">
        {tagTitle && (
          <Tag fontSize="sm" opacity="0.6" colorScheme={tagColorScheme}>
            {tagTitle}
          </Tag>
        )}
        <Text fontSize="sm" opacity="0.6">
          {subtitle}
        </Text>
        <Heading fontSize="2xl" d="flex">
          {titleIconComponent}
          {title}
        </Heading>
      </Box>
      {children && (
        <Accordion
          defaultIndex={defaultIndex}
          index={index}
          onChange={onChange}
          allowToggle={false}
          variant="boxed"
          py={5}
        >
          {children}
        </Accordion>
      )}
    </Flex>
  )
}

export default MasterDetailMenu
