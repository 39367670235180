import { Box, Textarea, Select, Flex } from '@chakra-ui/react'
import { JSONSchema7 } from 'json-schema'
import React, { createElement, FC, useCallback, useEffect, useMemo, useState } from 'react'
import MonacoEditor from 'react-monaco-editor'
import { editor } from 'monaco-editor'
import { isSelect } from '../../core/utils/isSelect'
import { optionsList } from '../../core/utils/optionsList'
import { useDebounce } from '../../core/utils/useDebounce'
import MediaField from './MediaField'
import BaseField from '../../old-components/workbench/ComponentEditor/base/BaseField'
import { FieldProps } from '../../core/types/FieldProps'
import Surface from '../base/Surface'
import SourceSlugSelect from './SourceSlugSelect'
import RichTextField from './RichTextField'

type Props = FieldProps & {
  customFormatComponents: {
    [component: string]: React.ReactElement
  }
}

const StringField: FC<Props> = ({
  onChange,
  customFormatComponents = {
    'helium-ms-media': MediaField,
    'hydrogen-source-slug-select': SourceSlugSelect,
    'hydrogen-rich-text': RichTextField,
  },
  value = '',
  path,
  schema,
  label,
  rootSchema,

  ...props
}) => {
  const [fieldValue, setFieldValue] = useState<string>((value as any) || '')

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
      setFieldValue(event.target.value)
      // if (onChange) {
      //   onChange(path, event.target.value)
      // }
      // const target = event.target as HTMLTextAreaElement
      // target.style.height = '30px'
      // target.style.height = `${target.scrollHeight}px`
    },
    []
  )

  const handleOnHtmlChamge = useCallback((value: string) => {
    setFieldValue(value)
    // if (onChange) {
    //   onChange(path, event.target.value)
    // }
    // const target = event.target as HTMLTextAreaElement
    // target.style.height = '30px'
    // target.style.height = `${target.scrollHeight}px`
  }, [])

  const debouncedValue = useDebounce(fieldValue, 250)

  const monacoOptions: editor.IStandaloneEditorConstructionOptions = {
    minimap: { enabled: false },
    automaticLayout: true,
    scrollbar: {
      useShadows: false,
    },
  }

  const enumOptions = useMemo(() => {
    if (isSelect(schema || true, rootSchema)) {
      return optionsList(schema || true)
    }
  }, [rootSchema, schema])

  useEffect(() => {
    if (onChange && debouncedValue !== value) {
      onChange(path, debouncedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  useEffect(() => {
    if (value !== fieldValue) {
      if (value === null || (value === undefined && fieldValue === '')) {
        setFieldValue('')
      } else {
        // console.log('useEffect zio mailae', value, fieldValue)
        setFieldValue(value as string)
      }
    }
  }, [value])

  return (
    <BaseField label={label}>
      <Box my="2">
        {(schema as JSONSchema7).format &&
        customFormatComponents &&
        (customFormatComponents as any)[(schema as JSONSchema7).format as any] ? (
          createElement((customFormatComponents as any)[(schema as any).format] as any, {
            ...props,
            schema,
            rootSchema,
            value,
            sourceId: props.sourceId,
            path,
            schemaPath: props.schemaPath,
            parentPath: props.parentPath,
            parentSchemaPath: props.parentSchemaPath,
            typeName: props.typeName,
            label,
            onChange,
          })
        ) : enumOptions ? (
          <Select onChange={handleOnChange} value={fieldValue} mb="3">
            <option value="">{}</option>
            {enumOptions.map((item, index) => {
              return (
                <option key={`enum-option-${item.value}`} value={item.value}>
                  {item.label}
                </option>
              )
            })}
          </Select>
        ) : (schema as any).format && (schema as any).format === 'text/html' ? (
          <Surface
            d="flex"
            flexDir="column"
            overflow="scroll"
            w="full"
            h="400px"
            borderRadius="md"
            bgColor="white"
          >
            <MonacoEditor
              width="100%"
              height="100%"
              language="html"
              value={fieldValue}
              options={monacoOptions}
              onChange={handleOnHtmlChamge}
            />
          </Surface>
        ) : (
          <Textarea
            name="value"
            onChange={handleOnChange}
            // defaultValue={defaultValue}
            value={fieldValue}
            isReadOnly={false}
            mb="3"
            minHeight="60px"
          />
        )}
      </Box>
    </BaseField>
  )
}

export default StringField
