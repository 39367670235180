import {
  JSONSchema7Type,
  JSONSchema7Definition,
  JSONSchema7TypeName,
  JSONSchema7,
} from 'json-schema'
import jsonpointer from 'jsonpointer'
import { JsonPointer } from 'json-ptr'
import copy from 'fast-copy'
import merge from 'deepmerge'
import deepEqual from 'deep-equal'
import { AddableNode, InputNode, NodeDelegate } from './NodeDelegate'
import { getSchemaTypeName } from '../utils/getSchemaTypeName'
import { isObject } from '../utils/isObject'
import { defaultValues } from '../utils/defaultValues'
import { computeRequired } from '../utils/computeRequired'

const adjustPath = (path: string | undefined): string =>
  path === '/' || typeof path === 'undefined' ? '' : path

export class JsonSchemaNode implements NodeDelegate {
  getValue(
    content: JSONSchema7Type | undefined,
    path: string | undefined
  ): JSONSchema7Type | undefined {
    // const pointer = JsonPointer.create(path || '')
    if (content === null || content === undefined) {
      return null
    }

    if (content !== undefined && typeof content === 'object') {
      return jsonpointer.get(content, !path || path === '/' ? '' : path)
      // return jsonpointer.get(content, adjustPath(path))
    }

    return content
  }

  setValue(
    content: JSONSchema7Type | undefined,
    newLocaleValue: JSONSchema7Type | undefined,
    path: string | undefined
  ): JSONSchema7Type | undefined {
    const pointer = JsonPointer.create(path || '/')
    if (!content || typeof content !== 'object' || !path || path === '/') {
      return newLocaleValue
    }

    const newContent = copy(content)
    if (newLocaleValue === undefined) {
      // jsonpointer.set(newContent, path, newLocaleValue)
      pointer.unset(newContent)
    } else {
      pointer.set(newContent, newLocaleValue, true)
    }

    return newContent
  }

  computeDefaultAddableNode(
    typeName: JSONSchema7TypeName,
    node: InputNode,
    desiredValue?: JSONSchema7Type
  ): AddableNode {
    const newValue = copy(desiredValue === undefined ? defaultValues[typeName] : desiredValue)

    // manage new empty objects with required
    if (typeName === 'object') {
      computeRequired(node.schema || true, newValue)
    }

    return {
      path: node.path,
      node,
      required: false,
      typeName,
      newValue,
    }
  }

  manageOneOfOrAnyOfAvailableNodes({
    sourceId,
    value,
    path,
    schema: originalSchema,
    rootSchema,
    schemaPath,
    parentPath,
    parentSchemaPath,
  }: InputNode): AddableNode[] {
    const result: AddableNode[] = []
    const schema: any = originalSchema
    if (schema.anyOf || schema.oneOf) {
      const copiedSchema = copy(schema)
      const isAnyOf = !!copiedSchema.anyOf
      const subSchemas = isAnyOf ? copiedSchema.anyOf : copiedSchema.oneOf

      if (isAnyOf) {
        delete copiedSchema.anyOf
      } else {
        delete copiedSchema.oneOf
      }

      if (Array.isArray(subSchemas)) {
        for (let i = 0; i < subSchemas.length; i++) {
          const subSchema = subSchemas[i]
          if (subSchema && typeof subSchema !== 'boolean') {
            result.push(
              ...this.getAvailableNodes({
                sourceId,
                value: null,
                path,
                schema: merge(copiedSchema, subSchemas[i] as any),
                rootSchema,
                schemaPath: `${adjustPath(schemaPath)}/${isAnyOf ? 'anyOf' : 'oneOf'}/${i}`,
                parentSchemaPath: schemaPath || '/',
              })
            )
          }
        }
      }
    }
    return result
  }

  manageArrayAvailabeNodes({
    sourceId,
    value,
    path,
    schema: originalSchema,
    rootSchema,
    schemaPath,
    parentPath,
    parentSchemaPath,
  }: InputNode): AddableNode[] {
    const result: AddableNode[] = []
    const schema: any = originalSchema

    if (value && Array.isArray(value)) {
      if (schema.items && isObject(schema.items)) {
        result.push(
          ...this.getAvailableNodes({
            sourceId,
            value: null,
            path: `${adjustPath(path)}/${value.length}`,
            schema: schema.items as any,
            rootSchema,
            schemaPath: `${adjustPath(schemaPath)}/items`,
            parentPath: path,
            parentSchemaPath: schemaPath || '/',
          })
        )
      }
    } else {
      result.push(
        this.computeDefaultAddableNode('array', {
          sourceId,
          value,
          path,
          schema,
          rootSchema,
          schemaPath,
          parentPath,
          parentSchemaPath,
        })
      )
    }
    return result
  }

  manageObjectAvailableNodes({
    sourceId,
    value,
    path,
    schema: originalSchema,
    rootSchema,
    schemaPath,
    parentPath,
    parentSchemaPath,
  }: InputNode): AddableNode[] {
    const schema: JSONSchema7 = originalSchema as any
    const result: AddableNode[] = []

    if (value && isObject(value) && schema.properties) {
      // manage object with correct instance
      const keys = Object.keys(schema.properties)
      for (let i = 0; i < keys.length; i++) {
        if (!value!.hasOwnProperty!(keys[i])) {
          if (
            schema.dependencies &&
            schema.dependencies[keys[i]] &&
            Array.isArray(schema.dependencies[keys[i]])
          ) {
            const { [keys[i]]: dependency, ...derivedDependencies } = schema.dependencies
            const newAddableNodes = this.computeDefaultAddableNode(
              'object',
              {
                sourceId,
                value,
                path,
                schema: {
                  ...schema,
                  dependencies: derivedDependencies,
                  required: [
                    ...(schema.required ? schema.required : []),
                    keys[i],
                    ...(dependency as any),
                  ],
                },
                rootSchema,
                schemaPath,
                parentPath,
                parentSchemaPath,
              },
              value
            )
            result.push(newAddableNodes)
          } else if (
            schema.dependencies &&
            schema.dependencies[keys[i]] &&
            isObject(schema.dependencies[keys[i]])
          ) {
            const { [keys[i]]: dependency, ...derivedDependencies } = schema.dependencies
            if (
              dependency &&
              isObject(dependency) &&
              ('oneOf' in (dependency as any) || 'anyOf' in (dependency as any))
            ) {
              //
            } else {
              let newSchema: any = {
                ...schema,
                dependencies: derivedDependencies,
                required: [...(schema.required ? schema.required : []), keys[i]],
              }
              if (newSchema.dependencies && Object.keys(newSchema.dependencies).length === 0) {
                delete newSchema.dependencies
              }
              newSchema = merge(
                dependency && isObject(dependency) ? (dependency as any) : {},
                newSchema
              )
              const newAddableNodes = this.computeDefaultAddableNode(
                'object',
                {
                  sourceId,
                  value,
                  path,
                  schema: newSchema,
                  rootSchema,
                  schemaPath,
                  parentPath,
                  parentSchemaPath,
                },
                value
              )
              result.push(newAddableNodes)
            }
          } else {
            result.push(
              ...this.getAvailableNodes({
                sourceId,
                value: null,
                path: `${adjustPath(path)}/${keys[i]}`,
                schema: schema.properties[keys[i]],
                rootSchema,
                schemaPath: `${adjustPath(schemaPath)}/properties/${keys[i]}`,
                parentPath: path,
                parentSchemaPath: schemaPath || '/',
              })
            )
          }
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (schema.dependencies) {
        let isMultiSchema = false
        for (const key of Object.keys(schema.dependencies)) {
          if (
            schema.dependencies[key] &&
            isObject(schema.dependencies[key]) &&
            ((schema.dependencies[key] as any).oneOf || (schema.dependencies[key] as any).anyOf)
          ) {
            isMultiSchema = true
            break
          }
        }

        if (isMultiSchema) {
          const properties = Object.keys(schema.dependencies)
          let newSchema = schema
          if (newSchema.dependencies && Object.keys(newSchema.dependencies).length === 0) {
            delete newSchema.dependencies
          }

          for (const property of properties) {
            if (
              schema.required &&
              schema.required.indexOf(property) > -1 &&
              ((schema.dependencies[property] as any).anyOf ||
                (schema.dependencies[property] as any).oneOf)
            ) {
              const { [property]: dependency, ...derivedDependencies } = schema.dependencies

              newSchema = {
                ...schema,
                dependencies: derivedDependencies,
                required: [
                  ...(schema.required ? schema.required : []),
                  // property
                ],
              }
              if (newSchema.dependencies && Object.keys(newSchema.dependencies).length === 0) {
                delete newSchema.dependencies
              }
              newSchema = merge(schema.dependencies[property] as any, newSchema)
            }
          }

          result.push(
            ...this.getAvailableNodes({
              sourceId,
              value: null,
              path,
              schema: newSchema,
              rootSchema,
              schemaPath: schemaPath || '/',
              parentPath: parentPath || '/',
              parentSchemaPath,
            })
          )

          return result
        } else {
          result.push(
            this.computeDefaultAddableNode('object', {
              sourceId,
              value,
              path,
              schema,
              rootSchema,
              schemaPath: schemaPath || '/',
              parentPath: parentPath || '/',
              parentSchemaPath,
            })
          )
        }
      } else {
        result.push(
          this.computeDefaultAddableNode('object', {
            sourceId,
            value,
            path,
            schema,
            rootSchema,
            schemaPath: schemaPath || '/',
            parentPath: parentPath || '/',
            parentSchemaPath,
          })
        )
      }
    }

    return result
  }

  getAvailableNodes({
    sourceId,
    value,
    path,
    schema,
    rootSchema,
    schemaPath,
    parentPath,
    parentSchemaPath,
  }: InputNode): AddableNode[] {
    if (schema && typeof schema !== 'boolean') {
      const schemaTypeName = getSchemaTypeName(schema)
      const result: AddableNode[] = []

      if (schema.anyOf || schema.oneOf) {
        result.push(
          ...this.manageOneOfOrAnyOfAvailableNodes({
            sourceId,
            value,
            path,
            schema,
            rootSchema,
            schemaPath,
            parentPath,
            parentSchemaPath,
          })
        )

        return result
      }

      if (schemaTypeName === 'array') {
        result.push(
          ...this.manageArrayAvailabeNodes({
            sourceId,
            value,
            path,
            schema,
            rootSchema,
            schemaPath,
            parentPath,
            parentSchemaPath,
          })
        )
      } else if (schemaTypeName === 'object') {
        result.push(
          ...this.manageObjectAvailableNodes({
            sourceId,
            value,
            path,
            schema,
            rootSchema,
            schemaPath,
            parentPath,
            parentSchemaPath,
          })
        )
      } else if (
        (schemaTypeName === 'boolean' ||
          schemaTypeName === 'integer' ||
          schemaTypeName === 'null' ||
          schemaTypeName === 'number' ||
          schemaTypeName === 'string') &&
        (value === null || value === undefined) &&
        value !== '' &&
        value !== 0
      ) {
        result.push(
          this.computeDefaultAddableNode(schemaTypeName, {
            sourceId,
            value,
            path,
            schema,
            rootSchema,
            schemaPath,
            parentPath,
            parentSchemaPath,
          })
        )
      }

      return result.filter((item, index) => {
        const foundedIndex = result.findIndex((searchingItem) =>
          deepEqual(searchingItem.newValue, item.newValue)
        )
        return foundedIndex === index
      })
    }
    return []
  }

  getObjectAdjacentAvailableNodes(
    content: JSONSchema7Type,
    schema: JSONSchema7Definition,
    path: string
  ): { type: JSONSchema7TypeName; required: boolean; path: string }[] {
    throw new Error('Method not implemented.')
  }

  getArrayPreviousAvailableNodes(
    content: JSONSchema7Type,
    schema: JSONSchema7Definition,
    path: string
  ): { type: JSONSchema7TypeName; required: boolean; path: string }[] {
    throw new Error('Method not implemented.')
  }

  getArrayNextAvailableNodes(
    content: JSONSchema7Type,
    schema: JSONSchema7Definition,
    path: string
  ): { type: JSONSchema7TypeName; required: boolean; path: string }[] {
    throw new Error('Method not implemented.')
  }
}
