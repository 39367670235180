import { JSONSchema7TypeName } from 'json-schema'

export const defaultValues: { [type in JSONSchema7TypeName]: any } = {
  array: [],
  boolean: false,
  integer: 0,
  null: null,
  number: 0,
  object: {},
  string: '',
}
