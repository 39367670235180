import React, { FC, useCallback } from 'react'
import {
  BoxProps,
  chakra,
  Flex,
  Image,
  ListItem,
  Tag,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import BaseIcon, { IconsCollections } from '../base/BaseIcon'
import Surface from '../base/Surface'
import BoxIcon from '../base/BoxIcon'

export type SearchResult = {
  id?: string
  name: string
  type: string
  image?: string
  iconName?: string
  iconCollection?: IconsCollections
}

type GlobalSearchResultProps = {
  searchResult: SearchResult
  onClick?: (searchResult: SearchResult) => void
}

const GlobalSearchResult: FC<GlobalSearchResultProps> = ({ searchResult, onClick }) => {
  const { id, name, type, image, iconName, iconCollection, ...rest } = searchResult
  const styles = useMultiStyleConfig('GlobalSearch', { ...rest })

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(searchResult)
    }
  }, [searchResult, onClick])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>) => {
      const enterOrSpace =
        e.key === 'Enter' ||
        e.key === ' ' ||
        e.key === 'Spacebar' ||
        e.which === 13 ||
        e.which === 32
      if (enterOrSpace) {
        e.preventDefault()
        handleClick()
      }
    },
    [handleClick]
  )

  return (
    <ListItem
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      sx={styles.listItem}
    >
      {image ? (
        <Image src={image} boxSize="60px" rounded="md" mr={3} />
      ) : (
        <BoxIcon name={iconName} collection={iconCollection} />
      )}
      <chakra.div display="flex" flexDir="column" overflow="hidden" w="100%">
        <Text fontWeight="bold" color="gray.500" w="100%" textOverflow="ellipsis">
          {name}
        </Text>
        <Tag color="gray.400" alignSelf="flex-start">{`${type}`}</Tag>
      </chakra.div>
      <BaseIcon name="BsArrowReturnRight" collection="bs" fontSize="3xl" />
    </ListItem>
  )
}
export default GlobalSearchResult
