import {
  Box,
  Flex,
  Modal,
  Text,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  chakra,
  Divider,
  ModalBody,
  ModalFooter,
  Collapse,
  useToast,
  Tag,
} from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useClient } from 'urql'
import {
  gql_EnumDKeySourceKind,
  gql_EnumSourceSchemaMode,
  gql_GetListSourcesQuery,
  gql_GetListSourcesQueryVariables,
  gql_GetProjectByIdQuery,
  gql_GetProjectByIdQueryVariables,
  gql_GetSourceByIdQuery,
  gql_GetSourceByIdQueryVariables,
} from '../../core/graphql'
import { ID } from '../../core/types/BaseCRUD'
import {
  MUTATION_UPDATE_PAGE,
  MUTATION_UPDATE_SOURCE,
  QUERY_GET_LIST_SOURCES,
  QUERY_GET_ONE_PROJECT,
  QUERY_GET_ONE_SOURCE,
} from '../../queries'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import { MasterDetailMenuItem, MasterDetailPanel } from '../base/MasterDetail'
import MasterDetailMenu, { ModalMenuItem } from '../base/MasterDetail/MasterDetailMenu'
import Surface from '../base/Surface'
import PageSEOFormSection from './SourceForm/PageSEOFormSection'
import SourceBasicInfoFormSection from './SourceForm/SourceBasicInfoFormSection'
import SourceSlugsFormSection from './SourceForm/SourceSlugsFormSection'

const menu: ModalMenuItem[] = [
  {
    id: 'create-source-options-section-0',
    title: 'Options',
    subtitle: 'lorem ipsum dolor sit',
    iconComponent: <BaseIcon name="GoSettings" collection="go" mr={2} fontSize="2xl" />,
  },
  {
    id: 'create-source-slugs-section-1',
    title: 'Slug',
    subtitle: 'The source slug',
    iconComponent: <BaseIcon name="HiFlag" collection="hi" mr={2} fontSize="2xl" />,
  },
  {
    id: 'create-source-seo-section-2',
    title: 'SEO',
    subtitle: 'Search Engine Optimization',
    iconComponent: <BaseIcon name="AiOutlineFileSearch" collection="ai" mr={2} fontSize="2xl" />,
  },
]

type Props = {
  projectId: ID
  sourceId: ID
  isOpen: boolean
  onClose: () => void
  finalFocusRef?: React.RefObject<any>
}

const EditSourceModal: FC<Props> = (props) => {
  const { projectId, sourceId, onClose, finalFocusRef, isOpen, ...rest } = props
  const [source, setSource] = useState<any | undefined>(undefined)
  const methods = useForm()
  const toast = useToast()
  const client = useClient()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const { handleSubmit, setValue, trigger } = methods
  const [layouts, setLayouts] = useState<any[]>([])

  const onSubmit = useCallback(
    async (data) => {
      console.log('ciaone', data)
      try {
        if (submitting) {
          return
        }

        setSubmitting(true)
        const record: any = {
          name: data.name,
          slug: data.slug,
          defaultLocale: data.defaultLocale,
          // schemaMode: data.schemaMode || gql_EnumSourceSchemaMode.Components,
          schemaMode:
            data.kind === gql_EnumDKeySourceKind.BasicSource
              ? gql_EnumSourceSchemaMode.Free
              : gql_EnumSourceSchemaMode.Components,
          project: projectId,
          isDefaultSource: data.isDefaultSource,
          isPublic: data.isPublic,
          isGlobal: data.isGlobal,
          locales: (data.locales || [])
            .filter((item: any) => item.active)
            .map((item: any) => ({
              slug: item.slug,
              locale: item.locale,
            })),
        }

        if (data.kind === gql_EnumDKeySourceKind.Page) {
          record.seo = data.seo.map((item: any) => ({
            locale: item.locale,
            seo: {
              title: item.title,
              description: item.description,
            },
          }))
          record.layout = !data.layout || data.layout === '' ? null : data.layout
        } else {
          record.kind = data.kind
        }

        const result = await client
          .mutation(
            data.kind === gql_EnumDKeySourceKind.Page
              ? MUTATION_UPDATE_PAGE
              : MUTATION_UPDATE_SOURCE,
            {
              id: sourceId,
              record,
            }
          )
          .toPromise()

        if (
          result.error?.message ||
          result.data[
            `${data.kind === gql_EnumDKeySourceKind.Page ? 'pageUpdateById' : 'sourceUpdateById'}`
          ].error?.message
        ) {
          const message = result.error?.message
            ? result.error.message
            : data.kind === gql_EnumDKeySourceKind.Page
            ? result.data.pageUpdateById.error.message
            : result.data.sourceUpdateById.error.message
          throw new Error(message)
        } else {
          toast({
            title: 'Source Updated correctly',
            description: 'Source has been updated',
            duration: 3000,
            status: 'success',
            isClosable: true,
            variant: 'left-accent',
          })
        }
      } catch (error) {
        toast({
          title: 'Error updating source',
          description: error.message,
          duration: 3000,
          status: 'error',
          isClosable: true,
          variant: 'left-accent',
        })
      } finally {
        setSubmitting(false)
      }
    },
    [client, projectId, source, sourceId, submitting, toast]
  )

  const handleSectionClick = useCallback((menuItem: ModalMenuItem) => {
    const splittedId = menuItem.id.split('-')
    setSelectedIndex(parseInt(splittedId[splittedId.length - 1], 10))
  }, [])

  useEffect(() => {
    const initSourceForm = async () => {
      try {
        setInitialized(false)
        const projectResult = await client
          .query<gql_GetProjectByIdQuery, gql_GetProjectByIdQueryVariables>(QUERY_GET_ONE_PROJECT, {
            id: projectId,
          })
          .toPromise()
        const sourceResult = await client
          .query<gql_GetSourceByIdQuery, gql_GetSourceByIdQueryVariables>(QUERY_GET_ONE_SOURCE, {
            id: sourceId,
          })
          .toPromise()
        const layoutResult = await client
          .query<gql_GetListSourcesQuery, gql_GetListSourcesQueryVariables>(
            QUERY_GET_LIST_SOURCES,
            {
              filter: { kind: gql_EnumDKeySourceKind.Layout },
            }
          )
          .toPromise()

        if (layoutResult.data?.sourcePagination?.items) {
          setLayouts(layoutResult.data.sourcePagination.items)
        }

        if (projectResult.data?.project?.id && sourceResult.data?.sourceById?.id) {
          setSource(sourceResult.data?.sourceById)
          const fields = [
            'name',
            'isPublic',
            'isDefaultSource',
            'isGlobal',
            'slug',
            'locales',
            'seo',
            'layout',
            'kind',
          ]
          fields.forEach((key) => {
            switch (key) {
              case 'locales':
                // eslint-disable-next-line no-case-declarations
                const locales =
                  projectResult &&
                  projectResult.data &&
                  projectResult.data.project &&
                  projectResult.data.project.locales
                    ? projectResult.data.project.locales.filter(
                        (item) => item !== sourceResult.data?.sourceById?.defaultLocale
                      )
                    : []

                setValue(
                  key,
                  locales.map((locale) => {
                    const foundedSlug = (sourceResult.data?.sourceById?.locales || []).find(
                      (s: any) => s.locale === locale
                    )
                    return {
                      locale,
                      slug: foundedSlug?.slug,
                      active: !!foundedSlug,
                    }
                  })
                )
                break

              case 'seo':
                if (
                  sourceResult.data &&
                  sourceResult.data.sourceById &&
                  sourceResult.data.sourceById.kind === 'Page'
                ) {
                  const pageSeo =
                    sourceResult.data?.sourceById && (sourceResult.data?.sourceById as any).seo
                      ? (sourceResult.data?.sourceById as any).seo
                      : []
                  setValue(
                    key,
                    projectResult.data?.project?.locales?.map((locale) => {
                      const foundedSeo = pageSeo.find((s: any) => s.locale === locale)
                      return {
                        locale,
                        title: foundedSeo && foundedSeo.seo ? foundedSeo.seo.title : '',
                        description: foundedSeo && foundedSeo.seo ? foundedSeo.seo.description : '',
                      }
                    })
                  )
                }
                break

              default:
                setValue(key, (sourceResult.data!.sourceById as any)[key as any])
            }
          })
        } else {
          throw new Error('Unexpected error fetching source')
        }
      } catch (error) {
        toast({
          title: 'Error fetching source',
          description: error.message,
          variant: 'error',
          duration: 3000,
        })
        console.error('Error fetching source', error)
      } finally {
        setInitialized(true)
      }
    }

    if (isOpen) {
      initSourceForm()
    }
  }, [isOpen])

  return (
    <FormProvider {...methods}>
      <Modal motionPreset="scale" size="4xl" onClose={onClose} isOpen={isOpen} isCentered {...rest}>
        <ModalOverlay />

        <Surface
          as={ModalContent}
          darkVariant="heavy"
          lightBgColor="light.100"
          boxShadow="lg"
          overflow="hidden"
          d="flex"
          flexDir="row"
          justifyContent="stretch"
          alignContent="stretch"
          maxH="90vh"
        >
          <MasterDetailMenu
            tagTitle={source && (source as any).type}
            subtitle="Source settings"
            title={source ? (source as any).name : 'New Source'}
            //  titleIconComponent={<BaseIcon name="RiPagesFill" collection="ri" mr={2} />}
          >
            {menu
              .filter(
                (menuItem) =>
                  !(
                    menuItem.id === 'create-source-seo-section-2' &&
                    source &&
                    source.kind !== 'Page'
                  )
              )
              .map((menuItem, index) => (
                <MasterDetailMenuItem key={index} {...menuItem} onClickItem={handleSectionClick} />
              ))}
          </MasterDetailMenu>
          <MasterDetailPanel
            as={Surface}
            isActive={true}
            w="65%"
            position="relative"
            d="flex"
            flexDir="column"
            darkBgColor="dark.800"
            lightBgColor="light.50"
          >
            <chakra.form
              onSubmit={handleSubmit(onSubmit)}
              display="flex"
              flexDir="column"
              boxSize="100%"
              noValidate
            >
              {/* <pre>{JSON.stringify(currentValues, null, 2)}</pre> */}
              <Box overflow="scroll" w="100%" pb={5}>
                <ModalHeader
                  as={Surface}
                  mb={0}
                  d="flex"
                  justifyContent="space-between"
                  position="sticky"
                  top="0"
                  zIndex={2}
                  darkBgColor="dark.800"
                  alignItems="center"
                >
                  {/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
                  <Flex flexDir="column">
                    <Text
                      fontSize="xl"
                      isTruncated
                      noOfLines={2}
                      d="flex"
                      textTransform="capitalize"
                    >
                      {menu && menu[selectedIndex] && menu[selectedIndex].title}
                    </Text>
                  </Flex>
                  <ModalCloseButton
                    as={BaseButton}
                    size="lg"
                    top={0}
                    right={0}
                    position="relative"
                  />
                  <Divider zIndex={2} position="absolute" bottom="0" left="0" />
                </ModalHeader>
                <ModalBody pt={4} userSelect="none">
                  <Collapse in={selectedIndex === 0}>
                    <SourceBasicInfoFormSection layouts={layouts} disableKindRadio />
                  </Collapse>
                  <Collapse in={selectedIndex === 1}>
                    <SourceSlugsFormSection
                      name="locales"
                      projectId={projectId}
                      sourceId={sourceId}
                    />
                  </Collapse>
                  <Collapse in={selectedIndex === 2}>
                    <PageSEOFormSection name="seo" projectId={projectId} sourceId={sourceId} />
                  </Collapse>
                </ModalBody>
              </Box>
              <Divider />
              <ModalFooter>
                <BaseButton variant="ghost" mr={3} onClick={onClose}>
                  Cancel
                </BaseButton>
                <BaseButton
                  disabled={submitting}
                  isLoading={submitting}
                  color="green.400"
                  lightHue="lighter"
                  darkHue="light"
                  type="submit"
                >
                  Save
                </BaseButton>
              </ModalFooter>
            </chakra.form>
          </MasterDetailPanel>
        </Surface>
      </Modal>
    </FormProvider>
  )
}

export default EditSourceModal
