import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any;
  Date: any;
  /** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  RegExpAsString: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type gql_ValidationError = gql_ErrorInterface & {
  __typename?: 'ValidationError';
  /** Combined error message from all validators */
  message?: Maybe<Scalars['String']>;
  /** List of validator errors */
  errors?: Maybe<Array<gql_ValidatorError>>;
};

export type gql_ErrorInterface = {
  /** Generic error message */
  message?: Maybe<Scalars['String']>;
};

export type gql_ValidatorError = {
  __typename?: 'ValidatorError';
  /** Validation error message */
  message?: Maybe<Scalars['String']>;
  /** Source of the validation error from the model path */
  path?: Maybe<Scalars['String']>;
  /** Field value which occurs the validation error */
  value?: Maybe<Scalars['JSON']>;
  /** Input record idx in array which occurs the validation error. This `idx` is useful for createMany operation. For singular operations it always be 0. For *Many operations `idx` represents record index in array received from user. */
  idx: Scalars['Int'];
};


export type gql_MongoError = gql_ErrorInterface & {
  __typename?: 'MongoError';
  /** MongoDB error message */
  message?: Maybe<Scalars['String']>;
  /** MongoDB error code */
  code?: Maybe<Scalars['Int']>;
};

export type gql_RuntimeError = gql_ErrorInterface & {
  __typename?: 'RuntimeError';
  /** Runtime error message */
  message?: Maybe<Scalars['String']>;
};

export type gql_Source = gql_SourceInterface & {
  __typename?: 'Source';
  _id: Scalars['MongoID'];
  kind?: Maybe<gql_EnumDKeySourceKind>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocales>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
};

export type gql_SourceInterface = {
  _id: Scalars['MongoID'];
  kind?: Maybe<gql_EnumDKeySourceKind>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocales>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
};


export enum gql_EnumDKeySourceKind {
  BasicSource = 'BasicSource',
  Layout = 'Layout',
  Page = 'Page',
  Folder = 'Folder'
}


export type gql_SourceLocales = {
  __typename?: 'SourceLocales';
  slug: Scalars['String'];
  locale: Scalars['String'];
  _id?: Maybe<Scalars['MongoID']>;
};

export enum gql_EnumSourceSchemaMode {
  Components = 'components',
  Free = 'free'
}

export type gql_BasicSource = gql_SourceInterface & {
  __typename?: 'BasicSource';
  _id: Scalars['MongoID'];
  kind?: Maybe<gql_EnumDKeySourceKind>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocales>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
};

export type gql_Layout = gql_SourceInterface & {
  __typename?: 'Layout';
  _id: Scalars['MongoID'];
  kind?: Maybe<gql_EnumDKeySourceKind>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocales>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
};

export type gql_Page = gql_SourceInterface & {
  __typename?: 'Page';
  _id: Scalars['MongoID'];
  kind?: Maybe<gql_EnumDKeySourceKind>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocales>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['MongoID']>;
  seo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_Folder = gql_SourceInterface & {
  __typename?: 'Folder';
  _id: Scalars['MongoID'];
  kind?: Maybe<gql_EnumDKeySourceKind>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocales>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  children?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
};

export type gql_Query = {
  __typename?: 'Query';
  tenantById?: Maybe<gql_Tenant>;
  tenantByIds: Array<gql_Tenant>;
  tenantOne?: Maybe<gql_Tenant>;
  tenantMany: Array<gql_Tenant>;
  tenantCount?: Maybe<Scalars['Int']>;
  tenantConnection?: Maybe<gql_TenantConnection>;
  tenantPagination?: Maybe<gql_TenantPagination>;
  userById?: Maybe<gql_User>;
  userByIds: Array<gql_User>;
  userOne?: Maybe<gql_User>;
  userMany: Array<gql_User>;
  userCount?: Maybe<Scalars['Int']>;
  userConnection?: Maybe<gql_UserConnection>;
  userPagination?: Maybe<gql_UserPagination>;
  projectById?: Maybe<gql_Project>;
  projectByIds: Array<gql_Project>;
  projectOne?: Maybe<gql_Project>;
  projectMany: Array<gql_Project>;
  projectCount?: Maybe<Scalars['Int']>;
  projectConnection?: Maybe<gql_ProjectConnection>;
  projectPagination?: Maybe<gql_ProjectPagination>;
  sourceById?: Maybe<gql_SourceInterface>;
  sourceByIds?: Maybe<Array<Maybe<gql_SourceInterface>>>;
  sourceOne?: Maybe<gql_SourceInterface>;
  sourceMany?: Maybe<Array<Maybe<gql_SourceInterface>>>;
  sourceCount?: Maybe<Scalars['Int']>;
  sourceConnection?: Maybe<gql_SourceConnection>;
  sourcePagination?: Maybe<gql_SourcePagination>;
  pageMany: Array<gql_Page>;
  contentById?: Maybe<gql_Content>;
  contentOne?: Maybe<gql_Content>;
  mediaById?: Maybe<gql_Media>;
  mediaByIds: Array<gql_Media>;
  mediaOne?: Maybe<gql_Media>;
  mediaMany: Array<gql_Media>;
  mediaCount?: Maybe<Scalars['Int']>;
  mediaConnection?: Maybe<gql_MediaConnection>;
  mediaPagination?: Maybe<gql_MediaPagination>;
  componentById?: Maybe<gql_Component>;
  componentByIds: Array<gql_Component>;
  componentOne?: Maybe<gql_Component>;
  componentMany: Array<gql_Component>;
  componentCount?: Maybe<Scalars['Int']>;
  componentConnection?: Maybe<gql_ComponentConnection>;
  componentPagination?: Maybe<gql_ComponentPagination>;
  environmentById?: Maybe<gql_Environment>;
  environmentByIds: Array<gql_Environment>;
  environmentOne?: Maybe<gql_Environment>;
  environmentMany: Array<gql_Environment>;
  environmentCount?: Maybe<Scalars['Int']>;
  environmentConnection?: Maybe<gql_EnvironmentConnection>;
  environmentPagination?: Maybe<gql_EnvironmentPagination>;
  userMe: gql_UserMe;
  resolveDynamicContent: gql_ResolvedDynamicContent;
  contentHistoryOne: Array<gql_ContentHistory>;
  sourceSlugs: Array<gql_LocalizedSlug>;
};


export type gql_QueryTenantByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryTenantByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsTenantInput>;
};


export type gql_QueryTenantOneArgs = {
  filter?: Maybe<gql_FilterFindOneTenantInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneTenantInput>;
};


export type gql_QueryTenantManyArgs = {
  filter?: Maybe<gql_FilterFindManyTenantInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyTenantInput>;
};


export type gql_QueryTenantCountArgs = {
  filter?: Maybe<gql_FilterCountTenantInput>;
};


export type gql_QueryTenantConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManyTenantInput>;
  sort?: Maybe<gql_SortConnectionTenantEnum>;
};


export type gql_QueryTenantPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyTenantInput>;
  sort?: Maybe<gql_SortFindManyTenantInput>;
};


export type gql_QueryUserByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryUserByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsUserInput>;
};


export type gql_QueryUserOneArgs = {
  filter?: Maybe<gql_FilterFindOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneUserInput>;
};


export type gql_QueryUserManyArgs = {
  filter?: Maybe<gql_FilterFindManyUserInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyUserInput>;
};


export type gql_QueryUserCountArgs = {
  filter?: Maybe<gql_FilterCountUserInput>;
};


export type gql_QueryUserConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManyUserInput>;
  sort?: Maybe<gql_SortConnectionUserEnum>;
};


export type gql_QueryUserPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyUserInput>;
  sort?: Maybe<gql_SortFindManyUserInput>;
};


export type gql_QueryProjectByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryProjectByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsProjectInput>;
};


export type gql_QueryProjectOneArgs = {
  filter?: Maybe<gql_FilterFindOneProjectInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneProjectInput>;
};


export type gql_QueryProjectManyArgs = {
  filter?: Maybe<gql_FilterFindManyProjectInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyProjectInput>;
};


export type gql_QueryProjectCountArgs = {
  filter?: Maybe<gql_FilterCountProjectInput>;
};


export type gql_QueryProjectConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManyProjectInput>;
  sort?: Maybe<gql_SortConnectionProjectEnum>;
};


export type gql_QueryProjectPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyProjectInput>;
  sort?: Maybe<gql_SortFindManyProjectInput>;
};


export type gql_QuerySourceByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QuerySourceByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsSourceInput>;
};


export type gql_QuerySourceOneArgs = {
  filter?: Maybe<gql_FilterFindOneSourceInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneSourceInput>;
};


export type gql_QuerySourceManyArgs = {
  filter?: Maybe<gql_FilterFindManySourceInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManySourceInput>;
};


export type gql_QuerySourceCountArgs = {
  filter?: Maybe<gql_FilterCountSourceInput>;
};


export type gql_QuerySourceConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManySourceInput>;
  sort?: Maybe<gql_SortConnectionSourceEnum>;
};


export type gql_QuerySourcePaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManySourceInput>;
  sort?: Maybe<gql_SortFindManySourceInput>;
};


export type gql_QueryPageManyArgs = {
  filter?: Maybe<gql_FilterFindManyPageInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyPageInput>;
};


export type gql_QueryContentByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryContentOneArgs = {
  filter?: Maybe<gql_FilterFindOneContentInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneContentInput>;
};


export type gql_QueryMediaByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryMediaByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsMediaInput>;
};


export type gql_QueryMediaOneArgs = {
  filter?: Maybe<gql_FilterFindOneMediaInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneMediaInput>;
};


export type gql_QueryMediaManyArgs = {
  filter?: Maybe<gql_FilterFindManyMediaInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyMediaInput>;
};


export type gql_QueryMediaCountArgs = {
  filter?: Maybe<gql_FilterCountMediaInput>;
};


export type gql_QueryMediaConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManyMediaInput>;
  sort?: Maybe<gql_SortConnectionMediaEnum>;
};


export type gql_QueryMediaPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyMediaInput>;
  sort?: Maybe<gql_SortFindManyMediaInput>;
};


export type gql_QueryComponentByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryComponentByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsComponentInput>;
};


export type gql_QueryComponentOneArgs = {
  filter?: Maybe<gql_FilterFindOneComponentInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneComponentInput>;
};


export type gql_QueryComponentManyArgs = {
  filter?: Maybe<gql_FilterFindManyComponentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyComponentInput>;
};


export type gql_QueryComponentCountArgs = {
  filter?: Maybe<gql_FilterCountComponentInput>;
};


export type gql_QueryComponentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManyComponentInput>;
  sort?: Maybe<gql_SortConnectionComponentEnum>;
};


export type gql_QueryComponentPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyComponentInput>;
  sort?: Maybe<gql_SortFindManyComponentInput>;
};


export type gql_QueryEnvironmentByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_QueryEnvironmentByIdsArgs = {
  _ids: Array<Scalars['MongoID']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindByIdsEnvironmentInput>;
};


export type gql_QueryEnvironmentOneArgs = {
  filter?: Maybe<gql_FilterFindOneEnvironmentInput>;
  skip?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindOneEnvironmentInput>;
};


export type gql_QueryEnvironmentManyArgs = {
  filter?: Maybe<gql_FilterFindManyEnvironmentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<gql_SortFindManyEnvironmentInput>;
};


export type gql_QueryEnvironmentCountArgs = {
  filter?: Maybe<gql_FilterCountEnvironmentInput>;
};


export type gql_QueryEnvironmentConnectionArgs = {
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  filter?: Maybe<gql_FilterFindManyEnvironmentInput>;
  sort?: Maybe<gql_SortConnectionEnvironmentEnum>;
};


export type gql_QueryEnvironmentPaginationArgs = {
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyEnvironmentInput>;
  sort?: Maybe<gql_SortFindManyEnvironmentInput>;
};


export type gql_QueryResolveDynamicContentArgs = {
  payload: gql_ResolveDynamicContentPayload;
};


export type gql_QueryContentHistoryOneArgs = {
  payload: gql_ContentHistoryPayload;
};


export type gql_QuerySourceSlugsArgs = {
  projectId: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};

export type gql_Tenant = {
  __typename?: 'Tenant';
  _id: Scalars['MongoID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  projectsLimit: Scalars['Float'];
  sourcesPerProjectLimit: Scalars['Float'];
  mediaLibraryTotalKBLimit: Scalars['Float'];
  owner: Scalars['MongoID'];
};

export enum gql_SortFindByIdsTenantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export type gql_FilterFindOneTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneTenantInput>>;
  AND?: Maybe<Array<gql_FilterFindOneTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneTenantOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindOneTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterFindOneTenantOwnerOperatorsInput>;
};

export type gql_FilterFindOneTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};


export type gql_FilterFindOneTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneTenantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export type gql_FilterFindManyTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManyTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManyTenantInput>>;
  AND?: Maybe<Array<gql_FilterFindManyTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManyTenantOperatorsInput = {
  _id?: Maybe<gql_FilterFindManyTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindManyTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterFindManyTenantOwnerOperatorsInput>;
};

export type gql_FilterFindManyTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManyTenantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export type gql_FilterCountTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountTenantInput>>;
  AND?: Maybe<Array<gql_FilterCountTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountTenantOperatorsInput = {
  _id?: Maybe<gql_FilterCountTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterCountTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterCountTenantOwnerOperatorsInput>;
};

export type gql_FilterCountTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_TenantConnection = {
  __typename?: 'TenantConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  /** Information to aid in pagination. */
  edges: Array<gql_TenantEdge>;
};

/** Information about pagination in a connection. */
export type gql_PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type gql_TenantEdge = {
  __typename?: 'TenantEdge';
  /** The item at the end of the edge */
  node: gql_Tenant;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionTenantEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_TenantPagination = {
  __typename?: 'TenantPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<gql_Tenant>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export type gql_PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  pageCount?: Maybe<Scalars['Int']>;
  itemCount?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type gql_User = {
  __typename?: 'User';
  _id: Scalars['MongoID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindByIdsUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type gql_FilterFindOneUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneUserInput>>;
  AND?: Maybe<Array<gql_FilterFindOneUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneUserOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneUser_IdOperatorsInput>;
};

export type gql_FilterFindOneUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type gql_FilterFindManyUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManyUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManyUserInput>>;
  AND?: Maybe<Array<gql_FilterFindManyUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManyUserOperatorsInput = {
  _id?: Maybe<gql_FilterFindManyUser_IdOperatorsInput>;
};

export type gql_FilterFindManyUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManyUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type gql_FilterCountUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountUserInput>>;
  AND?: Maybe<Array<gql_FilterCountUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountUserOperatorsInput = {
  _id?: Maybe<gql_FilterCountUser_IdOperatorsInput>;
};

export type gql_FilterCountUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_UserConnection = {
  __typename?: 'UserConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  /** Information to aid in pagination. */
  edges: Array<gql_UserEdge>;
};

/** An edge in a connection. */
export type gql_UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node: gql_User;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionUserEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_UserPagination = {
  __typename?: 'UserPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<gql_User>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export type gql_Project = {
  __typename?: 'Project';
  tenant: Scalars['MongoID'];
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings: gql_ProjectSettings;
  defaultLocale: Scalars['String'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_ProjectSettings = {
  __typename?: 'ProjectSettings';
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export enum gql_SortFindByIdsProjectInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC'
}

export type gql_FilterFindOneProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterFindOneProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneProjectInput>>;
  AND?: Maybe<Array<gql_FilterFindOneProjectInput>>;
};

export type gql_FilterFindOneProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneProjectOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindOneProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterFindOneProjectPathOperatorsInput>;
};

export type gql_FilterFindOneProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneProjectInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC'
}

export type gql_FilterFindManyProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterFindManyProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManyProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManyProjectInput>>;
  AND?: Maybe<Array<gql_FilterFindManyProjectInput>>;
};

export type gql_FilterFindManyProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManyProjectOperatorsInput = {
  _id?: Maybe<gql_FilterFindManyProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindManyProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterFindManyProjectPathOperatorsInput>;
};

export type gql_FilterFindManyProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManyProjectInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC'
}

export type gql_FilterCountProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterCountProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountProjectInput>>;
  AND?: Maybe<Array<gql_FilterCountProjectInput>>;
};

export type gql_FilterCountProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountProjectOperatorsInput = {
  _id?: Maybe<gql_FilterCountProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterCountProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterCountProjectPathOperatorsInput>;
};

export type gql_FilterCountProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_ProjectConnection = {
  __typename?: 'ProjectConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  /** Information to aid in pagination. */
  edges: Array<gql_ProjectEdge>;
};

/** An edge in a connection. */
export type gql_ProjectEdge = {
  __typename?: 'ProjectEdge';
  /** The item at the end of the edge */
  node: gql_Project;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionProjectEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_ProjectPagination = {
  __typename?: 'ProjectPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<gql_Project>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export enum gql_SortFindByIdsSourceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_FilterFindOneSourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterFindOneSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneSourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneSourceInput>>;
  AND?: Maybe<Array<gql_FilterFindOneSourceInput>>;
};

export type gql_FilterFindOneSourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneSourceOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneSource_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindOneSourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterFindOneSourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterFindOneSourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterFindOneSourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterFindOneSourceIsGlobalOperatorsInput>;
};

export type gql_FilterFindOneSource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneSourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneSourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneSourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneSourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneSourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneSourceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_FilterFindManySourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterFindManySourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManySourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManySourceInput>>;
  AND?: Maybe<Array<gql_FilterFindManySourceInput>>;
};

export type gql_FilterFindManySourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManySourceOperatorsInput = {
  _id?: Maybe<gql_FilterFindManySource_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindManySourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterFindManySourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterFindManySourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterFindManySourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterFindManySourceIsGlobalOperatorsInput>;
};

export type gql_FilterFindManySource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManySourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManySourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManySourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManySourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManySourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManySourceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_FilterCountSourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterCountSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountSourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountSourceInput>>;
  AND?: Maybe<Array<gql_FilterCountSourceInput>>;
};

export type gql_FilterCountSourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountSourceOperatorsInput = {
  _id?: Maybe<gql_FilterCountSource_IdOperatorsInput>;
  name?: Maybe<gql_FilterCountSourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterCountSourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterCountSourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterCountSourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterCountSourceIsGlobalOperatorsInput>;
};

export type gql_FilterCountSource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountSourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountSourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountSourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountSourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountSourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_SourceConnection = {
  __typename?: 'SourceConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  edges: Array<gql_SourceEdge>;
};

/** An edge in a connection. */
export type gql_SourceEdge = {
  __typename?: 'SourceEdge';
  /** The item at the end of the edge */
  node: gql_SourceInterface;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionSourceEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_SourcePagination = {
  __typename?: 'SourcePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<Maybe<gql_SourceInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export type gql_FilterFindManyPageInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterFindManySourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['MongoID']>;
  seo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManySourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManySourceInput>>;
  AND?: Maybe<Array<gql_FilterFindManySourceInput>>;
};

export enum gql_SortFindManyPageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_Content = {
  __typename?: 'Content';
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  content: Scalars['JSON'];
  locale: Scalars['String'];
  source: Scalars['MongoID'];
  environment: Scalars['MongoID'];
};

export type gql_FilterFindOneContentInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  content?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneContentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneContentInput>>;
  AND?: Maybe<Array<gql_FilterFindOneContentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneContentOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneContent_IdOperatorsInput>;
  locale?: Maybe<gql_FilterFindOneContentLocaleOperatorsInput>;
  source?: Maybe<gql_FilterFindOneContentSourceOperatorsInput>;
};

export type gql_FilterFindOneContent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneContentLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneContentSourceOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneContentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  LocaleAsc = 'LOCALE_ASC',
  LocaleDesc = 'LOCALE_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC'
}

export type gql_Media = {
  __typename?: 'Media';
  tenant: Scalars['MongoID'];
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  mimeType: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
};

export enum gql_SortFindByIdsMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MimetypeAsc = 'MIMETYPE_ASC',
  MimetypeDesc = 'MIMETYPE_DESC',
  AlternativetextAsc = 'ALTERNATIVETEXT_ASC',
  AlternativetextDesc = 'ALTERNATIVETEXT_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC'
}

export type gql_FilterFindOneMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneMediaInput>>;
  AND?: Maybe<Array<gql_FilterFindOneMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneMediaOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindOneMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterFindOneMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterFindOneMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterFindOneMediaParentOperatorsInput>;
};

export type gql_FilterFindOneMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MimetypeAsc = 'MIMETYPE_ASC',
  MimetypeDesc = 'MIMETYPE_DESC',
  AlternativetextAsc = 'ALTERNATIVETEXT_ASC',
  AlternativetextDesc = 'ALTERNATIVETEXT_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC'
}

export type gql_FilterFindManyMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManyMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManyMediaInput>>;
  AND?: Maybe<Array<gql_FilterFindManyMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManyMediaOperatorsInput = {
  _id?: Maybe<gql_FilterFindManyMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindManyMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterFindManyMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterFindManyMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterFindManyMediaParentOperatorsInput>;
};

export type gql_FilterFindManyMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManyMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MimetypeAsc = 'MIMETYPE_ASC',
  MimetypeDesc = 'MIMETYPE_DESC',
  AlternativetextAsc = 'ALTERNATIVETEXT_ASC',
  AlternativetextDesc = 'ALTERNATIVETEXT_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC',
  UpdatedatDesc = 'UPDATEDAT_DESC',
  UpdatedatAsc = 'UPDATEDAT_ASC'
}

export type gql_FilterCountMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountMediaInput>>;
  AND?: Maybe<Array<gql_FilterCountMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountMediaOperatorsInput = {
  _id?: Maybe<gql_FilterCountMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterCountMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterCountMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterCountMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterCountMediaParentOperatorsInput>;
};

export type gql_FilterCountMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_MediaConnection = {
  __typename?: 'MediaConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  /** Information to aid in pagination. */
  edges: Array<gql_MediaEdge>;
};

/** An edge in a connection. */
export type gql_MediaEdge = {
  __typename?: 'MediaEdge';
  /** The item at the end of the edge */
  node: gql_Media;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionMediaEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_MediaPagination = {
  __typename?: 'MediaPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<gql_Media>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export type gql_Component = {
  __typename?: 'Component';
  tenant?: Maybe<Scalars['MongoID']>;
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  partialSchema?: Maybe<Scalars['JSON']>;
  project: Scalars['MongoID'];
  environment: Scalars['MongoID'];
};

export enum gql_SortFindByIdsComponentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_FilterFindOneComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneComponentInput>>;
  AND?: Maybe<Array<gql_FilterFindOneComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneComponentOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindOneComponentNameOperatorsInput>;
};

export type gql_FilterFindOneComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneComponentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_FilterFindManyComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManyComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManyComponentInput>>;
  AND?: Maybe<Array<gql_FilterFindManyComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManyComponentOperatorsInput = {
  _id?: Maybe<gql_FilterFindManyComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindManyComponentNameOperatorsInput>;
};

export type gql_FilterFindManyComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManyComponentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_FilterCountComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountComponentInput>>;
  AND?: Maybe<Array<gql_FilterCountComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountComponentOperatorsInput = {
  _id?: Maybe<gql_FilterCountComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterCountComponentNameOperatorsInput>;
};

export type gql_FilterCountComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_ComponentConnection = {
  __typename?: 'ComponentConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  /** Information to aid in pagination. */
  edges: Array<gql_ComponentEdge>;
};

/** An edge in a connection. */
export type gql_ComponentEdge = {
  __typename?: 'ComponentEdge';
  /** The item at the end of the edge */
  node: gql_Component;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionComponentEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_ComponentPagination = {
  __typename?: 'ComponentPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<gql_Component>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export type gql_Environment = {
  __typename?: 'Environment';
  tenant: Scalars['MongoID'];
  _id: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_EnvironmentPreviewSettings>;
  project: Scalars['MongoID'];
};

export type gql_EnvironmentPreviewSettings = {
  __typename?: 'EnvironmentPreviewSettings';
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export enum gql_SortFindByIdsEnvironmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_FilterFindOneEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterFindOneEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindOneEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindOneEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterFindOneEnvironmentInput>>;
};

export type gql_FilterFindOneEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindOneEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterFindOneEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindOneEnvironmentNameOperatorsInput>;
};

export type gql_FilterFindOneEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindOneEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindOneEnvironmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_FilterFindManyEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterFindManyEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterFindManyEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterFindManyEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterFindManyEnvironmentInput>>;
};

export type gql_FilterFindManyEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterFindManyEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterFindManyEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterFindManyEnvironmentNameOperatorsInput>;
};

export type gql_FilterFindManyEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterFindManyEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortFindManyEnvironmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_FilterCountEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterCountEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterCountEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterCountEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterCountEnvironmentInput>>;
};

export type gql_FilterCountEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterCountEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterCountEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterCountEnvironmentNameOperatorsInput>;
};

export type gql_FilterCountEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterCountEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type gql_EnvironmentConnection = {
  __typename?: 'EnvironmentConnection';
  /** Total object count. */
  count: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: gql_PageInfo;
  /** Information to aid in pagination. */
  edges: Array<gql_EnvironmentEdge>;
};

/** An edge in a connection. */
export type gql_EnvironmentEdge = {
  __typename?: 'EnvironmentEdge';
  /** The item at the end of the edge */
  node: gql_Environment;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum gql_SortConnectionEnvironmentEnum {
  IdDesc = '_ID_DESC',
  IdAsc = '_ID_ASC'
}

/** List of items with pagination. */
export type gql_EnvironmentPagination = {
  __typename?: 'EnvironmentPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']>;
  /** Array of objects. */
  items?: Maybe<Array<gql_Environment>>;
  /** Information to aid in pagination. */
  pageInfo: gql_PaginationInfo;
};

export type gql_UserMe = {
  __typename?: 'UserMe';
  _id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type gql_ResolvedDynamicContent = {
  __typename?: 'ResolvedDynamicContent';
  content: Scalars['JSON'];
};

export type gql_ResolveDynamicContentPayload = {
  content: Scalars['String'];
  locale: Scalars['String'];
  projectId: Scalars['ID'];
  environmentId: Scalars['ID'];
  schema?: Maybe<Scalars['String']>;
};

export type gql_ContentHistory = {
  __typename?: 'ContentHistory';
  locale?: Maybe<Scalars['String']>;
  environmentId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<gql_ContentHistoryVersions>>;
};

export type gql_ContentHistoryVersions = {
  __typename?: 'ContentHistoryVersions';
  version?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  diff?: Maybe<Scalars['JSON']>;
};


export type gql_ContentHistoryPayload = {
  locale: Scalars['String'];
  sourceId: Scalars['ID'];
  environmentId: Scalars['ID'];
};

export type gql_LocalizedSlug = {
  __typename?: 'LocalizedSlug';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type gql_Mutation = {
  __typename?: 'Mutation';
  /** Create one document with mongoose defaults, setters, hooks and validation */
  tenantCreateOne?: Maybe<gql_CreateOneTenantPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  tenantCreateMany?: Maybe<gql_CreateManyTenantPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  tenantUpdateById?: Maybe<gql_UpdateByIdTenantPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  tenantUpdateOne?: Maybe<gql_UpdateOneTenantPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  tenantUpdateMany?: Maybe<gql_UpdateManyTenantPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  tenantRemoveById?: Maybe<gql_RemoveByIdTenantPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  tenantRemoveOne?: Maybe<gql_RemoveOneTenantPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  tenantRemoveMany?: Maybe<gql_RemoveManyTenantPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  userCreateOne?: Maybe<gql_CreateOneUserPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  userCreateMany?: Maybe<gql_CreateManyUserPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  userUpdateById?: Maybe<gql_UpdateByIdUserPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  userUpdateOne?: Maybe<gql_UpdateOneUserPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  userUpdateMany?: Maybe<gql_UpdateManyUserPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  userRemoveById?: Maybe<gql_RemoveByIdUserPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  userRemoveOne?: Maybe<gql_RemoveOneUserPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  userRemoveMany?: Maybe<gql_RemoveManyUserPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  projectCreateOne?: Maybe<gql_CreateOneProjectPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  projectCreateMany?: Maybe<gql_CreateManyProjectPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  projectUpdateById?: Maybe<gql_UpdateByIdProjectPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  projectUpdateOne?: Maybe<gql_UpdateOneProjectPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  projectUpdateMany?: Maybe<gql_UpdateManyProjectPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  projectRemoveById?: Maybe<gql_RemoveByIdProjectPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  projectRemoveOne?: Maybe<gql_RemoveOneProjectPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  projectRemoveMany?: Maybe<gql_RemoveManyProjectPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  sourceCreateOne?: Maybe<gql_CreateOneSourcePayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  sourceCreateMany?: Maybe<gql_CreateManySourcePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  sourceUpdateById?: Maybe<gql_UpdateByIdSourcePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  sourceUpdateOne?: Maybe<gql_UpdateOneSourcePayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  sourceUpdateMany?: Maybe<gql_UpdateManySourcePayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  sourceRemoveById?: Maybe<gql_RemoveByIdSourcePayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  sourceRemoveOne?: Maybe<gql_RemoveOneSourcePayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  sourceRemoveMany?: Maybe<gql_RemoveManySourcePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  pageCreateOne?: Maybe<gql_CreateOnePagePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  pageUpdateById?: Maybe<gql_UpdateByIdPagePayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  pageUpdateOne?: Maybe<gql_UpdateOnePagePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  contentCreateOne?: Maybe<gql_CreateOneContentPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  contentUpdateById?: Maybe<gql_UpdateByIdContentPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  contentRemoveById?: Maybe<gql_RemoveByIdContentPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  mediaCreateOne?: Maybe<gql_CreateOneMediaPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  mediaCreateMany?: Maybe<gql_CreateManyMediaPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  mediaUpdateById?: Maybe<gql_UpdateByIdMediaPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  mediaUpdateOne?: Maybe<gql_UpdateOneMediaPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  mediaUpdateMany?: Maybe<gql_UpdateManyMediaPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  mediaRemoveById?: Maybe<gql_RemoveByIdMediaPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  mediaRemoveOne?: Maybe<gql_RemoveOneMediaPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  mediaRemoveMany?: Maybe<gql_RemoveManyMediaPayload>;
  mediaUpload?: Maybe<gql_Media>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  componentCreateOne?: Maybe<gql_CreateOneComponentPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  componentCreateMany?: Maybe<gql_CreateManyComponentPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  componentUpdateById?: Maybe<gql_UpdateByIdComponentPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  componentUpdateOne?: Maybe<gql_UpdateOneComponentPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  componentUpdateMany?: Maybe<gql_UpdateManyComponentPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  componentRemoveById?: Maybe<gql_RemoveByIdComponentPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  componentRemoveOne?: Maybe<gql_RemoveOneComponentPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  componentRemoveMany?: Maybe<gql_RemoveManyComponentPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  environmentCreateOne?: Maybe<gql_CreateOneEnvironmentPayload>;
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  environmentCreateMany?: Maybe<gql_CreateManyEnvironmentPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  environmentUpdateById?: Maybe<gql_UpdateByIdEnvironmentPayload>;
  /** Update one document: 1) Retrieve one document via findOne. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  environmentUpdateOne?: Maybe<gql_UpdateOneEnvironmentPayload>;
  /** Update many documents without returning them: Use Query.update mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  environmentUpdateMany?: Maybe<gql_UpdateManyEnvironmentPayload>;
  /** Remove one document: 1) Retrieve one document and remove with hooks via findByIdAndRemove. 2) Return removed document. */
  environmentRemoveById?: Maybe<gql_RemoveByIdEnvironmentPayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  environmentRemoveOne?: Maybe<gql_RemoveOneEnvironmentPayload>;
  /** Remove many documents without returning them: Use Query.remove mongoose method. Do not apply mongoose defaults, setters, hooks and validation.  */
  environmentRemoveMany?: Maybe<gql_RemoveManyEnvironmentPayload>;
  login: gql_User;
  logout: Scalars['Boolean'];
  generateApiKey: Scalars['String'];
};


export type gql_MutationTenantCreateOneArgs = {
  record: gql_CreateOneTenantInput;
};


export type gql_MutationTenantCreateManyArgs = {
  records: Array<gql_CreateManyTenantInput>;
};


export type gql_MutationTenantUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdTenantInput;
};


export type gql_MutationTenantUpdateOneArgs = {
  record: gql_UpdateOneTenantInput;
  filter?: Maybe<gql_FilterUpdateOneTenantInput>;
  sort?: Maybe<gql_SortUpdateOneTenantInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationTenantUpdateManyArgs = {
  record: gql_UpdateManyTenantInput;
  filter?: Maybe<gql_FilterUpdateManyTenantInput>;
  sort?: Maybe<gql_SortUpdateManyTenantInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationTenantRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationTenantRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneTenantInput>;
  sort?: Maybe<gql_SortRemoveOneTenantInput>;
};


export type gql_MutationTenantRemoveManyArgs = {
  filter: gql_FilterRemoveManyTenantInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationUserCreateOneArgs = {
  record: gql_CreateOneUserInput;
};


export type gql_MutationUserCreateManyArgs = {
  records: Array<gql_CreateManyUserInput>;
};


export type gql_MutationUserUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdUserInput;
};


export type gql_MutationUserUpdateOneArgs = {
  record: gql_UpdateOneUserInput;
  filter?: Maybe<gql_FilterUpdateOneUserInput>;
  sort?: Maybe<gql_SortUpdateOneUserInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationUserUpdateManyArgs = {
  record: gql_UpdateManyUserInput;
  filter?: Maybe<gql_FilterUpdateManyUserInput>;
  sort?: Maybe<gql_SortUpdateManyUserInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationUserRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationUserRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneUserInput>;
  sort?: Maybe<gql_SortRemoveOneUserInput>;
};


export type gql_MutationUserRemoveManyArgs = {
  filter: gql_FilterRemoveManyUserInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationProjectCreateOneArgs = {
  record: gql_CreateOneProjectInput;
};


export type gql_MutationProjectCreateManyArgs = {
  records: Array<gql_CreateManyProjectInput>;
};


export type gql_MutationProjectUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdProjectInput;
};


export type gql_MutationProjectUpdateOneArgs = {
  record: gql_UpdateOneProjectInput;
  filter?: Maybe<gql_FilterUpdateOneProjectInput>;
  sort?: Maybe<gql_SortUpdateOneProjectInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationProjectUpdateManyArgs = {
  record: gql_UpdateManyProjectInput;
  filter?: Maybe<gql_FilterUpdateManyProjectInput>;
  sort?: Maybe<gql_SortUpdateManyProjectInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationProjectRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationProjectRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneProjectInput>;
  sort?: Maybe<gql_SortRemoveOneProjectInput>;
};


export type gql_MutationProjectRemoveManyArgs = {
  filter: gql_FilterRemoveManyProjectInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationSourceCreateOneArgs = {
  record: gql_CreateOneSourceInput;
};


export type gql_MutationSourceCreateManyArgs = {
  records: Array<gql_CreateManySourceInput>;
};


export type gql_MutationSourceUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdSourceInput;
};


export type gql_MutationSourceUpdateOneArgs = {
  record: gql_UpdateOneSourceInput;
  filter?: Maybe<gql_FilterUpdateOneSourceInput>;
  sort?: Maybe<gql_SortUpdateOneSourceInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationSourceUpdateManyArgs = {
  record: gql_UpdateManySourceInput;
  filter?: Maybe<gql_FilterUpdateManySourceInput>;
  sort?: Maybe<gql_SortUpdateManySourceInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationSourceRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationSourceRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneSourceInput>;
  sort?: Maybe<gql_SortRemoveOneSourceInput>;
};


export type gql_MutationSourceRemoveManyArgs = {
  filter: gql_FilterRemoveManySourceInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationPageCreateOneArgs = {
  record: gql_CreateOnePageInput;
};


export type gql_MutationPageUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdPageInput;
};


export type gql_MutationPageUpdateOneArgs = {
  record: gql_UpdateOnePageInput;
  filter?: Maybe<gql_FilterUpdateOnePageInput>;
  sort?: Maybe<gql_SortUpdateOnePageInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationContentCreateOneArgs = {
  record: gql_CreateOneContentInput;
};


export type gql_MutationContentUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdContentInput;
};


export type gql_MutationContentRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationMediaCreateOneArgs = {
  record: gql_CreateOneMediaInput;
};


export type gql_MutationMediaCreateManyArgs = {
  records: Array<gql_CreateManyMediaInput>;
};


export type gql_MutationMediaUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdMediaInput;
};


export type gql_MutationMediaUpdateOneArgs = {
  record: gql_UpdateOneMediaInput;
  filter?: Maybe<gql_FilterUpdateOneMediaInput>;
  sort?: Maybe<gql_SortUpdateOneMediaInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationMediaUpdateManyArgs = {
  record: gql_UpdateManyMediaInput;
  filter?: Maybe<gql_FilterUpdateManyMediaInput>;
  sort?: Maybe<gql_SortUpdateManyMediaInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationMediaRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationMediaRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneMediaInput>;
  sort?: Maybe<gql_SortRemoveOneMediaInput>;
};


export type gql_MutationMediaRemoveManyArgs = {
  filter: gql_FilterRemoveManyMediaInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationMediaUploadArgs = {
  file?: Maybe<Scalars['Upload']>;
  record?: Maybe<gql_MediaUploadRecordInput>;
};


export type gql_MutationComponentCreateOneArgs = {
  record: gql_CreateOneComponentInput;
};


export type gql_MutationComponentCreateManyArgs = {
  records: Array<gql_CreateManyComponentInput>;
};


export type gql_MutationComponentUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdComponentInput;
};


export type gql_MutationComponentUpdateOneArgs = {
  record: gql_UpdateOneComponentInput;
  filter?: Maybe<gql_FilterUpdateOneComponentInput>;
  sort?: Maybe<gql_SortUpdateOneComponentInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationComponentUpdateManyArgs = {
  record: gql_UpdateManyComponentInput;
  filter?: Maybe<gql_FilterUpdateManyComponentInput>;
  sort?: Maybe<gql_SortUpdateManyComponentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationComponentRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationComponentRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneComponentInput>;
  sort?: Maybe<gql_SortRemoveOneComponentInput>;
};


export type gql_MutationComponentRemoveManyArgs = {
  filter: gql_FilterRemoveManyComponentInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationEnvironmentCreateOneArgs = {
  record: gql_CreateOneEnvironmentInput;
};


export type gql_MutationEnvironmentCreateManyArgs = {
  records: Array<gql_CreateManyEnvironmentInput>;
};


export type gql_MutationEnvironmentUpdateByIdArgs = {
  _id: Scalars['MongoID'];
  record: gql_UpdateByIdEnvironmentInput;
};


export type gql_MutationEnvironmentUpdateOneArgs = {
  record: gql_UpdateOneEnvironmentInput;
  filter?: Maybe<gql_FilterUpdateOneEnvironmentInput>;
  sort?: Maybe<gql_SortUpdateOneEnvironmentInput>;
  skip?: Maybe<Scalars['Int']>;
};


export type gql_MutationEnvironmentUpdateManyArgs = {
  record: gql_UpdateManyEnvironmentInput;
  filter?: Maybe<gql_FilterUpdateManyEnvironmentInput>;
  sort?: Maybe<gql_SortUpdateManyEnvironmentInput>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationEnvironmentRemoveByIdArgs = {
  _id: Scalars['MongoID'];
};


export type gql_MutationEnvironmentRemoveOneArgs = {
  filter?: Maybe<gql_FilterRemoveOneEnvironmentInput>;
  sort?: Maybe<gql_SortRemoveOneEnvironmentInput>;
};


export type gql_MutationEnvironmentRemoveManyArgs = {
  filter: gql_FilterRemoveManyEnvironmentInput;
  limit?: Maybe<Scalars['Int']>;
};


export type gql_MutationLoginArgs = {
  credentials: gql_CredentialsInput;
};

export type gql_CreateOneTenantPayload = {
  __typename?: 'CreateOneTenantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Tenant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneTenantInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  projectsLimit: Scalars['Float'];
  sourcesPerProjectLimit: Scalars['Float'];
  mediaLibraryTotalKBLimit: Scalars['Float'];
  owner: Scalars['MongoID'];
};

export type gql_CreateManyTenantPayload = {
  __typename?: 'CreateManyTenantPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<gql_Tenant>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManyTenantInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  projectsLimit: Scalars['Float'];
  sourcesPerProjectLimit: Scalars['Float'];
  mediaLibraryTotalKBLimit: Scalars['Float'];
  owner: Scalars['MongoID'];
};

export type gql_UpdateByIdTenantPayload = {
  __typename?: 'UpdateByIdTenantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Tenant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdTenantInput = {
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateOneTenantPayload = {
  __typename?: 'UpdateOneTenantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Tenant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneTenantInput = {
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateOneTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneTenantInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneTenantOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateOneTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterUpdateOneTenantOwnerOperatorsInput>;
};

export type gql_FilterUpdateOneTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneTenantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export type gql_UpdateManyTenantPayload = {
  __typename?: 'UpdateManyTenantPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManyTenantInput = {
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateManyTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManyTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManyTenantInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManyTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManyTenantOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManyTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateManyTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterUpdateManyTenantOwnerOperatorsInput>;
};

export type gql_FilterUpdateManyTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManyTenantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export type gql_RemoveByIdTenantPayload = {
  __typename?: 'RemoveByIdTenantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Tenant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneTenantPayload = {
  __typename?: 'RemoveOneTenantPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Tenant>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneTenantInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneTenantOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveOneTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterRemoveOneTenantOwnerOperatorsInput>;
};

export type gql_FilterRemoveOneTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneTenantInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC'
}

export type gql_RemoveManyTenantPayload = {
  __typename?: 'RemoveManyTenantPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManyTenantInput = {
  _id?: Maybe<Scalars['MongoID']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  projectsLimit?: Maybe<Scalars['Float']>;
  sourcesPerProjectLimit?: Maybe<Scalars['Float']>;
  mediaLibraryTotalKBLimit?: Maybe<Scalars['Float']>;
  owner?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManyTenantOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManyTenantInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManyTenantInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManyTenantOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManyTenant_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveManyTenantNameOperatorsInput>;
  owner?: Maybe<gql_FilterRemoveManyTenantOwnerOperatorsInput>;
};

export type gql_FilterRemoveManyTenant_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyTenantNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyTenantOwnerOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_CreateOneUserPayload = {
  __typename?: 'CreateOneUserPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_User>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type gql_CreateManyUserPayload = {
  __typename?: 'CreateManyUserPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<gql_User>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManyUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type gql_UpdateByIdUserPayload = {
  __typename?: 'UpdateByIdUserPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_User>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type gql_UpdateOneUserPayload = {
  __typename?: 'UpdateOneUserPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_User>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneUserInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneUserOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneUser_IdOperatorsInput>;
};

export type gql_FilterUpdateOneUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type gql_UpdateManyUserPayload = {
  __typename?: 'UpdateManyUserPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManyUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManyUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManyUserInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManyUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManyUserOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManyUser_IdOperatorsInput>;
};

export type gql_FilterUpdateManyUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManyUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type gql_RemoveByIdUserPayload = {
  __typename?: 'RemoveByIdUserPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_User>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneUserPayload = {
  __typename?: 'RemoveOneUserPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_User>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneUserInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneUserOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneUser_IdOperatorsInput>;
};

export type gql_FilterRemoveOneUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type gql_RemoveManyUserPayload = {
  __typename?: 'RemoveManyUserPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManyUserInput = {
  _id?: Maybe<Scalars['MongoID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManyUserOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManyUserInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManyUserInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManyUserOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManyUser_IdOperatorsInput>;
};

export type gql_FilterRemoveManyUser_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_CreateOneProjectPayload = {
  __typename?: 'CreateOneProjectPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Project>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneProjectInput = {
  tenant: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings: gql_ProjectSettingsInput;
  defaultLocale: Scalars['String'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_ProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_CreateManyProjectPayload = {
  __typename?: 'CreateManyProjectPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<gql_Project>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManyProjectInput = {
  tenant: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings: gql_ProjectSettingsInput;
  defaultLocale: Scalars['String'];
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_UpdateByIdProjectPayload = {
  __typename?: 'UpdateByIdProjectPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Project>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_UpdateByIdProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_UpdateByIdProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateOneProjectPayload = {
  __typename?: 'UpdateOneProjectPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Project>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_UpdateOneProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_UpdateOneProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateOneProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterUpdateOneProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneProjectInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneProjectInput>>;
};

export type gql_FilterUpdateOneProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneProjectOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateOneProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterUpdateOneProjectPathOperatorsInput>;
};

export type gql_FilterUpdateOneProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneProjectInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC'
}

export type gql_UpdateManyProjectPayload = {
  __typename?: 'UpdateManyProjectPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManyProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_UpdateManyProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_UpdateManyProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateManyProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterUpdateManyProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManyProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManyProjectInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManyProjectInput>>;
};

export type gql_FilterUpdateManyProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManyProjectOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManyProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateManyProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterUpdateManyProjectPathOperatorsInput>;
};

export type gql_FilterUpdateManyProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManyProjectInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC'
}

export type gql_RemoveByIdProjectPayload = {
  __typename?: 'RemoveByIdProjectPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Project>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneProjectPayload = {
  __typename?: 'RemoveOneProjectPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Project>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterRemoveOneProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneProjectInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneProjectInput>>;
};

export type gql_FilterRemoveOneProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneProjectOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveOneProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterRemoveOneProjectPathOperatorsInput>;
};

export type gql_FilterRemoveOneProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneProjectInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC'
}

export type gql_RemoveManyProjectPayload = {
  __typename?: 'RemoveManyProjectPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManyProjectInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  defaultContentSchema?: Maybe<Scalars['JSON']>;
  defaultContentSchemaUrl?: Maybe<Scalars['String']>;
  settings?: Maybe<gql_FilterRemoveManyProjectSettingsInput>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultSeo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManyProjectOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManyProjectInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManyProjectInput>>;
};

export type gql_FilterRemoveManyProjectSettingsInput = {
  previewUrl?: Maybe<Scalars['String']>;
  livePreviewOrigin?: Maybe<Scalars['String']>;
  developerPreviewUrl?: Maybe<Scalars['String']>;
  developerPreviewOrigin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManyProjectOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManyProject_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveManyProjectNameOperatorsInput>;
  path?: Maybe<gql_FilterRemoveManyProjectPathOperatorsInput>;
};

export type gql_FilterRemoveManyProject_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyProjectNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyProjectPathOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_CreateOneSourcePayload = {
  __typename?: 'CreateOneSourcePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_SourceInterface>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneSourceInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind: gql_EnumDKeySourceKind;
};

export type gql_SourceLocalesInput = {
  slug: Scalars['String'];
  locale: Scalars['String'];
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_CreateManySourcePayload = {
  __typename?: 'CreateManySourcePayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records: Array<Maybe<gql_SourceInterface>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManySourceInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind: gql_EnumDKeySourceKind;
};

export type gql_UpdateByIdSourcePayload = {
  __typename?: 'UpdateByIdSourcePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_SourceInterface>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdSourceInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_UpdateByIdSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
};

export type gql_UpdateByIdSourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateOneSourcePayload = {
  __typename?: 'UpdateOneSourcePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_SourceInterface>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneSourceInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_UpdateOneSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
};

export type gql_UpdateOneSourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateOneSourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterUpdateOneSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneSourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneSourceInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneSourceInput>>;
};

export type gql_FilterUpdateOneSourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneSourceOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneSource_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateOneSourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterUpdateOneSourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterUpdateOneSourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterUpdateOneSourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterUpdateOneSourceIsGlobalOperatorsInput>;
};

export type gql_FilterUpdateOneSource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneSourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneSourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneSourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneSourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneSourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneSourceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_UpdateManySourcePayload = {
  __typename?: 'UpdateManySourcePayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManySourceInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_UpdateManySourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
};

export type gql_UpdateManySourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateManySourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterUpdateManySourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManySourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManySourceInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManySourceInput>>;
};

export type gql_FilterUpdateManySourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManySourceOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManySource_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateManySourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterUpdateManySourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterUpdateManySourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterUpdateManySourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterUpdateManySourceIsGlobalOperatorsInput>;
};

export type gql_FilterUpdateManySource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManySourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManySourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManySourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManySourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManySourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManySourceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_RemoveByIdSourcePayload = {
  __typename?: 'RemoveByIdSourcePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_SourceInterface>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneSourcePayload = {
  __typename?: 'RemoveOneSourcePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_SourceInterface>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneSourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterRemoveOneSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneSourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneSourceInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneSourceInput>>;
};

export type gql_FilterRemoveOneSourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneSourceOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneSource_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveOneSourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterRemoveOneSourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterRemoveOneSourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterRemoveOneSourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterRemoveOneSourceIsGlobalOperatorsInput>;
};

export type gql_FilterRemoveOneSource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneSourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneSourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneSourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneSourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneSourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneSourceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_RemoveManySourcePayload = {
  __typename?: 'RemoveManySourcePayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManySourceInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterRemoveManySourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<gql_EnumDKeySourceKind>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManySourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManySourceInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManySourceInput>>;
};

export type gql_FilterRemoveManySourceLocalesInput = {
  slug?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManySourceOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManySource_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveManySourceNameOperatorsInput>;
  slug?: Maybe<gql_FilterRemoveManySourceSlugOperatorsInput>;
  defaultLocale?: Maybe<gql_FilterRemoveManySourceDefaultLocaleOperatorsInput>;
  isDefaultSource?: Maybe<gql_FilterRemoveManySourceIsDefaultSourceOperatorsInput>;
  isGlobal?: Maybe<gql_FilterRemoveManySourceIsGlobalOperatorsInput>;
};

export type gql_FilterRemoveManySource_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManySourceNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManySourceSlugOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManySourceDefaultLocaleOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManySourceIsDefaultSourceOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManySourceIsGlobalOperatorsInput = {
  gt?: Maybe<Scalars['Boolean']>;
  gte?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Boolean']>;
  lte?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
  in?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  nin?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_CreateOnePagePayload = {
  __typename?: 'CreateOnePagePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Page>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOnePageInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_SourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project: Scalars['MongoID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['MongoID']>;
  seo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_UpdateByIdPagePayload = {
  __typename?: 'UpdateByIdPagePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Page>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdPageInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_UpdateByIdSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['MongoID']>;
  seo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_UpdateOnePagePayload = {
  __typename?: 'UpdateOnePagePayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Page>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOnePageInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_UpdateOneSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['MongoID']>;
  seo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};

export type gql_FilterUpdateOnePageInput = {
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  defaultLocale?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<Maybe<gql_FilterUpdateOneSourceLocalesInput>>>;
  schemaMode?: Maybe<gql_EnumSourceSchemaMode>;
  contentSchema?: Maybe<Scalars['JSON']>;
  contentSchemaUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['MongoID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDefaultSource?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<Scalars['MongoID']>;
  seo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneSourceOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneSourceInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneSourceInput>>;
};

export enum gql_SortUpdateOnePageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  DefaultlocaleAsc = 'DEFAULTLOCALE_ASC',
  DefaultlocaleDesc = 'DEFAULTLOCALE_DESC',
  IsdefaultsourceAsc = 'ISDEFAULTSOURCE_ASC',
  IsdefaultsourceDesc = 'ISDEFAULTSOURCE_DESC',
  IsglobalAsc = 'ISGLOBAL_ASC',
  IsglobalDesc = 'ISGLOBAL_DESC'
}

export type gql_CreateOneContentPayload = {
  __typename?: 'CreateOneContentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Content>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneContentInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  content: Scalars['JSON'];
  locale: Scalars['String'];
  source: Scalars['MongoID'];
  environment: Scalars['MongoID'];
};

export type gql_UpdateByIdContentPayload = {
  __typename?: 'UpdateByIdContentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Content>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdContentInput = {
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  content?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
};

export type gql_RemoveByIdContentPayload = {
  __typename?: 'RemoveByIdContentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Content>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneMediaPayload = {
  __typename?: 'CreateOneMediaPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Media>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneMediaInput = {
  tenant: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  mimeType: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
};

export type gql_CreateManyMediaPayload = {
  __typename?: 'CreateManyMediaPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<gql_Media>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManyMediaInput = {
  tenant: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  mimeType: Scalars['String'];
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
};

export type gql_UpdateByIdMediaPayload = {
  __typename?: 'UpdateByIdMediaPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Media>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
};

export type gql_UpdateOneMediaPayload = {
  __typename?: 'UpdateOneMediaPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Media>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
};

export type gql_FilterUpdateOneMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneMediaInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneMediaOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateOneMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterUpdateOneMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterUpdateOneMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterUpdateOneMediaParentOperatorsInput>;
};

export type gql_FilterUpdateOneMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MimetypeAsc = 'MIMETYPE_ASC',
  MimetypeDesc = 'MIMETYPE_DESC',
  AlternativetextAsc = 'ALTERNATIVETEXT_ASC',
  AlternativetextDesc = 'ALTERNATIVETEXT_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC'
}

export type gql_UpdateManyMediaPayload = {
  __typename?: 'UpdateManyMediaPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManyMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
};

export type gql_FilterUpdateManyMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManyMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManyMediaInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManyMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManyMediaOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManyMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateManyMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterUpdateManyMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterUpdateManyMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterUpdateManyMediaParentOperatorsInput>;
};

export type gql_FilterUpdateManyMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManyMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MimetypeAsc = 'MIMETYPE_ASC',
  MimetypeDesc = 'MIMETYPE_DESC',
  AlternativetextAsc = 'ALTERNATIVETEXT_ASC',
  AlternativetextDesc = 'ALTERNATIVETEXT_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC'
}

export type gql_RemoveByIdMediaPayload = {
  __typename?: 'RemoveByIdMediaPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Media>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneMediaPayload = {
  __typename?: 'RemoveOneMediaPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Media>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneMediaInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneMediaOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveOneMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterRemoveOneMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterRemoveOneMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterRemoveOneMediaParentOperatorsInput>;
};

export type gql_FilterRemoveOneMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  MimetypeAsc = 'MIMETYPE_ASC',
  MimetypeDesc = 'MIMETYPE_DESC',
  AlternativetextAsc = 'ALTERNATIVETEXT_ASC',
  AlternativetextDesc = 'ALTERNATIVETEXT_DESC',
  ParentAsc = 'PARENT_ASC',
  ParentDesc = 'PARENT_DESC'
}

export type gql_RemoveManyMediaPayload = {
  __typename?: 'RemoveManyMediaPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManyMediaInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['MongoID']>;
  urlSmall?: Maybe<Scalars['String']>;
  urlMedium?: Maybe<Scalars['String']>;
  urlLarge?: Maybe<Scalars['String']>;
  smallWidth?: Maybe<Scalars['Float']>;
  smallHeight?: Maybe<Scalars['Float']>;
  mediumWidth?: Maybe<Scalars['Float']>;
  mediumHeight?: Maybe<Scalars['Float']>;
  largeWidth?: Maybe<Scalars['Float']>;
  largeHeight?: Maybe<Scalars['Float']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManyMediaOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManyMediaInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManyMediaInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManyMediaOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManyMedia_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveManyMediaNameOperatorsInput>;
  mimeType?: Maybe<gql_FilterRemoveManyMediaMimeTypeOperatorsInput>;
  alternativeText?: Maybe<gql_FilterRemoveManyMediaAlternativeTextOperatorsInput>;
  parent?: Maybe<gql_FilterRemoveManyMediaParentOperatorsInput>;
};

export type gql_FilterRemoveManyMedia_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyMediaNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyMediaMimeTypeOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyMediaAlternativeTextOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyMediaParentOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};


export type gql_MediaUploadRecordInput = {
  name?: Maybe<Scalars['String']>;
  alternativeText?: Maybe<Scalars['Int']>;
  parentDirectory?: Maybe<Scalars['ID']>;
  reviewerId?: Maybe<Scalars['ID']>;
};

export type gql_CreateOneComponentPayload = {
  __typename?: 'CreateOneComponentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Component>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  partialSchema?: Maybe<Scalars['JSON']>;
  project: Scalars['MongoID'];
  environment: Scalars['MongoID'];
};

export type gql_CreateManyComponentPayload = {
  __typename?: 'CreateManyComponentPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<gql_Component>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManyComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  partialSchema?: Maybe<Scalars['JSON']>;
  project: Scalars['MongoID'];
  environment: Scalars['MongoID'];
};

export type gql_UpdateByIdComponentPayload = {
  __typename?: 'UpdateByIdComponentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Component>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateOneComponentPayload = {
  __typename?: 'UpdateOneComponentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Component>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateOneComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneComponentInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneComponentOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateOneComponentNameOperatorsInput>;
};

export type gql_FilterUpdateOneComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneComponentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_UpdateManyComponentPayload = {
  __typename?: 'UpdateManyComponentPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManyComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateManyComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManyComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManyComponentInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManyComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManyComponentOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManyComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateManyComponentNameOperatorsInput>;
};

export type gql_FilterUpdateManyComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManyComponentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_RemoveByIdComponentPayload = {
  __typename?: 'RemoveByIdComponentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Component>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneComponentPayload = {
  __typename?: 'RemoveOneComponentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Component>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneComponentInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneComponentOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveOneComponentNameOperatorsInput>;
};

export type gql_FilterRemoveOneComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneComponentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_RemoveManyComponentPayload = {
  __typename?: 'RemoveManyComponentPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManyComponentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  partialSchema?: Maybe<Scalars['JSON']>;
  project?: Maybe<Scalars['MongoID']>;
  environment?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManyComponentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManyComponentInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManyComponentInput>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManyComponentOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManyComponent_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveManyComponentNameOperatorsInput>;
};

export type gql_FilterRemoveManyComponent_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyComponentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_CreateOneEnvironmentPayload = {
  __typename?: 'CreateOneEnvironmentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Created document */
  record?: Maybe<gql_Environment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateOneEnvironmentInput = {
  tenant: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_EnvironmentPreviewSettingsInput>;
  project: Scalars['MongoID'];
};

export type gql_EnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_CreateManyEnvironmentPayload = {
  __typename?: 'CreateManyEnvironmentPayload';
  /** Documents IDs */
  recordIds: Array<Scalars['MongoID']>;
  /** Created documents */
  records?: Maybe<Array<gql_Environment>>;
  /** Number of created documents */
  createdCount: Scalars['Int'];
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_CreateManyEnvironmentInput = {
  tenant: Scalars['MongoID'];
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_EnvironmentPreviewSettingsInput>;
  project: Scalars['MongoID'];
};

export type gql_UpdateByIdEnvironmentPayload = {
  __typename?: 'UpdateByIdEnvironmentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Environment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateByIdEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_UpdateByIdEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateByIdEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateOneEnvironmentPayload = {
  __typename?: 'UpdateOneEnvironmentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Updated document */
  record?: Maybe<gql_Environment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateOneEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_UpdateOneEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateOneEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateOneEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterUpdateOneEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateOneEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateOneEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterUpdateOneEnvironmentInput>>;
};

export type gql_FilterUpdateOneEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateOneEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateOneEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateOneEnvironmentNameOperatorsInput>;
};

export type gql_FilterUpdateOneEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateOneEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateOneEnvironmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_UpdateManyEnvironmentPayload = {
  __typename?: 'UpdateManyEnvironmentPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_UpdateManyEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_UpdateManyEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
};

export type gql_UpdateManyEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

export type gql_FilterUpdateManyEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterUpdateManyEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterUpdateManyEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterUpdateManyEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterUpdateManyEnvironmentInput>>;
};

export type gql_FilterUpdateManyEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterUpdateManyEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterUpdateManyEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterUpdateManyEnvironmentNameOperatorsInput>;
};

export type gql_FilterUpdateManyEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterUpdateManyEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortUpdateManyEnvironmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_RemoveByIdEnvironmentPayload = {
  __typename?: 'RemoveByIdEnvironmentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Environment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_RemoveOneEnvironmentPayload = {
  __typename?: 'RemoveOneEnvironmentPayload';
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']>;
  /** Removed document */
  record?: Maybe<gql_Environment>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveOneEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterRemoveOneEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveOneEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveOneEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterRemoveOneEnvironmentInput>>;
};

export type gql_FilterRemoveOneEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveOneEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveOneEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveOneEnvironmentNameOperatorsInput>;
};

export type gql_FilterRemoveOneEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveOneEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export enum gql_SortRemoveOneEnvironmentInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type gql_RemoveManyEnvironmentPayload = {
  __typename?: 'RemoveManyEnvironmentPayload';
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>;
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<gql_ErrorInterface>;
};

export type gql_FilterRemoveManyEnvironmentInput = {
  tenant?: Maybe<Scalars['MongoID']>;
  _id?: Maybe<Scalars['MongoID']>;
  updatedAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  previewSettings?: Maybe<gql_FilterRemoveManyEnvironmentPreviewSettingsInput>;
  project?: Maybe<Scalars['MongoID']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<gql_FilterRemoveManyEnvironmentOperatorsInput>;
  OR?: Maybe<Array<gql_FilterRemoveManyEnvironmentInput>>;
  AND?: Maybe<Array<gql_FilterRemoveManyEnvironmentInput>>;
};

export type gql_FilterRemoveManyEnvironmentPreviewSettingsInput = {
  path?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  _id?: Maybe<Scalars['MongoID']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type gql_FilterRemoveManyEnvironmentOperatorsInput = {
  _id?: Maybe<gql_FilterRemoveManyEnvironment_IdOperatorsInput>;
  name?: Maybe<gql_FilterRemoveManyEnvironmentNameOperatorsInput>;
};

export type gql_FilterRemoveManyEnvironment_IdOperatorsInput = {
  gt?: Maybe<Scalars['MongoID']>;
  gte?: Maybe<Scalars['MongoID']>;
  lt?: Maybe<Scalars['MongoID']>;
  lte?: Maybe<Scalars['MongoID']>;
  ne?: Maybe<Scalars['MongoID']>;
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_FilterRemoveManyEnvironmentNameOperatorsInput = {
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  regex?: Maybe<Scalars['RegExpAsString']>;
  exists?: Maybe<Scalars['Boolean']>;
};

export type gql_CredentialsInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type gql_HyProjectFragment = (
  { __typename?: 'Project' }
  & Pick<gql_Project, 'name' | 'locales' | 'defaultLocale' | 'updatedAt' | 'createdAt'>
  & { id: gql_Project['_id'], schema: gql_Project['defaultContentSchema'], schemaUrl: gql_Project['defaultContentSchemaUrl'] }
  & { settings: (
    { __typename?: 'ProjectSettings' }
    & Pick<gql_ProjectSettings, 'previewUrl' | 'livePreviewOrigin' | 'developerPreviewUrl' | 'developerPreviewOrigin'>
  ) }
);

type gql_HySource_Source_Fragment = (
  { __typename?: 'Source' }
  & Pick<gql_Source, 'name' | 'contentSchema' | 'contentSchemaUrl' | 'slug' | 'defaultLocale' | 'schemaMode' | 'isPublic' | 'isDefaultSource' | 'isGlobal' | 'createdAt' | 'updatedAt' | 'kind'>
  & { id: gql_Source['_id'], projectId: gql_Source['project'] }
  & { locales?: Maybe<Array<Maybe<(
    { __typename?: 'SourceLocales' }
    & Pick<gql_SourceLocales, 'slug' | 'locale'>
  )>>> }
);

type gql_HySource_BasicSource_Fragment = (
  { __typename?: 'BasicSource' }
  & Pick<gql_BasicSource, 'name' | 'contentSchema' | 'contentSchemaUrl' | 'slug' | 'defaultLocale' | 'schemaMode' | 'isPublic' | 'isDefaultSource' | 'isGlobal' | 'createdAt' | 'updatedAt' | 'kind'>
  & { id: gql_BasicSource['_id'], projectId: gql_BasicSource['project'] }
  & { locales?: Maybe<Array<Maybe<(
    { __typename?: 'SourceLocales' }
    & Pick<gql_SourceLocales, 'slug' | 'locale'>
  )>>> }
);

type gql_HySource_Layout_Fragment = (
  { __typename?: 'Layout' }
  & Pick<gql_Layout, 'name' | 'contentSchema' | 'contentSchemaUrl' | 'slug' | 'defaultLocale' | 'schemaMode' | 'isPublic' | 'isDefaultSource' | 'isGlobal' | 'createdAt' | 'updatedAt' | 'kind'>
  & { id: gql_Layout['_id'], projectId: gql_Layout['project'] }
  & { locales?: Maybe<Array<Maybe<(
    { __typename?: 'SourceLocales' }
    & Pick<gql_SourceLocales, 'slug' | 'locale'>
  )>>> }
);

type gql_HySource_Page_Fragment = (
  { __typename?: 'Page' }
  & Pick<gql_Page, 'layout' | 'seo' | 'name' | 'contentSchema' | 'contentSchemaUrl' | 'slug' | 'defaultLocale' | 'schemaMode' | 'isPublic' | 'isDefaultSource' | 'isGlobal' | 'createdAt' | 'updatedAt' | 'kind'>
  & { id: gql_Page['_id'], projectId: gql_Page['project'] }
  & { locales?: Maybe<Array<Maybe<(
    { __typename?: 'SourceLocales' }
    & Pick<gql_SourceLocales, 'slug' | 'locale'>
  )>>> }
);

type gql_HySource_Folder_Fragment = (
  { __typename?: 'Folder' }
  & Pick<gql_Folder, 'name' | 'contentSchema' | 'contentSchemaUrl' | 'slug' | 'defaultLocale' | 'schemaMode' | 'isPublic' | 'isDefaultSource' | 'isGlobal' | 'createdAt' | 'updatedAt' | 'kind'>
  & { id: gql_Folder['_id'], projectId: gql_Folder['project'] }
  & { locales?: Maybe<Array<Maybe<(
    { __typename?: 'SourceLocales' }
    & Pick<gql_SourceLocales, 'slug' | 'locale'>
  )>>> }
);

export type gql_HySourceFragment = gql_HySource_Source_Fragment | gql_HySource_BasicSource_Fragment | gql_HySource_Layout_Fragment | gql_HySource_Page_Fragment | gql_HySource_Folder_Fragment;

export type gql_HyContentFragment = (
  { __typename?: 'Content' }
  & Pick<gql_Content, 'locale' | 'content' | 'createdAt' | 'updatedAt'>
  & { id: gql_Content['_id'], sourceId: gql_Content['source'] }
);

export type gql_HyEnvironmentFragment = (
  { __typename?: 'Environment' }
  & Pick<gql_Environment, 'name' | 'settings' | 'createdAt' | 'updatedAt'>
  & { id: gql_Environment['_id'], projectId: gql_Environment['project'] }
  & { previewSettings?: Maybe<(
    { __typename?: 'EnvironmentPreviewSettings' }
    & Pick<gql_EnvironmentPreviewSettings, 'path' | 'origin'>
  )> }
);

export type gql_HyUserFragment = (
  { __typename?: 'User' }
  & Pick<gql_User, '_id' | 'firstName' | 'lastName' | 'email' | 'isActive'>
);

export type gql_HyUserMeFragment = (
  { __typename?: 'UserMe' }
  & Pick<gql_UserMe, '_id' | 'firstName' | 'lastName' | 'email'>
);

export type gql_GetProjectByIdQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type gql_GetProjectByIdQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & gql_HyProjectFragment
  )> }
);

export type gql_GetListProjectsQueryVariables = Exact<{
  sort?: Maybe<gql_SortFindManyProjectInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyProjectInput>;
}>;


export type gql_GetListProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<(
    { __typename?: 'ProjectPagination' }
    & Pick<gql_ProjectPagination, 'count'>
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<gql_PaginationInfo, 'currentPage' | 'perPage' | 'pageCount' | 'itemCount' | 'hasNextPage' | 'hasPreviousPage'>
    ), items?: Maybe<Array<(
      { __typename?: 'Project' }
      & gql_HyProjectFragment
    )>> }
  )> }
);

export type gql_GetSourceByIdQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type gql_GetSourceByIdQuery = (
  { __typename?: 'Query' }
  & { sourceById?: Maybe<(
    { __typename?: 'Source' }
    & gql_HySource_Source_Fragment
  ) | (
    { __typename?: 'BasicSource' }
    & gql_HySource_BasicSource_Fragment
  ) | (
    { __typename?: 'Layout' }
    & gql_HySource_Layout_Fragment
  ) | (
    { __typename?: 'Page' }
    & gql_HySource_Page_Fragment
  ) | (
    { __typename?: 'Folder' }
    & gql_HySource_Folder_Fragment
  )> }
);

export type gql_GetListSourcesQueryVariables = Exact<{
  sort?: Maybe<gql_SortFindManySourceInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManySourceInput>;
}>;


export type gql_GetListSourcesQuery = (
  { __typename?: 'Query' }
  & { sourcePagination?: Maybe<(
    { __typename?: 'SourcePagination' }
    & Pick<gql_SourcePagination, 'count'>
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<gql_PaginationInfo, 'currentPage' | 'perPage' | 'pageCount' | 'itemCount' | 'hasNextPage' | 'hasPreviousPage'>
    ), items?: Maybe<Array<Maybe<(
      { __typename?: 'Source' }
      & gql_HySource_Source_Fragment
    ) | (
      { __typename?: 'BasicSource' }
      & gql_HySource_BasicSource_Fragment
    ) | (
      { __typename?: 'Layout' }
      & gql_HySource_Layout_Fragment
    ) | (
      { __typename?: 'Page' }
      & gql_HySource_Page_Fragment
    ) | (
      { __typename?: 'Folder' }
      & gql_HySource_Folder_Fragment
    )>>> }
  )> }
);

export type gql_CreateSourceMutationVariables = Exact<{
  record: gql_CreateOneSourceInput;
}>;


export type gql_CreateSourceMutation = (
  { __typename?: 'Mutation' }
  & { sourceCreateOne?: Maybe<(
    { __typename?: 'CreateOneSourcePayload' }
    & Pick<gql_CreateOneSourcePayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Source' }
      & gql_HySource_Source_Fragment
    ) | (
      { __typename?: 'BasicSource' }
      & gql_HySource_BasicSource_Fragment
    ) | (
      { __typename?: 'Layout' }
      & gql_HySource_Layout_Fragment
    ) | (
      { __typename?: 'Page' }
      & gql_HySource_Page_Fragment
    ) | (
      { __typename?: 'Folder' }
      & gql_HySource_Folder_Fragment
    )> }
  )> }
);

export type gql_UpdateSourceMutationVariables = Exact<{
  id: Scalars['MongoID'];
  record: gql_UpdateByIdSourceInput;
}>;


export type gql_UpdateSourceMutation = (
  { __typename?: 'Mutation' }
  & { sourceUpdateById?: Maybe<(
    { __typename?: 'UpdateByIdSourcePayload' }
    & Pick<gql_UpdateByIdSourcePayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Source' }
      & gql_HySource_Source_Fragment
    ) | (
      { __typename?: 'BasicSource' }
      & gql_HySource_BasicSource_Fragment
    ) | (
      { __typename?: 'Layout' }
      & gql_HySource_Layout_Fragment
    ) | (
      { __typename?: 'Page' }
      & gql_HySource_Page_Fragment
    ) | (
      { __typename?: 'Folder' }
      & gql_HySource_Folder_Fragment
    )> }
  )> }
);

export type gql_UpdatePageMutationVariables = Exact<{
  id: Scalars['MongoID'];
  record: gql_UpdateByIdPageInput;
}>;


export type gql_UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { pageUpdateById?: Maybe<(
    { __typename?: 'UpdateByIdPagePayload' }
    & Pick<gql_UpdateByIdPagePayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Page' }
      & gql_HySource_Page_Fragment
    )> }
  )> }
);

export type gql_DeleteSourceMutationVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type gql_DeleteSourceMutation = (
  { __typename?: 'Mutation' }
  & { sourceRemoveById?: Maybe<(
    { __typename?: 'RemoveByIdSourcePayload' }
    & Pick<gql_RemoveByIdSourcePayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )> }
  )> }
);

export type gql_GetContentQueryVariables = Exact<{
  sourceId?: Maybe<Scalars['MongoID']>;
  locale?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['MongoID']>;
}>;


export type gql_GetContentQuery = (
  { __typename?: 'Query' }
  & { contentOne?: Maybe<(
    { __typename?: 'Content' }
    & gql_HyContentFragment
  )> }
);

export type gql_CreateContentMutationVariables = Exact<{
  record: gql_CreateOneContentInput;
}>;


export type gql_CreateContentMutation = (
  { __typename?: 'Mutation' }
  & { contentCreateOne?: Maybe<(
    { __typename?: 'CreateOneContentPayload' }
    & Pick<gql_CreateOneContentPayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Content' }
      & gql_HyContentFragment
    )> }
  )> }
);

export type gql_UpdateContentMutationVariables = Exact<{
  id: Scalars['MongoID'];
  record: gql_UpdateByIdContentInput;
}>;


export type gql_UpdateContentMutation = (
  { __typename?: 'Mutation' }
  & { contentUpdateById?: Maybe<(
    { __typename?: 'UpdateByIdContentPayload' }
    & Pick<gql_UpdateByIdContentPayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Content' }
      & gql_HyContentFragment
    )> }
  )> }
);

export type gql_GetEnvironmentByIdQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type gql_GetEnvironmentByIdQuery = (
  { __typename?: 'Query' }
  & { environmentById?: Maybe<(
    { __typename?: 'Environment' }
    & gql_HyEnvironmentFragment
  )> }
);

export type gql_GetEnvironmentByNameQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type gql_GetEnvironmentByNameQuery = (
  { __typename?: 'Query' }
  & { environmentOne?: Maybe<(
    { __typename?: 'Environment' }
    & gql_HyEnvironmentFragment
  )> }
);

export type gql_GetListEnvironmentsQueryVariables = Exact<{
  sort?: Maybe<gql_SortFindManyEnvironmentInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyEnvironmentInput>;
}>;


export type gql_GetListEnvironmentsQuery = (
  { __typename?: 'Query' }
  & { environmentPagination?: Maybe<(
    { __typename?: 'EnvironmentPagination' }
    & Pick<gql_EnvironmentPagination, 'count'>
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<gql_PaginationInfo, 'currentPage' | 'perPage' | 'pageCount' | 'itemCount' | 'hasNextPage' | 'hasPreviousPage'>
    ), items?: Maybe<Array<(
      { __typename?: 'Environment' }
      & gql_HyEnvironmentFragment
    )>> }
  )> }
);

export type gql_ResolveDynamicContentQueryVariables = Exact<{
  payload: gql_ResolveDynamicContentPayload;
}>;


export type gql_ResolveDynamicContentQuery = (
  { __typename?: 'Query' }
  & { resolveDynamicContent: (
    { __typename?: 'ResolvedDynamicContent' }
    & Pick<gql_ResolvedDynamicContent, 'content'>
  ) }
);

export type gql_GetListComponentsQueryVariables = Exact<{
  sort?: Maybe<gql_SortFindManyComponentInput>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<gql_FilterFindManyComponentInput>;
}>;


export type gql_GetListComponentsQuery = (
  { __typename?: 'Query' }
  & { componentPagination?: Maybe<(
    { __typename?: 'ComponentPagination' }
    & Pick<gql_ComponentPagination, 'count'>
    & { pageInfo: (
      { __typename?: 'PaginationInfo' }
      & Pick<gql_PaginationInfo, 'currentPage' | 'perPage' | 'pageCount' | 'itemCount' | 'hasNextPage' | 'hasPreviousPage'>
    ), items?: Maybe<Array<(
      { __typename?: 'Component' }
      & Pick<gql_Component, 'name' | 'partialSchema' | 'environment'>
      & { id: gql_Component['_id'] }
    )>> }
  )> }
);

export type gql_GetComponentQueryVariables = Exact<{
  id: Scalars['MongoID'];
}>;


export type gql_GetComponentQuery = (
  { __typename?: 'Query' }
  & { componentById?: Maybe<(
    { __typename?: 'Component' }
    & Pick<gql_Component, 'name' | 'partialSchema' | 'environment' | 'createdAt' | 'updatedAt'>
    & { id: gql_Component['_id'] }
  )> }
);

export type gql_CreateComponentMutationVariables = Exact<{
  record: gql_CreateOneComponentInput;
}>;


export type gql_CreateComponentMutation = (
  { __typename?: 'Mutation' }
  & { componentCreateOne?: Maybe<(
    { __typename?: 'CreateOneComponentPayload' }
    & Pick<gql_CreateOneComponentPayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Component' }
      & Pick<gql_Component, 'name' | 'partialSchema' | 'environment' | 'createdAt' | 'updatedAt'>
      & { id: gql_Component['_id'] }
    )> }
  )> }
);

export type gql_UpdateComponentMutationVariables = Exact<{
  id: Scalars['MongoID'];
  record: gql_UpdateByIdComponentInput;
}>;


export type gql_UpdateComponentMutation = (
  { __typename?: 'Mutation' }
  & { componentUpdateById?: Maybe<(
    { __typename?: 'UpdateByIdComponentPayload' }
    & Pick<gql_UpdateByIdComponentPayload, 'recordId'>
    & { error?: Maybe<(
      { __typename: 'ValidationError' }
      & Pick<gql_ValidationError, 'message'>
    ) | (
      { __typename: 'MongoError' }
      & Pick<gql_MongoError, 'message'>
    ) | (
      { __typename: 'RuntimeError' }
      & Pick<gql_RuntimeError, 'message'>
    )>, record?: Maybe<(
      { __typename?: 'Component' }
      & Pick<gql_Component, 'name' | 'partialSchema' | 'createdAt' | 'updatedAt'>
      & { id: gql_Component['_id'], projectId: gql_Component['project'] }
    )> }
  )> }
);

export type gql_GetUserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type gql_GetUserMeQuery = (
  { __typename?: 'Query' }
  & { userMe: (
    { __typename?: 'UserMe' }
    & gql_HyUserMeFragment
  ) }
);

export type gql_LoginMutationVariables = Exact<{
  credentials: gql_CredentialsInput;
}>;


export type gql_LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & gql_HyUserFragment
  ) }
);

export type gql_LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type gql_LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<gql_Mutation, 'logout'>
);

export type gql_GetContentHistoryQueryVariables = Exact<{
  payload: gql_ContentHistoryPayload;
}>;


export type gql_GetContentHistoryQuery = (
  { __typename?: 'Query' }
  & { contentHistoryOne: Array<(
    { __typename?: 'ContentHistory' }
    & Pick<gql_ContentHistory, 'locale' | 'environmentId'>
    & { versions?: Maybe<Array<(
      { __typename?: 'ContentHistoryVersions' }
      & Pick<gql_ContentHistoryVersions, 'version' | 'event' | 'date' | 'diff'>
    )>> }
  )> }
);

export const HyProjectFragmentDoc = gql`
    fragment HyProject on Project {
  id: _id
  name
  schema: defaultContentSchema
  schemaUrl: defaultContentSchemaUrl
  settings {
    previewUrl
    livePreviewOrigin
    developerPreviewUrl
    developerPreviewOrigin
  }
  locales
  defaultLocale
  updatedAt
  createdAt
}
    `;
export const HySourceFragmentDoc = gql`
    fragment HySource on SourceInterface {
  id: _id
  name
  contentSchema
  contentSchemaUrl
  slug
  defaultLocale
  locales {
    slug
    locale
  }
  schemaMode
  projectId: project
  isPublic
  isDefaultSource
  isGlobal
  createdAt
  updatedAt
  kind
  ... on Page {
    layout
    seo
  }
}
    `;
export const HyContentFragmentDoc = gql`
    fragment HyContent on Content {
  id: _id
  sourceId: source
  locale
  content
  createdAt
  updatedAt
}
    `;
export const HyEnvironmentFragmentDoc = gql`
    fragment HyEnvironment on Environment {
  id: _id
  name
  projectId: project
  settings
  previewSettings {
    path
    origin
  }
  createdAt
  updatedAt
}
    `;
export const HyUserFragmentDoc = gql`
    fragment HyUser on User {
  _id
  firstName
  lastName
  email
  isActive
}
    `;
export const HyUserMeFragmentDoc = gql`
    fragment HyUserMe on UserMe {
  _id
  firstName
  lastName
  email
}
    `;
export const GetProjectByIdDocument = gql`
    query GetProjectById($id: MongoID!) {
  project: projectById(_id: $id) {
    ...HyProject
  }
}
    ${HyProjectFragmentDoc}`;

export function useGetProjectByIdQuery(options: Omit<Urql.UseQueryArgs<gql_GetProjectByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetProjectByIdQuery>({ query: GetProjectByIdDocument, ...options });
};
export const GetListProjectsDocument = gql`
    query GetListProjects($sort: SortFindManyProjectInput, $page: Int, $perPage: Int, $filter: FilterFindManyProjectInput) {
  projects: projectPagination(
    page: $page
    perPage: $perPage
    filter: $filter
    sort: $sort
  ) {
    count
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
    items {
      ...HyProject
    }
  }
}
    ${HyProjectFragmentDoc}`;

export function useGetListProjectsQuery(options: Omit<Urql.UseQueryArgs<gql_GetListProjectsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetListProjectsQuery>({ query: GetListProjectsDocument, ...options });
};
export const GetSourceByIdDocument = gql`
    query GetSourceById($id: MongoID!) {
  sourceById(_id: $id) {
    ...HySource
  }
}
    ${HySourceFragmentDoc}`;

export function useGetSourceByIdQuery(options: Omit<Urql.UseQueryArgs<gql_GetSourceByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetSourceByIdQuery>({ query: GetSourceByIdDocument, ...options });
};
export const GetListSourcesDocument = gql`
    query GetListSources($sort: SortFindManySourceInput, $page: Int, $perPage: Int, $filter: FilterFindManySourceInput) {
  sourcePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
    count
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
    items {
      ...HySource
    }
  }
}
    ${HySourceFragmentDoc}`;

export function useGetListSourcesQuery(options: Omit<Urql.UseQueryArgs<gql_GetListSourcesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetListSourcesQuery>({ query: GetListSourcesDocument, ...options });
};
export const CreateSourceDocument = gql`
    mutation CreateSource($record: CreateOneSourceInput!) {
  sourceCreateOne(record: $record) {
    recordId
    error {
      message
      __typename
    }
    record {
      ...HySource
    }
  }
}
    ${HySourceFragmentDoc}`;

export function useCreateSourceMutation() {
  return Urql.useMutation<gql_CreateSourceMutation, gql_CreateSourceMutationVariables>(CreateSourceDocument);
};
export const UpdateSourceDocument = gql`
    mutation UpdateSource($id: MongoID!, $record: UpdateByIdSourceInput!) {
  sourceUpdateById(_id: $id, record: $record) {
    recordId
    error {
      message
      __typename
    }
    record {
      ...HySource
    }
  }
}
    ${HySourceFragmentDoc}`;

export function useUpdateSourceMutation() {
  return Urql.useMutation<gql_UpdateSourceMutation, gql_UpdateSourceMutationVariables>(UpdateSourceDocument);
};
export const UpdatePageDocument = gql`
    mutation UpdatePage($id: MongoID!, $record: UpdateByIdPageInput!) {
  pageUpdateById(_id: $id, record: $record) {
    recordId
    error {
      message
      __typename
    }
    record {
      ...HySource
    }
  }
}
    ${HySourceFragmentDoc}`;

export function useUpdatePageMutation() {
  return Urql.useMutation<gql_UpdatePageMutation, gql_UpdatePageMutationVariables>(UpdatePageDocument);
};
export const DeleteSourceDocument = gql`
    mutation DeleteSource($id: MongoID!) {
  sourceRemoveById(_id: $id) {
    recordId
    error {
      message
      __typename
    }
  }
}
    `;

export function useDeleteSourceMutation() {
  return Urql.useMutation<gql_DeleteSourceMutation, gql_DeleteSourceMutationVariables>(DeleteSourceDocument);
};
export const GetContentDocument = gql`
    query GetContent($sourceId: MongoID, $locale: String, $environment: MongoID) {
  contentOne(
    filter: {source: $sourceId, locale: $locale, environment: $environment}
  ) {
    ...HyContent
  }
}
    ${HyContentFragmentDoc}`;

export function useGetContentQuery(options: Omit<Urql.UseQueryArgs<gql_GetContentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetContentQuery>({ query: GetContentDocument, ...options });
};
export const CreateContentDocument = gql`
    mutation CreateContent($record: CreateOneContentInput!) {
  contentCreateOne(record: $record) {
    error {
      message
      __typename
    }
    recordId
    record {
      ...HyContent
    }
  }
}
    ${HyContentFragmentDoc}`;

export function useCreateContentMutation() {
  return Urql.useMutation<gql_CreateContentMutation, gql_CreateContentMutationVariables>(CreateContentDocument);
};
export const UpdateContentDocument = gql`
    mutation UpdateContent($id: MongoID!, $record: UpdateByIdContentInput!) {
  contentUpdateById(_id: $id, record: $record) {
    error {
      message
      __typename
    }
    recordId
    record {
      ...HyContent
    }
  }
}
    ${HyContentFragmentDoc}`;

export function useUpdateContentMutation() {
  return Urql.useMutation<gql_UpdateContentMutation, gql_UpdateContentMutationVariables>(UpdateContentDocument);
};
export const GetEnvironmentByIdDocument = gql`
    query GetEnvironmentById($id: MongoID!) {
  environmentById(_id: $id) {
    ...HyEnvironment
  }
}
    ${HyEnvironmentFragmentDoc}`;

export function useGetEnvironmentByIdQuery(options: Omit<Urql.UseQueryArgs<gql_GetEnvironmentByIdQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetEnvironmentByIdQuery>({ query: GetEnvironmentByIdDocument, ...options });
};
export const GetEnvironmentByNameDocument = gql`
    query GetEnvironmentByName($name: String) {
  environmentOne(filter: {name: $name}) {
    ...HyEnvironment
  }
}
    ${HyEnvironmentFragmentDoc}`;

export function useGetEnvironmentByNameQuery(options: Omit<Urql.UseQueryArgs<gql_GetEnvironmentByNameQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetEnvironmentByNameQuery>({ query: GetEnvironmentByNameDocument, ...options });
};
export const GetListEnvironmentsDocument = gql`
    query GetListEnvironments($sort: SortFindManyEnvironmentInput, $page: Int, $perPage: Int, $filter: FilterFindManyEnvironmentInput) {
  environmentPagination(
    page: $page
    perPage: $perPage
    filter: $filter
    sort: $sort
  ) {
    count
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
    items {
      ...HyEnvironment
    }
  }
}
    ${HyEnvironmentFragmentDoc}`;

export function useGetListEnvironmentsQuery(options: Omit<Urql.UseQueryArgs<gql_GetListEnvironmentsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetListEnvironmentsQuery>({ query: GetListEnvironmentsDocument, ...options });
};
export const ResolveDynamicContentDocument = gql`
    query ResolveDynamicContent($payload: ResolveDynamicContentPayload!) {
  resolveDynamicContent(payload: $payload) {
    content
  }
}
    `;

export function useResolveDynamicContentQuery(options: Omit<Urql.UseQueryArgs<gql_ResolveDynamicContentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_ResolveDynamicContentQuery>({ query: ResolveDynamicContentDocument, ...options });
};
export const GetListComponentsDocument = gql`
    query GetListComponents($sort: SortFindManyComponentInput, $page: Int, $perPage: Int, $filter: FilterFindManyComponentInput) {
  componentPagination(
    page: $page
    perPage: $perPage
    filter: $filter
    sort: $sort
  ) {
    count
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
    items {
      id: _id
      name
      partialSchema
      environment
    }
  }
}
    `;

export function useGetListComponentsQuery(options: Omit<Urql.UseQueryArgs<gql_GetListComponentsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetListComponentsQuery>({ query: GetListComponentsDocument, ...options });
};
export const GetComponentDocument = gql`
    query GetComponent($id: MongoID!) {
  componentById(_id: $id) {
    id: _id
    name
    partialSchema
    environment
    createdAt
    updatedAt
  }
}
    `;

export function useGetComponentQuery(options: Omit<Urql.UseQueryArgs<gql_GetComponentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetComponentQuery>({ query: GetComponentDocument, ...options });
};
export const CreateComponentDocument = gql`
    mutation CreateComponent($record: CreateOneComponentInput!) {
  componentCreateOne(record: $record) {
    recordId
    error {
      message
      __typename
    }
    record {
      id: _id
      name
      partialSchema
      environment
      createdAt
      updatedAt
    }
  }
}
    `;

export function useCreateComponentMutation() {
  return Urql.useMutation<gql_CreateComponentMutation, gql_CreateComponentMutationVariables>(CreateComponentDocument);
};
export const UpdateComponentDocument = gql`
    mutation UpdateComponent($id: MongoID!, $record: UpdateByIdComponentInput!) {
  componentUpdateById(_id: $id, record: $record) {
    recordId
    error {
      message
      __typename
    }
    record {
      id: _id
      name
      partialSchema
      projectId: project
      createdAt
      updatedAt
    }
  }
}
    `;

export function useUpdateComponentMutation() {
  return Urql.useMutation<gql_UpdateComponentMutation, gql_UpdateComponentMutationVariables>(UpdateComponentDocument);
};
export const GetUserMeDocument = gql`
    query GetUserMe {
  userMe {
    ...HyUserMe
  }
}
    ${HyUserMeFragmentDoc}`;

export function useGetUserMeQuery(options: Omit<Urql.UseQueryArgs<gql_GetUserMeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetUserMeQuery>({ query: GetUserMeDocument, ...options });
};
export const LoginDocument = gql`
    mutation Login($credentials: CredentialsInput!) {
  login(credentials: $credentials) {
    ...HyUser
  }
}
    ${HyUserFragmentDoc}`;

export function useLoginMutation() {
  return Urql.useMutation<gql_LoginMutation, gql_LoginMutationVariables>(LoginDocument);
};
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<gql_LogoutMutation, gql_LogoutMutationVariables>(LogoutDocument);
};
export const GetContentHistoryDocument = gql`
    query GetContentHistory($payload: ContentHistoryPayload!) {
  contentHistoryOne(payload: $payload) {
    locale
    environmentId
    versions {
      version
      event
      date
      diff
    }
  }
}
    `;

export function useGetContentHistoryQuery(options: Omit<Urql.UseQueryArgs<gql_GetContentHistoryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<gql_GetContentHistoryQuery>({ query: GetContentHistoryDocument, ...options });
};