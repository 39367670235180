import { Box, Flex, Tooltip } from '@chakra-ui/react'
import React, { FC, useCallback } from 'react'
import BaseIcon from '../base/BaseIcon'
import Surface from '../base/Surface'

type Props = {
  isVisible?: boolean
  onInsertBefore?: () => void
  onInsertAfter?: () => void
  onAvoidInsert?: () => void
}

const NodeViewEndControls: FC<Props> = ({
  isVisible,
  onInsertAfter,
  onInsertBefore,
  onAvoidInsert,
}) => {
  const handleInsertBefore = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      if (onInsertBefore) {
        onInsertBefore()
      }
    },
    [onInsertBefore]
  )

  const handleInsertAfter = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      if (onInsertAfter) {
        onInsertAfter()
      }
    },
    [onInsertAfter]
  )

  return (
    <Flex
      alignItems="center"
      color="dark.400"
      position="absolute"
      right="-5px"
      top="0px"
      w="15px"
      h="100%"
      zIndex={1}
      opacity={isVisible ? 1 : 0}
      pointerEvents={!isVisible ? 'none' : 'all'}
    >
      <Surface
        display="flex"
        rounded="full"
        darkBgColor="green.500"
        darkBgHoverColor="green.600"
        lightBgColor="green.600"
        lightBgHoverColor="green.500"
        color="lightAlpha.800"
        alignItems="center"
        boxSize="20px"
        position="absolute"
        top="-6px"
        cursor="pointer"
        left="-14px"
        shadow="sm"
        onClick={handleInsertBefore}
        hoverable
      >
        <Tooltip
          hasArrow
          placement="right"
          label="Add Element before"
          aria-label="Add Element before"
          zIndex={1}
        >
          <Flex mx="auto" alignItems="center">
            <BaseIcon name="FiPlus" collection="fi" fontSize="md" />
          </Flex>
        </Tooltip>
      </Surface>

      <Surface
        display="flex"
        rounded="full"
        darkBgColor="green.500"
        darkBgHoverColor="green.600"
        lightBgColor="green.600"
        lightBgHoverColor="green.500"
        color="lightAlpha.800"
        alignItems="center"
        boxSize="20px"
        position="absolute"
        bottom="-6px"
        left="-14px"
        shadow="sm"
        cursor="pointer"
        onClick={handleInsertAfter}
        hoverable
      >
        <Tooltip
          hasArrow
          placement="bottom"
          label="Add Element after"
          aria-label="Add Element after"
          zIndex={1}
        >
          <Flex mx="auto" alignItems="center">
            <BaseIcon name="FiPlus" collection="fi" fontSize="md" />
          </Flex>
        </Tooltip>
      </Surface>
    </Flex>
  )
}

export default NodeViewEndControls
