import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Flex, ScaleFade, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import BaseIcon from '../../base/BaseIcon'
import ProjectSourcesCard from './ProjectSourcesCard'
import { gql_HyProjectFragment } from '../../../core/graphql'

type ProjectsGridProps = {
  projects: any
  showAddFolder?: boolean
  showImportProject?: boolean
  onOpenSettings?: (target: gql_HyProjectFragment, ref?: any) => void
  onAddFolder?: () => void
  onAddProject?: () => void
  onImportProject?: () => void
  onOpenMove?: (target: gql_HyProjectFragment) => void
  onEdit?: (target: gql_HyProjectFragment) => void
}

type RefType = any

const ProjectsGrid = React.forwardRef<RefType, ProjectsGridProps>((props, ref) => {
  const {
    projects,
    showImportProject = true,
    showAddFolder = true,
    onOpenSettings,
    onAddFolder,
    onAddProject,
    onImportProject,
    onOpenMove,
    onEdit,
  } = props
  const history = useHistory()

  const bgColor = useColorModeValue('light.50', 'dark.600')
  const buttonBgHoverColor = useColorModeValue('light.100', 'dark.700')

  const handleEdit = (project: gql_HyProjectFragment): void => {
    onEdit ? onEdit(project) : history.push(`/workbench/${project.id}`)
  }

  const handleOpenSettings = (target: gql_HyProjectFragment, ref?: any): void => {
    onOpenSettings && onOpenSettings(target, ref)
  }

  const [gridVisible, setGridVisibile] = useState<boolean>(false)

  useEffect(() => {
    setGridVisibile(false)
    setTimeout(() => {
      setGridVisibile(true)
    }, 10)
  }, [projects])

  return (
    <>
      {gridVisible && (
        <ScaleFade in={!!projects}>
          <SimpleGrid columns={[1, 1, 2, 3, 4]} spacing="40px" w="100%" pb="5" ref={ref}>
            {/* First Card (add,import,new folder) */}
            <Flex h={230} flexDir="column" fontSize="lg" p={6}>
              {/* <Flex>
                {showImportProject && (
                  <Flex
                    bg={bgColor}
                    _hover={{ bgColor: buttonBgHoverColor, shadow: 'lg' }}
                    p="3"
                    textAlign="center"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    rounded="md"
                    overflow="hidden"
                    shadow="sm"
                    mb="3"
                    h="50px"
                    as={Button}
                    onClick={onImportProject}
                  >
                    <BaseIcon name="IoFolderOpen" collection="io5" />
                    <Text ml="3">New Folder</Text>
                  </Flex>
                )}
              </Flex>
              */}
              <Flex
                bg={bgColor}
                _hover={{ bgColor: buttonBgHoverColor, shadow: 'lg' }}
                alignItems="center"
                justifyContent="center"
                flexDir="column"
                p="3"
                textAlign="center"
                w="100%"
                flex="1"
                rounded="md"
                overflow="hidden"
                shadow="sm"
                as={Button}
                onClick={onAddProject}
              >
                <BaseIcon name="AiOutlinePlus" fontSize="4xl" collection="ai" />
                <Text fontSize="xl" mt="3">
                  New Project
                </Text>
              </Flex>
            </Flex>

            {projects &&
              projects.map((item: any, index: number) => {
                // if (item.type == 'folder')
                //   return (
                //     <FolderCard
                //       key={index}
                //       onSelect={handleSelectFolder}
                //       onClickSettings={handleOpenSettings}
                //       folder={item}
                //     />
                //   )

                return (
                  <ProjectSourcesCard
                    key={`project-${item.id}`}
                    onSelect={handleEdit}
                    onClickSettings={handleOpenSettings}
                    //  onMoveToFolder={onOpenMove}
                    project={item}
                  />
                )
              })}
          </SimpleGrid>
        </ScaleFade>
      )}
    </>
  )
})

export default ProjectsGrid
