import { Box, BoxProps, chakra, useColorMode, useMultiStyleConfig } from '@chakra-ui/react'
import { FC, forwardRef, RefAttributes } from 'react'

export type SurfaceVariants = 'lighter' | 'light' | 'base' | 'heavy' | 'heavier'
export interface SurfaceVariantsColorMode {
  light: SurfaceVariants
  dark: SurfaceVariants
}

export type SurfaceProps = {
  isSelected?: boolean
  hoverable?: boolean
  onClick?: (event: any) => void
  variant?: SurfaceVariants
  darkVariant?: SurfaceVariants
  lightVariant?: SurfaceVariants
  darkBgColor?: string
  lightBgColor?: string
  darkBgHoverColor?: string
  lightBgHoverColor?: string
  darkTextColor?: string
  lightTextColor?: string
} & BoxProps &
  RefAttributes<any>

const Surface = forwardRef<HTMLDivElement, SurfaceProps>((props, ref) => {
  const {
    isSelected,
    hoverable = false,
    // onClick,
    children,
    variant,
    darkBgColor,
    lightBgColor,
    darkVariant,
    lightVariant,
    darkBgHoverColor,
    lightBgHoverColor,
    darkTextColor,
    lightTextColor,
    ...rest
  } = props
  const styles = useMultiStyleConfig('Surface', props)

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <chakra.div
      ref={ref}
      {...rest}
      sx={{
        ...styles.wrapper,
        ...(hoverable ? styles.hoverable : {}),
        ...(rest.onClick ? styles.clickable : {}),
        ...(isSelected ? styles.selected : {}),
        ...rest.sx,
      }}
      // onClick={(event) => {
      //   if (onClick) onClick(event)
      // }}
    >
      {children}
    </chakra.div>
  )
})

export default Surface
