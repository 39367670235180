import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwt from 'jsonwebtoken'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'

const uploadhttpLink = createUploadLink({
  uri: process.env.REACT_APP_GATEWAY_URI,
  credentials: 'include',
})

// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_GATEWAY_URI,
//   credentials: 'include'
// })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'newToken',
  // No need to refresh if token exists and is still valid
  isTokenValidOrUndefined: () => {
    const userId = localStorage.getItem('userId')
    // No need to refresh if we don't have a userId
    if (!userId) {
      return true
    }

    const token = localStorage.getItem('token')
    // No need to refresh if token exists and is valid
    if (token && (jwt.decode(token) as any).exp * 1000 > Date.now()) {
      return true
    }

    return false
  },

  fetchAccessToken: async () => {
    const userId = localStorage.getItem('userId')
    if (!userId) {
      // no need to refresh if userId is not defined
      return null
    }
    // Use fetch to access the refreshUserToken mutation
    const response = await fetch(process.env.REACT_APP_GATEWAY_URI as string, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        query: `mutation {
                  refreshToken(userId: "${userId}") {
                    userId
                    token
                  }
                }`,
      }),
    })
    return response.json()
  },

  handleFetch: (newToken) => {
    // save new authentication token to state
    localStorage.setItem('token', newToken)
  },

  handleResponse: () => (response: any) => {
    if (!response) return { newToken: null }
    return { newToken: response.data?.refreshToken?.token }
  },

  handleError: (error) => {
    console.error('Cannot refresh access token:', error)
  },
})

export const apolloClient = new ApolloClient({
  link: (refreshLink as any).concat(authLink).concat(uploadhttpLink),
  cache: new InMemoryCache(),
})
