import { extendTheme } from '@chakra-ui/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { theme, ThemeConfig } from '@chakra-ui/theme'
import { mode } from '@chakra-ui/theme-tools'
import components from './components'
import colors from './foundations/colors'
import shadows from './foundations/shadows'
import radii from './foundations/radii'
import fonts from './foundations/fonts'
import styles from './styles'

const config: ThemeConfig = {
  useSystemColorMode: true,
}

const defaultTheme = extendTheme({
  ...theme,
  styles,
  components,
  fonts,
  radii,
  shadows,
  colors,
  config,
})

export default defaultTheme
