import React, { FC, useCallback } from 'react'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
} from '@chakra-ui/react'
import { JSONSchema7Type, JSONSchema7TypeName } from 'json-schema'
import { source } from 'react-uid/dist/es5/context'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import { gql_HySourceFragment } from '../../core/graphql'

type Props = Record<string, any> &
  BoxProps & {
    source?: gql_HySourceFragment
    valueTypeName?: JSONSchema7TypeName | 'unsupported'
    value?: JSONSchema7Type
    path?: string
    isSelected?: boolean
    onValueChange?: (path: string, value: JSONSchema7Type | undefined) => void
  }

const NodeViewOptions: FC<Props> = ({
  children,
  source,
  valueTypeName,
  value,
  path,
  isSelected,
  onValueChange,
  ...rest
}) => {
  const handleDeleteNode = useCallback(() => {
    if (onValueChange) {
      onValueChange(path || '/', undefined)
    }
  }, [onValueChange, path])

  const onMenuButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation()
    },
    []
  )

  return (
    <Flex flexDir="row" pl="2" alignItems="center" justifyContent="center" {...rest}>
      {/* <Menu autoSelect={false}>
        <MenuButton
          as={BaseButton}
          cursor="pointer"
          h="40px"
          px={4}
          onClick={onMenuButtonClick}
          darkHue="heavier"
          lightHue="lighter"
          alignSelf="flex-start"
        >
          <BaseIcon name="FaEllipsisH" />
        </MenuButton>
        <Portal>
          <MenuList bg="dark.900" borderWidth={1} border="0px solid" shadow="lg" position="absolute" id="menu-list">
            <MenuOptionGroup title="Options">
              <MenuItem>Duplicate</MenuItem>
          
              <MenuDivider borderColor="dark.600" />
              <MenuItem onClick={handleDeleteNode} as="a" color="red.500">
                <Text flex="1">Delete</Text>
                <BaseIcon name="FaTrashAlt" />
              </MenuItem>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu> */}

      {children}
    </Flex>
  )
}

export default NodeViewOptions
