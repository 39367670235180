export const fragments = {
  Project: /* GraphQL */ `
    fragment HyProject on Project {
      id: _id
      name
      schema: defaultContentSchema
      schemaUrl: defaultContentSchemaUrl
      settings {
        previewUrl
        livePreviewOrigin
        developerPreviewUrl
        developerPreviewOrigin
      }
      locales
      defaultLocale
      updatedAt
      createdAt
    }
  `,
  Source: /* GraphQL */ `
    fragment HySource on SourceInterface {
      id: _id
      name
      contentSchema
      contentSchemaUrl
      slug
      defaultLocale
      locales {
        slug
        locale
      }
      schemaMode
      projectId: project
      isPublic
      isDefaultSource
      isGlobal
      createdAt
      updatedAt
      kind
      ... on Page {
        layout
        seo
      }
    }
  `,
  Content: /* GraphQL */ `
    fragment HyContent on Content {
      id: _id
      sourceId: source
      locale
      content
      createdAt
      updatedAt
    }
  `,
  Environment: /* GraphQL */ `
    fragment HyEnvironment on Environment {
      id: _id
      name
      projectId: project
      settings
      previewSettings {
        path
        origin
      }
      createdAt
      updatedAt
    }
  `,
  User: /* GraphQL */ `
    fragment HyUser on User {
      _id
      firstName
      lastName
      email
      isActive
    }
  `,
  UserMe: /* GraphQL */ `
    fragment HyUserMe on UserMe {
      _id
      firstName
      lastName
      email
    }
  `,
  // Media: /* GraphQL */ `
  //   fragment HyMedia on Media {

  //   }
  // `,
}

export const QUERY_GET_ONE_PROJECT = /* GraphQL */ `
  ${fragments.Project}
  query GetProjectById($id: MongoID!) {
    project: projectById(_id: $id) {
      ...HyProject
    }
  }
`

export const QUERY_GET_LIST_PROJECTS = /* GraphQL */ `
  ${fragments.Project}
  query GetListProjects(
    $sort: SortFindManyProjectInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManyProjectInput
  ) {
    projects: projectPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        ...HyProject
      }
    }
  }
`

export const QUERY_GET_ONE_SOURCE = /* GraphQL */ `
  ${fragments.Source}
  query GetSourceById($id: MongoID!) {
    sourceById(_id: $id) {
      ...HySource
    }
  }
`

export const QUERY_GET_LIST_SOURCES = /* GraphQL */ `
  ${fragments.Source}
  query GetListSources(
    $sort: SortFindManySourceInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManySourceInput
  ) {
    sourcePagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        ...HySource
      }
    }
  }
`

export const MUTATION_CREATE_SOURCE = /* GraphQL */ `
  ${fragments.Source}
  mutation CreateSource($record: CreateOneSourceInput!) {
    sourceCreateOne(record: $record) {
      recordId
      error {
        message
        __typename
      }
      record {
        ...HySource
      }
    }
  }
`

export const MUTATION_CREATE_PAGE = /* GraphQL */ `
  ${fragments.Source}
  mutation CreatePage($record: CreateOnePageInput!) {
    pageCreateOne(record: $record) {
      recordId
      error {
        message
        __typename
      }
      record {
        ...HySource
      }
    }
  }
`

export const MUTATION_UPDATE_SOURCE = /* GraphQL */ `
  ${fragments.Source}
  mutation UpdateSource($id: MongoID!, $record: UpdateByIdSourceInput!) {
    sourceUpdateById(_id: $id, record: $record) {
      recordId
      error {
        message
        __typename
      }
      record {
        ...HySource
      }
    }
  }
`

export const MUTATION_UPDATE_PAGE = /* GraphQL */ `
  ${fragments.Source}
  mutation UpdatePage($id: MongoID!, $record: UpdateByIdPageInput!) {
    pageUpdateById(_id: $id, record: $record) {
      recordId
      error {
        message
        __typename
      }
      record {
        ...HySource
      }
    }
  }
`

export const MUTATION_DELETE_SOURCE = /* GraphQL */ `
  mutation DeleteSource($id: MongoID!) {
    sourceRemoveById(_id: $id) {
      recordId
      error {
        message
        __typename
      }
    }
  }
`

export const QUERY_GET_CONTENT = /* GraphQL */ `
  ${fragments.Content}
  query GetContent($sourceId: MongoID, $locale: String, $environment: MongoID) {
    contentOne(filter: { source: $sourceId, locale: $locale, environment: $environment }) {
      ...HyContent
    }
  }
`

export const MUTATION_CREATE_CONTENT = /* GraphQL */ `
  ${fragments.Content}
  mutation CreateContent($record: CreateOneContentInput!) {
    contentCreateOne(record: $record) {
      error {
        message
        __typename
      }
      recordId
      record {
        ...HyContent
      }
    }
  }
`

export const MUTATION_UPDATE_CONTENT = /* GraphQL */ `
  ${fragments.Content}
  mutation UpdateContent($id: MongoID!, $record: UpdateByIdContentInput!) {
    contentUpdateById(_id: $id, record: $record) {
      error {
        message
        __typename
      }
      recordId
      record {
        ...HyContent
      }
    }
  }
`

export const QUERY_GET_ENVIRONMENT_BY_ID = /* GraphQL */ `
  ${fragments.Environment}
  query GetEnvironmentById($id: MongoID!) {
    environmentById(_id: $id) {
      ...HyEnvironment
    }
  }
`

export const QUERY_GET_ENVIRONMENT_BY_NAME = /* GraphQL */ `
  ${fragments.Environment}
  query GetEnvironmentByName($name: String) {
    environmentOne(filter: { name: $name }) {
      ...HyEnvironment
    }
  }
`

export const QUERY_GET_LIST_ENVIRONMENTS = /* GraphQL */ `
  ${fragments.Environment}
  query GetListEnvironments(
    $sort: SortFindManyEnvironmentInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManyEnvironmentInput
  ) {
    environmentPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        ...HyEnvironment
      }
    }
  }
`

export const QUERY_RESOLVE_DYNAMIC_CONTENT = /* GraphQL */ `
  query ResolveDynamicContent($payload: ResolveDynamicContentPayload!) {
    resolveDynamicContent(payload: $payload) {
      content
    }
  }
`

export const QUERY_GET_LIST_COMPONENTS = /* GraphQL */ `
  query GetListComponents(
    $sort: SortFindManyComponentInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManyComponentInput
  ) {
    componentPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        id: _id
        name
        partialSchema
        environment
      }
    }
  }
`

export const QUERY_GET_ONE_COMPONENT = /* GraphQL */ `
  query GetComponent($id: MongoID!) {
    componentById(_id: $id) {
      id: _id
      name
      partialSchema
      environment
      createdAt
      updatedAt
    }
  }
`

export const MUTATION_CREATE_COMPONENT = /* GraphQL */ `
  mutation CreateComponent($record: CreateOneComponentInput!) {
    componentCreateOne(record: $record) {
      recordId
      error {
        message
        __typename
      }
      record {
        id: _id
        name
        partialSchema
        environment
        createdAt
        updatedAt
      }
    }
  }
`

export const MUTATION_UPDATE_COMPONENT = /* GraphQL */ `
  mutation UpdateComponent($id: MongoID!, $record: UpdateByIdComponentInput!) {
    componentUpdateById(_id: $id, record: $record) {
      recordId
      error {
        message
        __typename
      }
      record {
        id: _id
        name
        partialSchema
        projectId: project
        createdAt
        updatedAt
      }
    }
  }
`

export const QUERY_USER_ME = /* GraphQL */ `
  ${fragments.UserMe}
  query GetUserMe {
    userMe {
      ...HyUserMe
    }
  }
`

export const MUTATION_LOGIN = /* GraphQL */ `
  ${fragments.User}
  mutation Login($credentials: CredentialsInput!) {
    login(credentials: $credentials) {
      ...HyUser
    }
  }
`

export const MUTATION_LOGOUT = /* GraphQL */ `
  mutation Logout {
    logout
  }
`

export const QUERY_GET_CONTENT_HISTORY = /* GraphQL */ `
  query GetContentHistory($payload: ContentHistoryPayload!) {
    contentHistoryOne(payload: $payload) {
      locale
      environmentId
      versions {
        version
        event
        date
        diff
      }
    }
  }
`
