import React, { FC, useCallback, useMemo, useState } from 'react'

import {
  chakra,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useRadioGroup,
  useToast,
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useClient } from 'urql'
import { useHistory } from 'react-router-dom'
import { ID } from '../../core/types/BaseCRUD'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import GradientBox from '../base/GradientBox'
import Surface from '../base/Surface'
import { MasterDetailMenu, MasterDetailMenuItem, MasterDetailPanel } from '../base/MasterDetail'
import { ModalMenuItem } from '../base/MasterDetail/MasterDetailMenu'
import SourceBasicInfoFormSection from './SourceForm/SourceBasicInfoFormSection'
import SourceSlugsFormSection from './SourceForm/SourceSlugsFormSection'
import { MUTATION_CREATE_PAGE, MUTATION_CREATE_SOURCE } from '../../queries'
import { CreateSourceData } from '../../core/source-workbench/CreateSourceData'
import {
  gql_CreateSourceMutation,
  gql_CreateSourceMutationVariables,
  gql_EnumDKeySourceKind,
  gql_EnumSourceSchemaMode,
  gql_SortFindManySourceInput,
  useCreateSourceMutation,
  useGetListSourcesQuery,
} from '../../core/graphql'
import PageSEOFormSection from './SourceForm/PageSEOFormSection'
import RadioCard from '../base/RadioCard'

type Props = {
  projectId: ID
  isOpen: boolean
  onClose: () => void
  finalFocusRef?: React.RefObject<any>
}

const menu: ModalMenuItem[] = [
  {
    id: 'create-source-options-section-0',
    title: 'Options',
    subtitle: 'lorem ipsum dolor sit',
    iconComponent: <BaseIcon name="GoSettings" collection="go" mr={2} fontSize="2xl" />,
  },
  {
    id: 'create-source-slugs-section-1',
    title: 'Slug',
    subtitle: 'The source slug',
    iconComponent: <BaseIcon name="HiFlag" collection="hi" mr={2} fontSize="2xl" />,
  },
]

type CustomCreateData = {
  layout: string
  kind: gql_EnumDKeySourceKind
} & CreateSourceData

const CreateSourceDrawer: FC<Props> = (props) => {
  const { projectId, onClose, finalFocusRef, isOpen, ...rest } = props
  const methods = useForm({
    defaultValues: {
      schemaMode: gql_EnumSourceSchemaMode.Components,
    },
  })
  const client = useClient()
  const toast = useToast()
  const history = useHistory()
  const { handleSubmit, trigger } = methods
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  // const [createSourceResult, createSource] = useCreateSourceMutation()
  const [submitting, setSubmitting] = useState<boolean>(false)
  // fetching available layouts
  const [sourcesResult, refetchSources] = useGetListSourcesQuery({
    variables: {
      filter: { kind: gql_EnumDKeySourceKind.Layout },
      sort: gql_SortFindManySourceInput.NameAsc,
      page: 0,
      perPage: 100,
    },
  })

  // const { fetching: submitting, data } = createSourceResult
  const options = [
    { value: 'basic-source', title: 'Basic', icon: 'SiNextDotJs', iconCollection: 'si' },
    {
      value: 'basic-with-components',
      title: 'Basic + Components',
      icon: 'SiReact',
      iconCollection: 'si',
    },
    { value: 'new-page', title: 'Create new page', icon: 'FaHtml5', iconCollection: 'fa' },
  ]
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'projectType',
    defaultValue: 'nextjs',
    onChange: console.log,
  })
  const group = getRootProps()
  const selectedOption = useMemo(() => {
    return options.find((option) => option.value == value)
  }, [value])

  const onSubmit = useCallback(
    async (data: CustomCreateData) => {
      try {
        if (submitting) {
          return
        }
        setSubmitting(true)
        console.log(data, 'ciaon ciao')
        // const result = await createSource({
        //   record: {
        //     name: data.name,
        //     slug: data.slug,
        //     defaultLocale: data.defaultLocale,
        //     schemaMode: data.schemaMode,
        //     project: projectId,
        //     isDefaultSource: data.isDefaultSource,
        //     isPublic: data.isPublic,
        //     isGlobal: data.isGlobal,
        //     // layout: data.layout === '' ? null : data.layout,
        //     kind: gql_EnumDKeySourceKind.Page,
        //     locales: data.locales
        //       ? data.locales
        //           .filter((item) => item.active)
        //           .map((item) => ({
        //             slug: item.slug,
        //             locale: item.locale,
        //           }))
        //       : [],
        //   } as any,
        // })

        const record: any = {
          name: data.name,
          slug: data.slug,
          defaultLocale: data.defaultLocale,
          schemaMode: data.schemaMode,
          project: projectId,
          isDefaultSource: data.isDefaultSource,
          isPublic: data.isPublic,
          isGlobal: data.isGlobal,
          // layout: data.layout === '' ? null : data.layout,
          // kind: data.kind,
          locales: data.locales
            ? data.locales
                .filter((item) => item.active)
                .map((item) => ({
                  slug: item.slug,
                  locale: item.locale,
                }))
            : [],
        }

        const result = await client
          .mutation(
            data.kind === gql_EnumDKeySourceKind.Page
              ? MUTATION_CREATE_PAGE
              : MUTATION_CREATE_SOURCE,
            data.kind === gql_EnumDKeySourceKind.Page
              ? {
                  record: {
                    ...record,
                    layout: data.layout === '' ? null : data.layout,
                  },
                }
              : {
                  record: {
                    ...record,
                    kind: data.kind,
                  },
                }
          )
          .toPromise()

        if (result.error?.message) {
          throw new Error(result.error?.message)
        } else {
          history.push(
            data.kind === gql_EnumDKeySourceKind.Page
              ? `/workbench/${projectId}/sources/${result.data?.pageCreateOne?.record?.id}`
              : `/workbench/${projectId}/sources/${result.data?.sourceCreateOne?.record?.id}`
          )
        }
      } catch (error) {
        toast({
          title: 'Error creating source',
          description: error.message,
          duration: 3000,
          status: 'error',
          isClosable: true,
          variant: 'left-accent',
        })
      } finally {
        setSubmitting(false)
      }
    },
    [client, history, projectId, submitting, toast]
  )

  const handleSectionClick = useCallback((menuItem: ModalMenuItem) => {
    const splittedId = menuItem.id.split('-')
    setSelectedIndex(parseInt(splittedId[splittedId.length - 1], 10))
  }, [])

  const handleSectionMenuClick = useCallback((index: number | number[]) => {
    setSelectedIndex(Array.isArray(index) ? index[0] : index)
  }, [])

  const handleGoToNextSection = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let isCurrentSectionValid = false
      if (selectedIndex === 0) {
        isCurrentSectionValid = await trigger(['schemaMode'])
      }

      console.log(isCurrentSectionValid, 'vediamo che problemi ha')
      if (isCurrentSectionValid) {
        setSelectedIndex(selectedIndex + 1)
      }
    },
    [selectedIndex, trigger]
  )

  const handleCloseDrawer = useCallback(() => {
    if (submitting) {
      return
    }

    onClose && onClose()
  }, [onClose, submitting])

  const handleNewSourceClick = useCallback(
    (kind: gql_EnumDKeySourceKind, schemaMode?: gql_EnumSourceSchemaMode) => () => {},
    []
  )

  return (
    <FormProvider {...methods}>
      <Drawer isOpen={isOpen} placement="left" onClose={handleCloseDrawer} size="xl" {...rest}>
        <DrawerOverlay>
          <Surface as={DrawerContent} lightBgColor="light.50" d="flex" flexDirection="row">
            <MasterDetailMenu
              subtitle="Source settings"
              title="New Source"
              titleIconComponent={<BaseIcon name="RiPagesFill" collection="ri" mr={2} />}
              index={selectedIndex}
              onChange={handleSectionMenuClick}
            >
              {menu.map((item, index) => {
                return (
                  <MasterDetailMenuItem
                    key={`menu-item-${item.id}`}
                    {...item}
                    onClickItem={handleSectionClick}
                  />
                )
              })}
            </MasterDetailMenu>
            <MasterDetailPanel
              as={Surface}
              isActive={true}
              w="65%"
              position="relative"
              d="flex"
              flexDir="column"
              darkBgColor="dark.800"
              lightBgColor="light.50"
            >
              <chakra.form
                display="flex"
                flexDir="column"
                w="100%"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <GradientBox
                  position="absolute"
                  height="100px"
                  width="100%"
                  top={0}
                  left={0}
                  right={0}
                  maxWidth="100%"
                  zIndex="1"
                  lightColors={['light.100', 'transparent']}
                  darkColors={['dark.800', 'transparent']}
                  direction="to-b"
                  pointerEvents="none"
                />
                <BaseButton
                  right="-50px"
                  top={3}
                  position="absolute"
                  boxSize="40px"
                  p={2}
                  onClick={handleCloseDrawer}
                >
                  <BaseIcon name="IoCloseOutline" collection="io5" fontSize="3xl" />
                </BaseButton>
                <DrawerHeader pos="absolute" zIndex="1" left="0" top="0" w="100%">
                  <Flex>
                    <BaseIcon name="HiFolder" collection="hi" fontSize="3xl" mr={2} />
                    <Text fontSize="2xl">Create New Source</Text>
                  </Flex>
                </DrawerHeader>
                <DrawerBody pb={4} pt="80px" zIndex="0" h="100%">
                  {/** Form Body */}
                  <Stack>
                    <Collapse in={selectedIndex === 0}>
                      <SourceBasicInfoFormSection
                        layouts={sourcesResult.data?.sourcePagination?.items || []}
                      />
                    </Collapse>
                    <Collapse in={selectedIndex === 1}>
                      <SourceSlugsFormSection projectId={projectId} />
                    </Collapse>
                    {/* <Collapse in={selectedIndex === 2}>
                      <PageSEOFormSection name="seo" projectId={projectId} />
                    </Collapse> */}
                  </Stack>
                </DrawerBody>
                <DrawerFooter>
                  <BaseButton variant="ghost" mr={3} onClick={handleCloseDrawer}>
                    Cancel
                  </BaseButton>
                  {selectedIndex === 0 ? (
                    <BaseButton
                      color="green.400"
                      lightHue="lighter"
                      darkHue="light"
                      size="lg"
                      onClick={handleGoToNextSection}
                      type="button"
                    >
                      Proceed
                    </BaseButton>
                  ) : (
                    <BaseButton
                      color="green.400"
                      lightHue="lighter"
                      darkHue="light"
                      size="lg"
                      type="submit"
                      isLoading={submitting}
                      disabled={submitting}
                    >
                      Create Source
                    </BaseButton>
                  )}
                </DrawerFooter>
              </chakra.form>
            </MasterDetailPanel>
          </Surface>
        </DrawerOverlay>
      </Drawer>
    </FormProvider>
  )
}

export default CreateSourceDrawer
