import React, { FC, forwardRef, useCallback, useMemo } from 'react'
import {
  Box,
  BoxProps,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useContent } from '../../core/contents/useContent'
import { useGetSourceByIdQuery } from '../../core/graphql'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import FlagIcon from '../base/FlagIcon'
import SourceLanguageMenuItem from './SourceLanguageMenuItem'
import { ID } from '../../core/types/BaseCRUD'
import { QUERY_PARAM_LANG_KEY } from './SourceWorkbench'

type Lang = {
  name: string
  code: 'it' | 'en'
}

type Props = {
  langs?: any[]
  sourceId: ID
  environmentId: ID
} & BoxProps

const SourceLanguageMenu = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, environmentId, sourceId, ...rest } = props
  const [locale, setLocale] = useQueryParam(QUERY_PARAM_LANG_KEY, StringParam)

  const [sourceResult, refetchSource] = useGetSourceByIdQuery({
    variables: { id: sourceId },
    pause: !sourceId,
  })
  const { readContent } = useContent({
    sourceId: sourceId!,
    locale: locale!,
    environmentId: environmentId!,
  })

  const { fetching, data, error } = sourceResult

  const locationFlag = useMemo(
    () => (locale && locale.indexOf('-') !== -1 ? locale.split('-')[1].toLowerCase() : undefined),
    [locale]
  )

  const handleLocaleChange = useCallback(
    (newLocale: string) => {
      setLocale(newLocale)
    },
    [setLocale]
  )

  const styles = useMultiStyleConfig('LangMenu', props)
  return (
    <Menu isLazy>
      <MenuButton
        isLoading={fetching}
        as={BaseButton}
        pr={2}
        pl={3}
        ml={2}
        ref={ref}
        darkBgColor="dark.800"
      >
        <Flex>
          <Text mr={2} opacity="0.6">
            {locale}
          </Text>
          <Box borderRadius="sm" overflow="hidden" d="flex">
            {locationFlag ? <FlagIcon code={locationFlag} /> : <span>ND</span>}
          </Box>
          <BaseIcon name="IoIosArrowDown" collection="io" fontSize="sm" m={0} ml={1} />
        </Flex>
      </MenuButton>
      {data?.sourceById?.locales && data?.sourceById?.defaultLocale && (
        <MenuList>
          <MenuOptionGroup defaultValue="Language" title="Select language">
            <SourceLanguageMenuItem
              locale={data.sourceById.defaultLocale}
              onClick={handleLocaleChange}
            />
            {data.sourceById.locales
              .filter((item) => item?.locale !== data?.sourceById?.defaultLocale)
              .map((item, index: number) => (
                <SourceLanguageMenuItem
                  key={`language-chooser-${item}`}
                  locale={item?.locale!}
                  onClick={handleLocaleChange}
                />
              ))}
          </MenuOptionGroup>
        </MenuList>
      )}
    </Menu>
  )
})

export default SourceLanguageMenu
