import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  AvatarBadge,
  Badge,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { mode } from '@chakra-ui/theme-tools'
import { userInfo } from 'os'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import { useAuthProvider } from '../../../core/auth/useAuthProvider'
import { apolloClient } from '../../../apolloClient'
import { UserIdentity } from '../../../core/auth/UserIdentity'
import Surface from '../../base/Surface'

type Props = {
  showUserInfo?: boolean
  showUserPopover?: boolean
}

const LoggedUserNavbarInfo: FC<Props> = (props) => {
  const { showUserInfo = false, showUserPopover = true } = props
  const authProvider = useAuthProvider()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [identity, setIdentity] = useState<UserIdentity | undefined>()

  const handleLogout = useCallback(async () => {
    await authProvider?.logout()
    await apolloClient.resetStore()
    history.replace('/login')
  }, [authProvider, history])

  const tooltipLabel = useMemo(() => {
    return `${identity && identity.fullName} ${loading || !identity ? 'Loading...' : ''}`
  }, [identity, loading])

  useEffect(() => {
    const fetchIdentity = async () => {
      try {
        setError(false)
        setLoading(true)
        const result = await authProvider?.getIdentity()
        if (result && result.id) {
          setIdentity(result)
        }
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchIdentity()
  }, [])

  return (
    <Flex flexDir="column" alignItems="center">
      <Popover placement="top-start" trigger="hover">
        <PopoverTrigger>
          <Flex>
            <Surface
              justifyContent="center"
              d="flex"
              flexDir="column"
              alignContent="center"
              alignItems="center"
              mb={1}
              borderRadius="full"
            >
              <Avatar
                name={identity?.fullName}
                icon={<BaseIcon name="FaUserAstronaut" collection="fa" />}
                boxSize="40px"
                src={identity?.avatarUrl}
              >
                <AvatarBadge boxSize="0.8em" bg="green.500" minH="0.8em" />
              </Avatar>
            </Surface>
            {showUserInfo && (
              <Text alignSelf="center" ml={3}>
                {identity?.fullName}
              </Text>
            )}
          </Flex>
        </PopoverTrigger>
        {showUserPopover && (
          <Surface as={PopoverContent}>
            {
              // <PopoverArrow />
            }

            <PopoverBody p={5}>
              <Flex>
                <Avatar
                  name={identity?.fullName}
                  icon={<BaseIcon name="FaUserAstronaut" collection="fa" />}
                  boxSize="40px"
                  src={identity?.avatarUrl}
                  mr={3}
                >
                  <AvatarBadge boxSize="0.8em" bg="green.500" minH="0.8em" />
                </Avatar>
                <chakra.div>
                  <Heading fontSize="xl">{identity?.fullName}</Heading>
                  <Text fontSize="sm">{identity?.email}</Text>
                </chakra.div>
              </Flex>
              <Flex mt={2}>
                <BaseButton
                  size="xs"
                  darkBgColor="dark.700"
                  lightBgColor="light.100"
                  // onClick={}
                  cursor="pointer"
                  d="flex"
                >
                  Show profile
                </BaseButton>
                <BaseButton
                  size="xs"
                  variant="ghost"
                  darkBgColor="dark.700"
                  lightBgColor="light.100"
                  onClick={handleLogout}
                  cursor="pointer"
                  d="flex"
                  color="red.400"
                  ml="2"
                >
                  <BaseIcon name="HiOutlineLogout" collection="hi" fontSize="sm" />
                </BaseButton>
              </Flex>
            </PopoverBody>
          </Surface>
        )}
      </Popover>
    </Flex>
  )
}

export default LoggedUserNavbarInfo
