import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { selectedNodeState } from './selectedNodeState'
import { SelectedNode } from '../types/SelectedNode'

export type SetSelectedNodeStateFunction = (newValue: SelectedNode) => void

export const useGetSelectedNodeState = (): SelectedNode => {
  return useRecoilValue(selectedNodeState)
}

export const useSetSelectedNodeState = (): SetSelectedNodeStateFunction => {
  return useSetRecoilState(selectedNodeState)
}

export const useSelectedNodeState = (): [SelectedNode, SetSelectedNodeStateFunction] => {
  return useRecoilState(selectedNodeState)
}
