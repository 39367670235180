import React, { useCallback, useRef } from 'react'
import {
  Flex,
  Text,
  chakra,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
  Portal,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast,
  Tag,
} from '@chakra-ui/react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DeleteIcon } from '@chakra-ui/icons'
import Moment from 'react-moment'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { gql_HySourceFragment, useDeleteSourceMutation } from '../../../core/graphql'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import Status from '../../base/Status'
import Surface from '../../base/Surface'

type Props = {
  source: gql_HySourceFragment
  onClick?: (source: gql_HySourceFragment) => void
  onDeleteComplete?: () => void
}

const SourceCard = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { source, onClick, onDeleteComplete, ...rest } = props
  const toast = useToast()
  const cancelRef = useRef<any>()
  const history = useHistory()
  const { params } = useRouteMatch<{ projectId: string; id: string }>()
  const { id: sourceId, projectId } = params
  const [deleteSourceResult, deleteSource] = useDeleteSourceMutation()
  const { fetching } = deleteSourceResult
  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure()

  const handleOnClick = useCallback(
    (event: any): void => {
      // event.stopPropagation()
      if (onClick) {
        onClick(source)
      }
    },
    [source, onClick]
  )

  const handleDeleteClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation()
      onConfirmDeleteOpen()
    },
    [onConfirmDeleteOpen]
  )

  const handleDeleteConfirm = useCallback(async () => {
    try {
      const result = await deleteSource({
        id: source.id,
      })

      if (result?.error) {
        throw new Error(result.error?.message)
      } else {
        if (onDeleteComplete) {
          await onDeleteComplete()
        }

        onConfirmDeleteClose()
        toast({
          title: 'Source Deleted',
          description: 'Source has been deleted correctly',
          status: 'success',
          duration: 3000,
        })

        if (sourceId === source.id) {
          history.push(`/workbench/${projectId}`)
        }
      }
    } catch (error) {
      toast({
        title: 'Error deleting source',
        description: error.message,
        status: 'error',
        duration: 3000,
      })
      console.error('error deleting source', error)
    }
  }, [deleteSource, history, onConfirmDeleteClose, projectId, source.id, sourceId, toast])

  return (
    <Surface
      display="flex"
      flexDir="column"
      rounded="md"
      shadow="sm"
      onClick={handleOnClick}
      cursor="pointer"
      position="relative"
      ref={ref}
      lightBgColor="white"
      lightBgHoverColor="whiteAlpha.600"
      darkBgColor="dark.600"
      darkBgHoverColor="dark.500"
      minHeight="175px"
      alignItems="flex-end"
      hoverable
      {...rest}
    >
      <Menu>
        <BaseButton
          as={MenuButton}
          size="xs"
          onClick={(e) => e.stopPropagation()}
          darkBgColor="dark.700"
          position="absolute"
          right={2}
          top={2}
        >
          <BaseIcon name="FaEllipsisH" collection="fa" fontSize="xs" />
        </BaseButton>
        <MenuList>
          <MenuItem onClick={handleDeleteClick} icon={<DeleteIcon />}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>

      <Flex
        flexDir="column"
        flex="1"
        px="4"
        my="4"
        bottom="0"
        left="0"
        w="100%"
        justifyContent="flex-end"
      >
        <Surface
          w="100%"
          boxSize="35px"
          alignItems="center"
          justifyContent="center"
          d="flex"
          borderRadius="md"
          mb={2}
          darkBgColor="dark.700"
        >
          <BaseIcon name="HiDocument" collection="hi" fontSize="2xl" opacity="0.5" />
        </Surface>
        {source.isPublic ? (
          <Flex position="absolute" left={3} top={3}>
            <Status />{' '}
            <Text ml={1} fontSize="xs" color="green.500">
              Public
            </Text>
          </Flex>
        ) : (
          <Text d="flex" color="gray.400" fontSize="xs" position="absolute" left={3} top={3}>
            <Status variant="gray" /> Private
          </Text>
        )}

        <Text fontSize="sm" fontWeight="bold" isTruncated noOfLines={2}>
          {source.name}
        </Text>

        <Flex w="100%" fontSize="xs">
          <Text size="sm" m={0} borderRadius="sm" mt={1} fontSize="xs">
            {source.kind}
          </Text>
        </Flex>
      </Flex>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isConfirmDeleteOpen}
        onClose={onConfirmDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Source
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onConfirmDeleteClose}>
                Cancel
              </Button>
              <Button
                disabled={fetching}
                isLoading={fetching}
                colorScheme="red"
                onClick={handleDeleteConfirm}
                ml={3}
              >
                Delete Source
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Surface>
  )
})

export default SourceCard
