import { useCallback } from 'react'
import Container from 'typedi'
import { ClassType } from '../../types/ClassType'
import { MediaCRUD } from './Media'

export const TOKEN_MEDIA_PROVIDER = 'hydrogen_mediaProvider'

type SetMediasProviderFunction = (newValue: ClassType<MediaCRUD> | undefined) => void

export const useMediaProvider = (): MediaCRUD | undefined => {
  return Container.get<MediaCRUD>(TOKEN_MEDIA_PROVIDER)
}

export const useSetMediaProvider = (): SetMediasProviderFunction => {
  return useCallback((newValue: ClassType<MediaCRUD> | undefined) => {
    Container.set({ id: TOKEN_MEDIA_PROVIDER, type: newValue })
  }, [])
}
