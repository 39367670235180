// eslint-disable-next-line import/no-extraneous-dependencies
import { theme } from '@chakra-ui/theme'

const colors = {
  ...theme.colors,
  green: {
    50: '#dfffee',
    100: '#b7f6d5',
    200: '#8defbb',
    300: '#62e7a0',
    400: '#38e186',
    500: '#1ec76d',
    600: '#139b54',
    700: '#1D4446',
    800: '#004322',
    900: '#001807',
  },
  dark: {
    50: '#d0d3e3',
    100: '#b3b5cc',
    200: '#9598b6',
    300: '#777ba0',
    400: '#5e6187',
    500: '#414562',
    600: '#33364d',
    700: '#27283a',
    800: '#232638',
    900: '#1e2030',
  },
  light: {
    50: '#eeeef5',
    100: '#e2e3e8',
    200: '#c7c7d0',
    300: '#b2b4c1',
    400: '#9e9ea7',
    500: '#5e6187',
    600: '#484c69',
    700: '#33364c',
    800: '#2A2C3E',
    900: '#1e2030',
  },
  darkAlpha: {
    50: ' rgba(0,0,0,0.05)',
    100: ' rgba(0,0,0,0.1)',
    200: ' rgba(0,0,0,0.2)',
    300: ' rgba(0,0,0,0.3)',
    400: ' rgba(0,0,0,0.4)',
    500: ' rgba(0,0,0,0.5)',
    600: ' rgba(0,0,0,0.6)',
    700: ' rgba(0,0,0,0.7)',
    800: ' rgba(0,0,0,0.8)',
    900: ' rgba(0,0,0,0.9)',
  },
  lightAlpha: {
    50: ' rgba(245,245,245,0.05)',
    100: ' rgba(245,245,245,0.1)',
    200: ' rgba(245,245,245,0.2)',
    300: ' rgba(245,245,245,0.3)',
    400: ' rgba(245,245,245,0.4)',
    500: ' rgba(245,245,245,0.5)',
    600: ' rgba(245,245,245,0.6)',
    700: ' rgba(245,245,245,0.7)',
    800: ' rgba(245,245,245,0.8)',
    900: ' rgba(245,245,245,0.9)',
  },
}

export default colors
