import React, { FC, isValidElement, ReactElement, ReactNode, useCallback, useMemo } from 'react'
import {
  Badge,
  Box,
  chakra,
  Divider,
  Flex,
  ListItem,
  Tag,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import { JSONSchema7Type, JSONSchema7TypeName } from 'json-schema'
import { AddableNode } from '../../../core/workbench/NodeDelegate'
import BaseIcon from '../../base/BaseIcon'
import BaseButton from '../../base/BaseButton'
import BoxesStack from '../../../old-components/cms/nodes/ShowcaseGridNode/BoxesStack'
import NodeViewIcon from '../../nodes/NodeViewIcon'

type SuggestedNodesItemProps = AddableNode & {
  divider?: ReactElement | null
  as?: any
  onClick: (typeName: JSONSchema7TypeName, path: string, newContent: JSONSchema7Type) => void
}

const SuggestedNodesItem: FC<SuggestedNodesItemProps> = (props) => {
  const { path, typeName, newValue, onClick, node, divider = Divider, as = ListItem } = props
  const styles = useMultiStyleConfig('SuggestedNodes', props)

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(typeName, path, newValue)
    }
  }, [onClick, typeName, path, newValue])

  const propertyName = useMemo(() => {
    if (!path || path === '/') {
      return `Root ${typeName}`
    } else {
      const splitted = path.split('/')
      return `${splitted[splitted.length - 1]}: ${typeName}`
    }
  }, [path, typeName])

  return (
    <>
      <Box as={as} sx={styles.item} onClick={handleClick}>
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          alignSelf="flex-start"
          mr={3}
        >
          <NodeViewIcon icon="GrStackOverflow" collection="gr" />
        </Flex>
        <Flex alignSelf="flex-start" flexDir="column" flex="1">
          <Tag size="sm" mt={1} alignSelf="flex-start" mb={1} colorScheme="green">
            {typeName}
          </Tag>
          <Text>{(newValue as any).component || propertyName}</Text>
        </Flex>
      </Box>
      {isValidElement(divider) && React.cloneElement<any>(divider, {})}
    </>
  )
}

export default SuggestedNodesItem
