import React, { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import BaseIcon from '../base/BaseIcon'
import Surface from '../base/Surface'
import BaseButton from '../base/BaseButton'

type Props = {
  direction?: 'horizontal' | 'vertical'
  onClick?: () => void
} & Record<string, any>

const DragHandle: FC<Props> = (props) => {
  const { direction, onClick, ...rest } = props

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onClick && onClick()
  }

  return (
    <Flex
      position="absolute"
      w={direction == 'horizontal' ? '' : '100%'}
      h={direction == 'horizontal' ? '100%' : ''}
      alignItems="center"
      justifyContent="center"
      zIndex={2}
      cursor={direction == 'horizontal' ? 'col-resize' : 'row-resize'}
      pointerEvents="all"
      {...rest}
    >
      <Surface
        p={1}
        alignItems="center"
        justifyContent="center"
        d="flex"
        rounded="full"
        pointerEvents={!handleMouseDown ? 'none' : 'all'}
        onMouseUp={handleMouseDown}
      >
        <BaseIcon
          name="HiSelector"
          collection="hi"
          fontSize="12.5px"
          transform={direction == 'horizontal' ? 'rotate(90deg)' : ''}
        />
      </Surface>
    </Flex>
  )
}

export default DragHandle
