import { BoxProps, ButtonProps, chakra, Text, useMultiStyleConfig } from '@chakra-ui/react'
import React, { FC, forwardRef, useMemo } from 'react'

type Props = {
  variant?: 'success' | 'primary' | 'error' | 'warning' | 'gray'
} & BoxProps

const Status = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { variant, children, ...rest } = props
  const styles = useMultiStyleConfig('BaseButton', props)

  const variantBgColor = useMemo(() => {
    switch (variant) {
      case 'primary':
        return 'blue.400'
      case 'success':
        return 'green.400'
      case 'error':
        return 'red.400'
      case 'warning':
        return 'yellow.400'
      case 'gray':
        return 'gray.400'
      default:
        return 'green.400'
    }
  }, [variant])

  return (
    <chakra.div
      w="10px"
      h="10px"
      minW="10px"
      bg={variantBgColor}
      borderRadius="full"
      border="1px solid"
      borderColor="blackAlpha.400"
      boxSizing="border-box"
      alignSelf="center"
      mr={1}
      d="inline-block"
      boxShadow="md"
      {...rest}
    />
  )
})

export default Status
