/* eslint-disable no-underscore-dangle */
import { Client } from 'urql'
import { MUTATION_LOGIN, MUTATION_LOGOUT, QUERY_USER_ME } from '../../queries'
import {
  gql_GetUserMeQuery,
  gql_LoginMutation,
  gql_LoginMutationVariables,
  gql_LogoutMutation,
} from '../graphql'
import { AuthProvider } from './AuthProvider'
import { UserIdentity } from './UserIdentity'

export class HydrogenAuthProvider implements AuthProvider {
  urqlClient!: Client

  init(client: Client) {
    this.urqlClient = client
  }

  async login(params: Record<string, any>) {
    try {
      const result = await this.urqlClient
        .mutation<gql_LoginMutation, gql_LoginMutationVariables>(MUTATION_LOGIN, {
          credentials: { email: params.email, password: params.password },
        })
        .toPromise()

      if (result && result.data && result.data.login && result.data.login._id) {
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (e) {
      return Promise.reject()
    }
  }

  async checkError(params: { status?: number }): Promise<void> {
    if (params.status === 401 || params.status === 403) {
      localStorage.removeItem('token')
      return Promise.reject()
    }

    Promise.resolve()
  }

  async checkAuth() {
    const result = await this.urqlClient.query<gql_GetUserMeQuery>(QUERY_USER_ME).toPromise()
    if (result && result.data && result.data.userMe && result.data.userMe._id) {
      return
    }

    throw new Error('Auth error')
  }

  async logout() {
    try {
      const result = await this.urqlClient.mutation<gql_LogoutMutation>(MUTATION_LOGOUT).toPromise()

      if (result && result.data && result.data.logout) {
        // localStorage.setItem('userId', result.data.login.id)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (e) {
      return Promise.reject()
    }
  }

  async getIdentity(): Promise<UserIdentity> {
    const result = await this.urqlClient.query<gql_GetUserMeQuery>(QUERY_USER_ME).toPromise()
    if (result && result.data && result.data.userMe && result.data.userMe._id) {
      return {
        id: result.data.userMe._id,
        email: result.data.userMe.email!,
        // fullName: `${result.data.userMe.firstName || ''} ${result.data.userMe.lastName}`,
        fullName: result.data.userMe.lastName,
        // todo avatarUrl
        // avatarUrl: 'https://pbs.twimg.com/profile_images/745236379228069888/5Zre6uSy_400x400.jpg',
      } as any
    }

    return Promise.reject()
  }
}
