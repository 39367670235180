import { ID } from '../../types/BaseCRUD'
import { useGetOne, UseGetOneResult } from '../../crud/useGetOne'
import { Media } from './Media'
import { useMediaProvider } from './useMediaProvider'

export type UseGetOneMediaOptions = {
  id: ID
  skip?: () => boolean
}

export type UseGetOneMediaResult = UseGetOneResult<Media>

export const useGetOneMedia = ({ id, skip }: UseGetOneMediaOptions): UseGetOneMediaResult => {
  const dataProvider = useMediaProvider()
  if (!dataProvider) {
    throw new Error('MediaProvider not present')
  }

  return useGetOne<Media>({
    id,
    skip,
    dataProvider,
  })
}
