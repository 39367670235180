import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Text,
  propNames,
} from '@chakra-ui/react'
import React, { FC, useCallback, useState } from 'react'
import { uid } from 'react-uid'
import { useNode } from '../../core/workbench/useNode'
import { NodeProps } from '../../core/types/NodeProps'
import NodeView from './NodeView'
import NodeViewListInfo from './NodeViewListInfo'
import ObjectNodeOptions from './ObjectNode/ObjectNodeOptions'
import SchemaNode from './SchemaNode'

type Props = NodeProps

const ArrayNode: FC<Props> = (props) => {
  const { value, path, sourceId, typeName, schema, schemaNodes, schemaPath, onChange } = props
  const { isSelected, selectedNode, toggleSelected } = useNode(props)
  const [expanded, setExpanded] = useState<boolean>(
    !!(
      !path ||
      path === '/' ||
      (selectedNode.path?.startsWith(path) && selectedNode.path.length > path.length)
    )
  )

  const handleNodeClick = useCallback<any>(
    (path: string) => {
      // setExpanded(!isSelected)
      toggleSelected()
    },
    [toggleSelected]
  )

  const onToggleExpanded = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  if (!value || !Array.isArray(value)) {
    return (
      <Alert borderRadius={5} my={2} status="error">
        <AlertIcon />
        <AlertTitle color="black" mr={2}>
          Source Error!
        </AlertTitle>
        <AlertDescription color="black">
          Type {typeof value} is not supported by ArrayNode
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <NodeView
      onValueChange={props.onChange!}
      source={{} as any}
      value={value}
      path={path}
      valueTypeName={typeName}
      isSelected={isSelected}
      onClick={handleNodeClick}
      typeLabel="Array"
      icon="FaListOl"
      infoComponent={<NodeViewListInfo length={value.length} />}
      optionsComponent={<ObjectNodeOptions expanded={expanded} toggleExpand={onToggleExpanded} />}
      p={4}
    >
      {expanded &&
        value
          .filter((i) => !!i)
          .map((item, index) => (
            <SchemaNode
              key={uid(item)}
              // source={source}
              sourceId={sourceId}
              value={item}
              schema={
                schema &&
                typeof schema !== 'boolean' &&
                schema.items &&
                !Array.isArray(schema.items)
                  ? schema.items
                  : undefined
              }
              rootSchema={props.rootSchema}
              path={path === '/' ? path + index : `${path}/${index}`}
              parentPath={path}
              schemaPath={
                schema &&
                typeof schema !== 'boolean' &&
                schema.items &&
                !Array.isArray(schema.items)
                  ? schemaPath === '/'
                    ? '/items'
                    : `${schemaPath}/items`
                  : schemaPath
              }
              parentSchemaPath={schemaPath}
              schemaNodes={schemaNodes}
              onChange={onChange}
            />
          ))}
    </NodeView>
  )
}

export default ArrayNode
