import 'reflect-metadata'
import React, { FC } from 'react'
import { Provider } from 'urql'
import Hydrogen from './components/hydrogen/Hydrogen'
import './App.css'
import 'react-datasheet/lib/react-datasheet.css'
import WorkbenchGroup from './components/workbench/WorkbenchGroup'
import { JsonContentBridge } from './core/contents/JsonContentBridge'
import { JsonSchemaNode } from './core/workbench/JsonSchemaNode'
import { JsonSchemaBridge } from './core/schema/JsonSchemaBridge'
import CMSObjectNode from './old-components/cms/nodes/CMSObjectNode'
// import PreviewEditor from './old-components/workbench/PreviewEditor'
// import ArrayNode from './old-components/workbench/PreviewEditor/nodes/ArrayNode'
// import BooleanNode from './old-components/workbench/PreviewEditor/nodes/BooleanNode'
// import NullNode from './old-components/workbench/PreviewEditor/nodes/NullNode'
// import NumberNode from './old-components/workbench/PreviewEditor/nodes/NumberNode'
// import StringNode from './old-components/workbench/PreviewEditor/nodes/StringNode'
import { HydrogenAuthProvider } from './core/auth/HydrogenAuthProvider'
import { HydrogenContentProvider } from './core/contents/HydrogenContentProvider'
import ProjectsExplorer from './components/projects/ProjectExplorer'
import { HydrogenMediaProvider } from './core/cms/media/HydrogenMediaProvider'
import { HydrogenSchemaProvider } from './core/schema/HydrogenSchemaProvider'

const App: FC = () => {
  return (
    <Hydrogen
      authProvider={HydrogenAuthProvider}
      contentProvider={HydrogenContentProvider}
      schemaProvider={HydrogenSchemaProvider}
      mediaProvider={HydrogenMediaProvider}
      contentBridge={JsonContentBridge}
      schemaBridge={JsonSchemaBridge}
      nodeDelegate={JsonSchemaNode}
    >
      <ProjectsExplorer />
      <WorkbenchGroup />
    </Hydrogen>
  )
}

export default App
