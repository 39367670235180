export const secureJsonParse = (value: string | undefined | null): any => {
  if (!value) {
    return undefined
  }

  try {
    return JSON.parse(value)
  } catch (e) {
    return undefined
  }
}
