import React, { FC, memo, useCallback, useMemo } from 'react'
import {
  BoxProps,
  chakra,
  Collapse,
  Divider,
  Fade,
  Flex,
  HStack,
  Tag,
  Text,
} from '@chakra-ui/react'
import SuggestedNodes from '../sources/SuggestedNodes'
import { gql_HySourceFragment } from '../../core/graphql'

type Props = {
  isOpen: boolean
  source: gql_HySourceFragment
} & BoxProps

const NodeViewSuggestions: FC<Props> = ({ source, isOpen, px = 6, ...rest }) => {
  // TODO: FIX ME
  // const listComponent = useMemo(
  //   () => ({ children }: any) => (
  //     <HStack
  //       spacing={1}
  //       justifyContent="space-between"
  //       shouldWrapChildren={false}
  //       children={children}
  //       px={px}
  //       divider={<Divider orientation="vertical" />}
  //       overflow="scroll"
  //     />
  //   ),
  //   [source]
  // )

  return (
    <chakra.div d="flex" flexDir="column" {...rest}>
      {source && (
        <Collapse in={isOpen}>
          {/* <SuggestedNodes
            listComponent={listComponent}
            source={source}
            divider={<Divider orientation="vertical" />}
            p={0}
            m={0}
          /> */}
        </Collapse>
      )}
    </chakra.div>
  )
}

export default NodeViewSuggestions
