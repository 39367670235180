import { gql } from '@apollo/client/core'
import { Client } from 'urql'
import { Create, Delete, GetList, GetOne, Update } from '../../types/BaseCRUD'
import {
  Media,
  MediaCreateInput,
  MediaCRUD,
  MediaFilters,
  MediaSort,
  MediaUpdateInput,
} from './Media'

const QUERY_GET_ONE_MEDIA = gql`
  query GetOneMedia($id: MongoID!) {
    mediaById(_id: $id) {
      _id
      updatedAt
      createdAt
      name
      mimeType
      alternativeText
      parent
      urlSmall
      urlMedium
      urlLarge
      smallWidth
      smallHeight
      mediumWidth
      mediumHeight
      largeWidth
      largeHeight
    }
  }
`

const QUERY_GET_LIST_MEDIAS = gql`
  query GetListMedias(
    $sort: SortFindManyMediaInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManyMediaInput
  ) {
    mediaPagination(page: $page, perPage: $perPage, filter: $filter, sort: $sort) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        _id
        updatedAt
        createdAt
        name
        mimeType
        alternativeText
        parent
        urlSmall
        urlMedium
        urlLarge
        smallWidth
        smallHeight
        mediumWidth
        mediumHeight
        largeWidth
        largeHeight
      }
    }
  }
`

const MUTATION_CREATE_FILE = gql`
  mutation UploadFile($file: Upload, $record: MediaUploadRecordInput) {
    mediaUpload(file: $file, record: $record) {
      id: _id
      updatedAt
      createdAt
      name
      mimeType
      alternativeText
      parent
      urlSmall
      urlMedium
      urlLarge
      smallWidth
      smallHeight
      mediumWidth
      mediumHeight
      largeWidth
      largeHeight
    }
  }
`

export class HydrogenMediaProvider implements MediaCRUD {
  urqlClient!: Client

  init(client: Client) {
    this.urqlClient = client
  }

  getOne: GetOne<Media> = async (id, options) => {
    const result = await this.urqlClient
      .query(QUERY_GET_ONE_MEDIA, {
        id,
      })
      .toPromise()

    return {
      data: result.data.mediaById,
      loading: false,
    }
  }

  getList: GetList<Media, MediaFilters, MediaSort> = async ({
    filters,
    pagination,
    sort,
    options,
  }) => {
    const { ...derivedFilters } = filters
    const sortKeys = Object.keys(sort)
    const result = await this.urqlClient
      .query(QUERY_GET_LIST_MEDIAS, {
        filter: {
          _id: derivedFilters.id,
          ...derivedFilters,
        },
        page: pagination.offset,
        perPage: pagination.limit,
        sort:
          sortKeys.length > 0
            ? `${sortKeys[0] === 'id' ? '_' : ''}${sortKeys[0].toUpperCase()}_${
                (sort as any)[sortKeys[0]]
              }`
            : undefined,
      })
      .toPromise()

    return {
      // eslint-disable-next-line no-underscore-dangle
      data: result.data.mediaPagination.items.map((item: any) => ({ ...item, id: item._id })),
      loading: false,
      error: result.error,
      total: result.data.mediaPagination.count,
      pagination,
    }
  }

  create: Create<Media, MediaCreateInput> = async (data) => {
    const result = await this.urqlClient
      .mutation(MUTATION_CREATE_FILE, {
        record: {
          name: data.name,
          alternativeText: data.alternativeText,
          parentDirectory: data.parent,
        },
        file: data.file,
      })
      .toPromise()

    return {
      ...result.data.mediaUpload,
    }
  }

  update: Update<Media, MediaUpdateInput> = (id, data) => {
    return {
      ...data,
      description: 'sto cazz',
      id,
      name: 'prova',
      createdAt: new Date(),
      updatedAt: new Date(),
      urlLarge: '',
      urlSmall: '',
      urlMedium: '',
      galleryId: '',
    }
  }

  delete: Delete = (id) => false
}
