import {
  Text,
  Flex,
  InputGroup,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  chakra,
  useRadio,
  HStack,
  useRadioGroup,
  Tag,
  Textarea,
  Switch,
  Spacer,
  Button,
  Alert,
  Heading,
  InputLeftAddon,
  InputRightAddon,
} from '@chakra-ui/react'
import React, { FC, useCallback, useMemo, useState } from 'react'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import Surface from '../../base/Surface'

type ProjectEnvoirmentFormProps = {
  projectData?: any[any]
  register: any
}

const ProjectEnvoirmentForm: FC<ProjectEnvoirmentFormProps> = ({ register, projectData }) => {
  const options = [
    { value: 'nextjs', title: 'Next.JS', icon: 'SiNextDotJs', iconCollection: 'si' },
    { value: 'react', title: 'React', icon: 'SiReact', iconCollection: 'si' },
    { value: 'static', title: 'Static', icon: 'FaHtml5', iconCollection: 'fa' },
    { value: 'email', title: 'Email', icon: 'SiMinutemailer', iconCollection: 'si' },
  ]
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'projectType',
    defaultValue: 'nextjs',
    onChange: console.log,
  })
  const group = getRootProps()
  const envoirmentsList = [
    {
      name: 'Development',
      host: 'localhost:3000',
      isDefault: true,
    },
  ]
  const [envoirments, setEnvoirments] = useState(envoirmentsList)

  const addNewEnvoirment = useCallback(() => {
    envoirments.push({
      name: 'Development',
      host: 'localhost:3000',
      isDefault: true,
    })
    setEnvoirments(envoirments)
  }, [envoirments])

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value == value)
  }, [value])

  return (
    <>
      <Accordion allowMultiple variant="boxed" defaultIndex={0}>
        {envoirments.map((envoirment, key) => {
          return (
            <AccordionItem key={key} mb={4}>
              <AccordionButton p={5}>
                <chakra.div flex="1" textAlign="left" pl={3}>
                  <Heading fontSize="xl">{envoirment.name}</Heading>
                  <Text fontSize="sm" fontWeight="normal">
                    {envoirment.host}
                  </Text>
                </chakra.div>
                {envoirment.isDefault && (
                  <Tag
                    size="md"
                    colorScheme="green"
                    textOverflow="ellipsis"
                    justifySelf="flex-end"
                    mr={3}
                  >
                    Production
                  </Tag>
                )}
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel p={1}>
                <FormControl id="envoirmentUrl" mb={6}>
                  <FormLabel>Host</FormLabel>
                  <InputGroup size="lg">
                    <InputLeftAddon children="https://" />
                    <Input placeholder="mysite" defaultValue={envoirment.host} />
                  </InputGroup>
                </FormControl>
                <FormControl id="envoirmentName" mb={6}>
                  <FormLabel>Name</FormLabel>
                  <InputGroup size="lg">
                    <Input
                      placeholder="Envoirment name"
                      name="envoirmentName"
                      ref={register({ required: false, minLength: 1, maxLength: 50 })}
                      defaultValue={envoirment.name}
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="productionEnvironment">
                  <FormLabel>Is Production</FormLabel>
                  <Switch name="value" colorScheme="green" size="lg" mb="3" />
                </FormControl>
              </AccordionPanel>
            </AccordionItem>
          )
        })}
      </Accordion>
      <Flex mt={6} p="1">
        <BaseButton size="lg" color="green.500" onClick={addNewEnvoirment}>
          <BaseIcon name="HiPlus" collection="hi" fontSize="2xl" mr={2} alignSelf="center" /> New
          envoirment
        </BaseButton>
      </Flex>
    </>
  )
}

export default ProjectEnvoirmentForm
