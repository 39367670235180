import React, { FC, useCallback } from 'react'
import {
  Flex,
  Text,
  Badge,
  chakra,
  Box,
  Accordion,
  Stack,
  AccordionItem,
  AccordionPanel,
  Divider,
  AccordionIcon,
  AccordionButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react'
import Moment from 'react-moment'
import BaseButton from '../../../components/base/BaseButton'
import BaseIcon from '../../../components/base/BaseIcon'
import { fileSizeFormat } from '../../../core/utils/files'

type Props = {
  files: any[]
}

const MediaExplorerImporFiles: FC<Props> = ({ files }) => {
  const formatFileSize = useCallback((size: number) => {
    return fileSizeFormat(size)
  }, [])

  return (
    <chakra.div>
      {files && files.length && <Text>{files.length} dropped files</Text>}

      <Accordion defaultIndex={[0]} allowToggle py={2} variant="boxed">
        <Stack spacing={3}>
          {files.map((file, index) => {
            return (
              <>
                <AccordionItem key={index}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        as={BaseButton}
                        boxShadow="none"
                        px={3}
                        py={4}
                        mx={0}
                        mb={0}
                        isActive={!!isExpanded}
                      >
                        <Box
                          boxSize="60px"
                          ml={4}
                          bgColor="darkAlpha.100"
                          bgImage={`url(${file?.previewUrl})`}
                          bgSize="cover"
                          bgPos="center"
                          borderRadius="md"
                        />
                        <Box flex="1" textAlign="left" pl={3}>
                          <Text fontSize="lg" mb={1}>
                            {file?.name}
                          </Text>
                          <Flex alignItems="center">
                            <Badge fontSize="xs" mr={2}>
                              {formatFileSize(file?.size)}
                            </Badge>
                            <Text fontSize="xs" mr={2}>
                              <Moment fromNow>{file.lastModified}</Moment>
                            </Text>
                            <Button
                              opacity="0.6"
                              colorScheme="red"
                              variant="ghost"
                              size="xs"
                              borderRadius="sm"
                              ml={0}
                            >
                              <BaseIcon name="FaTrashAlt" collection="fa" fontSize="xs" />
                            </Button>
                          </Flex>
                        </Box>
                        <AccordionIcon fontSize="2xl" />
                      </AccordionButton>
                      <AccordionPanel py={4} px={2}>
                        <Stack spacing={5}>
                          <FormControl>
                            <FormLabel>File name</FormLabel>
                            <Input type="text" placeholder="File name" defaultValue={file?.name} />
                          </FormControl>
                          <FormControl>
                            <FormLabel>File description</FormLabel>
                            <Textarea
                              type="text"
                              placeholder="File description"
                              defaultValue={file?.name}
                            />
                          </FormControl>
                        </Stack>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
                {index < files.length - 1 && <Divider zIndex="1" />}
              </>
            )
          })}
        </Stack>
      </Accordion>
    </chakra.div>
  )
}

export default MediaExplorerImporFiles
