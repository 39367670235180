import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import moment from 'moment'
import Surface from '../../base/Surface'
import BaseButton from '../../base/BaseButton'
import BaseIcon from '../../base/BaseIcon'
import SourceHistoryModalItem from './SourceHistoryListItem'
import { useSourceWorkbenchState } from '../../../core/source-workbench/useSourceWorkbenchState'
import GradientBox from '../../base/GradientBox'
import { useGetContentHistoryQuery } from '../../../core/graphql'
import { ID } from '../../../core/types/BaseCRUD'

type Props = Omit<ModalProps, 'children'> & {
  isOpen: boolean
  onClose: () => void
  locale: string
  environmentId: ID
  sourceId: ID
}

const SourceHistoryModal: FC<Props> = (props) => {
  const { isOpen, onClose, environmentId, locale, sourceId, ...rest } = props
  const [sourceWorkbenchState, setSourceWorkbenchState] = useSourceWorkbenchState()
  const styles = useMultiStyleConfig('SourceHistoryModal', props)
  const [filter, setFilter] = useState('drafts')
  const [selected, setSelected] = useState(0)
  const [{ fetching, data, error }, refetchHistory] = useGetContentHistoryQuery({
    variables: {
      payload: {
        sourceId,
        environmentId,
        locale,
      },
    },
  })

  const handleClose = useCallback(() => {
    setSourceWorkbenchState({
      ...sourceWorkbenchState,
      showHistory: false,
    })
    onClose()
  }, [onClose, setSourceWorkbenchState, sourceWorkbenchState])

  const handleSelect = useCallback((selected) => {
    setSelected(selected)
  }, [])

  const history = useMemo(
    () => [
      {
        date: new Date(),
        addCount: 10,
        removeCount: 50,
      },
      {
        date: moment().add(1, 'h'),
        addCount: 33,
        removeCount: 20,
      },
      {
        date: moment().add(1, 'd'),
        addCount: 8,
        removeCount: 32,
      },
      {
        date: moment().add(1, 'w'),
        addCount: 1,
        removeCount: 3,
      },
      {
        date: moment().add(1, 'y'),
        addCount: 93,
        removeCount: 12,
      },
    ],
    []
  )

  return (
    <Modal
      motionPreset="scale"
      size="full"
      onClose={handleClose}
      isOpen={sourceWorkbenchState.showHistory}
      isCentered
      {...rest}
    >
      <ModalOverlay />
      <Surface
        as={ModalContent}
        darkVariant="heavy"
        lightBgColor="light.100"
        boxShadow="lg"
        overflow="hidden"
        d="flex"
        flexDir="row"
        justifyContent="stretch"
        alignContent="stretch"
        m={0}
      >
        <ModalBody flexDir="column" position="relative" w="70%" p={9}>
          <GradientBox
            position="absolute"
            height="200px"
            width="100%"
            top={0}
            left={0}
            right={0}
            maxWidth="100%"
            zIndex="1"
            lightColors={['light.200', 'transparent']}
            darkColors={['dark.900', 'transparent']}
            direction="to-b"
          />

          <ModalHeader pos="absolute" zIndex="1" left="0" top="0" d="flex" w="100%">
            <chakra.div w="100%">
              <Flex>
                <BaseIcon name="BsClockHistory" collection="bs" fontSize="2xl" mr={3} />
                <Flex flexDir="column">
                  <Text fontSize="2xl">History Preview</Text>
                  <Text fontSize="xs">1 versions</Text>
                </Flex>
              </Flex>
              <pre>{JSON.stringify(data?.contentHistoryOne, null, 2)}</pre>
            </chakra.div>
          </ModalHeader>
        </ModalBody>
        <Surface
          w="30%"
          position="relative"
          d="flex"
          flexDir="column"
          darkBgColor="dark.700"
          lightBgColor="light.50"
        >
          <ModalHeader zIndex="1" left="0" top="0" w="100%">
            <Text d="flex" cursor="pointer" fontSize="sm" opacity="0.6">
              History
            </Text>
            <Text fontSize="xl">History list</Text>
          </ModalHeader>
          <ModalCloseButton
            as={BaseButton}
            variant="solid"
            size="lg"
            top={3}
            right={3}
            zIndex={9}
          />
          <Stack sx={styles.list} p={6} h="100%">
            {history.map((item, index) => {
              return (
                <SourceHistoryModalItem
                  key={index}
                  item={item}
                  index={index}
                  selected={selected}
                  onSelect={handleSelect}
                />
              )
            })}
          </Stack>
          <ModalFooter position="sticky" bottom="0">
            <BaseButton variant="ghost" mr={3}>
              Cancel
            </BaseButton>
            <BaseButton variant="ghost" colorScheme="green" size="lg" lightHue="light">
              Select
              <BaseIcon name="BiUpload" collection="bi" ml={2} fontSize="xl" />
            </BaseButton>
          </ModalFooter>
        </Surface>
      </Surface>
    </Modal>
  )
}

export default SourceHistoryModal
