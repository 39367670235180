import React, { FC } from 'react'
import { NodeProps } from '../../../core/types/NodeProps'
import NodeView from '../NodeView'
import { useNode } from '../../../core/workbench/useNode'
import BooleanNodeInfo from './BooleanNodeInfo'

type Props = NodeProps

const BooleanNode: FC<Props> = (props) => {
  const { path, value, typeName } = props
  const { isSelected, toggleSelected } = useNode(props)
  return (
    <NodeView
      onValueChange={props.onChange!}
      source={{} as any}
      path={path}
      value={value}
      valueTypeName={typeName}
      isSelected={isSelected}
      onClick={toggleSelected}
      typeLabel="Boolean"
      icon="FaToggleOn"
      infoComponent={<BooleanNodeInfo />}
      p={4}
    />
  )
}

export default BooleanNode
