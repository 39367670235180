import React, { FC, useCallback } from 'react'
import {
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'
import BaseButton from '../base/BaseButton'
import BaseIcon from '../base/BaseIcon'
import { ID } from '../../core/types/BaseCRUD'
import { useGetListEnvironmentsQuery } from '../../core/graphql'
import SuggestedNodes from './SuggestedNodes'

type Props = {
  sourceId: ID
  projectId: ID
  locale: string
  environmentId: ID
  [prop: string]: any
}

const SuggestionsPopover: FC<Props> = ({ sourceId, projectId, locale, environmentId }) => {
  const [envResult, refetchEnv] = useGetListEnvironmentsQuery({
    variables: { filter: { project: projectId } },
  })
  const { data: envData, fetching: fetchingEnv, error: envError } = envResult
  const initialFocusRef = React.createRef<HTMLInputElement>()

  if (fetchingEnv) {
    return (
      <BaseButton
        boxSize="40px"
        size="md"
        disabled
        isLoading
        alignSelf="center"
        // onClick={(event: any): void => {}}
      />
    )
  }

  return (
    <Popover
      placement="bottom-start"
      trigger="hover" // non va l'auto focus
      isLazy
      initialFocusRef={initialFocusRef}
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <BaseButton lightBgColor="light.50" boxShadow="sm">
          <BaseIcon fontSize="sm" name="HiViewGrid" collection="hi" />
        </BaseButton>
      </PopoverTrigger>
      <PopoverContent d="flex" justifyContent="center" boxShadow="md">
        <PopoverHeader fontWeight="semibold" border="none">
          Suggestions
          <Input
            placeholder="Search node"
            size="sm"
            mb={2}
            shadow="sm"
            ref={initialFocusRef}
            mt={2}
          />
        </PopoverHeader>
        {/* <PopoverCloseButton /> */}
        <PopoverBody overflow="scroll" h="100%" maxHeight="450px">
          <SuggestedNodes
            locale={locale}
            environmentId={environmentId}
            projectId={projectId}
            sourceId={sourceId}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default SuggestionsPopover
