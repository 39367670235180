import { JSONSchema7Definition } from 'json-schema'
import { isConstant } from './isConstant'

export function isSelect(schema: JSONSchema7Definition, rootSchema = {}) {
  if (typeof schema !== 'boolean' && schema) {
    const altSchemas = schema.oneOf || schema.anyOf
    if (Array.isArray(schema.enum)) {
      return true
    } else if (Array.isArray(altSchemas)) {
      return altSchemas.every((altSchemas) => isConstant(altSchemas))
    }
  }

  return false
}
