import { JSONSchema7Definition, JSONSchema7TypeName } from 'json-schema'

export const getSchemaTypeName = (
  schema?: JSONSchema7Definition
): JSONSchema7TypeName | JSONSchema7TypeName[] | undefined => {
  if (!schema) {
    return 'null'
  }

  if (typeof schema === 'boolean') {
    return 'boolean'
  }

  if (schema.type) {
    return schema.type
  }

  if (schema.properties) {
    return 'object'
  }

  if (schema.enum) {
    return 'string'
  }

  // TODO: guess type

  return undefined
}
