import React, { FC, useMemo } from 'react'
import {
  Box,
  BoxProps,
  useColorMode,
  useMultiStyleConfig,
  useStyleConfig,
  useTheme,
} from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

type GradientBoxProps = {
  lightColors?: string[]
  darkColors?: string[]
  direction?: 'to-t' | 'to-tr' | 'to-r' | 'to-br' | 'to-b' | 'to-bl' | 'to-l' | 'to-tl'
} & BoxProps

const GradientBox: FC<GradientBoxProps> = (props) => {
  const {
    lightColors = ['light.100', 'transparent'],
    darkColors = ['dark.800', 'transparent'],
    direction = 'to-b',
    ...rest
  } = props
  const theme = useTheme()
  const styles = useStyleConfig('GradientBox', props)
  const { colorMode } = useColorMode()

  const defaultStartColor =
    colorMode == 'dark' ? theme.colors.dark[800] : theme.colors.lightAlpha[700]

  const colorsList = useMemo(() => {
    return lightColors || darkColors
      ? mode(lightColors, darkColors)({ colorMode }).join(',')
      : [defaultStartColor, 'transparent']
  }, [lightColors, darkColors, colorMode])

  return <Box bgGradient={`linear(${direction}, ${colorsList})`} __css={styles} {...rest} />
}

export default GradientBox
