import { useCallback, useEffect, useRef } from 'react'
import { RecoilState, useRecoilState } from 'recoil'
import { ID } from '../types/BaseCRUD'
import { Content } from '../types/Content'
import { contentStateFamily } from './contentStateFamily'
import { useContentProvider } from './useContentProvider'

export type UseContentResult<T> = Content<T> & {
  readContent: () => Promise<void>
  writeContent: () => Promise<void>
  setValue: (value: T) => void
  undo: () => void
}

export type UseContentOptions = {
  sourceId: ID
  projectId?: ID
  environmentId: ID
  locale?: string
  atomFamily?: (sourceId: ID) => RecoilState<Content<any>>
}

export const useContent = <T = string>({
  sourceId,
  projectId,
  locale,
  environmentId,
  atomFamily = contentStateFamily,
}: UseContentOptions): UseContentResult<T> => {
  if (!sourceId) {
    throw new Error('sourceId not provided')
  }

  const contentProvider = useContentProvider()
  if (!contentProvider) {
    throw new Error('ContentProvider not present')
  }
  const lastEnvironmentId = useRef(environmentId)

  const [contentState, setContentState] = useRecoilState(atomFamily(sourceId))

  const readContent = useCallback(async () => {
    // console.log(locale, 'mò leggo')
    try {
      setContentState({ ...contentState, reading: true })
      const value = await contentProvider.read(sourceId, projectId, environmentId, locale)
      setContentState({
        ...contentState,
        sourceId,
        locale,
        initialized: true,
        originalValue: value,
        environmentId,
        value,
        reading: false,
      })
    } catch (error) {
      setContentState({
        ...contentState,
        initialized: true,
        error,
        errorMessage: error.message,
        reading: false,
      })
    }
  }, [setContentState, contentState, contentProvider, sourceId, projectId, environmentId, locale])

  const writeContent = useCallback(async () => {
    try {
      setContentState({ ...contentState, writing: true })
      const { success, error, errorMessage } = await contentProvider.write(
        sourceId,
        locale || '',
        environmentId!,
        contentState.value
      )
      if (success) {
        setContentState({
          ...contentState,
          writing: false,
          modified: false,
          locale,
          originalValue: contentState.value,
        })
      } else {
        setContentState({
          ...contentState,
          writing: false,
          error,
          errorMessage,
        })
      }
    } catch (error) {
      setContentState({
        ...contentState,
        writing: false,
        error,
        errorMessage: error.message,
      })
    }
  }, [setContentState, contentState, contentProvider, sourceId, locale, environmentId])

  const setValue = useCallback(
    (value: T) => {
      setContentState({
        ...contentState,
        modified: true,
        value,
      })
    },
    [contentState, setContentState]
  )

  const undo = useCallback(() => {
    setContentState({
      ...contentState,
      value: contentState.originalValue,
    })
  }, [contentState, setContentState])

  useEffect(() => {
    if (!contentState.initialized && !contentState.reading) {
      readContent()
    }
  }, [contentState.initialized, contentState.reading, readContent])

  useEffect(() => {
    if (
      (sourceId !== contentState.sourceId ||
        locale !== contentState.locale ||
        environmentId !== lastEnvironmentId.current) &&
      !contentState.reading
    ) {
      lastEnvironmentId.current = environmentId
      console.log(
        'preso',
        sourceId,
        contentState.sourceId,
        environmentId,
        lastEnvironmentId.current,
        locale,
        contentState.locale
      )
      readContent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, locale, environmentId])

  // useEffect(() => {
  //   if (
  //     contentState.initialized &&
  //     contentState.sourceId &&
  //     locale &&
  //     contentState?.locale &&
  //     contentState.locale !== locale &&
  //     !contentState.reading
  //   ) {
  //     console.log('locale cambiato?', contentState.locale, locale)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [locale])

  return { ...contentState, readContent, writeContent, setValue, undo }
}
