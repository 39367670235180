import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { chakra, Stack, Divider } from '@chakra-ui/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import * as locale from 'locale-codes'
import LangSelectButton from './LangSelectButton'
import { useGetProjectByIdQuery } from '../../../core/graphql'
import Loading from '../../base/Loading'
import LanguageSlugField from './LanguageSlugField'
import { ID } from '../../../core/types/BaseCRUD'

type Props = {
  name?: string
  projectId?: ID
  sourceId?: ID
}

const SourceSlugsFormSection: FC<Props> = ({ name = 'locales', projectId }) => {
  const [projectResult, refetchProject] = useGetProjectByIdQuery({
    variables: { id: projectId },
  })
  const { fetching, data } = projectResult

  const [defaultLocale, setDefaultLocale] = useState<string | undefined>()

  const { control, getValues, setValue } = useFormContext()
  const { fields, insert, append, remove } = useFieldArray({ control, name })

  const handleSetAsDefault = useCallback(
    (locale: string) => {
      const values = getValues()
      const foundedLocaleIndex = values.locales.findIndex((item: any) => item.locale === locale)
      if (foundedLocaleIndex !== -1) {
        setDefaultLocale(values.locales[foundedLocaleIndex].locale)
        setValue('defaultLocale', values.locales[foundedLocaleIndex].locale)
        setValue('slug', values.locales[foundedLocaleIndex].slug || '')
        remove(foundedLocaleIndex)
        append({
          slug: values.slug,
          locale: values.defaultLocale,
          active: true,
        })
        // console.log(
        //   'faccio cose',
        //   values.defaultLocale,
        //   values.slug,
        //   values.locales[foundedLocaleIndex],
        //   foundedLocaleIndex
        // )
      }
    },
    [append, getValues, remove, setValue]
  )

  useEffect(() => {
    if (data?.project?.defaultLocale && !defaultLocale) {
      setDefaultLocale(data.project.defaultLocale)
    }
  }, [data?.project?.defaultLocale, defaultLocale])

  // useEffect(() => {
  //   if (
  //     fields.length === 0 &&
  //     data &&
  //     data.project &&
  //     data.project.locales &&
  //     data.project.locales.length > 0
  //   ) {
  //     const filteredLocales = data.project.locales.filter(
  //       (item) => item !== data.project?.defaultLocale
  //     )

  //     append(
  //       filteredLocales.map((locale) => ({
  //         slug: '',
  //         locale,
  //         active: true,
  //       }))
  //     )
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, fields.length])

  if (fetching) {
    return <Loading />
  }

  return (
    <chakra.div>
      <Stack spacing={5} divider={<Divider />}>
        {defaultLocale && (
          <LanguageSlugField
            projectUrl={data?.project?.settings.livePreviewOrigin || ''}
            tag={defaultLocale}
            localeInputName="defaultLocale"
            slugInputName="slug"
            isDefault
          />
        )}
        {fields.map((item, index) => {
          return (
            <LanguageSlugField
              key={`source-locale-${item.locale}`}
              projectUrl={data?.project?.settings.livePreviewOrigin || ''}
              tag={item.locale}
              slugInputName={`${name}[${index}].slug`}
              localeInputName={`${name}[${index}].locale`}
              activeInputName={`${name}[${index}].active`}
              onSetAsDefault={handleSetAsDefault}
            />
          )
        })}
        {/* {fields.map((lang, index) => (
          <>
            <Collapse in={lang.active} unmountOnExit>
              <FormControl id={`sourceSlug-${lang.name}`} key={index}>
                <FormLabel d="flex" px={0} mx={0} alignItems="center">
                  <FlagIcon code={lang.code} />
                  <Text fontWeight="bold" textTransform="capitalize" ml={2}>
                    {lang.name}
                  </Text>
                  <Tag ml={1} colorScheme="green">
                    Active
                  </Tag>
                  {lang.isDefault && (
                    <Tag colorScheme="blue" ml={1}>
                      Default
                    </Tag>
                  )}
                  <Spacer w="100%" />
                  <Switch
                    size="lg"
                    colorScheme="green"
                    isDisabled={lang.isDefault}
                    onClick={() => toggleLang(lang)}
                    isChecked={lang.active}
                  />
                </FormLabel>
                <InputGroup size="md">
                  <InputLeftAddon>Project Name</InputLeftAddon>
                  <Input name="slug" placeholder="source slug" />
                </InputGroup>
                <FormHelperText>The source slug rules (A-Z,0-9, no spaces)</FormHelperText>
                {!lang.isDefault && (
                  <Flex mt={4} alignItems="center">
                    <Text mr={2}>Set as default language</Text>
                    <Switch
                      size="sm"
                      onClick={() => setDefaultLang(lang)}
                      isChecked={lang.isDefault}
                    />
                  </Flex>
                )}
              </FormControl>
            </Collapse>
            <Collapse in={!lang.active} unmountOnExit>
              <chakra.div py={0} d="flex" alignItems="center">
                <FlagIcon code={lang.code} />
                <Text fontWeight="bold" textTransform="capitalize" ml={2}>
                  {lang.name}
                </Text>
                <Tag ml={1}>Disabled</Tag>
                <Spacer />
                <Switch size="lg" onClick={(event) => toggleLang(lang)} checked={lang.active} />
              </chakra.div>
            </Collapse>
          </>
        ))} */}
      </Stack>
      <chakra.div mt={5}>
        <LangSelectButton label="Add" color="green.500" />
      </chakra.div>
    </chakra.div>
  )
}

export default SourceSlugsFormSection
