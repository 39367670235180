import { useCallback } from 'react'
import Container from 'typedi'
import { ClassType } from '../types/ClassType'
import { ComponentsCRUD } from '../types/Component'

export const TOKEN_COMPONENTS_PROVIDER = 'hydrogen_componentsProvider'

type SetComponentsProviderFunction = (newValue: ClassType<ComponentsCRUD> | undefined) => void

export const useComponentsProvider = (): ComponentsCRUD | undefined => {
  return Container.get<ComponentsCRUD>(TOKEN_COMPONENTS_PROVIDER)
}

export const useSetComponentsProvider = (): SetComponentsProviderFunction => {
  return useCallback((newValue: ClassType<ComponentsCRUD> | undefined) => {
    Container.set({ id: TOKEN_COMPONENTS_PROVIDER, type: newValue })
  }, [])
}
