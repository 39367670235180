import { JSONSchema7Definition } from 'json-schema'
import { computeRequired } from './computeRequired'
import { defaultValues } from './defaultValues'
import { getSchemaTypeName } from './getSchemaTypeName'

export const computeDefaultValue = (schema: JSONSchema7Definition): any => {
  const typeName = getSchemaTypeName(schema)

  if (typeName === 'object') {
    const newObj: any = {}
    computeRequired(schema, newObj)
    const keys = Object.keys((schema as any).properties)
    for (const key of keys) {
      newObj[key] = computeDefaultValue((schema as any).properties[key])
    }

    return newObj
  }

  console.log('calcolo default', typeName)
  if (typeName) {
    return defaultValues[Array.isArray(typeName) ? typeName[0] : typeName]
  }

  return null
}
