import React, { FC, useCallback, useEffect } from 'react'
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Box,
  Text,
  Badge,
  Button,
  Flex,
  chakra,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from '@chakra-ui/react'
import Moment from 'react-moment'
import { useForm } from 'react-hook-form'
import { Media } from '../../../core/cms/media/Media'
import BaseIcon from '../../../components/base/BaseIcon'
import BaseButton from '../../../components/base/BaseButton'

type FormData = {
  name: string
  alternativeText?: string
}

type Props = {
  record?: Media
  onSubmit: (record: Media, data: FormData) => void
  onCancel: () => void
}

const MediaForm: FC<Props> = ({ record, onSubmit: onSubmitProp, onCancel }) => {
  const { register, setValue, handleSubmit } = useForm()

  const onSubmit = useCallback(
    (data: FormData) => {
      onSubmitProp(record!, data)
    },
    [onSubmitProp, record]
  )

  useEffect(() => {
    setValue('name', record?.name)
    setValue('alternativeText', record?.alternativeText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)}>
      <ModalBody p={5} flex="1">
        <ModalCloseButton as={BaseButton} variant="solid" size="lg" top={3} right={3} zIndex={9} />
        <chakra.div display="flex" px={3} py={4} mx={0} mb={0}>
          <Box
            boxSize="60px"
            bgColor="darkAlpha.100"
            bgImage={`url(${record?.urlSmall})`}
            bgSize="cover"
            bgPos="center"
            borderRadius="md"
          />
          <Box flex="1" textAlign="left" pl={3}>
            <Text fontSize="lg" mb={1}>
              {record?.name}
            </Text>
            <Flex alignItems="center">
              <Badge fontSize="xs" mr={2} textTransform="none">
                {`${record?.smallWidth}x${record?.smallHeight}`}
              </Badge>
              <Badge fontSize="xs" mr={2} textTransform="none">
                {`${record?.mediumWidth}x${record?.mediumHeight}`}
              </Badge>
              <Badge fontSize="xs" mr={2} textTransform="none">
                {`${record?.largeWidth}x${record?.largeHeight}`}
              </Badge>
              <Text fontSize="xs" mr={2}>
                <Moment fromNow>{record?.updatedAt}</Moment>
              </Text>
              {/* <Button
              opacity="0.6"
              colorScheme="red"
              variant="ghost"
              size="xs"
              borderRadius="sm"
              ml={0}
            >
              <BaseIcon name="FaTrashAlt" collection="fa" fontSize="xs" />
            </Button> */}
            </Flex>
          </Box>
        </chakra.div>
        <Stack spacing={5} py={4} px={2}>
          <FormControl>
            <FormLabel>File name</FormLabel>
            <Input
              name="name"
              ref={register({ required: true })}
              type="text"
              placeholder="File name"
            />
          </FormControl>
          <FormControl>
            <FormLabel>File description</FormLabel>
            <Textarea
              name="alternativeText"
              ref={register}
              type="text"
              placeholder="File description"
            />
          </FormControl>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <BaseButton variant="ghost" size="lg" mr={3} onClick={onCancel}>
          Cancel
        </BaseButton>
        <Button type="submit" color="green.500" size="lg" lightHue="light">
          Save
        </Button>
      </ModalFooter>
    </chakra.form>
  )
}

export default MediaForm
